import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withTranslation } from 'react-i18next';
import ChipInput from 'material-ui-chip-input';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 10,
    minWidth: 140,
    maxWidth: 300,
    display: 'inline-flex',
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
  },
}));

function SimpleSelect(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState([]);

  if (props.isEdit) {
    if (props.invoiceDeliveryMethod === 'Email') {
      return (
        <div>
          <Autocomplete
            style={{
              margin: 10,
              minWidth: 300,
              maxWidth: 300,
              display: 'inline-flex',
            }}
            id="combo-box-demo"
            defaultValue={props.invoiceDeliveryMethod}
            options={props.options}
            onChange={(event, newValue) => {
              props.callbackValue(props.dbid, newValue);
              setValue(newValue);
            }}
            getOptionLabel={(option) => option}
            className={classes.formControl}
            renderInput={(params) => (
              <TextField
                {...params}
                label={props.t(props.label)}
                required={props.required}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: `new-password`,
                }}
              />
            )}
          />
          <ChipInput
            classes={{
              root: 'h-56',
              input: 'mg-top-20',
              chipContainer: 'mg-top-20',
            }}
            style={{
              margin: '2px 10px',
              minWidth: 300,
              display: 'inline-flex',
            }}
            defaultValue={props.invoiceEmail}
            onChange={(chips) => props.callbackValueText(chips, 'invoiceEmail')}
            placeholder={props.t(props.emailLabel)}
            required={true}
          />
        </div>
      );
    } else {
      return (
        <Autocomplete
          style={{
            margin: 10,
            minWidth: 300,
            maxWidth: 300,
            display: 'inline-flex',
          }}
          id="combo-box-demo"
          defaultValue={props.invoiceDeliveryMethod}
          options={props.options}
          onChange={(event, newValue) => {
            props.callbackValue(props.dbid, newValue);
            setValue(newValue);
          }}
          getOptionLabel={(option) => props.t(option)}
          className={classes.formControl}
          renderInput={(params) => (
            <TextField
              {...params}
              label={props.t(props.label)}
              required={props.required}
              inputProps={{
                ...params.inputProps,
                autoComplete: `new-password`,
              }}
            />
          )}
        />
      );
    }
  } else {
    if (props.invoiceDeliveryMethod === 'Email') {
      return (
        <div>
          <Autocomplete
            style={{
              margin: 10,
              minWidth: 300,
              maxWidth: 300,
              display: 'inline-flex',
            }}
            id="combo-box-demo"
            defaultValue={props.invoiceDeliveryMethod}
            options={props.options}
            onChange={(event, newValue) => {
              props.callbackValue(props.dbid, newValue);
              setValue(newValue);
            }}
            getOptionLabel={(option) => props.t(option)}
            className={classes.formControl}
            renderInput={(params) => (
              <TextField
                {...params}
                label={props.t(props.label)}
                required={props.required}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: `new-password`,
                }}
              />
            )}
          />
          <ChipInput
            classes={{
              root: 'h-56',
              input: 'mg-top-20',
              chipContainer: 'mg-top-20',
            }}
            key={props.key2}
            style={{
              margin: '2px 10px',
              minWidth: 300,
              display: 'inline-flex',
            }}
            defaultValue={[]}
            onChange={(chips) => {
              props.callbackValueText(chips, 'invoiceEmail')
              setValue(chips);
            }}
            placeholder={props.t(props.emailLabel)}
            required={props.required}
          />
        </div>
      );
    } else {
      return (
        <Autocomplete
          style={{
            margin: 10,
            minWidth: 300,
            maxWidth: 300,
            display: 'inline-flex',
          }}
          id="combo-box-demo"
          defaultValue={props.invoiceDeliveryMethod}
          options={props.options}
          onChange={(event, newValue) => {
            props.callbackValue(props.dbid, newValue);
            setValue(newValue);
          }}
          getOptionLabel={(option) => props.t(option)}
          className={classes.formControl}
          renderInput={(params) => (
            <TextField
              {...params}
              label={props.t(props.label)}
              required={props.required}
              inputProps={{
                ...params.inputProps,
                autoComplete: `new-password`,
              }}
            />
          )}
        />
      );
    }
  }
}

export default withTranslation('common')(SimpleSelect);
