import { FILTER_DATA, GET_STOCKS, RESET } from "../actions/stocks";

const initialState = {
  stocks: [],
  totalBales: 0,
  totalValue: 0,
  totalWeight: 0
};

const stocks = function (state = initialState, action) {
  switch (action.type) {
    case RESET: {
      return {
        ...state,
        stocks: [],
      };
    }
    case GET_STOCKS: {
      return {
        ...state,
        stocks: action.payload.orders,
        totalBales: action.payload.overallBaleCount,
        totalValue: action.payload.overallValue,
        totalWeight: action.payload.overallWeight,
      };
    }
    case FILTER_DATA: {
      return {
        ...state,
        totalBales: action.payload.baleCount,
        totalValue: action.payload.totalValue,
        totalWeight: action.payload.totalWeight,
      }
    }
    default: {
      return state;
    }
  }
};

export default stocks;
