import React from 'react';
import { getGermanNumber, getLanguageAcronym } from '../../helpers/documents';
import { getApiUrl } from '../../util/environment-utils';
import moment from 'moment';

import './contract.css';
import './bootstrap.min.css';
import Footer from './footer';
import { formatNumberToEn } from '../../helpers/common';

class ContractDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      article: {
        basalAnnexIX: null,
        euWasteList: null,
      },
      createdAt: new Date(),
      company: null,
      shipperCompany: null,
      employees: null,
      bank: null,
      companyBank: null,
      buyerPartner: null,
      consigneePartner: null,
      endUserPartner: null,
      notifyPartner: null,
      shipperPartner: null,
      currentEntity: null,
      apiUrl: getApiUrl(),
    };
  }

  componentWillMount() {
    let data = this.props.data.bankDetails.BankFound;
    this.setState({
      bank: data,
      company: this.props.data.company.allCompanys[0]
        ? this.props.data.company.allCompanys[0]
        : null,
      shipperCompany: this.props.data.company.allCompanys[0]
        ? this.props.data.company.allCompanys[0]
        : null,
      employee: this.props.commercials,
      companyBank: this.props.data.company.allCompanys[0]
        ? this.props.data.company.allCompanys[0].bankId[0]
        : null,
      buyerPartner: this.props.data.buyerPartner.PartnerFound || {},
      currentEntity: this.props.data.currentEntity,
    });
  }

  getTotal = () => {
    let total = 0;
    (this.state.currentEntity.articles || []).map((_a) => {
      total +=
        parseFloat(formatNumberToEn(_a.weight_per_load)) * parseFloat(formatNumberToEn(_a.price));
    });
    return getGermanNumber(total);
  };

  getLanguage = () => {
    let defaultLanguage = 'de';
    let language = this.props.sourceData
      ? this.props.sourceData
        ? this.props.sourceData.documentLanguage
        : defaultLanguage
      : defaultLanguage;

    return getLanguageAcronym(language);
  };
  getPercentage = () => {
    let percentage = 0;
    percentage =
      (formatNumberToEn(this.getTotal()) *
        parseInt(this.state.currentEntity.paymentTerm.replace('%', ''))) /
      100;

    return percentage;
  };

  render() {
    return (
      <div className={'doc-annex contract_doc'}>
        <section className="_margin-top-30">
          <div className="container row-margin">
            <div className="row">
              <div className="column-12 col-align">
                <img
                  src={`${this.state.apiUrl}/companys/image/${
                    (this.state.company || {}).companyLogo
                  }`}
                  alt="img"
                  className="img-fluid width-195 contract-logo-width"
                />
              </div>
            </div>
            <div className="row">
              <div className="column-12">
                <h6 className="contract-font-size-12 contract-h6-margin-bottom">
                  {(this.state.company || {}).name1}, {(this.state.company || {}).address}
                </h6>
              </div>
            </div>
            <div className="row">
              <div className="column-12">
                <p className="p-margin contract-font-size-13">
                  {(this.state.buyerPartner || {}).name1}
                </p>
                <p className="p-margin contract-font-size-13">
                  {(this.state.buyerPartner || {}).street}{' '}
                  {(this.state.buyerPartner || {}).houseNumber}
                </p>

                <p className="p-margin contract-font-size-13">
                  {' '}
                  {(this.state.buyerPartner || {}).zipcode} {(this.state.buyerPartner || {}).city}
                </p>

                <p className="p-margin contract-font-size-13">
                  {' '}
                  {(this.state.buyerPartner || {}).country}
                </p>
                <p className="p-margin contract-font-size-13 contract-h6-margin-bottom">
                  {' '}
                  VAT: {(this.state.buyerPartner || {}).taxID || ''}
                </p>
              </div>
            </div>
            <div className="row  margin-top-22">
              <div className="column-3">
                <p className="p-margin contract-font-size-13">Contact Person:</p>
              </div>
              <div className="column-9">
                <p className="p-margin contract-font-size-13">
                  {this.state.currentEntity.buyerContactPerson !== undefined
                    ? `${this.state.currentEntity.buyerContactPerson.salutation} ${this.state.currentEntity.buyerContactPerson.firstName} ${this.state.currentEntity.buyerContactPerson.surname}`
                    : 'N/A'}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="column-3">
                <p className="p-margin contract-font-size-13">Tel: </p>
              </div>
              <div className="column-9">
                <p className="p-margin contract-font-size-13">
                  {this.state.currentEntity.buyerContactPerson !== undefined
                    ? (this.state.currentEntity.buyerContactPerson || {}).phone
                    : 'N/A'}
                </p>
              </div>
            </div>
            <div className="row col-margin">
              <div className="column-3">
                <p className="p-margin contract-font-size-13 ">E-Mail: </p>
              </div>
              <div className="column-9">
                <p className="p-margin contract-font-size-13">
                  {this.state.currentEntity.buyerContactPerson !== undefined
                    ? (this.state.currentEntity.buyerContactPerson || {}).emails[0]
                    : 'N/A'}
                </p>
              </div>
            </div>
            <div className="row ">
              <div className="column-12">
                <h5 className="p-margin contract-font-size-18">
                  PROFORMA INVOICE & SALES CONTRACT NO.{' '}
                  <span className={'hidden-on-pdf'}>{this.state.currentEntity.contractNumber}</span>{' '}
                  <span className={'show-only-on-pdf'}>%contract_number%</span>
                  {`${
                    this.state.currentEntity.docStatus.sales.date
                      ? `/ ${moment(this.state.currentEntity.docStatus.sales.date).format(
                          'DD.MM.YYYY',
                        )}`
                      : ''
                  }`}{' '}
                </h5>
              </div>
              <div className="column-12 row-margin">
                <p className="p-margin contract-font-size-13">
                  Subject to this contract is the transboundary movement of wastes destined for
                  recovery operations
                </p>
              </div>
            </div>
            <div className="row">
              <div className="column-2 header">
                <h4 className="contract-header-border margin-bottom-5 contract-font-size-16 contract-margin-0 line-height-17">
                  QUANTITY
                </h4>
              </div>
              <div className="column-6 header padding-left-10">
                <h4 className="contract-header-border margin-bottom-5 contract-font-size-16 contract-margin-0 line-height-17">
                  QUALITY
                </h4>
              </div>
              <div className="column-2 header">
                <h4 className="contract-header-border margin-bottom-5 contract-font-size-16 contract-margin-0 line-height-17">
                  PRICE
                </h4>
              </div>
              <div className="column-2 header">
                <h4 className="contract-header-border margin-bottom-5 contract-font-size-16 contract-margin-0 line-height-17">
                  VALUE
                </h4>
              </div>
            </div>
            {(this.state.currentEntity.articles || []).map((_a, index) => {
              return (
                <div className="row" key={index}>
                  <div className="column-2">
                    {' '}
                    <h5 className="contract-font-size-16 contract-h6-margin-bottom line-height-17">
                      {_a.loads} x {_a.container_type} <br /> (+/- {_a.weight_per_load} to){' '}
                    </h5>{' '}
                  </div>
                  <div className="column-6 padding-left-10">
                    <h5 className="p-margin contract-font-size-16 line-height-17">
                      {_a.articleDetails.descriptionInEnglish}
                    </h5>
                    <p className="p-margin contract-font-size-10">
                      Description: {_a.articleDetails.descriptionOfWaste}, HS CODE:{' '}
                      {_a.articleDetails.hsCode && _a.articleDetails.hsCode !== "" ? _a.articleDetails.hsCode : 'N/A'}
                    </p>
                  </div>
                  <div className="column-2">
                    <h5 className="contract-font-size-16">
                      {_a.currency === 'Euro' ? '€' : '$'}{' '}
                      {getGermanNumber(formatNumberToEn(_a.price))}/{_a.unit}
                    </h5>{' '}
                  </div>
                  <div className="column-2">
                    <h5 className="contract-font-size-16">
                      {' '}
                      {_a.currency === 'Euro' ? '€' : '$'}{' '}
                      {getGermanNumber(
                        parseFloat(formatNumberToEn(_a.weight_per_load)) *
                          parseFloat(formatNumberToEn(_a.price)),
                      )}
                    </h5>
                  </div>
                </div>
              );
            })}

            <div className="row">
              <div className="column-2"> &nbsp; </div>
              <div className="column-6 ">
                <h5 className="p-margin">&nbsp; </h5>
              </div>
              <div className="column-2">
                <h5 className="contract-font-size-16 mar-0">Total value:</h5>{' '}
              </div>
              <div className="column-2">
                <h5 className="contract-font-size-16 mar-0">
                  {(this.state.currentEntity.articles[0] || []).currency === 'Euro' ? '€' : '$'}{' '}
                  {this.getTotal()}
                </h5>
              </div>
            </div>
            <div className="row padding-3">
              <div className="column-2 header-h5">
                <h5 className="contract-font-size-16">DELIVERY: </h5>
              </div>
              <div className="column-10 padding-left-10">
                <h6 className="p-margin contract-font-size-16">
                  {this.state.currentEntity.incoterms && this.state.currentEntity.incoterms}{' '}
                  {(this.state.company || {}).port || ''},{this.state.currentEntity.dtch}. DTHC
                  <br />
                </h6>
                <p className="p-margin contract-font-size-16">
                  {' '}
                  All cost is port {(this.state.company || {}).port || ''} are not for{' '}
                  {(this.state.company || {}).name1 || ''}
                </p>
              </div>
            </div>
            <div className="row padding-3 ">
              <div className="column-2">
                <h5 className="contract-font-size-16 white-space-nowrap mar-0 line-height-17">
                  Recycler/ end-user:{' '}
                </h5>
              </div>
              <div className="column-10 padding-left-10">
                <h6 className="p-margin contract-font-size-16 mar-0 line-height-17">
                  {(this.state.currentEntity || {}).endUser}
                </h6>
              </div>
            </div>
            <div className="row padding-4">
              <div className="column-2">
                <h5 className="contract-font-size-16 white-space-nowrap line-height-17  mar-0">
                  Payment term:{' '}
                </h5>
              </div>
              <div className="column-10 padding-left-10   ">
                <h6 className="p-margin contract-font-size-16 line-height-17  mar-0 ">
                  {(this.state.currentEntity || {}).paymentTerm || ''} (
                  {(this.state.currentEntity.articles[0] || []).currency === 'Euro' ? '€' : '$'}{' '}
                  {getGermanNumber(Math.round(this.getPercentage()))}) payment in advance,{' '}
                  {(this.state.currentEntity || {}).paymentTerm || ''} payment by T/T after receipt
                  of B/L copy and invoice copy by E-mail to the following account:{' '}
                </h6>
              </div>
            </div>

            <div className="row">
              <div className="column-2">
                <h5 className="p-margin contract-font-size-16 line-height-17">Bank name:</h5>
              </div>
              <div className="column-10 padding-left-10">
                <h6 className="p-margin contract-font-size-16 line-height-17">
                  {(this.state.bank || {}).bankName}
                </h6>
              </div>
            </div>
            <div className="row">
              <div className="column-2">
                <h5 className="p-margin contract-font-size-16 line-height-17">Bank address: </h5>
              </div>
              <div className="column-10 padding-left-10">
                <h6 className="p-margin contract-font-size-16 line-height-17">
                  {(this.state.bank || {}).bankDescription}
                </h6>
              </div>
            </div>
            <div className="row">
              <div className="column-2">
                <h5 className="p-margin contract-font-size-16 line-height-17">Recipient: </h5>
              </div>
              <div className="column-10 padding-left-10">
                <h6 className="p-margin  contract-font-size-16 line-height-17">
                  {(this.state.company || {}).name1}
                </h6>
              </div>
            </div>
            <div className="row">
              <div className="column-2">
                <h5 className="p-margin contract-font-size-16 line-height-17 white-space-nowrap">
                  Recipient address:{' '}
                </h5>
              </div>
              <div className="column-10 padding-left-10">
                <h6 className="p-margin contract-font-size-16 line-height-17">
                  {(this.state.company || {}).address}
                </h6>
              </div>
            </div>
            <div className="row">
              <div className="column-2">
                <h5 className="p-margin contract-font-size-16 line-height-17">SWIFT/BIC: </h5>
              </div>
              <div className="column-10 padding-left-10">
                <h6 className="p-margin contract-font-size-16 line-height-17">
                  {(this.state.bank || {}).bic}
                </h6>
              </div>
            </div>
            <div className="row padding-4">
              <div className="column-2">
                <h5 className="p-margin contract-font-size-16 line-height-17">IBAN no:</h5>
              </div>
              <div className="column-10 padding-left-10">
                <h6 className="p-margin contract-font-size-16 line-height-17">
                  {(this.state.bank || {}).iban}
                </h6>
              </div>
            </div>

            <div className="row">
              <div className="column-6 header-h4">
                <h4 className="contract-header-border margin-bottom-5 contract-font-size-16 line-height-17">
                  Special instructions / conditions
                </h4>
              </div>
            </div>
            <div className="row">
              <div className="column-2">
                <h5 className="contract-font-size-16 white-space-nowrap mar-0 line-height-17">
                  Shipment:
                </h5>
              </div>
              <div className="column-10 padding-left-10">
                <h6 className="p-margin contract-font-size-16 line-height-17">
                  Shipment to be made in {this.state.currentEntity.noOfLots}{' '}
                  {this.state.currentEntity.noOfLots > 1 ? 'lots' : 'lot'}.
                </h6>
              </div>
            </div>
            <div className="row row-margin">
              <div className="column-2">
                <h5 className="contract-font-size-16 white-space-nowrap mar-0 line-height-17">
                  Document required:
                </h5>
              </div>
              <div className="column-9 padding-left-10">
                <h6 className="p-margin contract-font-size-16 line-height-17  ">
                  {this.state.currentEntity.documents.join(', ')}
                </h6>
              </div>
            </div>
          </div>
        </section>
        <section className="_margin-top-30">
          <div className="container">
            <div className="row">
              <div className="column-12">
                <h6 className="contract-font-size-13 line-height-17 mar-0">Claims:</h6>
              </div>
              <div className="column-12 col-margin">
                <h6 className="contract-font-size-13 line-height-15 mar-0">
                  In case of claim, claims will only be valid if they are presented with pictures
                  etc. latest 14 days after the arrival of the vessel! Buyers warehouse and buyer
                  should take at least 10 – 15 pictures during the unloading, showing the unloading
                  process and container number. At least 5 pictures should show the total overview
                  of the unloaded goods (total parcel) the other 10 pictures can show bales in close
                  up. If these pictures are not available, {(this.state.company || {}).name1} will
                  not be able to accept any claims. Seller will only accept a shortage claim of over
                  400KG with official weight ticket of turkey customs. Buyer has to accept a weight
                  difference up to 400KG. For example: Buyer claims a shortage of 450KG, Seller has
                  to make a credit note of 50KG weight difference.
                </h6>
              </div>
              <div className="column-12 col-margin">
                <h6 className="contract-font-size-13 line-height-15 mar-0">
                  This contract includes the obligation on the person who arranges the shipment to
                  take the waste back or ensure its recovery in an alternative way; and to provide,
                  if necessary, for its storage in the meantime, where the shipment of waste or its
                  recovery cannot be completed as intended or where it has been effected as an
                  illegal shipment, and on the consignee to take the waste back or ensure its
                  recovery in an alternative way; and to provide, if necessary, for its storage in
                  the meantime,where the person who arranges the shipment is not in a position to
                  complete the shipment of waste or its recovery (for example, is insolvent).
                </h6>
              </div>
            </div>
            <div className="row _margin-top-30">
              <div className="column-12 header2">
                <h4 className="contract-font-size-16">SHIPPING INSTRUCTIONS:</h4>
              </div>
            </div>
            <div className="row">
              <div className="column-12">
                <h5 className="p-margin contract-font-size-16 line-height-17 font-weight-normal">
                  SHIPPER:
                </h5>
              </div>
              <div className="column-12">
                <h4 className="contract-font-size-16 font-weight-normal line-height-17 contract-h6-margin-bottom">
                  {this.state.currentEntity.shipper}{' '}
                </h4>
              </div>
            </div>

            <div className="row">
              <div className="column-12">
                <h5 className="p-margin contract-font-size-16 line-height-17 font-weight-normal">
                  CONSIGNEE:{' '}
                </h5>
              </div>
              <div className="column-12">
                <h4 className="contract-font-size-16 font-weight-normal line-height-17 contract-h6-margin-bottom">
                  {this.state.currentEntity.consignee}{' '}
                </h4>
              </div>
            </div>

            <div className="row">
              <div className="column-12 ">
                <h5 className="p-margin contract-font-size-16 line-height-17 font-weight-normal">
                  NOTIFY:{' '}
                </h5>
              </div>
              <div className="column-12">
                <h4 className="contract-font-size-16 font-weight-normal line-height-17 contract-h6-margin-bottom">
                  {this.state.currentEntity.notify}
                </h4>
              </div>
            </div>
            <div className="row row-margin">
              <div className="column-12">
                <h4 className="contract-font-size-16 line-height-17">
                  Important comment: The signature on this contract is binding, which means that the
                  signatory approves for above contract and shipping details. No amendments can be
                  done after loading has taken place.
                </h4>
              </div>
            </div>
            <div className="row row-margin">
              <div className="column-12 header3-h5">
                <h5 className="contract-font-size-16">
                  {(this.state.company || {}).city},{' '}
                  {`${
                    this.state.currentEntity.docStatus.sales.date
                      ? `${moment(this.state.currentEntity.docStatus.sales.date).format(
                          'DD.MM.YYYY',
                        )}`
                      : ''
                  }`}{' '}
                </h5>
              </div>
            </div>

            <div className="row margin-2">
              <div className="column-2">
                {(this.state.currentEntity || {}).teamSignature !== undefined ? (
                  <img
                    src={`${this.state.apiUrl}/persons/team/download-file/${
                      (this.state.currentEntity || {}).teamSignature
                    }`}
                    alt="Signature"
                    className="img-fluid"
                    style={{ width: '190px', maxWidth: '190px', minWidth: '190px', height: '46px' }}
                  />
                ) : (
                  <div
                    style={{ width: '190px', maxWidth: '190px', minWidth: '190px', height: '46px' }}
                  >
                    {' '}
                    &nbsp;
                  </div>
                )}
                <div className="bottom-border"> </div>
                <h6 className="contract-font-size-13 mar-0">
                  {(this.state.company || {}).name1 || <>&nbsp;</>}
                </h6>
              </div>
              <div className="column-3">&nbsp;</div>
              <div className="column-7 align-self-end">
                <div
                  style={{ width: '190px', maxWidth: '190px', minWidth: '190px', height: '46px' }}
                >
                  {' '}
                  &nbsp;
                </div>
                <div className="bottom-border"></div>
                <h6 className="contract-font-size-13 mar-0">
                  {(this.state.currentEntity || {}).buyer || <>&nbsp;</>}
                </h6>
              </div>
            </div>
            <div className="row row-margin">
              <div className="column-12 header3-h5">
                <h5 className="contract-font-size-13 mar-0 ">
                  A copy of the contract shall be provided upon request by the competent authority
                  concerned
                </h5>
              </div>
            </div>
          </div>
        </section>

        <Footer
          companyData={this.props.data.company.allCompanys[0]}
          companyName={
            this.props.data.company.allCompanys[0]
              ? this.props.data.company.allCompanys[0].name1
              : ''
          }
          companyAddress={
            this.props.data.company.allCompanys[0]
              ? this.props.data.company.allCompanys[0].address
              : ''
          }
        />
      </div>
    );
  }
}

export default ContractDocument;
