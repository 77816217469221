import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import ContractArticles from '../submenus/contract-articles';
import Autocomplete from '@material-ui/lab/Autocomplete';

function Contract(props) {
  const { state, self } = props;

  return (
    <div style={{ flexGrow: 1 }}>
      <form noValidate autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper style={{ padding: 12, height: '100%', width: '100%' }}>
              <Typography variant="h4" style={{ paddingBottom: 30 }}>
                {self.props.t(self.state.header0)}
              </Typography>
              {self.state.tile0}

              {self.state.buyerContactPersons.length > 0 && (
                <Autocomplete
                  style={{
                    margin: 10,
                    minWidth: 300,
                    maxWidth: 300,
                    display: 'inline-flex',
                  }}
                  id="pod"
                  options={self.state.buyerContactPersons || []}
                  getOptionLabel={(option) =>
                    `${option.firstName}${option.surname ? ` ${option.surname}` : ''}`
                  }
                  onChange={(event, value) => {
                    self.setState({ buyerContactPersonId: value._id });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={props.t('Contact Person')}
                      placeholder={props.t('Contact Person')}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              )}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper style={{ padding: 12, height: '100%', width: '100%' }}>
              <Typography variant="h4" style={{ paddingBottom: 30 }}>
                {self.state.header1}
              </Typography>
              {self.state.tile1.map((val) =>
                val.key === 'POD' ? (
                  <Autocomplete
                    style={{
                      margin: 10,
                      minWidth: 300,
                      maxWidth: 300,
                      display: 'inline-flex',
                    }}
                    id="pod"
                    options={self.state.podOptions || []}
                    getOptionLabel={(option) => props.t(option)}
                    value={self.state[val.props.dbid] || ''}
                    onChange={(event, value) => {
                      self.setState({ pod: value });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={props.t('POD')}
                        placeholder={props.t('POD')}
                        required={val.props.required}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                ) : val.key === 'Number of Lots' ? (
                  self.state.lots === 'Multiple Lots' ? (
                    val
                  ) : (
                    ''
                  )
                ) : (
                  val
                ),
              )}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper style={{ padding: 12, height: '100%', width: '100%' }}>
              <Typography variant="h4" style={{ paddingBottom: 30 }}>
                {self.state.header2}
              </Typography>
              {self.state.tile2}
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper style={{ padding: 12, height: '100%', width: '100%' }}>
              <ContractArticles variant={'add'} state={state} self={self} />
            </Paper>
          </Grid>
        </Grid>
      </form>
      <Button
        variant="contained"
        color="primary"
        startIcon={<CloudUploadIcon />}
        style={{ marginTop: 20 }}
        onClick={() => self.handleAdd()}
      >
        ADD {props.t(self.props.currentTab)}
      </Button>
    </div>
  );
}

export default withTranslation('common')(Contract);
