import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import UploadSignatures from '../../company/upload-signatures';
import UploadLogo from '../../company/upload-logo';
import UploadAgb from '../../company/upload-agb';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { stateToHTML } from 'draft-js-export-html';
import { EditorState, Editor, RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { InlineStyleControls, styleMap } from '../../common/rich-text-editor';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';

function Company(props) {
  const { self } = props;

  function getBlockStyle(block) {
    switch (block.getType()) {
      case 'blockquote':
        return 'RichEditor-blockquote';
      default:
        return null;
    }
  }

  function toggleInlineStyle(inlineStyle) {
    let docType = self.state.docType;
    let documents = self.state.documents;
    documents[docType].description = RichUtils.toggleInlineStyle(
      documents[docType].description,
      inlineStyle,
    );
    self.setState({
      documents: documents,
    });
  }

  function handleEditorChange(value) {
    let docType = self.state.docType;
    let documents = self.state.documents;
    documents[docType].description = value;
    self.setState({
      documents: documents,
    });

    setHtmlValue();
  }

  function setHtmlValue() {
    let docType = self.state.docType;
    let documents = self.state.documents;
    let html = stateToHTML(documents[docType].description.getCurrentContent());

    documents[docType].descriptionHtml = html;

    self.setState({
      documents: documents,
    });
  }

  function handleSubjectChange(event) {
    let docType = self.state.docType;
    let documents = self.state.documents;
    documents[docType].subject = event.target.value;

    self.setState({
      documents: documents,
    });
  }

  function handleChange(event) {
    self.setState({ docType: event.target.value });
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <form noValidate autoComplete="off">
        <Grid container spacing={3}>
          <React.Fragment>
            <Grid item xs={12}>
              <Paper style={{ padding: 12, height: '100%' }}>
                <Typography variant="h4" style={{ paddingBottom: 16 }}>
                  {self.props.t(self.state.header0)}
                </Typography>
                {self.state.tile0}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper style={{ padding: 12, height: '100%' }}>
                <Typography variant="h4" style={{ paddingBottom: 16 }}>
                  {self.props.t(self.state.header1)}
                </Typography>
                {self.state.tile1}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper style={{ padding: 12, height: '100%' }}>
                <Typography variant="h4" style={{ paddingBottom: 16 }}>
                  {self.props.t(self.state.header2)}
                </Typography>
                {self.state.tile2}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper style={{ padding: 12, height: '100%' }}>
                <Typography variant="h4" style={{ paddingBottom: 16 }}>
                  {self.props.t(self.state.header4)}
                </Typography>
                <Grid item xs={12} sm={4} md={4} lg={4} style={{ marginTop: '16px' }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">{self.props.t('Select document type')}</InputLabel>
                    <Select
                      labelId="select-document-type"
                      id="select-document-type"
                      value={self.state.docType || ''}
                      onChange={handleChange}
                      fullWidth
                    >
                      <MenuItem value={'default'}>{self.props.t('Default')}</MenuItem>
                      <MenuItem value={'annex'}>{self.props.t('Annex Document')}  </MenuItem>
                      <MenuItem value={'purchase_confirmation'}> {self.props.t('Purchase Confirmation')} </MenuItem>
                      <MenuItem value={'sales_confirmation'}> {self.props.t('Sales Confirmation')} </MenuItem>
                      <MenuItem value={'transport_information'}> {self.props.t('Transportation Information')}</MenuItem>
                      <MenuItem value={'invoice'}> {self.props.t('Invoice Document')}</MenuItem>
                      <MenuItem value={'transport_order'}> {self.props.t('Transportation Order')}</MenuItem>
                      <MenuItem value={'billing_confirmation'}> {self.props.t('Billing confirmation')} </MenuItem>
                      <MenuItem value={'invoice_cancellation'}> {self.props.t('Invoice Cancellation')} </MenuItem>
                      <MenuItem value={'sales_contract'}> {self.props.t('Sales Contract')} </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} style={{ marginTop: '16px' }}>
                  <TextField
                    label="Subject"
                    value={self.state.documents[self.state.docType].subject || ''}
                    onChange={handleSubjectChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} style={{ marginTop: '16px' }}>
                  <div className="mb-16 RichEditor-root">
                    <InlineStyleControls
                      editorState={self.state.documents[self.state.docType].description}
                      onToggle={toggleInlineStyle}
                    />
                    <Editor
                      className="mt-8 mr-8"
                      editorState={self.state.documents[self.state.docType].description}
                      onChange={handleEditorChange}
                      customStyleMap={styleMap}
                      blockStyleFn={getBlockStyle}
                      placeholder="E-mail description"
                    />
                  </div>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper style={{ padding: 12, height: '100%' }}>
                <Typography variant="h4" style={{ paddingBottom: 16 }}>
                  {self.props.t(self.state.header3)}
                </Typography>
                {self.state.tile3}
              </Paper>
            </Grid>
          </React.Fragment>

          <UploadSignatures {...props} />
          <UploadLogo {...props} />
          <UploadAgb {...props} />
        </Grid>
      </form>
      <Button
        variant="contained"
        color="primary"
        startIcon={<CloudUploadIcon />}
        style={{ marginTop: 20, backgroundColor: 'white', color: 'green' }}
        onClick={() => self.handleAdd()}
      >
        {`${self.props.t('ADD')} ${self.props.t('Company')}`}
      </Button>
    </div>
  );
}

export default Company;
