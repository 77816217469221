import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import Dropzone from 'react-dropzone';
import { getApiUrl } from '../../../util/environment-utils';
import axios from 'axios';
import ReactImageMagnify from 'react-image-magnify';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Gallery({ gallery, setGallery, images, setImages, t }) {
  const classes = useStyles();
  const [apiUrl, setApiUrl] = useState(getApiUrl());
  const [selectedImage, setSelectedImage] = useState(null);

  const handleClose = () => {
    setGallery(false);
  };

  const openImageFullScreen = (id, url) => {
    var modal = document.getElementById('myModal');

    modal.style.display = 'block';
    setSelectedImage(url);

    var span = document.getElementsByClassName('close')[0];
    span.onclick = function () {
      modal.style.display = 'none';
      setSelectedImage(null);
    };
  };

  const onDrop = (files) => {
    for (let key in files) {
      let data = new FormData();
      let BASE_URL = getApiUrl();
      data.append('files', files[key]);

      axios.post(`${BASE_URL}/tuning/gallery/upload`, data).then(
        (res) => {
          let oldImages = images;
          oldImages.push(res.data.fileName);
          setImages([...images]);
        },
        (error) => {
          console.log(error);
        },
      );
    }
  };

  return (
    <div>
      <Dialog fullScreen open={gallery} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('Gallery')}
            </Typography>
            <Button autoFocus color="primary" onClick={handleClose}>
              {t('save')}
            </Button>
          </Toolbar>
        </AppBar>

        <Grid container spacing={3}>
          <Grid item sm={12} md={12} xs={12} lg={12} style={{ margin: '12px' }}>
            <Dropzone 
            onDrop={(event) => onDrop(event)}
            maxSize={10 * 1024 * 1024}
            onDropRejected={() => alert(t("file_size_limit"))}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="dropzone-container">
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop images here</p>
                  </div>
                </section>
              )}
            </Dropzone>
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ padding: '16px' }}>
          {images.map((img, index) => {
            return (
              <Grid item xs={3} sm={3} md={3} lg={3} key={index}>
                <img
                  src={`${apiUrl}/tuning/image/${img}`}
                  className={'gallery-image'}
                  id={`myImg_${index}`}
                  style={{ width: '100%', height: '100%' }}
                  onClick={() =>
                    openImageFullScreen(`myImg_${index}`, `${apiUrl}/tuning/image/${img}`)
                  }
                />
              </Grid>
            );
          })}
        </Grid>

        <div id="myModal" className="modal">
          <span className="close">&times;</span>
          {selectedImage && (
            <Grid container spacing={3} style={{ padding: '16px' }}>
              <Grid item xs={6} sm={6} md={6} lg={6}>
              <ReactImageMagnify
                smallImage={{
                  isFluidWidth: true,
                  src: selectedImage,
                }}
                largeImage={{
                  src: selectedImage,
                  width: 1200,
                  height: 1800,
                }}
                enlargedImageContainerStyle={{ background: '#fff', zIndex: 9 }}
              />
              </Grid>
            </Grid>
          )}
        </div>
      </Dialog>
    </div>
  );
}
