import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import PlusIcon from '@material-ui/icons/Add';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import { getArticles } from '../../redux/modules/article';
import * as Actions from '../../redux/store/actions/orders';
import { withTranslation } from 'react-i18next';
import { sourceCondition, sourceConditionGerman } from '../../redux/store/actions/orders';
import DatabaseMultiChoice from '../Inputs/DatabaseMultiChoice';

const filter = createFilterOptions();

function Articles(props) {
  const dispatch = useDispatch();
  const { order, variant, orderType, sourceCountry, processorCountry, state } = props;
  const { articles, form } = useSelector(({ orders }) => orders);
  const [options, setOptions] = useState([]);
  const [wasteCodeOptions] = useState(['19 12 04 ', '15 01 02', '20 01 39', '16 01 19']);

  useEffect(() => {
    async function fetchArticles() {
      let data = await dispatch(getArticles());
      data.allArticles.push({
        article_id: 0,
        descriptionInEnglish: 'Commission',
        descriptionInGerman: 'Provision',
        article_name: 'Commission',
      });
      setOptions(data.allArticles);
    }

    fetchArticles();
  }, [dispatch]);

  useEffect(() => {
    if (variant === 'edit' && (order.articles.length > 0 || order.commissionArticles.length > 0)) {
      dispatch(
        Actions.setValues([
          {
            article_name: '',
            article_id: '',
            source_price: '',
            source_currency: 'Euro',
            source_unit: 't',
            source_cashflow: '',
            source_condition:
              props.i18n.language === 'en' ? sourceCondition : sourceConditionGerman,
            source_deviating: '',
            processor_price: '',
            processor_currency: 'Euro',
            processor_unit: 't',
            processor_cashflow: '',
            processor_condition: '',
            processor_deviating: '',
            commissionPartner: '',
            commission_currency: 'Euro',
            commission_price: '',
            wastecode: '',
          },
        ]),
      );

      setTimeout(() => {
        let allArticles = order.articles;
        if (order.commissionArticles.length > 0) {
          allArticles =
            order.articles.length > 0
              ? order.articles.concat(order.commissionArticles)
              : order.commissionArticles;
        }
        dispatch(Actions.setValues(allArticles));
      });
    } else if (variant === 'add' || order.articles.length === 0) {
      dispatch(
        Actions.setValues([
          {
            article_name: '',
            article_id: '',
            source_price: '',
            source_currency: 'Euro',
            source_unit: 't',
            source_cashflow: '',
            source_condition:
              props.i18n.language === 'en' ? sourceCondition : sourceConditionGerman,
            source_deviating: '',
            processor_price: '',
            processor_currency: 'Euro',
            processor_unit: 't',
            processor_cashflow: '',
            processor_condition: '',
            processor_deviating: '',
            commissionPartner: '',
            commission_currency: 'Euro',
            commission_price: '',
            wastecode: '',
          },
        ]),
      );
    }
  }, [order, variant]);

  function addNewRow() {
    dispatch(Actions.add(props.i18n.language));
  }

  function deleteRow(id) {
    dispatch(Actions.remove(id));
  }

  function handleAutocompleteChange(event, newValue, index, type) {
    let data = articles;
    if (type === 'article') {
      if (!newValue) {
        data[index]['article_name'] = '';
        data[index]['articleDetails'] = newValue;
        data[index]['article_id'] = null;
        dispatch(Actions.setValues(data));

        return;
      }

      if (newValue.article_name && newValue.article_name.toLowerCase() === 'commission') {
        data[index]['article_name'] = 'Commission';
        data[index]['articleDetails'] = {
          descriptionInEnglish: 'Commission',
          descriptionInGerman: 'Provision',
        };
        data[index]['article_id'] = 0;
      } else {
        data[index]['article_name'] = `${newValue.descriptionInEnglish} ${
          newValue.avv ? `AVV ${newValue.avv}` : ''
        }`;
        data[index]['articleDetails'] = newValue;

        if (props.fromWarehouse) data[index]['orderArticleId'] = newValue.orderArticleId;

        data[index]['article_id'] = newValue._id;
        if (showWasteCode(index)) {
          data[index]['wastecode'] = newValue.avv ? newValue.avv : '';
        }
      }
    } else if (type === 'source_currency' || type === 'processor_currency') {
      data.map((val, index) => {
        data[index][type] = newValue ? newValue : 'Euro';
      });
    } else {
      let val = newValue && newValue.inputValue ? newValue.inputValue : newValue;
      data[index][type] = val;
    }

    dispatch(Actions.setValues(data));
  }

  function handleChange(event, index) {
    let data = articles;
    data[index][event.target.name] = event.target.value;

    dispatch(Actions.setValues(data));
  }

  function handleDatabaseMultiChoiceChange(name, value, data, dataLabel, allData, index) {
    let oldData = articles;
    oldData[index][name] = allData ? allData._id : allData;
    dispatch(Actions.setValues(oldData));
  }

  function showWasteCode(index) {
    const article = articles[index];
    const check = sourceCountry !== processorCountry && article;

    return check;
  }

  return (
    <div>
      <Typography variant="h4" style={{ paddingTop: 30, display: 'inline-block' }}>
        {props.t(state.header3)}
      </Typography>
      <AddCircleOutlineIcon
        style={{ fontSize: 28, position: 'relative', top: -2, marginLeft: 15, cursor: 'pointer' }}
        color="primary"
        onClick={() => {
          addNewRow();
        }}
      />


      {articles.map((article, index) => (
        <div key={index} style={{ paddingTop: 12, height: '100%' }} className="expense-block">
          <div className="display-flex justify-space-betweens align-center">
            <div>

              <Autocomplete
                style={{
                  margin: 10,
                  minWidth: 300,
                  maxWidth: 300,
                  display: 'inline-flex',
                }}
                id="combo-box-demo"
                value={
                  props.i18n.language === 'en'
                    ? article.articleDetails
                      ? article.articleDetails.descriptionInEnglish
                      : null
                    : article.articleDetails
                    ? article.articleDetails.descriptionInGerman
                    : null
                }
                options={
                  props.fromWarehouse &&
                  props.warehouseArticles &&
                  props.warehouseArticles.length > 0
                    ? props.warehouseArticles
                    : options
                }
                onChange={(event, newValue) => {
                  handleAutocompleteChange(event, newValue, index, 'article');
                }}
                getOptionLabel={(option) =>
                  option.descriptionInEnglish
                    ? `${
                        props.i18n.language === 'en'
                          ? option.descriptionInEnglish
                          : option.descriptionInGerman
                      } ${option.avv ? `(AVV ${option.avv})` : ''}`
                    : option
                }
                getOptionSelected={(option) =>
                  option.article_id == 0 || article.article_id === option._id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={props.t('Article')}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />

              {showWasteCode(index) && (
                <Autocomplete
                  style={{
                    margin: 10,
                    minWidth: 300,
                    maxWidth: 300,
                    display: 'inline-flex',
                  }}
                  id="combo-box-demo"
                  value={article.wastecode || ''}
                  options={wasteCodeOptions}
                  freeSolo
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  onChange={(event, newValue) => {
                    event.stopPropagation();
                    handleAutocompleteChange(event, newValue, index, 'wastecode');
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        title: `Add "${params.inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }

                    if (option.inputValue) {
                      return option.inputValue;
                    }

                    return option;
                  }}
                  renderOption={(option) => (option.title ? option.title : option)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={props.t('Wastecode')}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              )}

              <br />
              <span className="article_label">{props.t('Purchase')} : </span>
              {article.article_id == 0 && article.article_id !== '' ? (
                <React.Fragment>
                  <TextField
                    style={{ margin: 10, minWidth: '300px' }}
                    value={article.source_deviating}
                    onChange={(event) => handleChange(event, index)}
                    required={false}
                    label={`${props.t('source_deviating')}`}
                    name={'source_deviating'}
                  />

                  <DatabaseMultiChoice
                    id={'commissionPartner'}
                    name={'commissionPartner'}
                    dbid={'commissionPartner'}
                    value={article.commissionPartner}
                    required={true}
                    method={'getPartners'}
                    getPartners={() => this.props.getPartners()}
                    label={props.t('Commission Partner')}
                    callbackValue={(name, value, data, dataLabel, allData) =>
                      handleDatabaseMultiChoiceChange(name, value, data, dataLabel, allData, index)
                    }
                  />

                  <Autocomplete
                    style={{
                      margin: 10,
                      minWidth: 150,
                      maxWidth: 150,
                      display: 'inline-flex',
                    }}
                    id="combo-box-demo"
                    value={article.commission_currency || 'Euro'}
                    options={['Euro', 'USD']}
                    onChange={(event, newValue) =>
                      handleAutocompleteChange(event, newValue, index, 'source_currency')
                    }
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${props.t('source_currency')}`}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: `new-password`,
                        }}
                      />
                    )}
                  />

                  <TextField
                    style={{ margin: 10, minWidth: '150px' }}
                    value={article.commission_price}
                    onChange={(event) => handleChange(event, index)}
                    required={false}
                    label={`${props.t('Commission Price')}`}
                    name={'commission_price'}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <TextField
                    style={{ margin: 10, minWidth: '300px' }}
                    value={article.source_deviating}
                    onChange={(event) => handleChange(event, index)}
                    required={false}
                    label={`${props.t('source_deviating')}`}
                    name={'source_deviating'}
                  />

                  <Autocomplete
                    style={{
                      margin: 10,
                      minWidth: 150,
                      maxWidth: 150,
                      display: 'inline-flex',
                    }}
                    id="combo-box-demo"
                    value={article.source_currency || 'Euro'}
                    options={['Euro', 'USD']}
                    onChange={(event, newValue) =>
                      handleAutocompleteChange(event, newValue, index, 'source_currency')
                    }
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${props.t('source_currency')}`}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: `new-password`,
                        }}
                      />
                    )}
                  />

                  <TextField
                    style={{ margin: 10, minWidth: '150px' }}
                    value={article.source_price}
                    onChange={(event) => handleChange(event, index)}
                    label={`${props.t('source_price')}`}
                    name={'source_price'}
                    required={true}
                  />

                  <Autocomplete
                    style={{
                      margin: 10,
                      minWidth: 150,
                      maxWidth: 150,
                      display: 'inline-flex',
                    }}
                    id="combo-box-demo"
                    value={article.source_unit || 't'}
                    options={['t', 'Kg', 'Piece']}
                    onChange={(event, newValue) =>
                      handleAutocompleteChange(event, newValue, index, 'source_unit')
                    }
                    getOptionLabel={(option) => props.t(option)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${props.t('source_unit')}}`}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: `new-password`,
                        }}
                      />
                    )}
                  />

                    <Autocomplete
                      style={{
                        margin: 10,
                        minWidth: 300,
                        maxWidth: 300,
                        display: 'inline-flex',
                      }}
                      id="combo-box-demo"
                      value={
                        article.source_cashflow && article.source_cashflow.length > 0
                          ? article.source_cashflow
                          : null
                      }
                      options={['Supplementary Payment', 'Compensation']}
                      onChange={(event, newValue) =>
                        handleAutocompleteChange(event, newValue, index, 'source_cashflow')
                      }
                      getOptionLabel={(option) => props.t(`${option} ${'Source'}`)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required={true}
                          label={`${props.t(orderType === 'dropshippment' ? 'source_cashflow' : 'cashflow_warehouse')}`}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: `new-password`,
                          }}
                        />
                      )}
                    />

                  <TextField
                    style={{ margin: 10, minWidth: '300px' }}
                    onChange={(event) => handleChange(event, index)}
                    value={article.source_condition}
                    required={false}
                    label={`${props.t('source_condition')}`}
                    name={'source_condition'}
                  />


                  {orderType === 'dropshippment' && (
                    <React.Fragment>
                      <br />
                      <span className="article_label">{props.t('Sales')} : </span>
                      <TextField
                        style={{ margin: 10, minWidth: '300px' }}
                        value={article.processor_deviating}
                        onChange={(event) => handleChange(event, index)}
                        required={false}
                        label={`${props.t('processor_deviating')}`}
                        name={'processor_deviating'}
                      />

                      <Autocomplete
                        style={{
                          margin: 10,
                          minWidth: 150,
                          maxWidth: 150,
                          display: 'inline-flex',
                        }}
                        id="combo-box-demo"
                        value={article.processor_currency || 'Euro'}
                        options={['Euro', 'USD']}
                        onChange={(event, newValue) =>
                          handleAutocompleteChange(event, newValue, index, 'processor_currency')
                        }
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${props.t('processor_currency')}`}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: `new-password`,
                            }}
                          />
                        )}
                      />

                      <TextField
                        style={{ margin: 10, minWidth: '150px' }}
                        onChange={(event) => handleChange(event, index)}
                        label={`${props.t('processor_price')}`}
                        name={'processor_price'}
                        value={article.processor_price}
                        required={true}
                      />

                      <Autocomplete
                        style={{
                          margin: 10,
                          minWidth: 150,
                          maxWidth: 150,
                          display: 'inline-flex',
                        }}
                        id="combo-box-demo"
                        value={article.processor_unit || 't'}
                        options={['t', 'Kg', 'Piece']}
                        onChange={(event, newValue) =>
                          handleAutocompleteChange(event, newValue, index, 'processor_unit')
                        }
                        getOptionLabel={(option) => props.t(option)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${props.t('processor_unit')}`}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: `new-password`,
                            }}
                          />
                        )}
                      />

                      <Autocomplete
                        style={{
                          margin: 10,
                          minWidth: 300,
                          maxWidth: 300,
                          display: 'inline-flex',
                        }}
                        id="combo-box-demo"
                        value={
                          article.processor_cashflow && article.processor_cashflow.length > 0
                            ? article.processor_cashflow
                            : null
                        }
                        options={['Supplementary Payment', 'Compensation']}
                        onChange={(event, newValue) =>
                          handleAutocompleteChange(event, newValue, index, 'processor_cashflow')
                        }
                        getOptionLabel={(option) => props.t(`${option} ${'Processor'}`)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required={true}
                            label={`${props.t('processor_cashflow')}`}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: `new-password`,
                            }}
                          />
                        )}
                      />

                      <TextField
                        style={{ margin: 10, minWidth: '300px' }}
                        onChange={(event) => handleChange(event, index)}
                        value={article.processor_condition}
                        required={false}
                        label={`${props.t('processor_condition')}`}
                        name={'processor_condition'}
                      />
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </div>
            <div>
              <span
                style={{
                  color: 'red',
                  float: 'right',
                  margin: 12,
                  cursor: 'pointer',
                  fontSize: 30,
                }}
                onClick={() => {
                  deleteRow(index);
                }}
                class="material-icons"
              >
                remove_circle
              </span>
            </div>
          </div>
          <hr style={{ marginTop: 30, width: '100%', color: 'lightgray' }} />
        </div>
      ))}
    </div>
  );
}

export default withTranslation('common')(Articles);
