import React from 'react';
import TextField from '@material-ui/core/TextField';

import { getArticles } from '../../redux/modules/article';
import DeleteIcon from '@material-ui/icons/Delete';
import { Checkbox } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import MultiInvoice from './multiInvoice';
import SaveIcon from '@material-ui/icons/Save';
import axios from 'axios';
import { getApiUrl } from '../../util/environment-utils';
import { remove } from '../../redux/store/actions/orders';

import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { formatNumberToEn } from '../../helpers/common';
import { getGermanNumber } from '../../helpers/documents';
import download from 'downloadjs';

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

class MultiWeightSlip extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      weightSlips: [],
      sourceEntries: [],
      carrierEntries: [],
      processorEntries: [],
      partners: [],
      carriers: [],
      articles: [],
      deleteArticleDoc: [],
    };
  }

  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    let articles = await this.props.getArticles();
    let partners = await this.props.getPartners();
    let carriers = await this.props.getCarriers();
    this.setState({
      partners: partners.allPartners,
      carriers: carriers.allCarriers,
      articles: articles.allArticles,
    });

    let currentArticles = this.props.currentEntity.articles;

    let weightSlips = [];
    if (currentArticles.length > 0) {
      for (let value of currentArticles) {
        let files = [];

        for (let file of value.files) {
          if (file) {
            files.push({ file: file, status: false });
          }
        }

        weightSlips.push({
          _id: value._id,
          article: value.articleDetails,
          sourceDate: value.source_weightslip_date ? value.source_weightslip_date : null,
          sourceNumber: value.source_weightslip_number ? value.source_weightslip_number : '',
          sourceWeight: value.source_weight ? value.source_weight : '',
          sourcePrice: value.source_price,
          sourceCurrency: value.source_currency,
          sourceUnit: value.source_unit,
          sourceAmount: value.source_amount,
          sourcePackagingUnits: value.source_packaging_units ? value.source_packaging_units : '',
          sourceDeviatingArticleName: value.source_deviating,
          sourceCondition: value.source_condition,
          processorDate: value.processor_weightslip_date ? value.processor_weightslip_date : null,
          processorNumber: value.processor_weightslip_number
            ? value.processor_weightslip_number
            : '',
          processorWeight: value.processor_weight ? value.processor_weight : '',
          processorPrice: value.processor_price,
          processorCurrency: value.processor_currency,
          processorUnit: value.processor_unit,
          processorAmount: value.processor_amount,
          processorPackagingUnits: value.processor_packaging_units
            ? value.processor_packaging_units
            : '',
          processorDeviatingArticleName: value.processor_deviating,
          processorCondition: value.processor_condition,
          files: files,
        });
      }
    } else {
      weightSlips.push({
        article: {},
        sourceDate: null,
        sourceNumber: '',
        sourceWeight: '',
        sourcePrice: '',
        sourceCurrency: '',
        sourceUnit: '',
        sourceAmount: '',
        sourcePackagingUnits: '',
        sourceDeviatingArticleName: '',
        sourceCondition: '',
        processorDate: null,
        processorNumber: '',
        processorWeight: '',
        processorPrice: '',
        processorCurrency: '',
        processorUnit: '',
        processorAmount: '',
        processorPackagingUnits: '',
        processorDeviatingArticleName: '',
        processorCondition: '',
      });
    }

    if (
      Object.keys(this.props.currentEntity.weightslips).length > 0 &&
      this.props.currentEntity.weightslips[0]
    ) {
      let sourceEntries = this.props.currentEntity.weightslips[0].sourceEntries;
      let carrierEntries = this.props.currentEntity.weightslips[0].carrierEntries;
      let processorEntries = this.props.currentEntity.weightslips[0].processorEntries;

      this.setState({
        sourceEntries,
        carrierEntries,
        processorEntries,
        weightSlips,
      });

      return;
    }

    let sourceEntries = this.state.sourceEntries;
    let carrierEntries = this.state.carrierEntries;
    let processorEntries = this.state.processorEntries;

    sourceEntries.push({
      source: this.props.currentEntity
        ? this.props.currentEntity.source[0]
          ? this.props.currentEntity.source[0]
          : ''
        : '',
      invoiceNumber: '',
      invoiceDate: null,
      totalInvoiceAmount: '',
      whenAttachedDate: null,
      transferredToLexOfficeDate: null,
    });

    processorEntries.push({
      processor: this.props.currentEntity
        ? this.props.currentEntity.processor[0]
          ? this.props.currentEntity.processor[0]
          : ''
        : '',
      invoiceNumber: '',
      invoiceDate: null,
      totalInvoiceAmount: '',
      whenAttachedDate: null,
      transferredToLexOfficeDate: null,
    });

    carrierEntries.push({
      carrier: this.props.currentEntity
        ? this.props.currentEntity.carrier[0]
          ? this.props.currentEntity.carrier[0]
          : ''
        : '',
      invoiceNumber: '',
      invoiceDate: null,
      totalInvoiceAmount: '',
      whenAttachedDate: null,
      transferredToLexOfficeDate: null,
    });

    this.setState({
      weightSlips: weightSlips,
      sourceEntries: sourceEntries,
      processorEntries: processorEntries,
      carrierEntries: carrierEntries,
    });
  };
  addNewRow = () => {
    let weightSlips = this.state.weightSlips;
    weightSlips.push({
      article: {},
      files: [],
      sourceDate: null,
      sourceNumber: '',
      sourceWeight: '',
      sourcePrice: '',
      sourceCurrency: 'Euro',
      sourceUnit: '',
      sourceAmount: '',
      sourcePackagingUnits: '',
      sourceDeviatingArticleName: '',
      sourceCondition: '',
      processorDate: null,
      processorNumber: '',
      processorWeight: '',
      processorPrice: '',
      processorCurrency: 'Euro',
      processorUnit: '',
      processorAmount: '',
      processorPackagingUnits: '',
      processorDeviatingArticleName: '',
      processorCondition: '',
    });

    this.setState({ weightSlips: weightSlips });
  };

  deleteRow = (index) => {
    this.props.remove(index);
    let weightSlips = this.state.weightSlips;
    weightSlips.splice(index, 1);

    this.setState({ weightSlips: weightSlips });
  };

  handleTextFieldChange = (evt, index) => {
    let weightSlips = this.state.weightSlips;
    weightSlips[index][evt.target.name] = evt.target.value;

    this.setState({ weightSlips: weightSlips });
  };

  handleDateChange = (date, index, type) => {
    let weightSlips = this.state.weightSlips;
    weightSlips[index][type] = moment(date).format('DD.MM.YYYY');

    this.setState({ weightSlips: weightSlips });
  };

  handleDatabaseMultiChoiceChange = (name, value, data, dataLabel) => {
    this.setState({
      [name]: value,
      [dataLabel]: data,
    });
    this.props.callbackValue(dataLabel, data);
  };

  handleAutocompleteChange = (event, value, index) => {
    let weightSlips = this.state.weightSlips;
    weightSlips[index].article = value;
    this.setState({ weightSlips: weightSlips });
  };

  handleCurrencyWeightChange = (event, value, index, type) => {
    let weightSlips = this.state.weightSlips;
    weightSlips[index][type] = value;

    this.setState({ weightSlips: weightSlips });
  };

  updateWeightSlips = () => {
    let { weightSlips, sourceEntries, carrierEntries, processorEntries } = { ...this.state };

    weightSlips.map((data) => {
      let neWweightSlips = [];
      data.files.map((item) => {
        neWweightSlips.push(item.file);
        data.files = neWweightSlips;
      });
    });
    this.props.handleUpdateWeightSlip({
      weightSlips,
      sourceEntries,
      carrierEntries,
      processorEntries,
    });
  };

  onDrop = (files, index) => {
    for (let key in files) {
      let data = new FormData();
      let BASE_URL = getApiUrl();
      data.append('files', files[key]);

      axios.post(`${BASE_URL}/orders/upload`, data, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      }).then(
        (res) => {
          let fileName = res.data.fileName;
          let entries = this.state.weightSlips;
          if (entries[index]['files'] && entries[index]['files'].length > 0) {
            entries[index]['files'].push({ file: fileName, status: false });
          } else {
            entries[index]['files'] = [{ file: fileName, status: false }];
          }

          this.setState({ weightSlips: entries });
        },
        (error) => {
          console.log(error);
        },
      );
    }
  };

  deleteAttachment = (mainindex) => {
    // let value = this.state.deleteArticleDoc.find(x => x.mainIndex === index)
    let entries = this.state.weightSlips;
    let array = entries[mainindex].files.filter((item) => item.status == false);
    entries[mainindex].files = array;
    this.setState({ weightSlips: entries });
  };
  getArticlesCheck = (e, file, index, mainIndex) => {
    let Status = this.state.weightSlips;
    if (Status.length > 0) {
      Status[mainIndex].files[index].status = e.target.checked;
    }
    this.setState({ weightSlips: Status });
  };
  handleFileDownload = (name) => {
    let BASE_URL = getApiUrl();
    axios({
      url: `${BASE_URL}/orders/download-file/${name}`,
      method: 'GET',
      responseType: 'blob',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then((response) => {
      download(response.data, name);
    });
  };

  getArticleTotalOfSource(weightSlip) {
    let total =
      formatNumberToEn(weightSlip['sourcePrice']) * formatNumberToEn(weightSlip['sourceWeight']);
    let finalValue = isNaN(total) ? '0' : total;

    return `${getGermanNumber(finalValue)} ${weightSlip['sourceCurrency'] == 'USD' ? '$' : '€'}`;
  }

  getArticleTotalOfProcessor(weightSlip) {
    let total =
      formatNumberToEn(weightSlip['processorPrice']) *
      formatNumberToEn(weightSlip['processorWeight']);
    let finalValue = isNaN(total) ? '0' : total;

    return `${getGermanNumber(finalValue)} ${
      weightSlip['processorCurrency'] == 'USD' ? '$' : '€'
    } `;
  }

  handleOrderRemarkChange = (event) => {
    this.props.editOrderInstance.setState({ orderRemark: event.target.value });
  };

  render() {
    return (
      <div>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div style={{ display: 'flex' }}>
            <Button
              startIcon={<SaveIcon />}
              variant="contained"
              color="primary"
              style={{
                marginTop: 10,
                marginLeft: '12px',
                backgroundColor: 'white',
                color: 'green',
              }}
              onClick={() => {
                this.updateWeightSlips();
              }}
            >
              {this.props.t('Update Weight Slips')}
            </Button>

          </div>

          <Paper style={{ marginTop: 20 }}>
            <Typography
              variant="h4"
              style={{ paddingLeft: 12, paddingTop: 15, display: 'inline-block' }}
            >
              {this.props.t('Incoming Documents')}
            </Typography>
            <hr style={{ width: '100%', color: 'lightgray' }} />
            <MultiInvoice
              entityType={'Source'}
              type={'sourceEntries'}
              currentEntity={this.props.currentEntity}
              formInvoice={{
                source: this.props.currentEntity
                  ? this.props.currentEntity.source[0]
                    ? this.props.currentEntity.source[0]
                    : ''
                  : '',
                invoiceNumber: '',
                invoiceDate: null,
                totalInvoiceAmount: '',
                whenAttachedDate: null,
                transferredToLexOfficeDate: null,
              }}
              self={this}
              editOrderInstance={this.props.editOrderInstance}
              {...this.props}
            />

            <MultiInvoice
              entityType={'Processor'}
              type={'processorEntries'}
              currentEntity={this.props.currentEntity}
              formInvoice={{
                processor: this.props.currentEntity
                  ? this.props.currentEntity.processor[0]
                    ? this.props.currentEntity.processor[0]
                    : ''
                  : '',
                invoiceNumber: '',
                invoiceDate: null,
                totalInvoiceAmount: '',
                whenAttachedDate: null,
                transferredToLexOfficeDate: null,
              }}
              self={this}
              {...this.props}
            />

            <MultiInvoice
              entityType={'Forwarder'}
              type={'carrierEntries'}
              currentEntity={this.currentEntity}
              formInvoice={{
                carrier: this.props.currentEntity
                  ? this.props.currentEntity.carrier[0]
                    ? this.props.currentEntity.carrier[0]
                    : ''
                  : '',
                invoiceNumber: '',
                invoiceDate: null,
                totalInvoiceAmount: '',
                whenAttachedDate: null,
                transferredToLexOfficeDate: null,
              }}
              self={this}
              {...this.props}
            />
          </Paper>
          <Paper style={{ padding: 12, width: '100%', marginTop: 15 }}>
            <Typography variant="h4" style={{ paddingTop: 15, display: 'inline-block' }}>
              {this.props.t('Articles')}
            </Typography>

            <AddCircleOutlineIcon
              style={{
                fontSize: 28,
                position: 'relative',
                top: -13,
                marginLeft: 15,
                cursor: 'pointer',
              }}
              color="primary"
              onClick={() => {
                this.addNewRow();
              }}
            />
            <hr style={{ width: '100%', color: 'lightgray' }} />
            {this.state.weightSlips.map((weightSlip, i) => {
              return (
                <div
                  style={{ padding: 12, height: '100%' }}
                  className="expense-block"
                  key={`key_${i}`}
                >
                  <div className="display-flex justify-space-betweens align-center">

                    <div>
                      <Grid container spacing={3} style={{ marginLeft: 5 }}>
                        <Autocomplete
                          style={{
                            margin: 10,
                            minWidth: 300,
                            maxWidth: 300,
                            display: 'inline-flex',
                          }}
                          id="combo-box-demo"
                          value={weightSlip.article}
                          options={this.state.articles}
                          onChange={(event, newValue) =>
                            this.handleAutocompleteChange(event, newValue, i)
                          }
                          getOptionLabel={(option) => {
                            return option.descriptionInEnglish
                              ? this.props.i18n.language === 'en'
                                ? option.descriptionInEnglish
                                : option.descriptionInGerman
                              : option.articleDetails
                              ? this.props.i18n.language === 'en'
                                ? option.articleDetails.descriptionInEnglish
                                : option.articleDetails.descriptionInGerman
                              : option;
                          }}
                          getOptionSelected={(option) =>
                            typeof weightSlip.article !== 'string' && weightSlip.article
                              ? option._id === weightSlip.article._id
                              : weightSlip.article
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`${this.props.t('Article')}`}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: `new-article`,
                              }}
                            />
                          )}
                        />
                        <div className="display-flex justify-space-between align-center">
                          <span style={{ marginLeft: '25px' }}>
                            {' '}
                            {this.props.t('Purchase total')} :
                            {this.getArticleTotalOfSource(weightSlip)}{' '}
                          </span>
                          <span style={{ marginLeft: '25px' }}>
                            {this.props.t('Sales total')} :
                            {this.getArticleTotalOfProcessor(weightSlip)}{' '}
                          </span>
                        </div>

                      </Grid>
                      <Grid
                        container
                        className="display-flex align-center"
                        spacing={3}
                        style={{ marginLeft: 5 }}
                      >
                        <span
                          style={{ paddingTop: '10px' }}
                          className="article_label display-inline-flex"
                        >
                          {this.props.t('Purchase')} :{' '}
                        </span>
                        <KeyboardDatePicker
                          style={{ margin: 10, width: '180px' }}
                          disableToolbar
                          variant="inline"
                          format="DD.MM.YYYY"
                          margin="normal"
                          // label={`${this.props.t('Source')}: ${this.props.t('Weight Slip Date')}`}
                          label={`${this.props.t('Weight Slip Date')}`}
                          value={
                            moment(weightSlip.sourceDate, 'DD.MM.YYYY').isValid()
                              ? moment(weightSlip.sourceDate, 'DD.MM.YYYY').toDate()
                              : null
                          }
                          onChange={(date) => this.handleDateChange(date, i, 'sourceDate')}
                          autoOk={true}
                        />
                        <TextField
                          style={{ margin: 10, width: '165px' }}
                          onChange={(event) => this.handleTextFieldChange(event, i)}
                          required={false}
                          dbid={'sourceNumber'}
                          name={'sourceNumber'}
                          // label={`${this.props.t('Source')}: ${this.props.t('Weight Slip Number')}`}
                          label={`${this.props.t('Weight Slip Number')}`}
                          value={weightSlip.sourceNumber}
                        />
                        <TextField
                          style={{ margin: 10, width: '100px' }}
                          onChange={(event) => this.handleTextFieldChange(event, i)}
                          required={false}
                          dbid={'sourceWeight'}
                          name={'sourceWeight'}
                          value={weightSlip.sourceWeight}
                          // label={`${this.props.t('Source')}: ${this.props.t('Weight')}`}
                          label={`${this.props.t(
                            weightSlip.sourceUnit === 'Piece' ? 'Amount' : 'Weight',
                          )}`}
                        />
                        <TextField
                          style={{ margin: 10, width: '100px' }}
                          onChange={(event) => this.handleTextFieldChange(event, i)}
                          required={false}
                          dbid={'sourcePrice'}
                          name={'sourcePrice'}
                          value={weightSlip.sourcePrice}
                          label={`${this.props.t('Price')}`}
                          // label={`${this.props.t('Source')}: ${this.props.t('Price')}`}
                        />

                        <Autocomplete
                          style={{
                            margin: 10,
                            minWidth: 100,
                            maxWidth: 300,
                            display: 'inline-flex',
                          }}
                          id="combo-box-demo"
                          value={weightSlip.sourceCurrency}
                          options={['Euro', 'USD']}
                          onChange={(event, newValue) =>
                            this.handleCurrencyWeightChange(event, newValue, i, 'sourceCurrency')
                          }
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // label={`${this.props.t('Source')}: ${this.props.t('Currency')}`}
                              label={`${this.props.t('Currency')}`}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: `new-source-currency`,
                              }}
                            />
                          )}
                        />

                        <Autocomplete
                          style={{
                            margin: 10,
                            minWidth: 100,
                            maxWidth: 300,
                            display: 'inline-flex',
                          }}
                          id="combo-box-demo"
                          value={weightSlip.sourceUnit}
                          options={['t', 'Kg', 'Piece']}
                          onChange={(event, newValue) =>
                            this.handleCurrencyWeightChange(event, newValue, i, 'sourceUnit')
                          }
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // label={`${this.props.t('Source')}: ${this.props.t('Units')}`}
                              label={`${this.props.t('Units')}`}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: `new-source-unit`,
                              }}
                            />
                          )}
                        />

                        <TextField
                          style={{ margin: 10, width: '150px' }}
                          onChange={(event) => this.handleTextFieldChange(event, i)}
                          required={false}
                          value={weightSlip.sourceAmount}
                          dbid={'sourceAmount'}
                          name={'sourceAmount'}
                          // label={`${this.props.t('Source')}: ${this.props.t('Amount PU')}`}
                          label={`${this.props.t('Amount PU')}`}
                        />

                        <Autocomplete
                          style={{
                            margin: 10,
                            minWidth: 165,
                            maxWidth: 300,
                            display: 'inline-flex',
                          }}
                          id="combo-box-demo"
                          value={weightSlip.sourcePackagingUnits}
                          options={['Bales', 'Packing Units']}
                          onChange={(event, newValue) =>
                            this.handleCurrencyWeightChange(
                              event,
                              newValue,
                              i,
                              'sourcePackagingUnits',
                            )
                          }
                          getOptionLabel={(option) => this.props.t(option)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // label={`${this.props.t('Source')}: ${this.props.t('Packaging Unit')}`}
                              label={`${this.props.t('Packaging Unit')}`}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: `new-source-unit`,
                              }}
                            />
                          )}
                        />

                        <TextField
                          style={{ margin: 10, width: '168px' }}
                          onChange={(event) => this.handleTextFieldChange(event, i)}
                          required={false}
                          value={weightSlip.sourceDeviatingArticleName}
                          dbid={'sourceDeviatingArticleName'}
                          name={'sourceDeviatingArticleName'}
                          // label={`${this.props.t('Source')}: ${this.props.t('Deviating Article Name')}`}
                          label={`${this.props.t('Deviating Article Name')}`}
                        />
                        <TextField
                          style={{ margin: 10, width: '165px' }}
                          onChange={(event) => this.handleTextFieldChange(event, i)}
                          required={false}
                          dbid={'sourceCondition'}
                          name={'sourceCondition'}
                          value={weightSlip.sourceCondition}
                          // label={`${this.props.t('Source')}: ${this.props.t('Condition')}`}
                          label={`${this.props.t('Condition')}`}
                        />
                      </Grid>
                      <br />
                      <Grid
                        className="display-flex align-center"
                        container
                        spacing={3}
                        style={{ marginLeft: 5 }}
                      >
                        <span
                          style={{ paddingTop: '10px' }}
                          className="article_label display-inline-flex"
                        >
                          {this.props.t('Sales')} :{' '}
                        </span>
                        <KeyboardDatePicker
                          style={{ margin: 10, width: '180px' }}
                          disableToolbar
                          variant="inline"
                          format="DD.MM.YYYY"
                          margin="normal"
                          // label={`${this.props.t('Processor')}: ${this.props.t('Weight Slip Date')}`}
                          label={`${this.props.t('Weight Slip Date')}`}
                          value={
                            moment(weightSlip.processorDate, 'DD.MM.YYYY').isValid()
                              ? moment(weightSlip.processorDate, 'DD.MM.YYYY').toDate()
                              : null
                          }
                          onChange={(date) => this.handleDateChange(date, i, 'processorDate')}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          autoOk={true}
                        />
                        <TextField
                          style={{ margin: 10, width: '165px' }}
                          onChange={(event) => this.handleTextFieldChange(event, i)}
                          required={false}
                          dbid={'processorNumber'}
                          name={'processorNumber'}
                          value={weightSlip.processorNumber}
                          // label={`${this.props.t('Processor')}: ${this.props.t('Weight Slip Number')}`}

                          label={`${this.props.t('Weight Slip Number')}`}
                        />
                        <TextField
                          style={{ margin: 10, width: '100px' }}
                          onChange={(event) => this.handleTextFieldChange(event, i)}
                          required={false}
                          dbid={'processorWeight'}
                          name={'processorWeight'}
                          value={weightSlip.processorWeight}
                          // label={`${this.props.t('Processor')}: ${this.props.t('Weight')}`}
                          label={`${this.props.t(
                            weightSlip.processorUnit === 'Piece' ? 'Amount' : 'Weight',
                          )}`}
                        />
                        <TextField
                          style={{ margin: 10, width: '100px' }}
                          onChange={(event) => this.handleTextFieldChange(event, i)}
                          required={false}
                          dbid={'processorPrice'}
                          name={'processorPrice'}
                          value={weightSlip.processorPrice}
                          // label={`${this.props.t('Processor')}: ${this.props.t('Price')}`}
                          label={`${this.props.t('Price')}`}
                        />

                        <Autocomplete
                          style={{
                            margin: 10,
                            minWidth: 100,
                            maxWidth: 300,
                            display: 'inline-flex',
                          }}
                          id="combo-box-demo"
                          value={weightSlip.processorCurrency}
                          options={['Euro', 'USD']}
                          onChange={(event, newValue) =>
                            this.handleCurrencyWeightChange(event, newValue, i, 'processorCurrency')
                          }
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // label={`${this.props.t('Processor')}: ${this.props.t('Currency')}`}
                              label={`${this.props.t('Currency')}`}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: `new-processor-currency`,
                              }}
                            />
                          )}
                        />

                        <Autocomplete
                          style={{
                            margin: 10,
                            minWidth: 100,
                            maxWidth: 300,
                            display: 'inline-flex',
                          }}
                          id="combo-box-demo"
                          value={weightSlip.processorUnit}
                          options={['t', 'Kg', 'Piece']}
                          onChange={(event, newValue) =>
                            this.handleCurrencyWeightChange(event, newValue, i, 'processorUnit')
                          }
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // label={`${this.props.t('Processor')}: ${this.props.t('Units')}`}
                              label={`${this.props.t('Units')}`}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: `new-processor-unit`,
                              }}
                            />
                          )}
                        />

                        <TextField
                          style={{ margin: 10, width: '150px' }}
                          onChange={(event) => this.handleTextFieldChange(event, i)}
                          required={false}
                          dbid={'processorAmount'}
                          name={'processorAmount'}
                          value={weightSlip.processorAmount}
                          // label={`${this.props.t('Processor')}: ${this.props.t('Amount PU')}`}
                          label={`${this.props.t('Amount PU')}`}
                        />

                        <Autocomplete
                          style={{
                            margin: 10,
                            minWidth: 165,
                            maxWidth: 300,
                            display: 'inline-flex',
                          }}
                          id="combo-box-demo"
                          value={weightSlip.processorPackagingUnits}
                          options={['Bales', 'Packing Units', 'Add new ones']}
                          onChange={(event, newValue) =>
                            this.handleCurrencyWeightChange(
                              event,
                              newValue,
                              i,
                              'processorPackagingUnits',
                            )
                          }
                          getOptionLabel={(option) => this.props.t(option)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // label={`${this.props.t('Processor')}: ${this.props.t('Packaging Unit')}`}
                              label={`${this.props.t('Packaging Unit')}`}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: `new-processor-unit`,
                              }}
                            />
                          )}
                        />

                        <TextField
                          style={{ margin: 10, width: '168px' }}
                          onChange={(event) => this.handleTextFieldChange(event, i)}
                          required={false}
                          dbid={'processorDeviatingArticleName'}
                          name={'processorDeviatingArticleName'}
                          value={weightSlip.processorDeviatingArticleName}
                          // label={`${this.props.t('Processor')}: ${this.props.t(
                          //   'Deviating Article Name',
                          // )}`}
                          label={`${this.props.t('Deviating Article Name')}`}
                        />
                        <TextField
                          style={{ margin: 10, width: '165px' }}
                          onChange={(event) => this.handleTextFieldChange(event, i)}
                          required={false}
                          dbid={'processorCondition'}
                          name={'processorCondition'}
                          value={weightSlip.processorCondition}
                          // label={`${this.props.t('Processor')}: ${this.props.t('Condition')}`}
                          label={`${this.props.t('Condition')}`}
                        />
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item sm={12} md={12} xs={12} lg={12} style={{ margin: '12px' }}>
                          <Dropzone
                            onDrop={(event) => this.onDrop(event, i)}
                            maxSize={10 * 1024 * 1024}
                            onDropRejected={() => alert(this.props.t('file_size_limit'))}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section className="dropzone-container display-flex flex-direction-row">
                                <div {...getRootProps({ className: 'dropzone flex-unset' })}>
                                  <input {...getInputProps()} />
                                  <p>
                                    {this.props.t(
                                      "Drag 'n' drop invoice doc here, or click to select file",
                                    )}
                                  </p>
                                </div>
                                <aside className="display-flex align-center">
                                  {/* <h4>{this.props.t('Files')}</h4> */}
                                  <ul className="display-flex" style={{ margin: 0 }}>
                                    {weightSlip.files &&
                                      weightSlip.files.map((file, fileIndex) => (
                                        <div className="display-flex">
                                          {console.log(weightSlip.files, ' weightSlip.files')}
                                          <Checkbox
                                            checked={file.status}
                                            name={`check${i}_${file}`}
                                            onChange={(e) =>
                                              this.getArticlesCheck(e, file.file, fileIndex, i)
                                            }
                                          />
                                          <li className="list-style-none" key={fileIndex}>
                                            <div
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                              }}
                                            >
                                              <p
                                                className={'file-link'}
                                                onClick={() => this.handleFileDownload(file.file)}
                                              >
                                                {file.file == undefined
                                                  ? file
                                                  : typeof file.file === 'string'
                                                  ? file.file
                                                  : file['file'].file}
                                              </p>
                                            </div>
                                          </li>
                                        </div>
                                      ))}
                                  </ul>
                                  {weightSlip.files &&
                                    weightSlip.files.some((data) => data.status === true) && (
                                      <>
                                        <DeleteIcon
                                          style={{
                                            color: 'red',
                                            backgroundColor: 'white',
                                            float: 'right',
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                          }}
                                          onClick={() => {
                                            this.deleteAttachment(i);
                                          }}
                                        />
                                      </>
                                    )}
                                </aside>
                              </section>
                            )}
                          </Dropzone>
                        </Grid>
                      </Grid>
                    </div>

                    <div>
                      <span
                        style={{
                          color: 'red',
                          float: 'right',
                          margin: 12,
                          cursor: 'pointer',
                          fontSize: 30,
                        }}
                        onClick={() => {
                          this.deleteRow(i);
                        }}
                        class="material-icons"
                      >
                        remove_circle
                      </span>
                    </div>
                  </div>
                  <hr style={{ marginTop: 30, width: '100%', color: 'lightgray' }} />
                </div>
              );
            })}
          </Paper>
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}

const mapStateToProps = ({ authentication }) => ({});

export default connect(mapStateToProps, { getArticles, remove })(
  withTranslation('common')(MultiWeightSlip),
);
