import React from 'react';
import { getDates, getPaymentTerms } from '../../helpers/documents';
import { getApiUrl } from '../../util/environment-utils';
import { withTranslation } from 'react-i18next';
import Footer from './footer';
import Header from './header';

class Invoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date(),
      apiUrl: getApiUrl(),
    };
  }

  getTotalAmount = () => {
    let amount = 0;

    for (let article of this.props.currentEntity.commissionArticles) {
      amount += parseFloat(
        article.commission_price && article.commission_price !== '' ? article.commission_price : 0,
      );
    }

    return amount;
  };

  getTaxAmount = (total, taxPercent) => {
    return !isNaN(taxPercent) ? taxPercent * total : 0;
  };

  getTotalAmountWithTax = () => {
    let totalAmount = this.getTotalAmount();
    let taxAmount = this.getTaxAmount(totalAmount, this.props.tax.tax_rate_processor);

    return totalAmount + taxAmount;
  };

  getTaxPercent = (tax) => {
    return isNaN(tax) ? 0 : tax / 100;
  };

  render() {
    return (
      <div className={'documents-container'}>
        <div className="main invoice">
          <Header
            locationData={this.props.currentEntity.commissionArticles[0].commissionPartner}
            {...this.props}
          />
          <div className="invoice-body">
            <h3>
              Rechnung{' '}
              {this.props.currentEntity.invoiceNumber ? `${this.props.companyData.invoiceNumberPrefix ? `${this.props.companyData.invoiceNumberPrefix}-` : ''}${this.props.currentEntity.invoiceNumber}${this.props.companyData.invoiceNumberSuffix ? `-${this.props.companyData.invoiceNumberSuffix}` : ''}` : ''}
            </h3>
            <p className="disc">Sehr geehrte Damen und Herren,</p>
            <p className="disc-2">hiermit berechnen wir wie folgt:</p>

            <div className="tbl">
              <div className="row">
                <div className="cell bold first-col">Material</div>
                <div className="cell bold">Preis je t</div>
                <div className="cell bold">Gesamtpreis</div>
              </div>

              {this.props.currentEntity.commissionArticles.map((article, index) => (
                <div className="row" key={index} style={{ borderTop: "none" }}>
                  <div className="cell first-col">
                    {article.source_deviating && article.source_deviating !== ''
                      ? `${article.source_deviating}`
                      : article.article_name}
                  </div>
                  <div className="cell text-right">
                    {article.commission_price} {article.commission_currency === 'Euro' ? '€' : '$'}
                  </div>
                  <div className="cell text-right">
                    {1 *
                      parseFloat(
                        article.commission_price && article.commission_price !== ''
                          ? article.commission_price
                          : 0,
                      )}{' '}
                    {article.commission_currency === 'Euro' ? '€' : '$'}
                  </div>
                </div>
              ))}
            </div>

            <div className={'total page-avoid border-none'} style={{ marginTop: '14px' }}>
              <div className="row border-none">
                <div className="cell border-none text-right"></div>
                <div className="cell border-none text-right"></div>
                <div className="cell border-none text-right"></div>
                <div className="cell border-none text-right"></div>
                <div className="cell border-none text-right"></div>
                <div className="cell border-none text-right">Total</div>
                <div className="cell border-none text-right">
                  {this.getTotalAmount()}{' '}
                  {this.props.currentEntity.commissionArticles.length > 0
                    ? this.props.currentEntity.commissionArticles[0].commission_currency === 'Euro'
                      ? '€'
                      : '$'
                    : ''}
                </div>
              </div>

              <div className="row border-none">
                <div className="cell border-none"></div>
                <div className="cell border-none"></div>
                <div className="cell border-none"></div>
                <div className="cell border-none"></div>
                <div className="cell border-none"></div>
                <div className="cell border-none text-right">
                  MwSt {this.getTaxPercent(this.props.tax.tax_rate_processor)}%{' '}
                </div>
                <div className="cell border-none text-right">
                  {this.getTaxAmount(this.getTotalAmount(), this.props.tax.tax_rate_processor)}{' '}
                  {this.props.currentEntity.commissionArticles.length > 0
                    ? this.props.currentEntity.commissionArticles[0].commission_currency === 'Euro'
                      ? '€'
                      : '$'
                    : ''}
                </div>
              </div>

              <div className="row gesamtbetrag border-none">
                <div className="cell border-none"></div>
                <div className="cell border-none"></div>
                <div className="cell border-none"></div>
                <div className="cell border-none"></div>
                <div className="cell border-none"></div>
                <div className="cell border-none text-right bold">Gesamtbetrag</div>
                <div className="cell border-none text-right bold">
                  {this.getTotalAmountWithTax()}{' '}
                  {this.props.currentEntity.commissionArticles.length > 0
                    ? this.props.currentEntity.commissionArticles[0].commission_currency === 'Euro'
                      ? '€'
                      : '$'
                    : ''}
                </div>
              </div>
            </div>

            <div className="zahlungsziel">
              <h3
                dangerouslySetInnerHTML={{
                  __html: getPaymentTerms(
                    this.props.currentEntity.commissionArticles[0].commissionPartner.paymentTerms,
                    this.props.currentEntity,
                    'processor',
                    this.props.language,
                  ),
                }}
              />
            </div>

            <div className="team-address page-avoid">
              <p className={'mt-3'}>Vielen Dank für den Auftrag!</p>
              <p>Mit freundlichem Gruß</p>
              <p className="mt-3">Ihr {this.props.companyData.name2} Team</p>
            </div>
          </div>
          <Footer {...this.props} />
        </div>
      </div>
    );
  }
}

export default withTranslation('common')(Invoice);
