import React from 'react';
import {
  getCountry,
  getDates,
  getGermanCollectionValues,
  getGermanNumber, getLanguageAcronym,
  getPartnerPurchaseConf,
  getPaymentTerms,
  getRecipient,
} from '../../helpers/documents';
import { getApiUrl } from '../../util/environment-utils';
import { withTranslation } from 'react-i18next';
import Footer from './footer';
import Header from './header';
import { formatNumberToEn } from '../../helpers/common';

class PurchaseConf extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date(),
      apiUrl: getApiUrl(),
    };
  }

  getPartner() {
    let partner = getPartnerPurchaseConf(
      this.props.sourceData,
      this.props.processorData,
      this.props.currentEntity,
    );

    return partner;
  }

  getLanguage = () => {
    let defaultLanguage = 'de';
    let language = this.props.sourceData
      ? this.props.sourceData
        ? this.props.sourceData.documentLanguage
        : defaultLanguage
      : defaultLanguage;

    return getLanguageAcronym(language);
  };

  render() {
    const language = this.getLanguage();

    if (language === 'de') {
      return (
        <div className={'documents-container'}>
          <div className="main purchase">
            <Header
              address={getRecipient(this.props, 'purchase_confirmation', language)}
              accountManager={this.props.fieldAgent}
              partnerLanguage={language}
              {...this.props}
            />
            <div className="invoice-body">
              <h3>Einkaufsbestätigung {this.props.currentEntity.orderNumber}</h3>
              <p className="disc">Sehr geehrte Damen und Herren,</p>
              <p className="disc-2">wir bestätigen Ihnen den Einkauf von einer Ladung wie folgt:</p>

              <div className="tbl">
                <div className="row">
                  <div className="cell bold first-col">Material</div>
                  <div className="cell bold cell-small">
                    {this.getPartner() ? this.props.t(this.getPartner().heading) : ''}
                  </div>
                  <div className="cell bold reference-col">
                    {this.getPartner() ? this.getPartner().reference : ''}
                  </div>
                  <div className="cell bold cell-small">
                    {this.getPartner() ? this.getPartner().date : ''}
                  </div>
                  {/*<div className="cell bold cell-small">Menge in t</div>*/}
                  <div className="cell bold cell-small">Preis je Einheit</div>
                </div>
                {this.props.currentEntity.articles.map((article, index) => (
                  <div className="row" key={index} style={{ borderTop: "none" }}>
                    <div className="cell first-col">
                      {article.source_deviating && article.source_deviating !== ''
                        ? `${article.source_deviating}`
                        : this.props.i18n.language === 'en'
                        ? article.articleDetails.descriptionInEnglish
                        : article.articleDetails.descriptionInGerman}
                    </div>
                    <div className="cell cell-small">
                      {this.getPartner() ? this.getPartner().partner.city : ''}
                    </div>
                    <div className="cell reference-col">
                      {this.getPartner() ? this.getPartner().referenceValue : ''}
                    </div>
                    <div className="cell cell-small">
                      {getGermanCollectionValues(
                        this.getPartner() ? this.getPartner().collection : '',
                      )}{' '}
                      {getDates(this.getPartner() ? this.getPartner().referenceDate : '')}
                    </div>
                    {/*<div className="cell text-right cell-small">*/}
                    {/*  {getGermanNumber(formatNumberToEn(this.props.currentEntity.minLoadingWeight))}{' '}*/}
                    {/*  {article.source_unit}*/}
                    {/*</div>*/}
                    <div className="cell cell-small">
                      {getGermanNumber(formatNumberToEn(article.source_price))}{' '}
                      {article.source_currency === 'Euro' ? '€' : '$'}
                    </div>
                  </div>
                ))}
              </div>

              {this.props.currentEntity.articles[0].source_condition &&
                this.props.currentEntity.articles[0].source_condition.length > 0 && (
                  <p className="geschreddert">
                    <span className="mr-1">
                      <b>-</b>
                    </span>{' '}
                    <b>{this.props.currentEntity.articles[0].source_condition}</b>
                  </p>
                )}

              <div className="address">
                {this.getPartner() && (
                  <p>
                    <span className="underline">{this.getPartner().heading}:</span> <br />{' '}
                    {this.getPartner().partner.locationName
                      ? this.getPartner().partner.locationName
                      : this.getPartner().partner.name1}{' '}
                    <br />
                    {this.getPartner().partner.street} {this.getPartner().partner.houseNumber}{' '}
                    <br />
                    {this.getPartner().partner.zipcode} {this.getPartner().partner.city} <br />
                    {getCountry(this.getPartner().partner.country, language)}
                  </p>
                )}
              </div>

              <span className="mt-4 lieferbedingung">
                Mindestladegewicht:{' '}
                {this.props.currentEntity.minLoadingWeight
                  ? getGermanNumber(
                      formatNumberToEn(this.props.currentEntity.minLoadingWeight),
                      'weight',
                    )
                  : ''}
                {this.props.currentEntity.articles[0].source_unit}
              </span>
              <br />
              <span className="lieferbedingung">
                Lieferbedingung: {this.props.currentEntity.incotermSource}
              </span>
              <div
                className={'payment-terms'}
                dangerouslySetInnerHTML={{
                  __html: getPaymentTerms(
                    this.props.currentEntity.deviatingInvoiceAddressSource[0]
                      ? this.props.currentEntity.deviatingInvoiceAddressSource[0].paymentTerms
                      : this.props.sourceData.paymentTerms,
                    this.props.currentEntity,
                    'source',
                    language,
                  ),
                }}
              />
              <div className="team-address page-avoid">
                <p className={'mt-3'}>Wir danken für Ihren Auftrag.</p>
                <p>Mit freundlichem Gruß</p>
                <p className="mt-3">Ihr {this.props.companyData.name2} Team</p>
              </div>
            </div>
            <Footer {...this.props} />
          </div>
        </div>
      );
    } else {
      return (
        <div className={'documents-container'}>
          <div className="main purchase">
            <Header
              address={getRecipient(this.props, 'purchase_confirmation', language)}
              accountManager={this.props.fieldAgent}
              partnerLanguage={language}
              {...this.props}
            />
            <div className="invoice-body">
              <h3>Purchase confirmation {this.props.currentEntity.orderNumber}</h3>
              <p className="disc">Dear Sir or Madam,</p>
              <p className="disc-2">we hereby confirm the purchase of one load as follows:</p>

              <div className="tbl">
                <div className="row">
                  <div className="cell bold first-col">Material</div>
                  <div className="cell bold cell-small">
                    {this.props.t(this.getPartner() ? this.getPartner().headingEnglish : '')}
                  </div>
                  <div className="cell bold reference-col">
                    {this.props.t(this.getPartner() ? this.getPartner().referenceEnglish : '')}
                  </div>
                  <div className="cell bold cell-small">
                    {this.props.t(this.getPartner() ? this.getPartner().dateEnglish : '')}
                  </div>
                  {/*<div className="cell bold cell-small">Menge in t</div>*/}
                  <div className="cell bold cell-small"> Price per unit</div>
                </div>
                {this.props.currentEntity.articles.map((article, index) => (
                  <div className="row" key={index} style={{ borderTop: "none" }}>
                    <div className="cell first-col">
                      {article.source_deviating && article.source_deviating !== ''
                        ? `${article.source_deviating}`
                        : article.articleDetails.descriptionInEnglish}
                    </div>
                    <div className="cell cell-small">
                      {this.getPartner() ? this.getPartner().partner.city : ''}
                    </div>
                    <div className="cell reference-col">
                      {this.getPartner() ? this.getPartner().referenceValue : ''}
                    </div>
                    <div className="cell cell-small">
                      {this.getPartner() ? this.getPartner().collection : ''}{' '}
                      {getDates(this.getPartner() ? this.getPartner().referenceDate : '')}
                    </div>

                    <div className="cell cell-small">
                      {getGermanNumber(formatNumberToEn(article.source_price))}{' '}
                      {article.source_currency === 'Euro' ? '€' : '$'}
                    </div>
                  </div>
                ))}
              </div>

              {this.props.currentEntity.articles[0].source_condition &&
                this.props.currentEntity.articles[0].source_condition.length > 0 && (
                  <p className="geschreddert">
                    <span className="mr-1">
                      <b>-</b>
                    </span>{' '}
                    <b>{this.props.currentEntity.articles[0].source_condition}</b>
                  </p>
                )}

              <div className="address">
                {this.getPartner() && (
                  <p>
                    <span className="underline">{this.getPartner().headingEnglish}:</span> <br />{' '}
                    {this.getPartner().partner.locationName
                      ? this.getPartner().partner.locationName
                      : this.getPartner().partner.name1}{' '}
                    <br />
                    {this.getPartner().partner.street} {this.getPartner().partner.houseNumber}{' '}
                    <br />
                    {this.getPartner().partner.zipcode} {this.getPartner().partner.city} <br />
                    {getCountry(this.getPartner().partner.country, language)}
                  </p>
                )}
              </div>

              <span className="mt-4 lieferbedingung">
                Minimum loading weight:{' '}
                {this.props.currentEntity.minLoadingWeight
                  ? getGermanNumber(
                      formatNumberToEn(this.props.currentEntity.minLoadingWeight),
                      'weight',
                    )
                  : ''}
                {this.props.currentEntity.articles[0].source_unit}
              </span>
              <br />
              <span className="lieferbedingung">
                Delivery incoterm: {this.props.currentEntity.incotermSource}
              </span>
              <div
                className={'payment-terms'}
                dangerouslySetInnerHTML={{
                  __html: getPaymentTerms(
                    this.props.currentEntity.deviatingInvoiceAddressSource[0]
                      ? this.props.currentEntity.deviatingInvoiceAddressSource[0].paymentTerms
                      : this.props.sourceData.paymentTerms,
                    this.props.currentEntity,
                    'source',
                    language,
                  ),
                }}
              />
              <div className="team-address page-avoid">
                <p className={'mt-3'}> Thank you for your order.</p>
                <p> With kind regards </p>
                <p className="mt-3">Your {this.props.companyData.name2} team</p>
              </div>
            </div>
            <Footer {...this.props} />
          </div>
        </div>
      );
    }
  }
}

export default withTranslation('common')(PurchaseConf);
