import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { withTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDispatch } from 'react-redux';
import { getCountries } from '../../redux/modules/countries';
const unzip = require('zip-to-city');

function ZipcodeInput(props) {
  const dispatch = useDispatch();
  const { index = null } = props;
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState({});
  const [city, setCity] = useState('');

  useEffect(() => {
    function getCountriesList() {
      dispatch(getCountries()).then((res) => {
        setCountries(res);
      });
    }

    if (countries.length === 0) {
      getCountriesList();
    }
  }, [dispatch, countries]);

  useEffect(() => {
    if (city === '') {
      setCity(props.city);
    }
  }, [props.city]);

  useEffect(() => {
    if (Object.keys(country).length === 0 && countries.length > 0) {
      for (let c of countries) {
        if (c.name === props.country) {
          setCountry(c);
          break;
        }
      }
    }
  }, [props.country, countries.length]);

  function handleChange(evt) {
    let data = unzip(evt.target.value);
    if (data) {
      props.setState('city', data, index);
      props.setState('country', 'Germany', index);
      setCity(data);
      setCountry({ name: 'Germany', code: 'DE' });
    }

    props.setState('zipcode', evt.target.value, index);
  }

  function handleCityChange(evt) {
    setCity(evt.target.value);
    props.setState('city', evt.target.value, index);
  }

  function handleCountryChange(event, newValue) {
    if (newValue) {
      setCountry(newValue);
      props.setState('country', newValue.name, index);
    }
  }

  return (
    <React.Fragment>
      <TextField
        type="text"
        style={props.style}
        onChange={handleChange}
        name={props.dbid}
        required={props.required}
        label={props.t(props.label)}
        dbid={props.dbid}
        defaultValue={props.zipcode}
        autoComplete="new-zipcode"
      />

      <TextField
        style={props.style}
        onChange={handleCityChange}
        name={'city'}
        required={props.required}
        label={props.t('City')}
        dbid={'city'}
        value={city}
        autoComplete="new-city"
      />

      <Autocomplete
        style={{
          margin: 10,
          minWidth: 300,
          maxWidth: 300,
          display: 'inline-flex',
        }}
        id="combo-box-demo"
        autoComplete={`new-${props.country}`}
        value={country}
        options={countries}
        onChange={(event, newValue) => {
          handleCountryChange(event, newValue);
        }}
        getOptionLabel={(option) => props.t(option.name)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.t('Country')}
            required={true}
            inputProps={{
              ...params.inputProps,
              autoComplete: `new-password`,
            }}
          />
        )}
      />
    </React.Fragment>
  );
}

export default withTranslation('common')(ZipcodeInput);
