import React from 'react';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import OpeningHours from '../persons/OpeningHours';
import Locations from '../submenus/locations';
import PersonSelectTabs from './personSelectTabs';
import PersonAddModal from '../modals/personAddModal';
import PersonEditModal from '../modals/personEditModal';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useSelector, useDispatch } from 'react-redux';
import * as Actions from '../../redux/store/actions/partners';
import ChipInput from 'material-ui-chip-input';

function Partners(props) {
  const dispatch = useDispatch();
  const { self } = props;
  const { locations } = useSelector(({ partners }) => partners);

  function addNewRow() {
    dispatch(Actions.addLocation());
  }

  async function handleSave() {
    let payload = { ...self.state };
    payload.openingHours = self.props.openingHours;
    payload.locations = locations;

    let keysToRemove = [
      'signatures',
      'sourceLocation',
      'sourceLocations',
      'tile0',
      'tile1',
      'tile2',
      'tile3',
      'tile4',
      'tile5',
      'tileData',
      'vehicleTypes',
      'warehouseArticles',
      'processorLocation',
      'processorLocations',
      'selectedPersons',
      'selectedProcessor',
      'selectedSource',
      'documents'
    ];

    for (let key of Object.keys(payload)) {
      if (keysToRemove.includes(key)) delete payload[key];
    }

    await self.props.editPartner(self.currentEntity._id, payload);

    setTimeout(() => {
      self.getSchema(true);
    }, 300);
  }

  function handlePodChange(chips) {
    self.setState({ pod: chips });
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <form noValidate autoComplete="off">
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          style={{ margin: 10, float: 'left', backgroundColor: 'white', color: 'green' }}
          onClick={handleSave}
        >
          {`${self.props.t('Save Partner')}`}
        </Button>
        <Button
          variant="contained"
          startIcon={<CancelIcon />}
          style={{ margin: 10, float: 'left', backgroundColor: 'white', color: 'red' }}
          onClick={() => self.props.cancelBtn()}
        >
          {self.props.t('Cancel')}
        </Button>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper style={{ padding: 12, height: '100%' }}>
              <Typography variant="h4" style={{ paddingBottom: 30 }}>
                {self.props.t(self.state.header0)}
              </Typography>
              {self.state.tile0}
              {self.state.partnerType && self.state.partnerType === 'Debitor/Kreditor' && (
                <ChipInput
                classes={{
                root: 'h-56',
                input: 'mg-top-20',
                chipContainer: 'mg-top-20',
              }}
                style={{
                minWidth: 300,
                margin: '2px 10px',
              }}
                defaultValue={self.state['pod']}
                onChange={(chips) => handlePodChange(chips)}
                placeholder={'POD'}
                />
              )}
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper style={{ padding: 12, height: '100%' }}>
              <Typography variant="h4" style={{ paddingBottom: 30 }}>
                {self.props.t('Opening Hours')}
              </Typography>
              <OpeningHours />
            </Paper>
          </Grid>
          {self.state['partnerType'] && self.state['partnerType'] === 'Debitor/Kreditor' && (
            <Grid item xs={12}>
              <Paper style={{ padding: 12, height: '100%' }}>
                <Typography variant="h4" style={{ paddingBottom: 30 }}>
                  {self.props.t(self.state.header1)}
                </Typography>
                {self.state.tile1}
              </Paper>
            </Grid>
          )}

          <Grid item xs={12}>
            <Paper style={{ padding: 12, height: '100%' }}>
              <Typography variant="h4" style={{ display: 'inline-block' }}>
                {self.props.t('additional_locations')}
              </Typography>
              <AddCircleOutlineIcon
                style={{
                  fontSize: 28,
                  position: 'relative',
                  top: -2,
                  marginLeft: 15,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  addNewRow();
                }}
                color="primary"
              />

              <Locations variant={'edit'} selectedPartner={self.currentEntity} {...self.props} />
            </Paper>
          </Grid>

          {/*TABS HERE*/}
          <Grid item xs={12}>
            <Typography variant="h4" style={{ paddingBottom: 30 }}>
              {self.props.t('Contact Persons')}
            </Typography>
            <Paper style={{ padding: 12, height: '100%' }}>
              <PersonSelectTabs
                loaded={self.state.loadedPersons}
                allPersons={self.state.allPersons}
                onAdd={() => self.setState({ addModalOpen: true })}
                onEdit={(rowData) =>
                  self.setState({ editPersonModal: true, selectedRow: rowData._id })
                }
                onSelect={(selected) => {
                  self.setState({ contactPersons: selected });
                }}
                onRemove={(selected) => {
                  let persons = self.state.contactPersons;
                  let index = persons.findIndex((val) => val._id === selected);
                  if (index > -1) {
                    persons.splice(index, 1);
                    self.setState({ contactPersons: persons }, async () => {
                      self.state.openingHours = self.props.openingHours;
                      let payload = { ...self.state };
                      payload.contactPersons = persons;
                      await self.props.editPartner(self.currentEntity._id, payload);
                      setTimeout(() => {
                        self.getSchema(true);
                      }, 100);
                    });
                  }
                }}
                onSave={handleSave}
                reload={() => self.getSchema()}
              />
            </Paper>
            <PersonAddModal
              open={self.state.addModalOpen}
              currentEntity={self.currentEntity}
              variant={'partner'}
              handleClose={() => {
                self.setState({ addModalOpen: false });
                self.getSchema();
              }}
            />

            {self.state.editPersonModal && (
              <PersonEditModal
                open={self.state.editPersonModal}
                currentEntity={self.currentEntity}
                selectedRow={self.state.selectedRow}
                variant={'partner'}
                handleClose={(reload = false) => {
                  self.setState({ editPersonModal: false, selectedRow: null });
                  if (reload) self.getSchema();
                }}
                {...self.props}
              />
            )}
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default Partners;
