export const defaultPermissions = {
  carriers_create: false,
  carriers_delete: false,
  carriers_read: false,
  carriers_update: false,
  invoice_create: false,
  invoice_read: false,
  finance_read: false,
  orders_create: false,
  orders_delete: false,
  orders_read: false,
  orders_update: false,
  partners_create: false,
  partners_delete: false,
  partners_read: false,
  partners_update: false,
  tuning_delete: false,
  tuning_read: false,
  tuning_update: false,
  weightSlips_delete: false,
  weightSlips_read: false,
  weightSlips_update: false,
  system_read: false,
  stocks_read: false,
  company_read: false,
  contracts_read: false,
  contracts_update: false,
  contracts_create: false,
  contracts_delete: false,
};
