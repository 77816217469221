import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import EmailField from '../persons/Emails';
import TextField from '@material-ui/core/TextField';
import ContractArticles from '../submenus/contract-articles';
import Autocomplete from '@material-ui/lab/Autocomplete';

function Contract(props) {
  const { self, state } = props;

  function save() {
    let keysToRemove = [
      'signatures',
      'sourceLocation',
      'sourceLocations',
      'tile0',
      'tile1',
      'tile2',
      'tile3',
      'tile4',
      'tile5',
      'tileData',
      'vehicleTypes',
      'warehouseArticles',
      'processorLocation',
      'processorLocations',
      'selectedPersons',
      'selectedProcessor',
      'selectedSource',
      'documents',
    ];
    let payload = { ...state };

    for (let key of Object.keys(payload)) {
      if (keysToRemove.includes(key)) delete payload[key];
    }

    payload.articles = self.props.contractArticles;

    self.props.editContract(self.currentEntity._id, payload);

    setTimeout(() => {
      self.getContractDetails();
    }, 200);
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <Button
        variant="contained"
        color="primary"
        startIcon={<SaveIcon />}
        style={{ margin: 10, float: 'left' }}
        onClick={save}
      >
        {`${self.props.t('Save')} ${self.props.t(self.props.currentTab)}`}
      </Button>
      <Button
        variant="contained"
        startIcon={<CancelIcon />}
        style={{ margin: 10, float: 'left', backgroundColor: 'white', color: 'red' }}
        onClick={() => self.props.cancelBtn()}
      >
        {self.props.t('Cancel')}
      </Button>
      <form noValidate autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper style={{ padding: 12, height: '100%', width: '100%' }}>
              <Typography variant="h4" style={{ paddingBottom: 30 }}>
                {self.props.t(state.header0)}
              </Typography>
              {state.tile0.map((tile) =>
                tile ? tile.key !== 'email' ? tile : <EmailField /> : null,
              )}

              {self.state.buyerContactPersons.length > 0 && (
                <Autocomplete
                  style={{
                    margin: 10,
                    minWidth: 300,
                    maxWidth: 300,
                    display: 'inline-flex',
                  }}
                  id="pod"
                  options={self.state.buyerContactPersons || []}
                  value={self.state.buyerContactPerson}
                  onChange={(event, value) => {
                    self.setState({ buyerContactPersonId: value._id, buyerContactPerson: value });
                  }}
                  getOptionLabel={(option) =>
                    `${option.firstName}${option.surname ? ` ${option.surname}` : ''}`
                  }
                  getOptionSelected={(option) => self.state.buyerContactPersonId === option._id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={props.t('Contact Person')}
                      placeholder={props.t('Contact Person')}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              )}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper style={{ padding: 12, height: '100%', width: '100%' }}>
              <Typography variant="h4" style={{ paddingBottom: 30 }}>
                {self.props.t(state.header1)}
              </Typography>
              {self.state.tile1.map((val) =>
                val.key === 'pod' ? (
                  <Autocomplete
                    style={{
                      margin: 10,
                      minWidth: 300,
                      maxWidth: 300,
                      display: 'inline-flex',
                    }}
                    id="pod"
                    options={self.state.podOptions || []}
                    getOptionLabel={(option) => props.t(option)}
                    value={self.state[val.props.dbid] || ''}
                    onChange={(event, value) => {
                      self.setState({ pod: value });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={props.t('POD')}
                        placeholder={props.t('POD')}
                        required={val.props.required}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                ) : val.key === 'email' ? (
                  <EmailField />
                ) : val.key === 'noOfLots' ? (
                  self.state.lots === 'Multiple Lots' ? (
                    val
                  ) : (
                    ''
                  )
                ) : (
                  val
                ),
              )}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper style={{ padding: 12, height: '100%', width: '100%' }}>
              <Typography variant="h4" style={{ paddingBottom: 30 }}>
                {self.props.t(state.header2)}
              </Typography>
              {state.tile2.map((tile) =>
                tile ? tile.key !== 'email' ? tile : <EmailField /> : null,
              )}
            </Paper>
          </Grid>
          {self.currentEntity && (
            <Grid item xs={12}>
              <Paper style={{ padding: 12, marginTop: 30, width: '100%' }}>
                {state.tile3}
                <ContractArticles
                  variant={'edit'}
                  contract={self.currentEntity}
                  state={state}
                  self={self}
                />
              </Paper>
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
}

export default withTranslation('common')(Contract);
