import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CommissionConfirmation from "../pdfToHtml/commission-confirmation";

import Typography from "@material-ui/core/Typography";
import { withTranslation } from "react-i18next";
import VisibilityIcon from "@material-ui/icons/Visibility";
import * as Actions from '../../redux/store/actions/alerts';
import { useDispatch } from 'react-redux';
import Button from "@material-ui/core/Button";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function CustomizedDialogs(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if (props.articles.length > 0) {
      setOpen(true);
    } else {
      dispatch(
        Actions.showMessage({
          message: 'view_docs_add_articles_error',
          messageType: 'error',
        }),
      );
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/* <IconButton color={"primary"} onClick={handleClickOpen}>
        <VisibilityIcon />
      </IconButton> */}
      <Button
        startIcon={<VisibilityIcon />}
        color={'primary'}
        size={"large"}
        onClick={handleClickOpen}
      >
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={"lg"}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {props.t(`Preview ${props.title} Document`)}
        </DialogTitle>
        <DialogContent dividers>
          <CommissionConfirmation {...props} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default withTranslation("common")(CustomizedDialogs);
