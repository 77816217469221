import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 10,
    minWidth: 140,
    maxWidth: 300,
    display: "inline-flex",
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
  },
}));

function PaymentTermsMethod(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState("");
  const [menu, setMenu] = React.useState("");

  let menuItems = props.options.map((option) => {
    return <MenuItem value={option}>{option}</MenuItem>;
  });

  if (props.isEdit) {
    if (props.paymentTerms === "Other") {
      return (
        <div>
          <Autocomplete
            style={{
              margin: 10,
              minWidth: 300,
              maxWidth: 300,
              display: "inline-flex",
            }}
            id="combo-box-demo"
            defaultValue={props.paymentTerms}
            options={props.options}
            onChange={(event, newValue) => {
              props.callbackValue("paymentTerms", newValue);
              setValue(newValue);
            }}
            getOptionLabel={(option) => props.t(option)}
            className={classes.formControl}
            renderInput={(params) => (
              <TextField
                {...params}
                label={props.t(props.label)}
                required={props.required}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: `new-password`,
                }}
              />
            )}
          />
          <TextField
            defaultValue={props.paymentDays}
            key={props.daysLabel}
            style={{
              margin: 10,
              width: 300,
              maxWidth: 300,
              display: "inline-flex",
            }}
            onChange={(e) => {
              props.callbackValueText(e.target.value, "paymentDays");
            }}
            name={props.daysLabel}
            required={props.required}
            label={props.t(props.daysLabel)}
          />
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <Autocomplete
            style={{
              margin: 10,
              minWidth: 300,
              maxWidth: 300,
              display: "inline-flex",
            }}
            id="combo-box-demo"
            defaultValue={props.paymentTerms}
            options={props.options}
            onChange={(event, newValue) => {
              props.callbackValue("paymentTerms", newValue);
              setValue(newValue);
            }}
            getOptionLabel={(option) => props.t(option)}
            className={classes.formControl}
            renderInput={(params) => (
              <TextField
                {...params}
                label={props.t(props.label)}
                required={props.required}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: `new-password`,
                }}
              />
            )}
          />
          {value === "Other" && (
            <TextField
              defaultValue={props.paymentDays}
              key={props.daysLabel}
              style={{
                margin: 10,
                width: 300,
                maxWidth: 300,
                display: "inline-flex",
              }}
              onChange={(e) => {
                props.callbackValueText(e.target.value, "paymentDays");
              }}
              name={props.daysLabel}
              required={props.required}
              label={props.t(props.daysLabel)}
            />
          )}
        </React.Fragment>
      );
    }
  } else {
    if (props.paymentTerms === "Other") {
      return (
        <div>
          <Autocomplete
            style={{
              margin: 10,
              minWidth: 300,
              maxWidth: 300,
              display: "inline-flex",
            }}
            id="combo-box-demo"
            defaultValue={props.paymentTerms}
            options={props.options}
            onChange={(event, newValue) => {
              props.callbackValue(props.dbid, newValue);
              setValue(newValue);
            }}
            getOptionLabel={(option) => props.t(option)}
            className={classes.formControl}
            renderInput={(params) => (
              <TextField
                {...params}
                label={props.t(props.label)}
                required={props.required}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: `new-password`,
                }}
              />
            )}
          />
          <TextField
            key={props.key2}
            style={{
              margin: 10,
              width: 300,
              maxWidth: 300,
              display: "inline-flex",
            }}
            onChange={(event, newValue) => {
              props.callbackValueText(event);
              setValue(newValue);
            }}
            name={props.daysLabel}
            required={props.required}
            label={props.t(props.daysLabel)}
          />
        </div>
      );
    } else {
      return (
        <Autocomplete
          style={{
            margin: 10,
            minWidth: 300,
            maxWidth: 300,
            display: "inline-flex",
          }}
          id="combo-box-demo"
          defaultValue={props.paymentTerms}
          options={props.options}
          onChange={(event, newValue) => {
            props.callbackValue(props.dbid, newValue);
            setValue(newValue);
          }}
          getOptionLabel={(option) => props.t(option)}
          className={classes.formControl}
          renderInput={(params) => (
            <TextField
              {...params}
              label={props.t(props.label)}
              required={props.required}
              inputProps={{
                ...params.inputProps,
                autoComplete: `new-password`,
              }}
            />
          )}
        />
      );
    }
  }
}

export default withTranslation("common")(PaymentTermsMethod);
