export const SET_OPENING_HOURS = "[PARTNERS] SET_OPENING_HOURS";
export const RESET = "[PARTNERS] RESET";
export const ADD_LOCATION = "[PARTNERS] ADD LOCATION";
export const REMOVE_LOCATION = "[PARTNERS] REMOVE LOCATION";
export const SET_LOCATION_VALUES = "[PARTNERS] SET LOCATION VALUES";
export const RESET_LOCATIONS = "[PARTNERS] RESET LOCATIONS";

export function resetOpeningHours() {
  return {
    type: RESET,
  };
}
export function setOpeningHours(payload) {
  return {
    type: SET_OPENING_HOURS,
    payload,
  };
}

export function addLocation() {
  return {
    type: ADD_LOCATION
  }
}

export function removeLocation(id) {
  return {
    type: REMOVE_LOCATION,
    payload: id
  }
}

export function setLocationValues(payload) {
  return {
    type: SET_LOCATION_VALUES,
    payload
  }
}

export function resetLocations() {
  return {
    type: RESET_LOCATIONS,
  }
}
