import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
  TOP_NAVIGATION_ROUTES,
  BOTTOM_NAVIGATION_ROUTES,
} from '../../constants/ui-constants';
import { withTranslation } from 'react-i18next';

const AntTabs = withStyles((theme) => ({
  root: {
    borderBottom: '1px solid ' + theme.palette.secondary.main,
  },
  indicator: {
    backgroundColor: theme.palette.secondary.main,
  },
}))(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    color: theme.palette.secondary.main,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: theme.palette.secondary.main,
      opacity: 1,
    },
    '&:selected': {
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:active': {
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: theme.palette.secondary.main,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: '#2e1534',
  },
}));

const CustomizedTabs = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.currentSubTab ? props.currentSubTab : 0);
  const [label, setLabel] = React.useState('');

  useEffect(() => {
    setValue(parseInt(props.currentSubTab ? props.currentSubTab : 0));
  }, [props.currentTab, props.currentSubTab]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setLabel(label);

    if (props.currentTab === 'SYSTEM') props.self.setState({ currentAction: '' });
    props.parentCallback(newValue);
  };

  if (props.currentAction === 'ADD') {
    if (props.currentTab == TOP_NAVIGATION_ROUTES[3]) {
      return (
        <div className={classes.root}>
          <div className={classes.demo1}>
            <AntTabs value={value} onChange={handleChange} aria-label="ant example">
              <AntTab label={props.t('Order Details')} />
            </AntTabs>
          </div>
        </div>
      );
    }
  } else {
    if (
      props.currentTab == TOP_NAVIGATION_ROUTES[0] ||
      props.currentTab == TOP_NAVIGATION_ROUTES[1] ||
      props.currentTab == TOP_NAVIGATION_ROUTES[4]
    ) {
      return (
        <div className={classes.root}>
          {/*<div className={classes.demo1}>*/}
          {/*  /!*<AntTabs value={value} onChange={handleChange} aria-label="ant example">*!/*/}
          {/*  /!*  <AntTab label={props.t('Overview')} />*!/*/}
          {/*  /!*</AntTabs>*!/*/}
          {/*  /!*<Typography className={classes.padding} />*!/*/}
          {/*</div>*/}
        </div>
      );
    } else if (props.currentTab == TOP_NAVIGATION_ROUTES[2]) {
      return (
        <div className={classes.root}>
          {/*<div className={classes.demo1}>*/}
          {/*  <AntTabs value={value} onChange={handleChange} aria-label="ant example">*/}
          {/*    <AntTab label={props.t('Overview')} />*/}
          {/*  </AntTabs>*/}
          {/*  /!*<Typography className={classes.padding} />*!/*/}
          {/*</div>*/}
        </div>
      );
    } else if (props.currentTab == TOP_NAVIGATION_ROUTES[3]) {
      return (
        <div className={classes.root}>
          <div className={classes.demo1}>
            <AntTabs value={value} onChange={handleChange} aria-label="ant example">
              <AntTab label={props.t('Order Details')} />
              {props.self.state.user.permissions.weightSlips_read && (
                <AntTab label={props.t('WS+ Invoice')} />
              )}
              {props.self.state.user.permissions.tuning_read && (
                <AntTab label={props.t('Tuning')} />
              )}
            </AntTabs>
          </div>
        </div>
      );
    } else if (props.currentTab == BOTTOM_NAVIGATION_ROUTES[0]) {
      return (
        <div className={classes.root}>
          <div className={classes.demo1}>
            <AntTabs value={value} onChange={handleChange} aria-label="ant example">
              <AntTab label={props.t('Banking')} />
              <AntTab label={props.t('Tax Matrix')} />
              <AntTab label={props.t('Articles')} />
              <AntTab label={props.t('Team')} />
              <AntTab label={props.t('Warehouse')} />
            </AntTabs>
          </div>
        </div>
      );
    } else if (props.currentTab == BOTTOM_NAVIGATION_ROUTES[1]) {
      return (
        <div className={classes.root}>
          <div className={classes.demo1}>
            <AntTabs value={value} onChange={handleChange} aria-label="company tabs">
              <AntTab label={props.t('Overview')} />
              <AntTab label={props.t('documents_description')} />
              <AntTab label={props.t('assets')} />
            </AntTabs>
          </div>
        </div>
      );
    } else if (props.currentTab == TOP_NAVIGATION_ROUTES[6]) {
      return (
        <div className={classes.root}>
          <div className={classes.demo1}>
            <AntTabs value={value} onChange={handleChange} aria-label="ant example">
              <AntTab label={props.t('Contract Details')} />
              {/* <AntTab label={props.t('History')} /> */}
            </AntTabs>
          </div>
        </div>
      );
    }
    else {
      return (
        <div className={classes.root}>
          <div className={classes.demo1}>
            <AntTabs value={value} onChange={handleChange} aria-label="ant example">
              <AntTab label={props.t('Tab detsils')} />
              <AntTab label={props.t('History')} />
            </AntTabs>
          </div>
        </div>
      );
    }
  }
};

export default withTranslation('common')(CustomizedTabs);
