import React from 'react';
import download from 'downloadjs';
import MultiChoice from '../Inputs/MultiChoice';
import DatabaseMultiChoice from '../Inputs/DatabaseMultiChoice';
import LocationPicker from '../Inputs/LocationPicker';
import InvoiceDeliveryMethod from '../Inputs/InvoiceDeliveryMethodInput';

import CheckBox from '../Inputs/Checkbox';
import DatePicker from '../Inputs/DatePicker';
import { TOP_NAVIGATION_ROUTES, BOTTOM_NAVIGATION_ROUTES } from '../../constants/ui-constants';
import CarrierInputFieldData from '../../Schemas/AddCarrier.json';
import ContractInputFieldData from '../../Schemas/AddContracts.json';
import ArticleInputFieldData from '../../Schemas/AddArticle.json';
import TeamInputFieldData from '../../Schemas/AddTeams.json';
import PersonInputFieldData from '../../Schemas/AddPerson.json';
import OrderInputFieldData from '../../Schemas/AddOrder.json';
import PartnerInputFieldData from '../../Schemas/AddPartner.json';
import BankInputFieldData from '../../Schemas/AddBank.json';
import GroupInputFieldData from '../../Schemas/AddGroup.json';
import CompanyInputFieldData from '../../Schemas/AddCompany.json';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import PreviewInvoice from '../modals/previewInvoice';
import { getClient, editClient, getClients } from '../../redux/modules/client';
import { getCarrier, editCarrier, getCarriers } from '../../redux/modules/carrier';
import {
  getContract,
  getContracts,
  editContract,
  sendContractDocument,
} from '../../redux/modules/contracts';
import { getArticle, editArticle, getArticles } from '../../redux/modules/article';
import { getHoliday, editHoliday } from '../../redux/modules/holiday';
import { editWarehouse, getWarehouse } from '../../redux/modules/warehouse';
import {
  getPerson,
  editPerson,
  getPersons,
  getTeams,
  getFieldAgents,
  getContractPersons,
} from '../../redux/modules/person';
import {
  getOrder,
  editOrder,
  getOrders,
  sendDocument,
  checkIsWarehouse,
  getLocations,
} from '../../redux/modules/order';
import { getPartner, editPartner, getPartners } from '../../redux/modules/partner';
import { getBank, editBank } from '../../redux/modules/bank';
import { getGroup, editGroup, getGroups } from '../../redux/modules/group';
import { getCompanys, getCompany, editCompany } from '../../redux/modules/company';
import { getWeightslip } from '../../redux/modules/weightslip';
import { getInvoice } from '../../redux/modules/invoice';
import {
  setOpeningHours,
  resetOpeningHours,
  resetLocations,
  setLocationValues,
} from '../../redux/store/actions/partners';
import { resetPersons, setEmail } from '../../redux/store/actions/persons';
import { getArticleTypes } from '../../redux/modules/article';

import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import PaymentTermsMethod from '../Inputs/PaymentTermsMethod';
import OpeningHours from '../persons/OpeningHours';

import EmailField from '../persons/Emails';
import PersonAddModal from '../modals/personAddModal';
import PersonEditModal from '../modals/personEditModal';
import PersonSelectTabs from './personSelectTabs';
import Orders from './orders';
import Contract from './contract';
import MultiMultiChoice from '../Inputs/MultiMultichoice';
import TextInput from '../Inputs/TextInput';
import SendDocs from './orders/send_docs';
import ContractSendDocs from './contract/send_docs';
import DateRangePicker from '../Inputs/DateRangePicker';
import DateRangePicker2 from '../Inputs/DateRangePicker2';
import CountriesField from '../Orders/Countries';
import IbanTextInput from '../Inputs/ibanTextInput';
import MultipleChoice from '../Inputs/MultipleChoice';
import WeightSlipFieldData from '../../Schemas/AddWeightSlip.json';
import ClaimFieldData from '../../Schemas/AddClaim.json';
import MultiWeightSlip from '../submenus/multiWeightSlip';
import ZipcodeInput from '../Inputs/zipcodeInput';

import WarehouseInputFieldData from '../../Schemas/AddWarehouse.json';
import Tuning from './tuning';
import { getApiUrl } from '../../util/environment-utils';
import axios from 'axios';
import { TextField } from '@material-ui/core';
import Bank from './system/bank';
import Groups from './system/groups';
import Team from './system/team';
import Warehouse from './system/warehouse';
import Article from './system/article';
import Partners from './partners';
import Finance from './orders/finanace';
import { getArticleDetails, getCompanyAddress, getGermanNumber } from '../../helpers/documents';
import Company from './company';
import { EditorState } from 'draft-js';
import { convertFromHTML } from 'draft-convert';
import ColorInput from '../Inputs/ColorInput';
import PermissionsSelect from '../Inputs/PermissionsSelect';
import { formatDecimals, formatNumberToEn } from '../../helpers/common';

const form = reduxForm({
  form: 'add',
});

const warehouse = {
  type: 'DatabaseMultiChoice',
  label: 'Warehouse',
  required: true,
  method: 'getPartnersAndWarehouse',
  dbid: 'processor',
};

class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputFields: [],
      tileData: [],
      tile0: [],
      tile1: [],
      tile2: [],
      tile3: [],
      tile4: [],
      tile5: [],
      header0: '',
      header1: '',
      header2: '',
      header3: '',
      header4: '',
      sendorderdocument: false,
      loadedPersons: [],
      contactPersons: [],
      allPersons: [],
      addModalOpen: false,
      editPersonModal: false,
      accountManagerProcessor: {},
      ProcessorDelivery: '27/10/2020',
      vehicleTypes: [],
      selectedRow: null,
      destinationType: 'dropshippment',
      signatures: null,
      companyLogo: null,
      companyAgb: null,
      tax: null,
      loadingInvoiceTax: null,
      unloadingInvoiceTax: null,
      sourceLocations: [],
      processorLocations: [],
      sourceLocation: null,
      processorLocation: null,
      companyOfficer: null,
      docType: 'default',
      documents: {
        default: { subject: '', description: EditorState.createEmpty(), descriptionHtml: '' },
        annex: { subject: '', description: EditorState.createEmpty(), descriptionHtml: '' },
        purchase_confirmation: {
          subject: '',
          description: EditorState.createEmpty(),
          descriptionHtml: '',
        },
        sales_confirmation: {
          subject: '',
          description: EditorState.createEmpty(),
          descriptionHtml: '',
        },
        transport_information: {
          subject: '',
          description: EditorState.createEmpty(),
          descriptionHtml: '',
        },
        invoice: { subject: '', description: EditorState.createEmpty(), descriptionHtml: '' },
        transport_order: {
          subject: '',
          description: EditorState.createEmpty(),
          descriptionHtml: '',
        },
        billing_confirmation: {
          subject: '',
          description: EditorState.createEmpty(),
          descriptionHtml: '',
        },
        invoice_cancellation: {
          subject: '',
          description: EditorState.createEmpty(),
          descriptionHtml: '',
        },
        sales_contract:{
          subject: '',
          description: EditorState.createEmpty(),
          descriptionHtml: ''
        }
      },
      selectedSource: {},
      selectedProcessor: {},
      currency: 'Euro',
      permissions: {},
      contractDocuments: [],
      podOptions: [],
      signedContract: null,
      buyerContactPersons: [],
      buyerContactPersonId: '',
      teamSignature: '',
    };
  }

  setDestinationType = (val) => {
    this.setState({ destinationType: val });
  };

  handleChildStateChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  async componentDidMount() {
    await this.getSchema(false, true);
  }

  handleDateChange = (name, date) => {
    this.setState({
      [name]: date,
    });
  };

  getOrderDetails = async () => {
    let order = await this.props.getOrder(this.props.currentRowData._id);
    let companyData = await this.props.getCompanys();

    let fieldAgentData = order.OrderFound[0].fieldAgent[0];
    let processorData = order.OrderFound[0].processor[0];
    let carrierData = order.OrderFound[0].carrier[0];
    let sourceData = order.OrderFound[0].source[0];
    let accountManagerProcessor = order.OrderFound[0].accountManagerProcessor[0];
    let sourceLocations;
    if (sourceData) {
      sourceLocations = await this.props.getLocations(sourceData._id);
    } else {
      alert('Source Data not found, add new one');
    }
    let processorLocations;
    if (processorData) {
      processorLocations = await this.props.getLocations(processorData._id);
    } else {
      alert('Processor Data not found, add new one');
    }

    this.currentEntity = order.OrderFound[0];

    if (this.currentEntity.cancellationInvoice) {
      let order_details = this.currentEntity.cancellationInvoice.order_details;
      this.currentEntity.cancellationInvoice.order_details.tax = order_details.tax
        ? order_details.tax
        : {};
    }

    if (this.currentEntity.correctionInvoice) {
      let order_details = this.currentEntity.correctionInvoice.order_details;
      this.currentEntity.correctionInvoice.order_details.tax = order_details.tax
        ? order_details.tax
        : {};
    }

    this.setState({
      destinationType: this.currentEntity.destinationType,
      fieldAgentData: fieldAgentData,
      companyData: companyData.allCompanys[0],
      carrierData: carrierData,
      sourceData: sourceData,
      processorData: processorData,
      accountManagerProcessor: accountManagerProcessor,
      currentEntity: this.currentEntity,
      vehicle: this.currentEntity.vehicle,
      vehicleTypes: carrierData ? (carrierData.vehicle ? carrierData.vehicle : []) : [],
      tax: order.tax,
      loadingInvoiceTax: order.loadingInvoiceTax,
      unloadingInvoiceTax: order.unloadingInvoiceTax,
      processorLocations: processorLocations ? processorLocations.allLocations : [],
      sourceLocations: sourceLocations ? sourceLocations.allLocations : [],
      sourceLocation: order.OrderFound[0].sourceLocation
        ? order.OrderFound[0].sourceLocation
        : null,
      processorLocation: order.OrderFound[0].processorLocation
        ? order.OrderFound[0].processorLocation
        : null,
      companyOfficer: order.companyOfficer,
      selectedSource: this.currentEntity.source[0],
      selectedProcessor: this.currentEntity.processor[0],
      currency: this.currentEntity.currency,
      orderRemark: this.currentEntity.orderRemark,
    });

    this.setTiles(OrderInputFieldData);
  };

  getFilteredPersons = (selected, persons) => {
    for (let key in selected) {
      let index = persons.findIndex((val) => val._id === selected[key]._id);
      if (index > -1) {
        persons.splice(index, 1);
      }
    }
    return persons;
  };

  getSchema = async (reload = false, firstLoad = false) => {
    this.props.resetPersons();
    if (this.props.currentSubTab == 0 && this.props.currentTab == TOP_NAVIGATION_ROUTES[0]) {
      if (firstLoad) {
        this.props.resetOpeningHours();
        this.props.resetLocations();
      }
      let partner = await this.props.getPartner(this.props.currentRowData._id);

      if (
        partner.PartnerFound.openingHours &&
        partner.PartnerFound.openingHours.length > 0 &&
        !reload
      )
        this.props.setOpeningHours(partner.PartnerFound.openingHours);

      if (partner.PartnerFound.locations && partner.PartnerFound.locations.length > 0)
        this.props.setLocationValues(partner.PartnerFound.locations);

      this.currentEntity = partner.PartnerFound;

      let persons = await this.props.getTeams();

      let filteredPerson = this.getFilteredPersons(
        this.currentEntity.contactPersons,
        persons.allPersons,
      );

      this.setState({
        allPersons: filteredPerson,
        BIC: partner.PartnerFound.bic,
        BankName: partner.PartnerFound.bankName,
        country: partner.PartnerFound.country,
        invoiceEmail: partner.PartnerFound['invoiceEmail'],
        paymentDays: partner.PartnerFound['paymentDays'],
        contactPersons: partner.PartnerFound['contactPersons'],
        loadedPersons: partner.PartnerFound['contactPersons'],
        pod: this.currentEntity.pod,
      });

      await this.setTiles(PartnerInputFieldData);
    } else if (this.props.currentSubTab == 0 && this.props.currentTab == TOP_NAVIGATION_ROUTES[6]) {
      this.getContractDetails();
    } else if (this.props.currentSubTab == 0 && this.props.currentTab == TOP_NAVIGATION_ROUTES[1]) {
      let carrier = await this.props.getCarrier(this.props.currentRowData._id);
      let persons = await this.props.getTeams();
      this.currentEntity = carrier.CarrierFound;

      let filteredPerson = this.getFilteredPersons(
        this.currentEntity.contactPersons,
        persons.allPersons,
      );
      this.setState({
        allPersons: filteredPerson,
        BIC: carrier.CarrierFound.bic,
        BankName: carrier.CarrierFound.bankName,
        country: carrier.CarrierFound.country,
      });

      await this.setState({ invoiceEmail: carrier.CarrierFound['invoiceEmail'] });
      await this.setState({ paymentDays: carrier.CarrierFound['paymentDays'] });
      this.setState({ contactPersons: carrier.CarrierFound['contactPersons'] });
      this.setState({ loadedPersons: carrier.CarrierFound['contactPersons'] });
      this.setTiles(CarrierInputFieldData);
    } else if (this.props.currentSubTab == 0 && this.props.currentTab == TOP_NAVIGATION_ROUTES[2]) {
      let person = await this.props.getPerson(this.props.currentRowData._id);

      if (
        person.PersonFound.emails &&
        typeof person.PersonFound.emails !== 'string' &&
        person.PersonFound.emails.length > 0
      ) {
        this.props.setEmail(person.PersonFound.emails);
      }

      this.currentEntity = person.PersonFound;
      this.setTiles(PersonInputFieldData);
    } else if (this.props.currentSubTab == 0 && this.props.currentTab == TOP_NAVIGATION_ROUTES[3]) {
      this.getOrderDetails();
    } else if (this.props.currentSubTab == 1 && this.props.currentTab == TOP_NAVIGATION_ROUTES[3]) {
      this.getOrderDetails();
      this.setTiles(WeightSlipFieldData);
    } else if (this.props.currentSubTab == 2 && this.props.currentTab == TOP_NAVIGATION_ROUTES[3]) {
      this.getOrderDetails();
      this.setTiles(ClaimFieldData);
    } else if (
      this.props.currentSubTab == 0 &&
      this.props.currentTab == BOTTOM_NAVIGATION_ROUTES[0]
    ) {
      let bank = await this.props.getBank(this.props.currentRowData._id);
      this.currentEntity = bank.BankFound;
      this.setTiles(BankInputFieldData);
    } else if (
      this.props.currentSubTab == -1 &&
      this.props.currentTab == BOTTOM_NAVIGATION_ROUTES[0]
    ) {
      let group = await this.props.getGroup(this.props.currentRowData._id);
      this.currentEntity = group.GroupFound;
      this.setTiles(GroupInputFieldData);
    } else if (
      this.props.currentSubTab == 1 &&
      this.props.currentTab == BOTTOM_NAVIGATION_ROUTES[0]
    ) {
    } else if (
      this.props.currentSubTab == 2 &&
      this.props.currentTab == BOTTOM_NAVIGATION_ROUTES[0]
    ) {
      let article = await this.props.getArticle(this.props.currentRowData._id);
      this.currentEntity = article.ArticleFound;
      this.setTiles(ArticleInputFieldData);
    } else if (
      this.props.currentSubTab == 3 &&
      this.props.currentTab == BOTTOM_NAVIGATION_ROUTES[0]
    ) {
      let team = await this.props.getPerson(this.props.currentRowData._id);
      let formattedData = team.PersonFound;
      if (formattedData.userId) {
        formattedData.email = formattedData.userId.email;
      }

      this.currentEntity = formattedData;

      this.setState({ teamSignature: this.currentEntity.teamSignature });

      if (formattedData.permissions && Object.keys(formattedData.permissions).length > 0) {
        this.setState({ permissions: formattedData.permissions });
      }

      if (
        team.PersonFound.emails &&
        typeof team.PersonFound.emails !== 'string' &&
        team.PersonFound.emails.length > 0
      ) {
        this.props.setEmail(formattedData.emails);
      }

      this.setTiles(TeamInputFieldData);
    } else if (
      this.props.currentSubTab == 4 &&
      this.props.currentTab == BOTTOM_NAVIGATION_ROUTES[0]
    ) {
      if (firstLoad) {
        this.props.resetOpeningHours();
      }
      let warehouse = await this.props.getWarehouse(this.props.currentRowData._id);

      if (
        warehouse.WarehouseFound.openingHours &&
        warehouse.WarehouseFound.openingHours.length > 0 &&
        !reload
      )
        this.props.setOpeningHours(warehouse.WarehouseFound.openingHours);

      this.currentEntity = warehouse.WarehouseFound;

      let persons = await this.props.getTeams();

      let filteredPerson = this.getFilteredPersons(
        this.currentEntity.contactPersons,
        persons.allPersons,
      );

      this.setState({
        allPersons: filteredPerson,
        BIC: warehouse.WarehouseFound.bic,
        BankName: warehouse.WarehouseFound.bankName,
        country: warehouse.WarehouseFound.country,
      });

      await this.setState({ invoiceEmail: warehouse.WarehouseFound['invoiceEmail'] });
      await this.setState({ paymentDays: warehouse.WarehouseFound['paymentDays'] });
      this.setState({ contactPersons: warehouse.WarehouseFound['contactPersons'] });
      this.setState({ loadedPersons: warehouse.WarehouseFound['contactPersons'] });

      await this.setTiles(WarehouseInputFieldData);
    } else if (this.props.currentTab == BOTTOM_NAVIGATION_ROUTES[1]) {
      let company = await this.props.getCompany(this.props.currentRowData._id);
      this.currentEntity = company.CompanyFound;

      let documents = this.state.documents;
      documents = { ...documents, ...this.currentEntity.documents };

      for (let key of Object.keys(documents)) {
        if (documents[key].subject && documents[key].subject !== '') {
          documents[key].descriptionHtml = documents[key].description;
          documents[key].description = this.textEditorVal(documents[key].description);
        }
      }

      this.setState({
        signatures: company.CompanyFound.signatures ? company.CompanyFound.signatures : null,
        companyLogo: company.CompanyFound.companyLogo ? company.CompanyFound.companyLogo : null,
        companyAgb: company.CompanyFound.companyAgb ? company.CompanyFound.companyAgb : null,
        documents: documents,
      });
      this.setTiles(CompanyInputFieldData);
    }
  };

  async getContractDetails() {
    let contract = await this.props.getContract(this.props.currentRowData._id);
    this.currentEntity = contract.ContractFound[0];
    this.setState({
      pod: this.currentEntity.pod,
      contractDocuments: this.currentEntity.documents,
      podOptions: this.currentEntity.podOptions,
      signedContract: this.currentEntity.signedContract,
      buyerContactPersons: this.currentEntity.buyerContactPersons,
      buyerContactPersonId: this.currentEntity.buyerContactPersonId,
      buyerContactPerson: this.currentEntity.buyerContactPerson,
    });
    this.setTiles(ContractInputFieldData);
  }

  textEditorVal(value) {
    let editorVal = EditorState.createEmpty();
    if (value && value !== '' && value !== '<p><br></p>') {
      const message = EditorState.createWithContent(
        convertFromHTML(value.replace('<p><br></p>', '<p></p>')),
      );
      editorVal = message;
    }
    return editorVal;
  }

  setTiles = (data) => {
    let headers = Object.keys(data);
    headers.map((header, index) => {
      let stuffer = 'header' + index;
      this.setState({ [stuffer]: header });
    });

    const tiles = Object.values(data).map((tile, index) => {
      let tileValues = Object.values(tile).map((question) => {
        return this.createInputField(question);
      });
      let stuff = 'tile' + index;
      // console.log(stuff);
      {
        /**
         Adds the fields to the correct tile.
         */
      }
      this.setState({ [stuff]: tileValues });
    });

    this.setState({ tileData: tiles });
  };

  changeState = (name, val) => {
    this.setState({ [name]: val });
  };

  createInputField = (obj) => {
    if (this.state[obj.dbid] === undefined) {
      this.setState({ [obj.dbid]: this.currentEntity[obj.dbid] });
    }

    if (obj.dbid === 'zipcode') {
      this.setState({ [obj.city]: this.currentEntity[obj.city] });
    }

    if (obj.type == 'TextInput') {
      return (
        <TextInput
          key={obj.dbid}
          style={{
            margin: 10,
            minWidth: obj.width ? obj.width : '300px',
            maxWidth: obj.width ? obj.width : '300px',
          }}
          defaultValue={this.state[obj.dbid]}
          change={(e) => {
            const { value } = e.target;
            this.setState({ [obj.dbid]: value });
          }}
          name={obj.label}
          required={obj.required}
          label={obj.label}
          dbid={obj.dbid}
          type={obj.inputType}
          self={this}
          isError={obj.isError || false}
        />
      );
    } else if (obj.type === 'PermissionsSelect') {
      return (
        <PermissionsSelect
          self={this}
          variant={'edit'}
          obj={obj}
          translate={this.props.t}
          style={{ margin: 10, width: obj.width ? obj.width : 300 }}
        />
      );
    } else if (obj.type === 'ColorInput') {
      return (
        <ColorInput
          key={obj.label}
          style={{ margin: 10, width: obj.width ? obj.width : 300 }}
          change={this.handleColorChange}
          name={obj.label}
          required={obj.required}
          label={this.props.t(obj.label)}
          value={this.state[obj.dbid] || ''}
          dbid={obj.dbid}
        />
      );
    } else if (obj.type === 'TextArea') {
      return (
        <div>
          <TextField
            key={obj.label}
            style={{ margin: 10, width: '98%' }}
            onChange={(e) => {
              const { value } = e.target;
              this.setState({ [obj.dbid]: value });
            }}
            defaultValue={this.state[obj.dbid]}
            name={obj.label}
            required={obj.required}
            label={this.props.t(obj.label)}
            dbid={obj.dbid}
            rows={5}
            multiline={true}
          />
        </div>
      );
    } else if (obj.type === 'ZipcodeInput') {
      return (
        <ZipcodeInput
          defaultValue={this.state[obj.dbid]}
          dbid={obj.dbid}
          key={obj.label}
          style={{ margin: 10, width: obj.width ? obj.width : 300 }}
          change={this.handleTextFieldChange}
          name={obj.label}
          required={obj.required}
          label={this.props.t(obj.label)}
          setState={this.changeState}
          city={this.state[obj.city]}
          zipcode={this.state[obj.dbid]}
          country={this.state[obj.country]}
        />
      );
    } else if (obj.type === 'EmailInput') {
      return <EmailField />;
    } else if (obj.type === 'IbanTextInput') {
      return (
        <IbanTextInput
          handleTextFieldChange={this.handleTextFieldChange}
          IBAN={this.state['iban']}
          obj={obj}
          callbackValue={this.handleChildStateChange}
          isEdit
          {...this.state}
        />
      );
    } else if (obj.type === 'MultiChoice') {
      return (
        <MultiChoice
          key={obj.label}
          id={obj.id}
          dbid={obj.dbid}
          value={this.currentEntity[obj.dbid]}
          options={obj.dbid === 'vehicle' ? this.state.vehicleTypes : obj.options}
          callbackValue={this.handleMultiChoiceChange}
          required={obj.required}
          name={obj.label}
          label={obj.label}
          width={obj.width}
          isEdit={true}
        />
      );
    } else if (obj.type === 'LocationPicker') {
      return (
        <LocationPicker
          key={obj.label}
          id={obj.id}
          dbid={obj.dbid}
          value={this.state[obj.dbid]}
          callbackValue={this.handleMultiChoiceChange}
          required={obj.required}
          label={obj.label}
        />
      );
    } else if (obj.type === 'InvoiceDeliveryMethod') {
      return (
        <InvoiceDeliveryMethod
          key={obj.label}
          key2={obj.emailLabel}
          id={obj.id}
          isEdit={true}
          callbackValue={this.handleMultiChoiceChange}
          callbackValueText={this.handleTextFieldChange}
          emailLabel={obj.emailLabel}
          required={obj.required}
          options={obj.options}
          label={obj.label}
          dbid={obj.dbid}
          invoiceDeliveryMethod={this.state['invoiceDeliveryMethod']}
          invoiceEmail={this.state['invoiceEmail']}
        />
      );
    } else if (obj.type === 'PaymentTermsMethod') {
      return (
        <PaymentTermsMethod
          key={obj.label}
          key2={obj.daysLabel}
          id={obj.id}
          isEdit={true}
          callbackValue={this.handleMultiChoiceChange}
          callbackValueText={this.handleTextFieldChange}
          daysLabel={obj.daysLabel}
          required={obj.required}
          options={obj.options}
          label={this.props.t(obj.label)}
          dbid={obj.dbid}
          paymentTerms={this.state['paymentTerms']}
          paymentDays={this.state['paymentDays']}
        />
      );
    } else if (obj.type == 'CheckBox') {
      return (
        <CheckBox
          checked={this.state[obj.dbid]}
          key={obj.label}
          id={obj.id}
          dbid={obj.dbid}
          required={obj.required}
          label={obj.label}
          callbackValue={this.handleCheckboxChange}
        />
      );
    } else if (obj.type === 'MultiMultiChoice') {
      return (
        <MultiMultiChoice
          key={obj.label}
          id={obj.id}
          callbackValue={this.handleMultiChoiceChange}
          required={obj.required}
          options={obj.options}
          label={obj.label}
          dbid={obj.dbid}
          intern={
            this.props.currentTab === BOTTOM_NAVIGATION_ROUTES[0]
              ? 'Intern'
              : this.props.currentTab === TOP_NAVIGATION_ROUTES[6]
              ? 'contract'
              : 'Extern'
          }
          value={
            this.props.currentTab === TOP_NAVIGATION_ROUTES[6]
              ? this.state.contractDocuments
              : this.state.role
          }
        />
      );
    } else if (obj.type === 'DatePicker') {
      return (
        <DatePicker
          key={obj.label}
          id={obj.id}
          required={obj.required}
          label={obj.label}
          width={obj.width}
        />
      );
    } else if (obj.type === 'DatabaseMultiChoice') {
      if (TOP_NAVIGATION_ROUTES[3] && obj.dbid === 'processor') {
        return (
          <DatabaseMultiChoice
            key={this.state.destinationType === 'warehouse' ? warehouse.label : obj.label}
            id={this.state.destinationType === 'warehouse' ? warehouse.id : obj.id}
            name={this.state.destinationType === 'warehouse' ? warehouse.dbid : obj.dbid}
            dbid={this.state.destinationType === 'warehouse' ? warehouse.dbid : obj.dbid}
            value={
              this.state.destinationType === 'warehouse'
                ? this.state[obj.dbid]
                : this.state[obj.dbid]
            }
            required={
              this.state.destinationType === 'warehouse' ? warehouse.required : obj.required
            }
            method={this.state.destinationType === 'warehouse' ? warehouse.method : obj.method}
            getClients={() => this.props.getClients()}
            getCarriers={() => this.props.getCarriers()}
            getPersons={() => this.props.getPersons()}
            getOrders={() => this.props.getOrders()}
            getPartners={() => this.props.getPartners()}
            getArticles={() => this.props.getArticles()}
            label={this.state.destinationType === 'warehouse' ? warehouse.label : obj.label}
            callbackValue={this.handleDatabaseMultiChoiceChange}
            width={obj.width}
            self={this}
          />
        );
      } else {
        return (
          <DatabaseMultiChoice
            key={obj.label}
            id={obj.id}
            name={obj.dbid}
            dbid={obj.dbid}
            value={this.state[obj.dbid]}
            required={obj.required}
            method={obj.method}
            getClients={() => this.props.getClients()}
            getCarriers={() => this.props.getCarriers()}
            getPersons={() => this.props.getPersons()}
            getOrders={() => this.props.getOrders()}
            getPartners={() => this.props.getPartners()}
            getArticles={() => this.props.getArticles()}
            label={obj.label}
            callbackValue={this.handleDatabaseMultiChoiceChange}
            width={obj.width}
            self={this}
          />
        );
      }
    } else if (obj.type === 'DeliveryDateRangeMultiChoice') {
      return (
        <DateRangePicker
          key={obj.label}
          id={obj.id}
          callbackValue={this.handleMultiChoiceChange}
          callbackValueRange={this.handleChildStateChange}
          callbackDateChange={this.handleDateChange}
          required={obj.required}
          options={obj.options}
          label={this.props.t(obj.label)}
          dbid={obj.dbid}
          sourceCollection={this.state['collectionSource']}
          dateLabel={obj.dateLabel}
          dateDbid={obj.dateDbid}
          dateName={obj.dateName}
          dateValue={this.state.currentEntity[obj.dateDbid]}
          width={obj.width}
          {...this.state}
        />
      );
    } else if (obj.type === 'DeliveryDateRangeMultiChoice2') {
      return (
        <DateRangePicker2
          key={obj.label}
          id={obj.id}
          callbackValue={this.handleMultiChoiceChange}
          callbackValueRange={this.handleChildStateChange}
          callbackDateChange={this.handleDateChange}
          required={obj.required}
          options={obj.options}
          label={obj.label}
          dbid={obj.dbid}
          processorDelivery={this.state['deliveryProcess']}
          dateLabel={obj.dateLabel}
          dateDbid={obj.dateDbid}
          dateName={obj.dateName}
          dateValue={this.state.currentEntity[obj.dateDbid]}
          width={obj.width}
        />
      );
    } else if (obj.type === 'MultiCountries') {
      return <CountriesField value={this.state.countries} width={obj.width} {...this.props} />;
    } else if (obj.type === 'MultipleChoice') {
      return (
        <MultipleChoice
          currentValues={this.state[obj.dbid]}
          currentInput={obj}
          callbackValue={this.handleMultipleChoiceChange}
        />
      );
    } else {
      return;
    }
  };

  handleMultipleChoiceChange = (currentInput, value) => {
    this.setState({
      [currentInput.dbid]: value,
    });
  };

  handleCheckboxChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleMultiChoiceChange = (name, value) => {
    const state = { ...this.state };
    this.setState(
      {
        ...state,
        [name]: value,
      },
      this.reloadSetTiles,
    );
  };

  handleDatabaseMultiChoiceChange = async (name, value, data, dataLabel, allData) => {
    const nameId = name + 'Id';
    if (this.props.currentTab === BOTTOM_NAVIGATION_ROUTES[1] && name.toLowerCase() === 'bank') {
      this.setState({
        [name]: value,
        [dataLabel]: data,
        [nameId]: allData ? allData._id : allData,
        bankId: allData ? allData._id : allData,
      });

      return;
    }

    if (this.props.currentTab === TOP_NAVIGATION_ROUTES[6]) {
      if (name.toLowerCase() === 'buyer') {
        this.props.getContractPersons([allData._id]).then((res) => {
          this.setState({
            podOptions:
              allData && allData.pod && allData.pod.length > 0 ? allData.pod.split(',') : [],
            buyerContactPersons: res.data,
          });
        });
      }
    }

    if (this.props.currentTab === TOP_NAVIGATION_ROUTES[3]) {
      if (name.toLowerCase() === 'carrier')
        this.setState({
          vehicleTypes: allData && allData.vehicle ? allData.vehicle : [],
          vehicle: allData ? this.state.vehicle : null,
        });

      if (name.toLowerCase() === 'source') {
        let locations = null;
        if (allData) locations = await this.props.getLocations(allData._id);

        this.setState({
          sourceLocations: locations ? locations.allLocations : [],
          selectedSource: allData,
        });
      }

      if (name.toLowerCase() === 'processor') {
        let locations = null;
        if (allData) locations = await this.props.getLocations(allData._id);
        this.setState({
          processorLocations: locations ? locations.allLocations : [],
          selectedProcessor: allData,
        });
      }

      this.setState({
        [name]: allData ? [allData] : value,
        [dataLabel]: allData ? [allData] : data,
        [nameId]: allData ? allData._id : allData,
      });
    } else {
      this.setState({
        [name]: value,
        [dataLabel]: data,
        [nameId]: allData ? allData._id : allData,
      });
    }
  };

  handleTextFieldChange = (val, dbid) => {
    this.setState({
      [dbid]: val,
    });
  };

  handleColorChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  createDocumentProps = () => {
    return {
      companyOfficer: this.state.companyOfficer,
      language: this.props.i18n.language,
      accountManagerProcessor: this.state.accountManagerProcessor
        ? this.state.accountManagerProcessor[0]
          ? this.state.accountManagerProcessor[0]
          : this.state.accountManagerProcessor
        : null,
      fieldAgent: (this.currentEntity || {}).fieldAgent[0]
        ? this.currentEntity.fieldAgent[0]
        : null,
      companyAddress: getCompanyAddress(this.state.companyData, this.props.i18n.language),
      companyName: this.state.companyData['name1'],
      phone: this.state.fieldAgentData.phone,
      email: this.state.fieldAgentData.emails
        ? this.state.fieldAgentData.emails[0]
          ? this.state.fieldAgentData.emails[0]
          : 'N/A'
        : 'N/A',
      firstName: this.state.fieldAgentData.firstName,
      surName: this.state.fieldAgentData.surname,
      loadingReferenceNumber: this.state.loadingReferenceSource,
      dispositionNumber: this.currentEntity.orderNumber,
      carrierData: this.state.carrierData,
      currentEntity: this.currentEntity,
      sourceData: this.state.sourceData,
      processorData: this.state.processorData,
      companyData: this.state.companyData,
      zipcode: this.state.companyData.zipcode,
      city: this.state.companyData.city,
      countries: this.state.countries,
      tax: this.state.tax,
      loadingInvoiceTax: this.state.loadingInvoiceTax,
      unloadingInvoiceTax: this.state.unloadingInvoiceTax,
      articles: this.currentEntity.articles,
      billingConfirmationEmail: this.props.settings.billingConfirmationEmail,
      client: this.props.settings.client,
      transportRemark: this.state.transportRemark || '',
      oldInvoiceNumber: this.currentEntity.oldInvoiceNumber,
    };
  };

  createContractDocumentProps = () => {
    return {
      companyOfficer: this.state.companyOfficer,
      language: this.props.i18n.language,
      accountManagerProcessor: this.state.accountManagerProcessor
        ? this.state.accountManagerProcessor[0]
          ? this.state.accountManagerProcessor[0]
          : this.state.accountManagerProcessor
        : null,

      companyData: this.state.companyData,
    };
  };

  fetchArticle = async (id) => {
    const article = await this.props.getArticle(id);
    return article.ArticleFound;
  };

  fetchPartner = async (id) => {
    const partner = await this.props.getPartner(id);
    return partner.PartnerFound;
  };

  countSendDoc = () => {
    let docs = this.currentEntity.docs ? this.currentEntity.docs : {};
    let count = 0;

    if (Object.keys(docs).length > 0) {
      for (let key of Object.keys(docs)) {
        if (docs[key]) count += 1;
      }
    }

    return count;
  };

  reloadSetTiles = () => {
    if (this.props.currentSubTab == 0 && this.props.currentTab == TOP_NAVIGATION_ROUTES[0]) {
      this.setTiles(PartnerInputFieldData);
    } else if (this.props.currentSubTab == 0 && this.props.currentTab == TOP_NAVIGATION_ROUTES[6]) {
      this.setTiles(ContractInputFieldData);
    } else if (this.props.currentSubTab == 0 && this.props.currentTab == TOP_NAVIGATION_ROUTES[1]) {
      this.setTiles(CarrierInputFieldData);
    } else if (this.props.currentSubTab == 0 && this.props.currentTab == TOP_NAVIGATION_ROUTES[2]) {
      this.setTiles(PersonInputFieldData);
    } else if (this.props.currentSubTab == 0 && this.props.currentTab == TOP_NAVIGATION_ROUTES[3]) {
      this.setTiles(OrderInputFieldData);
    } else if (this.props.currentSubTab == 1 && this.props.currentTab == TOP_NAVIGATION_ROUTES[3]) {
      this.setTiles(WeightSlipFieldData);
    }
    // else if (this.props.currentSubTab == 2 && this.props.currentTab == TOP_NAVIGATION_ROUTES[3]) {
    //   this.setTiles(ClaimFieldData);
    // }
    else if (
      this.props.currentSubTab == 0 &&
      this.props.currentTab == BOTTOM_NAVIGATION_ROUTES[0]
    ) {
      this.setTiles(BankInputFieldData);
    } else if (
      this.props.currentSubTab == -1 &&
      this.props.currentTab == BOTTOM_NAVIGATION_ROUTES[0]
    ) {
      this.setTiles(GroupInputFieldData);
    } else if (
      this.props.currentSubTab == 1 &&
      this.props.currentTab == BOTTOM_NAVIGATION_ROUTES[0]
    ) {
    } else if (
      this.props.currentSubTab == 2 &&
      this.props.currentTab == BOTTOM_NAVIGATION_ROUTES[0]
    ) {
      this.setTiles(ArticleInputFieldData);
    } else if (
      this.props.currentSubTab == 3 &&
      this.props.currentTab == BOTTOM_NAVIGATION_ROUTES[0]
    ) {
      this.setTiles(TeamInputFieldData);
    } else if (this.props.currentTab == BOTTOM_NAVIGATION_ROUTES[1]) {
      this.setTiles(CompanyInputFieldData);
    }
  };

  getPayload = (data) => {
    let payload = {
      fieldAgent: data.accountManagerSource,
      source: data.source,
      deviatingInvoiceAddressProcessor: data.deviatingInvoiceAddressProcessor,
      deviatingInvoiceAddressSource: data.deviatingInvoiceAddressSource,
      processor: data.processor,
      carrier: data.carrier,
      vehicle: data.vehicle,
      category: data.category,
      minLoadingWeight: data.minLoadingWeight,
      transportationBudget: data.transportationBudget,
      shippingCost: data.shippingCost,
      currency: data.currency,
      incotermSource: data.incotermSource,
      incotermProcessor: data.incotermProcessor,
      collectionSource: data.collectionSource,
      deliveryProcess: data.deliveryProcess,
      pickupDate: data.pickupDate,
      deliveryDate: data.deliveryDate,
      loadingReferenceSource: data.loadingReferenceSource,
      loadingReferenceProcessor: data.loadingReferenceProcessor,
      articles: data.articles,
      weightslips: data.weightslips,
      claims: [],
      accountManagerProcessor:
        typeof data.accountManagerProcessor !== 'string'
          ? [data.accountManagerProcessor]
          : data.accountManagerProcessor,
      docs: data.docs,
      countries: data.countries,
      sourceLocationId: data.sourceLocation ? data.sourceLocation._id : null,
      processorLocationId: data.processorLocation ? data.processorLocation._id : null,
      orderRemark: data.orderRemark ? data.orderRemark : null,
      bookingNo: data.bookingNo,
    };
    return payload;
  };

  handleUpdateWeightSlip = (weightSlips) => {
    let data = { ...this.state };
    data['articles'] = this.props.articles;
    data['weightslips'] = weightSlips;
    const payload = this.getPayload(data);
    this.props.editOrder(this.currentEntity._id, payload);

    setTimeout(() => {
      this.getOrderDetails();
    }, 500);
  };

  onDrop = (files, type) => {
    let data = new FormData();
    let BASE_URL = getApiUrl();
    data.append('files', files[0]);

    axios.post(`${BASE_URL}/companys/assets/upload`, data, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    }).then(
      (res) => {
        let fileName = res.data.fileName;
        this.setState({ [type]: fileName });
      },
      (error) => {
        console.log(error);
      },
    );
  };

  handleDropContractors = (files) => {
    let data = new FormData();
    let BASE_URL = getApiUrl();
    data.append('files', files[0]);

    axios.post(`${BASE_URL}/contracts/upload`, data, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    }).then(
      (res) => {
        let fileName = res.data.fileName;
        this.setState({ signedContract: fileName });
      },
      (error) => {
        console.log(error);
      },
    );
  };

  handleTeamSignature = (files) => {
    let data = new FormData();
    let BASE_URL = getApiUrl();
    data.append('files', files[0]);

    axios.post(`${BASE_URL}/persons/team/signature/upload`, data, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    }).then(
      (res) => {
        let fileName = res.data.fileName;
        this.setState({ teamSignature: fileName });
      },
      (error) => {
        console.log(error);
      },
    );
  };

  deleteAttachment = (type) => {
    this.setState({ [type]: null });
  };

  handleFileDownload = (name) => {
    let BASE_URL = getApiUrl();
    axios({
      url: `${BASE_URL}/companys/image/${name}`,
      method: 'GET',
      responseType: 'blob',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then((response) => {
      download(response.data, name);
    });
  };

  getPurchaseTotal = () => {
    let articles = this.currentEntity ? this.currentEntity.articles : [];
    let total = 0;
    if (articles) {
      articles.map((article) => {
        const { price, weight, amount } = getArticleDetails(article, 'source');
        let unit = article.source_unit;

        // let totalPrice = price * (unit === 'Piece' ? amount : weight);
        let totalPrice = price * weight;

        total = totalPrice + total;
      });
    }

    return formatDecimals(total);
  };

  getSalesTotal = () => {
    let articles = this.currentEntity ? this.currentEntity.articles : [];
    let total = 0;
    if (articles) {
      articles.map((article) => {
        const { price, weight, amount } = getArticleDetails(article, 'processor');
        let unit = article.processor_unit;

        // let totalPrice = price * (unit === 'Piece' ? amount : weight);

        let totalPrice = price * weight;

        total = totalPrice + total;
      });
    }

    return formatDecimals(total);
  };

  getArticleCurrency = () => {
    return this.currentEntity.articles.length > 0
      ? this.currentEntity.articles[0].source_currency === 'Euro'
        ? '€'
        : '$'
      : '';
  };

  getTransportTotal = () => {
    return this.currentEntity ? this.currentEntity.shippingCost : 0;
  };

  handleOrderRemarkChange = (event) => {
    this.setState({ orderRemark: event.target.value });
  };

  handleSignedContractDownload = (name) => {
    let BASE_URL = getApiUrl();
    axios({
      url: `${BASE_URL}/contracts/download-file/${name}`,
      method: 'GET',
      responseType: 'blob',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then((response) => {
      download(response.data, name);
    });
  };

  handleTeamSignatureDownload = (name) => {
    let BASE_URL = getApiUrl();
    axios({
      url: `${BASE_URL}/persons/team/download-file/${name}`,
      method: 'GET',
      responseType: 'blob',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then((response) => {
      download(response.data, name);
    });
  };

  render() {
    if (this.props.currentTab === TOP_NAVIGATION_ROUTES[1]) {
      return (
        <div style={{ flexGrow: 1 }}>
          <form noValidate autoComplete="off">
            <Button
              startIcon={<SaveIcon />}
              variant="contained"
              color="primary"
              style={{ margin: 10, float: 'left', color: 'green', backgroundColor: 'white' }}
              onClick={async () => {
                await this.props.editCarrier(this.currentEntity._id, this.state);
                setTimeout(() => {
                  this.getSchema();
                }, 300);
              }}
            >
              {`${this.props.t('Save')} ${this.props.t(this.props.currentTab)}`}
            </Button>
            <Button
              variant="contained"
              startIcon={<CancelIcon />}
              style={{ margin: 10, float: 'left', backgroundColor: 'white', color: 'red' }}
              onClick={() => this.props.cancelBtn()}
            >
              {this.props.t('Cancel')}
            </Button>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper style={{ padding: 12, height: '100%', width: '100%' }}>
                  <Typography variant="h4" style={{ paddingBottom: 30 }}>
                    {this.props.t(this.state.header0)}
                  </Typography>
                  {this.state.tile0}
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper style={{ padding: 12, height: '100%', width: '100%' }}>
                  <Typography variant="h4" style={{ paddingBottom: 30 }}>
                    {this.props.t(this.state.header1)}
                  </Typography>
                  {this.state.tile1}
                </Paper>
              </Grid>

              {/*TABS HERE*/}
              <Grid item xs={12}>
                <Typography variant="h4" style={{ paddingBottom: 30 }}>
                  {this.props.t('Contact Persons')}
                </Typography>
                <Paper style={{ padding: 12, height: '100%', width: '100%' }}>
                  <PersonSelectTabs
                    loaded={this.state.loadedPersons}
                    allPersons={this.state.allPersons}
                    onAdd={() => this.setState({ addModalOpen: true })}
                    onEdit={(rowData) =>
                      this.setState({ editPersonModal: true, selectedRow: rowData._id })
                    }
                    contactPersons={this.state.contactPersons}
                    onSelect={(selected) => {
                      this.setState({ contactPersons: selected });
                    }}
                    onSave={async () => {
                      await this.props.editCarrier(this.currentEntity._id, this.state);
                      setTimeout(() => {
                        this.getSchema(true);
                      }, 100);
                    }}
                    onRemove={(selected) => {
                      let persons = this.state.contactPersons;
                      let index = persons.findIndex((val) => val._id === selected);
                      if (index > -1) {
                        persons.splice(index, 1);
                        this.setState({ contactPersons: persons }, async () => {
                          this.state.openingHours = this.props.openingHours;
                          let payload = { ...this.state };
                          payload.contactPersons = persons;
                          await this.props.editCarrier(this.currentEntity._id, payload);
                          setTimeout(() => {
                            this.getSchema(true);
                          }, 100);
                        });
                      }
                    }}
                    reload={() => this.getSchema()}
                  />
                </Paper>
                <PersonAddModal
                  open={this.state.addModalOpen}
                  currentEntity={this.currentEntity}
                  variant={'carrier'}
                  handleClose={() => {
                    this.setState({ addModalOpen: false });
                    this.getSchema();
                  }}
                />
                {this.state.editPersonModal && (
                  <PersonEditModal
                    open={this.state.editPersonModal}
                    currentEntity={this.currentEntity}
                    selectedRow={this.state.selectedRow}
                    variant={'carrier'}
                    handleClose={(reload = false) => {
                      this.setState({ editPersonModal: false, selectedRow: null });
                      if (reload) this.getSchema();
                    }}
                    {...this.props}
                  />
                )}
              </Grid>
            </Grid>
          </form>
        </div>
      );
    } else if (this.props.currentTab === TOP_NAVIGATION_ROUTES[2]) {
      return (
        <div style={{ flexGrow: 1 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            style={{ margin: 10, float: 'left' }}
            onClick={() => this.props.editPerson(this.currentEntity._id, this.state)}
          >
            {`${this.props.t('Save')} ${this.props.t(this.props.currentTab)}`}
          </Button>
          <Button
            variant="contained"
            startIcon={<CancelIcon />}
            style={{ margin: 10, float: 'left', backgroundColor: 'white', color: 'red' }}
            onClick={() => this.props.cancelBtn()}
          >
            {this.props.t('Cancel')}
          </Button>
          <form noValidate autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper style={{ padding: 12, height: '100%', width: '100%' }}>
                  <Typography variant="h4" style={{ paddingBottom: 30 }}>
                    {this.props.t(this.state.header0)}
                  </Typography>
                  {this.state.tile0.map((tile) =>
                    tile ? tile.key !== 'email' ? tile : <EmailField /> : null,
                  )}
                </Paper>
              </Grid>
            </Grid>
          </form>
        </div>
      );
    } else if (this.props.currentTab === TOP_NAVIGATION_ROUTES[3]) {
      if (this.state.fieldAgentData != undefined && this.props.currentSubTab == 0) {
        return (
          <div style={{ flexGrow: 1 }}>
            <form noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper style={{ padding: 12, height: '100%', width: '100%' }}>
                    {this.props.permission.state.user.permissions.finance_read && (
                      <Finance
                        heading={this.props.t(this.state.header2)}
                        currentEntity={this.currentEntity}
                        state={this.state}
                        self={this}
                        {...this.props}
                      />
                    )}

                    <SendDocs
                      currentEntity={this.currentEntity}
                      state={this.state}
                      self={this}
                      permissions={this.props.permission.state.user.permissions}
                      {...this.props}
                    />

                    {this.state.tile2}
                  </Paper>
                </Grid>

                <Grid item container xs={12}>
                  <Orders
                    self={this}
                    state={this.state}
                    demo={this.props.demo}
                    currentEntity={this.currentEntity}
                    currentEntity2={this.currentEntity2}
                    currentEntity3={this.currentEntity3}
                    handleMultipleChoiceChange={this.handleMultipleChoiceChange}
                    setDestinationType={this.setDestinationType}
                    {...this.props}
                  />
                </Grid>
              </Grid>
            </form>
          </div>
        );
      } else if (this.props.currentSubTab == 1) {
        return (
          <div style={{ flexGrow: 1 }}>
            <form noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  {/*removed paper and added div */}
                  <Paper className="" style={{ padding: 12, width: '100%', marginTop: 15 }}>
                    <div className="order_invoice_totals">
                      <Typography variant="h4">
                        {this.props.t('Weightslips')}{' '}
                        <span style={{ fontWeight: 'normal' }}>
                          {this.currentEntity.orderNumber}
                        </span>
                        <hr style={{ width: '100%', color: 'lightgray' }} />
                      </Typography>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="display-flex align-center">
                          <TextField
                            style={{ margin: 10, width: '450px' }}
                            defaultValue={this.currentEntity.orderRemark || ''}
                            required={false}
                            label={this.props.t('Order remark')}
                            name={'order_remark'}
                            onChange={this.handleOrderRemarkChange}
                          />
                          <PreviewInvoice {...this.createDocumentProps()} fullButton={true} />
                        </div>
                        {this.currentEntity && (
                          <div className="order_invoice_totals_box">
                            <p style={{ marginBottom: '10px' }}>
                              <span>
                                {this.currentEntity.status && this.currentEntity.status.yellow && (
                                  <span className={'yellow'} />
                                )}
                                {this.currentEntity.status && this.currentEntity.status.orange && (
                                  <span className={'orange'} />
                                )}
                                {this.currentEntity.status && this.currentEntity.status.green && (
                                  <span className={'green'} />
                                )}
                                {this.currentEntity.status &&
                                  this.currentEntity.status.checkbox && <span className={'blue'} />}
                              </span>
                            </p>
                            <p className={'font-16'}>
                              <b className="display-flex justify-content-between w-80">
                                <span> {this.props.t('Purchase total')}:</span>{' '}
                                <span>
                                  {getGermanNumber(this.getPurchaseTotal())}
                                  {this.getArticleCurrency()}{' '}
                                </span>
                              </b>
                            </p>
                            <p className={'font-16'}>
                              <b className="display-flex justify-content-between w-80">
                                <span>{this.props.t('Sales total')}: </span>
                                <span>
                                  {getGermanNumber(this.getSalesTotal())}
                                  {this.getArticleCurrency()}{' '}
                                </span>
                              </b>
                            </p>
                            <p className={'font-16'}>
                              <b className="display-flex justify-content-between w-80">
                                <span> {this.props.t('Transport')}: </span>{' '}
                                <span>
                                  {this.getTransportTotal()}
                                  {this.getArticleCurrency()}{' '}
                                </span>
                              </b>
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </Paper>
                  <MultiWeightSlip
                    sourcePrice={this.state.sourcePrice}
                    sourceWeight={this.state.sourceWeight}
                    processorPrice={this.state.processorPrice}
                    processorWeight={this.state.processorWeight}
                    callbackValue={this.handleChildStateChange}
                    currentEntity={this.currentEntity}
                    handleUpdateWeightSlip={this.handleUpdateWeightSlip}
                    editOrderInstance={this}
                    {...this.props}
                  />
                  {/* </Paper> */}
                </Grid>
              </Grid>
            </form>
          </div>
        );
      } else if (this.props.currentSubTab == -2) {
        return (
          <div style={{ flexGrow: 1, marginTop: '16px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper style={{ padding: 12, height: '100%', width: '100%' }}>
                  <Typography variant="h4" style={{ paddingBottom: 30 }}>
                    Claims will be here
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            {/*<form noValidate autoComplete="off">*/}
            {/*  <Grid container spacing={3}>*/}
            {/*    <MultiWeightSlip*/}
            {/*      sourcePrice={this.state.sourcePrice}*/}
            {/*      sourceWeight={this.state.sourceWeight}*/}
            {/*      processorPrice={this.state.processorPrice}*/}
            {/*      processorWeight={this.state.processorWeight}*/}
            {/*      callbackValue={this.handleChildStateChange}*/}
            {/*    />*/}
            {/*    <br />*/}
            {/*    <Paper style={{ padding: 12, margin: 10, width: "100%" }}>*/}
            {/*      <Grid container spacing={2}>*/}
            {/*        <Grid item xs={6}>*/}
            {/*          <CheckBoxControlledDate*/}
            {/*            checkboxLabel={"Open claim"}*/}
            {/*            checkboxId={"Open claim"}*/}
            {/*            checkboxDbid={"openClaim"}*/}
            {/*            callbackValue={this.handleDateChange}*/}
            {/*            required={false}*/}
            {/*            dateLabel={"Claim started"}*/}
            {/*            dateDbid={"claimStarted"}*/}
            {/*          />*/}
            {/*          <br />*/}
            {/*          <TextareaAutosize*/}
            {/*            rowsMin={5}*/}
            {/*            placeholder={`${this.props.t("Source")} ${this.props.t("Claim Comments")}`}*/}
            {/*          />*/}
            {/*          <FilePicker*/}
            {/*            onChange={this.handleFilePickerChange}*/}
            {/*            onError={(err) => alert("Failed to upload file...", err)}*/}
            {/*            name={"fileProof1"}*/}
            {/*            dbid={"fileProof1"}*/}
            {/*          >*/}
            {/*            <Button contained color="primary">*/}
            {/*              {`${this.props.t("SAVE")} ${this.props.t("PROOF")}`}*/}
            {/*            </Button>*/}
            {/*          </FilePicker>*/}
            {/*          <Button variant="contained" color="secondary">*/}
            {/*            {`${this.props.t("View")} ${this.props.t("proof")}`}*/}
            {/*            <AiFillEye />*/}
            {/*          </Button>*/}
            {/*        </Grid>*/}
            {/*        <Grid item xs={6}>*/}
            {/*          <CheckBoxControlledDate*/}
            {/*            checkboxLabel={"Claim closed"}*/}
            {/*            checkboxId={"Close claim"}*/}
            {/*            checkboxDbid={"closeClaim"}*/}
            {/*            callbackValue={this.handleDateChange}*/}
            {/*            required={false}*/}
            {/*            dateLabel={"Claim ended"}*/}
            {/*            dateDbid={"claimEnded"}*/}
            {/*          />*/}

            {/*          <br />*/}
            {/*          <TextareaAutosize*/}
            {/*            rowsMin={5}*/}
            {/*            placeholder={`${this.props.t("Processor")} ${this.props.t(*/}
            {/*              "Claim Comments"*/}
            {/*            )}`}*/}
            {/*          />*/}
            {/*          <FilePicker*/}
            {/*            onChange={this.handleFilePickerChange}*/}
            {/*            onError={(err) => alert("Failed to upload file...", err)}*/}
            {/*            name={"fileProof2"}*/}
            {/*            dbid={"fileProof2"}*/}
            {/*          >*/}
            {/*            <Button contained color="primary">*/}
            {/*              {`${this.props.t("SAVE")} ${this.props.t("PROOF")}`}*/}
            {/*            </Button>*/}
            {/*          </FilePicker>*/}
            {/*          <Button variant="contained" color="secondary">*/}
            {/*            {`${this.props.t("View")} ${this.props.t("proof")}`}*/}
            {/*            <AiFillEye />*/}
            {/*          </Button>*/}
            {/*        </Grid>*/}
            {/*      </Grid>*/}
            {/*    </Paper>*/}
            {/*  </Grid>*/}
            {/*</form>*/}
            {/*<Button*/}
            {/*  variant="contained"*/}
            {/*  color="primary"*/}
            {/*  startIcon={<CloudUploadIcon />}*/}
            {/*  style={{ marginTop: 20 }}*/}
            {/*  onClick={() => this.handleAdd()}*/}
            {/*>*/}
            {/*  {`${this.props.t("ADD")} ${this.props.t(this.props.currentTab)}`}*/}
            {/*</Button>*/}
          </div>
        );
      } else if (this.props.currentSubTab == 2) {
        return (
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Tuning self={this} />
            </Grid>
          </Grid>
        );
      } else {
        return (
          <div className={'order-progress'}>
            <span>{this.props.t('please_wait')}</span>
          </div>
        );
      }
    } else if (this.props.currentTab === TOP_NAVIGATION_ROUTES[6]) {
      return (
        <>
          <Grid>
            <ContractSendDocs
              state={this.state}
              self={this}
              currentEntity={this.currentEntity}
              permissions={this.props.permission.state.user.permissions}
              {...this.props}
            />
          </Grid>
          <Contract
            self={this}
            state={this.state}
            demo={this.props.demo}
            currentEntity={this.currentEntity}
            currentEntity2={this.currentEntity2}
            currentEntity3={this.currentEntity3}
            handleMultipleChoiceChange={this.handleMultipleChoiceChange}
            setDestinationType={this.setDestinationType}
            {...this.props}
          />
        </>
      );
    } else if (
      this.props.currentTab === BOTTOM_NAVIGATION_ROUTES[0] &&
      this.props.currentSubTab == 0
    ) {
      return <Bank self={this} />;
    } else if (
      this.props.currentTab === BOTTOM_NAVIGATION_ROUTES[0] &&
      this.props.currentSubTab == -1
    ) {
      return <Groups self={this} />;
    } else if (
      this.props.currentTab === BOTTOM_NAVIGATION_ROUTES[0] &&
      this.props.currentSubTab == 2
    ) {
      return this.currentEntity ? <Article self={this} /> : '';
    } else if (
      this.props.currentTab === BOTTOM_NAVIGATION_ROUTES[0] &&
      this.props.currentSubTab == 3
    ) {
      return <Team self={this} />;
    } else if (
      this.props.currentTab === BOTTOM_NAVIGATION_ROUTES[0] &&
      this.props.currentSubTab == 4
    ) {
      return <Warehouse self={this} />;
    } else if (this.props.currentTab === TOP_NAVIGATION_ROUTES[0]) {
      return (
        <Partners
          self={this}
          state={this.state}
          currentEntity={this.currentEntity}
          handleMultipleChoiceChange={this.handleMultipleChoiceChange}
          {...this.props}
        />
      );
    } else if (this.props.currentTab === BOTTOM_NAVIGATION_ROUTES[1]) {
      return <Company self={this} />;
    }
  }
}

const mapStateToProps = ({ partners, orders, persons, common, contracts }) => ({
  personForm: persons.form,
  openingHours: partners.openingHours,
  locations: partners.locations,
  articles: orders.articles,
  contractArticles: contracts.articles,
  settings: common.settings,
});

export default connect(mapStateToProps, {
  getCompanys,
  getCompany,
  editCompany,
  getClient,
  editClient,
  getClients,
  editHoliday,
  getHoliday,
  getCarrier,
  editCarrier,
  getCarriers,
  editArticle,
  getArticle,
  getArticles,
  getPerson,
  editPerson,
  getPersons,
  getFieldAgents,
  getOrder,
  editOrder,
  getOrders,
  getPartner,
  editPartner,
  getPartners,
  getBank,
  editBank,
  getGroup,
  editGroup,
  getGroups,
  getWeightslip,
  getInvoice,
  setOpeningHours,
  resetOpeningHours,
  resetLocations,
  setLocationValues,
  setEmail,
  sendDocument,
  sendContractDocument,
  getTeams,
  resetPersons,
  editWarehouse,
  getWarehouse,
  getArticleTypes,
  checkIsWarehouse,
  getLocations,
  getContract,
  getContracts,
  editContract,
  getContractPersons,
})(form(withTranslation('common')(EditForm)));
