import * as Actions from '../actions/contracts';

const initialState = {
  form: {
    buyer: '',
    shipper: '',
    consignee: '',
    notify: '',
    endUser: '',
    commercial: '',
    pod: '',
    incoterms: '',
    documents: '',
    dthc: '',
    lots: '',
    bank: '',
    paymentTerm: '',
  },
  articles: [
    {
      articleDetails: {},
      article_id: '',
      article_name: '',
      deviating_article_name: '',
      currency: 'Euro',
      price: '',
      loads: '',
      unit: 't',
      container_type: '40ft HC',
    },
  ],
};

const contracts = function (state = initialState, action) {
  switch (action.type) {
    case Actions.RESET: {
      return {
        ...state,
        articles: [],
      };
    }
    case Actions.ADD_ARTICLE: {
      let articles = state.articles;

      articles.push({
        articleDetails: {},
        article_id: '',
        article_name: '',
        deviating_article_name: '',
        currency: 'Euro',
        price: '',
        loads: '',
        unit: 't',
        container_type: '40ft HC',
      });

      return {
        ...state,
      };
    }

    case Actions.REMOVE_ARTICLE: {
      let articles = state.articles;
      articles.splice(action.payload, 1);

      return {
        ...state,
        articles: articles,
      };
    }

    case Actions.SET_VALUES: {
      return {
        ...state,
        articles: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default contracts;
