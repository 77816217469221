import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));

function MultipleChoice(props) {
  const classes = useStyles();
  const { currentInput, currentValues, callbackValue } = props;
  const [values, setValues] = useState([]);

  useEffect(() => {
    if (currentValues) setValues(currentValues);
  }, [currentValues.length]);

  function handleValueChange(event, value) {
    setValues(value);
    callbackValue(currentInput, value);
  }

  return (
    <div className={classes.root}>
      <Autocomplete
        style={{
          margin: 10,
          minWidth: 300,
          maxWidth: 300,
          display: "inline-flex",
        }}
        multiple
        id="tags-outlined"
        options={currentInput.options}
        getOptionLabel={(option) => props.t(option)}
        value={values}
        filterSelectedOptions
        onChange={(event, value) => handleValueChange(event, value)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.t(currentInput.label)}
            placeholder={props.t(currentInput.label)}
            inputProps={{
              ...params.inputProps,
              autoComplete: `new-password`,
            }}
          />
        )}
      />
    </div>
  );
}

export default withTranslation("common")(MultipleChoice);
