import React from 'react';
import TextInput from '../Inputs/TextInput';
import MultiChoice from '../Inputs/MultiChoice';
import MultiMultiChoice from '../Inputs/MultiMultichoice';
import CheckBox from '../Inputs/Checkbox';
import DatePicker from '../Inputs/DatePicker';
import DatabaseMultiChoice from '../Inputs/DatabaseMultiChoice';
import LocationPicker from '../Inputs/LocationPicker';
import DateRangePicker from '../Inputs/DateRangePicker';
import DateRangePicker2 from '../Inputs/DateRangePicker2';
import InvoiceDeliveryMethod from '../Inputs/InvoiceDeliveryMethodInput';
import { EditorState } from 'draft-js';

import { TOP_NAVIGATION_ROUTES, BOTTOM_NAVIGATION_ROUTES } from '../../constants/ui-constants';
import CarrierInputFieldData from '../../Schemas/AddCarrier.json';
import ArticleInputFieldData from '../../Schemas/AddArticle.json';
import TeamInputFieldData from '../../Schemas/AddTeams.json';
import PersonInputFieldData from '../../Schemas/AddPerson.json';
import OrderInputFieldData from '../../Schemas/AddOrder.json';
import PartnerInputFieldData from '../../Schemas/AddPartner.json';
import BankInputFieldData from '../../Schemas/AddBank.json';
import GroupInputFieldData from '../../Schemas/AddGroup.json';
import CompanyInputFieldData from '../../Schemas/AddCompany.json';
import ContractsInputFieldData from '../../Schemas/AddContracts.json';
import WeightSlipFieldData from '../../Schemas/AddWeightSlip.json';
import ClaimFieldData from '../../Schemas/AddClaim.json';
import WarehouseInputFieldData from '../../Schemas/AddWarehouse.json';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { addClient, getClients } from '../../redux/modules/client';
import { addCarrier, getCarriers } from '../../redux/modules/carrier';
import { addPartner, getPartners } from '../../redux/modules/partner';
import { addInvoice } from '../../redux/modules/invoice';
import { addWeightslip } from '../../redux/modules/weightslip';

import { addArticle, getArticles } from '../../redux/modules/article';
import { addHoliday, getHolidays } from '../../redux/modules/holiday';
import {
  addPerson,
  addPersonModal,
  getPersons,
  getTeams,
  getAccountManagers,
  getTruckNotifications,
  getContractPersons,
} from '../../redux/modules/person';
import { addOrder, getOrders, checkIsWarehouse, getLocations } from '../../redux/modules/order';
import { addContract } from '../../redux/modules/contracts';
import { addBank, getBank } from '../../redux/modules/bank';
import { addGroup } from '../../redux/modules/group';
import { addCompany } from '../../redux/modules/company';
import { resetOpeningHours, resetLocations } from '../../redux/store/actions/partners';
import { resetPersons } from '../../redux/store/actions/persons';
import { addWarehouse } from '../../redux/modules/warehouse';

import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import PaymentTermsMethod from '../Inputs/PaymentTermsMethod';
import EmailField from '../persons/Emails';
import Orders from './orders';
import Contract from './contracts';
import CountriesField from '../Orders/Countries';
import IbanTextInput from '../Inputs/ibanTextInput';
import MultipleChoice from '../Inputs/MultipleChoice';
import ZipcodeInput from '../Inputs/zipcodeInput';
import { getApiUrl } from '../../util/environment-utils';
import axios from 'axios';
import { TextField } from '@material-ui/core';
import System from './system';
import { showMessage } from '../../redux/store/actions/alerts';
import Partners from './partners';
import Company from './company';
import ColorInput from '../Inputs/ColorInput';
import PermissionsSelect from '../Inputs/PermissionsSelect';

const form = reduxForm({
  form: 'add',
});

class AddForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputFields: [],
      tileData: [],
      tile0: [],
      tile1: [],
      tile2: [],
      tile3: [],
      tile4: [],
      tile5: [],
      header0: '',
      header1: '',
      header2: '',
      header3: '',
      persons: [],
      selectedPersons: [],
      addModalOpen: false,
      multiExtern: false,
      extern: false,
      vehicleTypes: [],
      partnerType: 'Lade/Entladestelle',
      destinationType: 'dropshippment',
      warehouseArticles: [],
      fromWarehouse: false,
      signatures: null,
      companyLogo: null,
      companyAgb: null,
      sourceLocations: [],
      processorLocations: [],
      sourceLocation: null,
      processorLocation: null,
      docType: 'default',
      documents: {
        default: { subject: '', description: EditorState.createEmpty(), descriptionHtml: '' },
        annex: { subject: '', description: EditorState.createEmpty(), descriptionHtml: '' },
        purchase_confirmation: {
          subject: '',
          description: EditorState.createEmpty(),
          descriptionHtml: '',
        },
        sales_confirmation: {
          subject: '',
          description: EditorState.createEmpty(),
          descriptionHtml: '',
        },
        transport_information: {
          subject: '',
          description: EditorState.createEmpty(),
          descriptionHtml: '',
        },
        invoice: { subject: '', description: EditorState.createEmpty(), descriptionHtml: '' },
        transport_order: {
          subject: '',
          description: EditorState.createEmpty(),
          descriptionHtml: '',
        },
        billing_confirmation: {
          subject: '',
          description: EditorState.createEmpty(),
          descriptionHtml: '',
        },
        invoice_cancellation: {
          subject: '',
          description: EditorState.createEmpty(),
          descriptionHtml: '',
        },
        sales_contract:{
          subject: '',
          description: EditorState.createEmpty(),
          descriptionHtml: ''
        }
      },
      selectedSource: {},
      selectedProcessor: {},
      descriptionOfWaste: 'Plasticscrap for Recycling',
      currency: 'Euro',
      permissions: {},
      shipper: '',
      contractDocuments: ['B/L', 'Invoice', 'Packing List', 'Loading Photos'],
      podOptions: [],
      buyerContactPersons: [],
      teamSignature: null
    };
  }

  setDestinationType = (val) => {
    this.setState({ destinationType: val });
  };

  setFromWarehouse = () => {
    this.setState({ fromWarehouse: !this.state.fromWarehouse });
  };

  setExtern = async () => {
    await this.setState({ extern: true });
    this.forceUpdate();
  };

  getColumns = (columns) => {
    const updatedColumns = [];
    for (const column of columns) {
      updatedColumns.push({ title: this.props.t(column.title), field: column.field });
    }

    return updatedColumns;
  };

  componentDidMount() {
    this.getSchema(true);
  }

  getSchema = async (firstLoad = false) => {
    if (firstLoad) {
      this.props.resetPersons();
    }

    if (this.props.currentTab === TOP_NAVIGATION_ROUTES[0]) {
      if (firstLoad) {
        this.props.resetOpeningHours();
        this.props.resetLocations();
      }

      this.setTiles(PartnerInputFieldData);
      const personsList = await this.props.getTeams();
      this.setState({ persons: personsList.allPersons });
    } else if (this.props.currentTab === TOP_NAVIGATION_ROUTES[1]) {
      if (firstLoad) this.props.resetOpeningHours();

      this.setTiles(CarrierInputFieldData);
      const personsList = await this.props.getTeams();
      this.setState({ persons: personsList.allPersons });
    } else if (this.props.currentTab === TOP_NAVIGATION_ROUTES[2]) {
      this.setTiles(PersonInputFieldData);
    } else if (
      this.props.currentTab === TOP_NAVIGATION_ROUTES[3] &&
      this.props.currentSubTab == 0
    ) {
      this.setTiles(OrderInputFieldData);
    } else if (
      this.props.currentTab === TOP_NAVIGATION_ROUTES[3] &&
      this.props.currentSubTab == 1
    ) {
      this.setTiles(WeightSlipFieldData);
    } else if (
      this.props.currentTab === TOP_NAVIGATION_ROUTES[3] &&
      this.props.currentSubTab == 2
    ) {
      this.setTiles(ClaimFieldData);
    } else if (
      this.props.currentTab === BOTTOM_NAVIGATION_ROUTES[0] &&
      this.props.currentSubTab == 0
    ) {
      this.setTiles(BankInputFieldData);
    } else if (
      this.props.currentTab === BOTTOM_NAVIGATION_ROUTES[0] &&
      this.props.currentSubTab == -1
    ) {
      this.setTiles(GroupInputFieldData);
    } else if (
      this.props.currentTab === BOTTOM_NAVIGATION_ROUTES[0] &&
      this.props.currentSubTab == 1
    ) {
      this.setTiles(GroupInputFieldData);
    } else if (
      this.props.currentTab === BOTTOM_NAVIGATION_ROUTES[0] &&
      this.props.currentSubTab == 2
    ) {
      // this.setTiles(HolidayInputFieldData); hide
      this.setTiles(ArticleInputFieldData);
    } else if (
      this.props.currentTab === BOTTOM_NAVIGATION_ROUTES[0] &&
      this.props.currentSubTab == 3
    ) {
      this.setTiles(TeamInputFieldData);
    } else if (
      this.props.currentTab === BOTTOM_NAVIGATION_ROUTES[0] &&
      this.props.currentSubTab == 4
    ) {
      if (firstLoad) this.props.resetOpeningHours();
      this.setTiles(WarehouseInputFieldData);
    } else if (this.props.currentTab === BOTTOM_NAVIGATION_ROUTES[1]) {
      this.setTiles(CompanyInputFieldData);
    } else if (this.props.currentTab === TOP_NAVIGATION_ROUTES[6]) {
      this.setTiles(ContractsInputFieldData);
    }
  };

  setTiles = async (data) => {
    const headers = Object.keys(data);
    headers.map((header, index) => {
      const stuffer = `header${index}`;
      this.setState({ [stuffer]: header });
    });

    const tiles = Object.values(data).map(async (tile, index) => {
      const tileValues = Object.values(tile).map((question) => this.createInputField(question));
      const stuff = `tile${index}`;
      {
        /**
         Adds the fields to the correct tile.
       */
      }
      await this.setState({ [stuff]: tileValues });
    });
    this.setState({ tileData: tiles });
  };

  changeState = (name, val) => {
    this.setState({ [name]: val });
  };

  getUpdatedArticleRequiredFields(obj) {
    if (this.state.itemType === 'product') {
      if (obj.dbid === 'basalAnnexIX') return false;
    } else if (this.state.itemType === 'service') {
      if (obj.dbid === 'basalAnnexIX' || obj.dbid === 'hsCode') return false;
    } else {
      return true;
    }
  }

  createInputField = (obj) => {
    if (obj.type === 'TextInput') {
      return (
        <TextInput
          key={obj.label}
          style={{ margin: 10, width: obj.width ? obj.width : 300 }}
          change={(event, name) => {
            this.handleTextFieldChange(event, name);
          }}
          name={obj.label}
          required={obj.required}
          label={this.props.t(obj.label)}
          dbid={obj.dbid}
          type={obj.inputType ? obj.inputType : 'text'}
          self={this}
          isError={obj.isError || false}
        />
      );
    } else if (obj.type === 'PermissionsSelect') {
      return (
        <PermissionsSelect
          self={this}
          obj={obj}
          translate={this.props.t}
          style={{ margin: 10, width: obj.width ? obj.width : 300 }}
        />
      );
    } else if (obj.type === 'ColorInput') {
      return (
        <ColorInput
          key={obj.label}
          style={{ margin: 10, width: obj.width ? obj.width : 300 }}
          change={this.handleTextFieldChange}
          name={obj.label}
          required={obj.required}
          label={this.props.t(obj.label)}
          value={this.state[obj.dbid] || ''}
          dbid={obj.dbid}
        />
      );
    } else if (obj.type === 'TextArea') {
      return (
        <div>
          <TextField
            key={obj.label}
            style={{ margin: 10, width: '98%' }}
            onChange={(e) => {
              const { value } = e.target;
              this.setState({ [obj.dbid]: value });
            }}
            name={obj.label}
            required={obj.required}
            label={this.props.t(obj.label)}
            dbid={obj.dbid}
            rows={5}
            multiline={true}
          />
        </div>
      );
    } else if (obj.type === 'ZipcodeInput') {
      return (
        <ZipcodeInput
          dbid={obj.dbid}
          key={obj.label}
          style={{ margin: 10, width: 300 }}
          change={this.handleTextFieldChange}
          name={obj.label}
          required={obj.required}
          label={this.props.t(obj.label)}
          setState={this.changeState}
          zipcode={''}
          city={''}
          country={''}
        />
      );
    } else if (obj.type === 'EmailInput') {
      return <EmailField />;
    } else if (obj.type === 'IbanTextInput') {
      return (
        <IbanTextInput
          handleTextFieldChange={this.handleTextFieldChange}
          IBAN={this.state.IBAN}
          obj={obj}
          callbackValue={this.handleChildStateChange}
        />
      );
    } else if (obj.type === 'MultiChoice') {
      return (
        <MultiChoice
          key={obj.label}
          id={obj.id}
          callbackValue={this.handleMultiChoiceChange}
          required={obj.required}
          options={obj.options}
          label={this.props.t(obj.label)}
          dbid={obj.dbid}
          setExtern={this.setExtern}
          value={
            obj.dbid === 'partnerType'
              ? this.state[obj.dbid]
              : obj.dbid === 'currency'
              ? this.state.currency
              : obj.dbid === 'basisForInvoice'
              ? 'Standard'
              : undefined
          }
          width={obj.width}
        />
      );
    } else if (obj.type === 'MultiMultiChoice') {
      if (this.props.currentTab === TOP_NAVIGATION_ROUTES[2]) {
        if (this.props.extern2) {
          return '';
        }
      } else if (this.props.currentTab === TOP_NAVIGATION_ROUTES[6]) {
        return (
          <MultiMultiChoice
            key={obj.label}
            id={obj.id}
            callbackValue={this.handleMultiChoiceChange}
            required={obj.required}
            options={obj.options}
            label={this.props.t(obj.label)}
            dbid={obj.dbid}
            intern={'contract'}
            value={this.state.contractDocuments}
          />
        );
      }
      return (
        <MultiMultiChoice
          key={obj.label}
          id={obj.id}
          callbackValue={this.handleMultiChoiceChange}
          required={obj.required}
          options={obj.options}
          label={this.props.t(obj.label)}
          dbid={obj.dbid}
          intern={this.props.currentTab === BOTTOM_NAVIGATION_ROUTES[0] ? 'Intern' : 'Extern'}
        />
      );
    } else if (obj.type === 'DeliveryDateRangeMultiChoice') {
      return (
        <DateRangePicker
          key={obj.label}
          id={obj.id}
          callbackValue={this.handleMultiChoiceChange}
          callbackValueRange={this.handleChildStateChange}
          callbackDateChange={this.handleDateChange}
          required={obj.required}
          options={obj.options}
          label={this.props.t(obj.label)}
          dbid={obj.dbid}
          sourceCollection={this.state['collectionSource']}
          dateLabel={obj.dateLabel}
          dateDbid={obj.dateDbid}
          dateName={obj.dateName}
          width={obj.width}
        />
      );
    } else if (obj.type === 'DeliveryDateRangeMultiChoice2') {
      return (
        <DateRangePicker2
          key={obj.label}
          id={obj.id}
          callbackValue={this.handleMultiChoiceChange}
          callbackValueRange={this.handleChildStateChange}
          callbackDateChange={this.handleDateChange}
          required={obj.required}
          options={obj.options}
          label={this.props.t(obj.label)}
          dbid={obj.dbid}
          processorDelivery={this.state['deliveryProcess']}
          dateLabel={obj.dateLabel}
          dateDbid={obj.dateDbid}
          dateName={obj.dateName}
          width={obj.width}
        />
      );
    } else if (obj.type === 'InvoiceDeliveryMethod') {
      return (
        <InvoiceDeliveryMethod
          key={obj.label}
          key2={obj.emailLabel}
          id={obj.id}
          callbackValue={this.handleMultiChoiceChange}
          callbackValueText={this.handleTextFieldChange}
          emailLabel={obj.emailLabel}
          required={obj.required}
          options={obj.options}
          label={this.props.t(obj.label)}
          dbid={obj.dbid}
          invoiceDeliveryMethod={this.state['invoiceDeliveryMethod']}
        />
      );
    } else if (obj.type === 'PaymentTermsMethod') {
      return (
        <PaymentTermsMethod
          key={obj.label}
          key2={obj.daysLabel}
          id={obj.id}
          callbackValue={this.handleMultiChoiceChange}
          callbackValueText={this.handleTextFieldChange}
          daysLabel={obj.daysLabel}
          required={obj.required}
          options={obj.options}
          label={this.props.t(obj.label)}
          dbid={obj.dbid}
          paymentTerms={this.state['paymentTerms']}
        />
      );
    } else if (obj.type === 'CheckBox') {
      return (
        <CheckBox
          key={obj.label}
          id={obj.id}
          dbid={obj.dbid}
          callbackValue={this.handleCheckboxChange}
          required={obj.required}
          label={this.props.t(obj.label)}
        />
      );
    } else if (obj.type === 'DatePicker') {
      return (
        <DatePicker
          key={obj.label}
          id={obj.id}
          callbackValue={this.handleDateChange}
          required={obj.required}
          label={this.props.t(obj.label)}
          name={obj.label}
          dbid={obj.dbid}
          value={this.state[obj.dbid]}
          width={obj.width}
        />
      );
    } else if (obj.type === 'LocationPicker') {
      return (
        <LocationPicker
          key={obj.label}
          id={obj.id}
          dbid={obj.dbid}
          callbackValue={this.handleMultiChoiceChange}
          required={obj.required}
          label={this.props.t(obj.label)}
        />
      );
    } else if (obj.type === 'DatabaseMultiChoice') {
      return (
        <DatabaseMultiChoice
          key={obj.label}
          id={obj.id}
          name={obj.label}
          dbid={obj.dbid}
          value={this.state[obj.dbid]}
          required={obj.required}
          method={obj.method}
          getClients={() => this.props.getClients()}
          getCarriers={() => this.props.getCarriers()}
          getPersons={() => this.props.getPersons()}
          getOrders={() => this.props.getOrders()}
          getPartners={() => this.props.getPartners()}
          getArticles={() => this.props.getArticles()}
          getBank={() => this.props.getBank()}
          getTeams={() => this.props.getTeams()}
          getAccountManagers={() => this.props.getAccountManagers()} //
          label={this.props.t(obj.label)}
          self={this}
          callbackValue={this.handleDatabaseMultiChoiceChange}
          add={true}
          width={obj.width}
        />
      );
    } else if (obj.type === 'MultiCountries') {
      return <CountriesField width={obj.width} />;
    } else if (obj.type === 'MultipleChoice') {
      return (
        <MultipleChoice
          currentValues={[]}
          currentInput={obj}
          callbackValue={this.handleMultipleChoiceChange}
        />
      );
    }
  };

  handleMultipleChoiceChange = (currentInput, value) => {
    this.setState({
      [currentInput.dbid]: value,
    });
  };

  handleMultiChoiceChange = async (name, value) => {
    if (name === 'partnerType' && value === 'Debitor/Kreditor') {
      this.setState({ basisForInvoice: 'Standard' });
    }

    await this.setState({
      [name]: value,
    });

    this.getSchema();
  };

  handleTextFieldChange = (evt, name = null) => {
    const value = name ? evt : evt.target.value;
    this.setState({
      [name ? name : evt.target.name]: value,
    });

    this.getSchema();
  };

  handleDatabaseMultiChoiceChange = async (name, value, data, dataLabel, object) => {
    const nameId = name + 'Id';
    if (this.props.currentTab === BOTTOM_NAVIGATION_ROUTES[1] && name.toLowerCase() === 'bank') {
      this.setState({
        [name]: value,
        [dataLabel]: data,
        bankId: object ? object._id : object,
        [nameId]: object ? object._id : object,
      });

      return;
    }

    if (this.props.currentTab === TOP_NAVIGATION_ROUTES[6]) {
      if (name.toLowerCase() === 'buyer') {
        //get contact Persons
        this.props.getContractPersons([object._id]).then((res) => {
          this.setState({
            podOptions: object && object.pod && object.pod.length > 0 ? object.pod.split(',') : [],
            buyerContactPersons: res.data,
          });
        });
      }
    }

    if (this.props.currentTab === TOP_NAVIGATION_ROUTES[3]) {
      if (name.toLowerCase() === 'carrier')
        this.setState({ vehicleTypes: object && object.vehicle ? object.vehicle : [] });

      if (name.toLowerCase() === 'source') {
        let response = null;
        if (object) response = await this.props.checkIsWarehouse(object._id);

        let locations = null;
        if (object) locations = await this.props.getLocations(object._id);

        this.setState({
          warehouseArticles: response ? response.allArticles : [],
          sourceLocations: locations ? locations.allLocations : [],
          selectedSource: object,
        });
      }

      if (name.toLowerCase() === 'processor') {
        let locations = null;
        if (object) locations = await this.props.getLocations(object._id);
        this.setState({
          processorLocations: locations ? locations.allLocations : [],
          selectedProcessor: object,
        });
      }

      this.setState({
        sourceType: object ? object.partnerType : undefined,
        [name]: object ? object._id : value,
        [dataLabel]: object ? object._id : data,
        [nameId]: object ? object._id : object,
      });
    } else {
      this.setState({
        [name]: value,
        [dataLabel]: data,
        [nameId]: object ? object._id : object,
      });
    }
  };

  handleCheckboxChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleDateChange = (name, date) => {
    this.setState({
      [name]: date,
    });
  };

  handleChildStateChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  callback = (currentTab, currentSubTab = 0, currentCols, message, errors) => {
    if (errors === undefined) {
      this.props.setCurrentTab(currentTab, currentSubTab, currentCols);
      setTimeout(() => {
        this.props.showMessage({ message: message, messageType: 'success' });
      }, 200);
    } else {
      this.props.showMessage({ message: errors, messageType: 'error' });
    }
  };

  handleAdd = () => {
    let keysToRemove = ['tile0', 'tile1', 'tile2', 'tile3', 'tile4', 'tile5', 'tileData'];

    let payload = { ...this.state };

    for (let key of Object.keys(payload)) {
      if (keysToRemove.includes(key)) delete payload[key];
    }

    if (this.props.currentTab === TOP_NAVIGATION_ROUTES[0]) {
      payload.openingHours = this.props.openingHours;

      if (
        payload.invoiceDeliveryMethod === 'Email' &&
        (!payload.invoiceEmail || payload.invoiceEmail.length === 0)
      ) {
        this.props.showMessage({
          message: 'invoice_delivery_email_is_required',
          messageType: 'error',
        });
        return;
      }

      this.props.addPartner(payload, this.callback, TOP_NAVIGATION_ROUTES[0], '0');
    } else if (this.props.currentTab === TOP_NAVIGATION_ROUTES[1]) {
      if (
        payload.invoiceDeliveryMethod === 'Email' &&
        (!payload.invoiceEmail || payload.invoiceEmail.length === 0)
      ) {
        this.props.showMessage({
          message: 'invoice_delivery_email_is_required',
          messageType: 'error',
        });
        return;
      }
      this.props.addCarrier(payload, this.callback, TOP_NAVIGATION_ROUTES[1], '0');
    } else if (this.props.currentTab === TOP_NAVIGATION_ROUTES[2]) {
      payload.emails = this.props.personForm.emails;

      this.props.addPerson(payload, this.callback, TOP_NAVIGATION_ROUTES[2], '0');
    } else if (this.props.currentTab === TOP_NAVIGATION_ROUTES[6]) {
      payload.articles = this.props.contractArticles;

      this.props.addContract(payload, this.callback, TOP_NAVIGATION_ROUTES[6], '0');
    } else if (this.props.currentTab === TOP_NAVIGATION_ROUTES[3]) {
      payload.countries = this.props.transitCountries.countries;
      this.props.addOrder(payload, this.callback, TOP_NAVIGATION_ROUTES[3], 0);
    } else if (
      this.props.currentTab === BOTTOM_NAVIGATION_ROUTES[0] &&
      this.props.currentSubTab == 0
    ) {
      this.props.addBank(
        payload,
        this.callback,
        BOTTOM_NAVIGATION_ROUTES[0],
        this.props.currentSubTab,
      );
    } else if (
      this.props.currentTab === BOTTOM_NAVIGATION_ROUTES[0] &&
      this.props.currentSubTab == -1
    ) {
      this.props.addGroup(
        payload,
        this.callback,
        BOTTOM_NAVIGATION_ROUTES[0],
        this.props.currentSubTab,
      );
    } else if (
      this.props.currentTab === BOTTOM_NAVIGATION_ROUTES[0] &&
      this.props.currentSubTab == 2
    ) {
      // this.props.addHoliday(this.state); hides
      this.props.addArticle(
        payload,
        this.callback,
        BOTTOM_NAVIGATION_ROUTES[0],
        this.props.currentSubTab,
      );
    } else if (
      this.props.currentTab === BOTTOM_NAVIGATION_ROUTES[0] &&
      this.props.currentSubTab == 3
    ) {
      payload.emails = this.props.personForm.emails;

      this.props.addPerson(
        payload,
        this.callback,
        BOTTOM_NAVIGATION_ROUTES[0],
        this.props.currentSubTab,
      );
    } else if (
      this.props.currentTab === BOTTOM_NAVIGATION_ROUTES[0] &&
      this.props.currentSubTab == 4
    ) {
      payload.openingHours = this.props.openingHours;

      this.props.addWarehouse(
        payload,
        this.callback,
        BOTTOM_NAVIGATION_ROUTES[0],
        this.props.currentSubTab,
      );
    } else if (this.props.currentTab === BOTTOM_NAVIGATION_ROUTES[1]) {
      this.props.addCompany(payload, this.callback, BOTTOM_NAVIGATION_ROUTES[1], 0);
    } else {
    }
  };

  updateState = (value) => {
    this.setState(value);
  };

  onDrop = (files, type) => {
    let data = new FormData();
    let BASE_URL = getApiUrl();
    data.append('files', files[0]);

    axios.post(`${BASE_URL}/companys/assets/upload`, data, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    }).then(
      (res) => {
        let fileName = res.data.fileName;
        this.setState({ [type]: fileName });
      },
      (error) => {
        console.log(error);
      },
    );
  };

  deleteAttachment = (type) => {
    this.setState({ [type]: null });
  };

  handleFileDownload = (name) => {
    let BASE_URL = getApiUrl();
    window.open(`${BASE_URL}/companys/image/${name}`, '_blank');
  };

  handleTeamSignature = (files) => {
    let data = new FormData();
    let BASE_URL = getApiUrl();
    data.append('files', files[0]);

    axios.post(`${BASE_URL}/persons/team/signature/upload`, data, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    }).then(
      (res) => {
        let fileName = res.data.fileName;
        this.setState({ teamSignature: fileName });
      },
      (error) => {
        console.log(error);
      },
    );
  };

  handleTeamSignatureDownload = (name) => {
    let BASE_URL = getApiUrl();
    window.open(`${BASE_URL}/persons/team/download-file/${name}`, '_blank');
  };

  render() {
    if (this.props.currentTab === TOP_NAVIGATION_ROUTES[1]) {
      return (
        <div style={{ flexGrow: 1 }}>
          <form noValidate autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper style={{ padding: 12, height: '100%', width: '100%' }}>
                  <Typography variant="h4" style={{ paddingBottom: 30 }}>
                    {this.props.t(this.state.header0)}
                  </Typography>
                  {this.state.tile0}
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper style={{ padding: 12, height: '100%', width: '100%' }}>
                  <Typography variant="h4" style={{ paddingBottom: 30 }}>
                    {this.props.t(this.state.header1)}
                  </Typography>
                  {this.state.tile1}
                </Paper>
              </Grid>
            </Grid>
          </form>
          <Button
            variant="contained"
            color="primary"
            startIcon={<CloudUploadIcon />}
            style={{ marginTop: 20, backgroundColor: 'white', color: 'green' }}
            onClick={() => this.handleAdd()}
          >
            {`${this.props.t('ADD')} ${this.props.t(this.props.currentTab)}`}
          </Button>
        </div>
      );
    }
    // ADD ORDERS TAB
    else if (this.props.currentTab === TOP_NAVIGATION_ROUTES[3]) {
      if (this.props.currentSubTab == 0) {
        return (
          <Orders
            setDestinationType={this.setDestinationType}
            state={this.state}
            handleAdd={this.handleAdd}
            updateState={this.updateState}
            handleMultipleChoiceChange={this.handleMultipleChoiceChange}
            self={this}
            {...this.props}
          />
        );
      }

      return <div>{this.props.t('Nothing to show')}</div>;
    } else if (this.props.currentTab === BOTTOM_NAVIGATION_ROUTES[0]) {
      return <System self={this} />;
    } else if (this.props.currentTab === TOP_NAVIGATION_ROUTES[2]) {
      return (
        <div style={{ flexGrow: 1 }}>
          <form noValidate autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper style={{ padding: 12, height: '100%', width: '100%' }}>
                  <Typography variant="h4" style={{ paddingBottom: 30 }}>
                    {this.state.header0}
                  </Typography>
                  {this.state.tile0.map((tile) => (tile.key !== 'E-mail' ? tile : <EmailField />))}
                </Paper>
              </Grid>
            </Grid>
          </form>
          <Button
            variant="contained"
            color="primary"
            startIcon={<CloudUploadIcon />}
            style={{ marginTop: 20 }}
            onClick={() => this.handleAdd()}
          >
            {`${this.props.t('ADD')} ${this.props.t(this.props.currentTab)}`}
          </Button>
        </div>
      );
    } else if (this.props.currentTab === BOTTOM_NAVIGATION_ROUTES[1]) {
      return <Company self={this} />;
    } else if (this.props.currentTab === TOP_NAVIGATION_ROUTES[0]) {
      return (
        <Partners
          state={this.state}
          handleAdd={this.handleAdd}
          updateState={this.updateState}
          handleMultipleChoiceChange={this.handleMultipleChoiceChange}
          self={this}
          {...this.props}
        />
      );
    } else if (this.props.currentTab === TOP_NAVIGATION_ROUTES[6]) {
      return (
        <Contract
          setDestinationType={this.setDestinationType}
          state={this.state}
          handleAdd={this.handleAdd}
          updateState={this.updateState}
          handleMultipleChoiceChange={this.handleMultipleChoiceChange}
          self={this}
          {...this.props}
        />
      );
    }

    return (
      <div style={{ flexGrow: 1 }}>
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Paper style={{ padding: 12, height: '100%', width: '100%' }}>
                <Typography variant="h4" style={{ paddingBottom: 30 }}>
                  {this.props.t(this.state.header0)}
                </Typography>
                {this.state.tile0}
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper style={{ padding: 12, height: '100%', width: '100%' }}>
                <Typography variant="h4" style={{ paddingBottom: 30 }}>
                  {this.state.header1}
                </Typography>
                {this.state.tile1}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper style={{ padding: 12, height: '100%', width: '100%' }}>
                <Typography variant="h4" style={{ paddingBottom: 30 }}>
                  {this.state.header2}
                </Typography>
                {this.state.tile2}
              </Paper>
            </Grid>
          </Grid>
        </form>
        <Button
          variant="contained"
          color="primary"
          startIcon={<CloudUploadIcon />}
          style={{ marginTop: 20 }}
          onClick={() => this.handleAdd()}
        >
          ADD {this.props.t(this.props.currentTab)}
        </Button>
      </div>
    );
  }
}

const mapStateToProps = ({ authentication, partners, persons, orders, contracts }) => ({
  openingHours: partners.openingHours,
  locations: partners.locations,
  personForm: persons.form,
  transitCountries: orders.form,
  articles: orders.articles,
  contractArticles: contracts.articles,
});

export default connect(mapStateToProps, {
  addHoliday,
  addClient,
  addCarrier,
  addArticle,
  addPerson,
  getAccountManagers,
  getTruckNotifications,
  addOrder,
  addContract,
  getClients,
  getCarriers,
  getArticles,
  getHolidays,
  getPersons,
  getOrders,
  addPartner,
  getPartners,
  addBank,
  addGroup,
  addCompany,
  addWeightslip,
  addInvoice,
  resetOpeningHours,
  resetLocations,
  resetPersons,
  addPersonModal,
  getTeams,
  addWarehouse,
  checkIsWarehouse,
  getLocations,
  showMessage,
  getBank,
  getContractPersons,
})(form(withTranslation('common')(AddForm)));
