import React from 'react';
import {
  getGermanNumber,
  getLanguageAcronym,
  getPaymentTerms,
  getUnloadingPartner,
} from '../../helpers/documents';
import { getApiUrl } from '../../util/environment-utils';
import { withTranslation } from 'react-i18next';
import Footer from './footer';
import Header from './header';
import { formatNumberToEn } from '../../helpers/common';

class InvoiceCancellation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date(),
      apiUrl: getApiUrl(),
    };
  }

  getTotalAmount = (formatted = true) => {
    let amount = 0;

    for (let article of this.props.currentEntity.articles) {
      if (this.checkCashFlow()) {
        let total = article.source_weight
          ? formatNumberToEn(article.source_weight) *
            parseFloat(
              article.source_price && article.source_price !== ''
                ? formatNumberToEn(article.source_price)
                : 0,
            )
          : 0 *
            parseFloat(
              article.source_price && article.source_price !== ''
                ? formatNumberToEn(article.source_price)
                : 0,
            );
        amount += total;
      } else {
        let total = article.processor_weight
          ? formatNumberToEn(article.processor_weight) *
            parseFloat(
              article.processor_price && article.processor_price !== ''
                ? formatNumberToEn(article.processor_price)
                : 0,
            )
          : 0 *
            parseFloat(
              article.processor_price && article.processor_price !== ''
                ? formatNumberToEn(article.processor_price)
                : 0,
            );
        amount += total;
      }
    }

    return formatted ? getGermanNumber(amount) : amount;
  };

  getTaxAmount = (total, taxPercent, formatted = true) => {
    const totalTaxAmount = taxPercent * total;
    const finalValue = !isNaN(taxPercent)
      ? formatted
        ? getGermanNumber(totalTaxAmount)
        : totalTaxAmount
      : 0;

    return finalValue;
  };

  getTotalAmountWithTax = () => {
    const { loadingInvoiceTax, unloadingInvoiceTax, dataSource } = this.props;
    const invoiceTax = dataSource === 'sourceData' ? loadingInvoiceTax : unloadingInvoiceTax;
    let totalAmount = parseFloat(this.getTotalAmount(false));
    let tax = this.checkCashFlow() ? invoiceTax.tax_rate_source : invoiceTax.tax_rate_processor;
    let taxAmount = parseFloat(this.getTaxAmount(totalAmount, tax, false));

    return getGermanNumber(totalAmount + taxAmount);
  };

  getTaxPercent = (tax) => {
    return isNaN(tax) ? 0 : tax * 100;
  };

  getUnloadingCity = () => {
    let partner = getUnloadingPartner(
      this.props.sourceData,
      this.props.processorData,
      this.props.currentEntity,
      this.props.dataSource === 'sourceData' ? 'incotermSource' : 'incotermProcessor',
    );
    return partner;
  };

  checkCashFlow() {
    // return this.props.currentEntity.articles[0].source_cashflow === 'Supplementary Payment';
    return this.props.dataSource === 'sourceData';
  }

  getLanguage = () => {
    let defaultLanguage = 'de';
    let partner = this.checkCashFlow() ? this.props.sourceData : this.props.processorData;
    let language = partner
      ? partner
        ? partner.documentLanguage
        : defaultLanguage
      : defaultLanguage;

    return getLanguageAcronym(language);
  };

  displayArticle(article) {
    return (
      (article.source_cashflow === 'Supplementary Payment' &&
        this.props.dataSource === 'sourceData' &&
        formatNumberToEn(article.source_weight) > 0) ||
      (article.processor_cashflow === 'Compensation' &&
        this.props.dataSource === 'processorData' &&
        formatNumberToEn(article.processor_weight) > 0)
    );
  }

  render() {
    const { loadingInvoiceTax, unloadingInvoiceTax, dataSource } = this.props;
    const invoiceTax = dataSource === 'sourceData' ? loadingInvoiceTax : unloadingInvoiceTax;
    const tax = this.checkCashFlow() ? invoiceTax.tax_rate_source : invoiceTax.tax_rate_processor;

    const language = this.getLanguage();
    const invoiceNumberField = this.checkCashFlow()
      ? 'invoiceNumberSource'
      : 'invoiceNumberProcessor';

    if (language === 'de') {
      return (
        <div className={'documents-container'}>
          <div className="main invoice">
            {this.checkCashFlow() ? (
              <Header
                locationData={
                  this.props.currentEntity.deviatingInvoiceAddressSource[0]
                    ? this.props.currentEntity.deviatingInvoiceAddressSource[0]
                    : this.props.sourceData
                }
                partnerLanguage={language}
                {...this.props}
              />
            ) : (
              <Header
                locationData={
                  this.props.currentEntity.deviatingInvoiceAddressProcessor[0]
                    ? this.props.currentEntity.deviatingInvoiceAddressProcessor[0]
                    : this.props.processorData
                }
                partnerLanguage={language}
                {...this.props}
              />
            )}
            <div className="invoice-body">
              <h3>
                Stornorechnung{' '}
                <b className={'hidden-on-pdf'}>
                  {this.props.newInvoiceNumber
                    ? `${
                        this.props.companyData.invoiceNumberPrefix
                          ? `${this.props.companyData.invoiceNumberPrefix}-`
                          : ''
                      }${this.props.newInvoiceNumber}${
                        this.props.companyData.invoiceNumberSuffix
                          ? `-${this.props.companyData.invoiceNumberSuffix}`
                          : ''
                      } `
                    : ''}
                  <span className={'show-only-on-pdf'}>
                    <b>%invoice_number%</b>
                  </span>
                </b>
              </h3>
              <p className="disc">Sehr geehrte Damen und Herren,</p>
              <p className="disc-2">
                Hiermit stornieren wir die Rechnung{' '}
                <b>
                  {this.props.currentEntity[invoiceNumberField]
                    ? `${
                        this.props.companyData.invoiceNumberPrefix
                          ? `${this.props.companyData.invoiceNumberPrefix}-`
                          : ''
                      }${this.props.currentEntity[invoiceNumberField]}${
                        this.props.companyData.invoiceNumberSuffix
                          ? `-${this.props.companyData.invoiceNumberSuffix}`
                          : ''
                      } `
                    : ''}
                </b>
                wie folgt:
              </p>

              <div className="tbl">
                <div className="row">
                  <div className="cell bold invoice-first-col">Material</div>
                  <div className="cell bold">
                    {this.getUnloadingCity() ? this.getUnloadingCity().heading : 'Lieferstelle'}
                  </div>
                  <div className="cell bold reference-col">
                    {this.getUnloadingCity()
                      ? this.getUnloadingCity().reference
                      : 'Entladereferenz'}
                  </div>

                  <div className="cell bold">Menge</div>
                  <div className="cell bold">Preis je Einheit</div>
                  <div className="cell bold">Gesamtpreis</div>
                </div>
                {this.props.currentEntity.articles.map(
                  (article, index) =>
                    this.displayArticle(article) && (
                      <div className="row" key={index} style={{ borderTop: 'none' }}>
                        <div className="cell invoice-first-col">
                          {' '}
                          {article.processor_deviating && article.processor_deviating !== ''
                            ? `${article.processor_deviating}`
                            : this.props.i18n.language === 'en'
                            ? article.articleDetails.descriptionInEnglish
                            : article.articleDetails.descriptionInGerman}
                        </div>
                        <div className="cell">
                          {this.getUnloadingCity() ? this.getUnloadingCity().partner.city : ''}
                        </div>

                        {this.checkCashFlow() ? (
                          <React.Fragment>
                            <div className="cell reference-col">
                              {this.props.currentEntity.loadingReferenceSource}
                            </div>

                            <div className="cell">
                              {article.source_weight
                                ? `${getGermanNumber(
                                    formatNumberToEn(article.source_weight),
                                    'amount',
                                  )} ${article.source_unit}`
                                : 'N/V'}
                            </div>
                            <div className="cell">
                              {' '}
                              {getGermanNumber(formatNumberToEn(article.source_price))}{' '}
                              {article.source_currency === 'Euro' ? '€' : '$'}
                            </div>
                            <div className="cell">
                              {' '}
                              {getGermanNumber(
                                article.source_weight
                                  ? formatNumberToEn(article.source_weight) *
                                      parseFloat(
                                        article.source_price && article.source_price !== ''
                                          ? formatNumberToEn(article.source_price)
                                          : 0,
                                      )
                                  : 0 *
                                      parseFloat(
                                        article.source_price && article.source_price !== ''
                                          ? formatNumberToEn(article.source_price)
                                          : 0,
                                      ),
                              )}{' '}
                              {article.source_currency === 'Euro' ? '€' : '$'}
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div className="cell reference-col">
                              {this.props.currentEntity.loadingReferenceProcessor}
                            </div>

                            <div className="cell">
                              {article.processor_weight
                                ? `${getGermanNumber(
                                    formatNumberToEn(article.processor_weight),
                                    'weight',
                                  )} ${article.processor_unit}`
                                : 'N/V'}
                            </div>
                            <div className="cell">
                              {' '}
                              {getGermanNumber(formatNumberToEn(article.processor_price))}{' '}
                              {article.processor_currency === 'Euro' ? '€' : '$'}
                            </div>
                            <div className="cell">
                              {' '}
                              {getGermanNumber(
                                article.processor_weight
                                  ? formatNumberToEn(article.processor_weight) *
                                      parseFloat(
                                        article.processor_price && article.processor_price !== ''
                                          ? formatNumberToEn(article.processor_price)
                                          : 0,
                                      )
                                  : 0 *
                                      parseFloat(
                                        article.processor_price && article.processor_price !== ''
                                          ? formatNumberToEn(article.processor_price)
                                          : 0,
                                      ),
                              )}{' '}
                              {article.processor_currency === 'Euro' ? '€' : '$'}
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    ),
                )}
              </div>

              <div className={'total page-avoid border-none'} style={{ marginTop: '14px' }}>
                <div className="row border-none">
                  <div className="cell border-none text-right invoice-first-col"></div>
                  <div className="cell border-none text-right"></div>
                  <div className="cell border-none text-right reference-col"></div>
                  <div className="cell border-none text-right"></div>
                  <div className="cell border-none text-right">Total</div>
                  {this.checkCashFlow() ? (
                    <div className="cell border-none text-right">
                      -{this.getTotalAmount()}{' '}
                      {this.props.currentEntity.articles.length > 0
                        ? this.props.currentEntity.articles[0].source_currency === 'Euro'
                          ? '€'
                          : '$'
                        : ''}
                    </div>
                  ) : (
                    <div className="cell border-none text-right">
                      -{this.getTotalAmount()}{' '}
                      {this.props.currentEntity.articles.length > 0
                        ? this.props.currentEntity.articles[0].processor_currency === 'Euro'
                          ? '€'
                          : '$'
                        : ''}
                    </div>
                  )}
                </div>

                <div className="row border-none">
                  <div className="cell border-none invoice-first-col"></div>
                  <div className="cell border-none"></div>
                  <div className="cell border-none reference-col"></div>
                  <div className="cell border-none"></div>
                  <div className="cell border-none text-right">
                    MwSt {this.getTaxPercent(tax)}%{' '}
                  </div>
                  {this.checkCashFlow() ? (
                    <div className="cell border-none text-right">
                      -{this.getTaxAmount(this.getTotalAmount(false), tax)}{' '}
                      {this.props.currentEntity.articles.length > 0
                        ? this.props.currentEntity.articles[0].source_currency === 'Euro'
                          ? '€'
                          : '$'
                        : ''}
                    </div>
                  ) : (
                    <div className="cell border-none text-right">
                      -{this.getTaxAmount(this.getTotalAmount(false), tax)}{' '}
                      {this.props.currentEntity.articles.length > 0
                        ? this.props.currentEntity.articles[0].processor_currency === 'Euro'
                          ? '€'
                          : '$'
                        : ''}
                    </div>
                  )}
                </div>

                <div className="row gesamtbetrag border-none">
                  <div className="cell border-none invoice-first-col"></div>
                  <div className="cell border-none"></div>
                  <div className="cell border-none reference-col"></div>
                  <div className="cell border-none"></div>
                  <div className="cell border-none text-right bold">Gesamtbetrag</div>
                  {this.checkCashFlow() ? (
                    <div className="cell border-none text-right bold">
                      -{this.getTotalAmountWithTax()}{' '}
                      {this.props.currentEntity.articles.length > 0
                        ? this.props.currentEntity.articles[0].source_currency === 'Euro'
                          ? '€'
                          : '$'
                        : ''}
                    </div>
                  ) : (
                    <div className="cell border-none text-right bold">
                      -{this.getTotalAmountWithTax()}{' '}
                      {this.props.currentEntity.articles.length > 0
                        ? this.props.currentEntity.articles[0].processor_currency === 'Euro'
                          ? '€'
                          : '$'
                        : ''}
                    </div>
                  )}
                </div>
              </div>

              <p className={'font-12'}>{invoiceTax ? invoiceTax.tax_text_processor : ''}</p>

              <div className="team-address page-avoid">
                <p className={'mt-15'}>Vielen Dank für den Auftrag!</p>
                <p>Mit freundlichem Gruß</p>
                <p className="mt-15">Ihr {this.props.companyData.name2} Team</p>
              </div>
            </div>
            <Footer {...this.props} />
          </div>
        </div>
      );
    } else {
      return (
        <div className={'documents-container'}>
          <div className="main invoice">
            {this.checkCashFlow() ? (
              <Header
                locationData={
                  this.props.currentEntity.deviatingInvoiceAddressSource[0]
                    ? this.props.currentEntity.deviatingInvoiceAddressSource[0]
                    : this.props.sourceData
                }
                partnerLanguage={language}
                {...this.props}
              />
            ) : (
              <Header
                locationData={
                  this.props.currentEntity.deviatingInvoiceAddressProcessor[0]
                    ? this.props.currentEntity.deviatingInvoiceAddressProcessor[0]
                    : this.props.processorData
                }
                partnerLanguage={language}
                {...this.props}
              />
            )}
            <div className="invoice-body">
              <h3>
                Cancellation Invoice{' '}
                <b className={'hidden-on-pdf'}>
                  {this.props.newInvoiceNumber
                    ? `${
                        this.props.companyData.invoiceNumberPrefix
                          ? `${this.props.companyData.invoiceNumberPrefix}-`
                          : ''
                      }${this.props.newInvoiceNumber}${
                        this.props.companyData.invoiceNumberSuffix
                          ? `-${this.props.companyData.invoiceNumberSuffix}`
                          : ''
                      } `
                    : ''}
                </b>
                <span className={'show-only-on-pdf'}>
                  <b>%invoice_number%</b>
                </span>
              </h3>
              <p className="disc">Dear Sir or Madam,</p>
              <p className="disc-2">
                We hereby cancel the invoice{' '}
                <b>
                  {this.props.currentEntity[invoiceNumberField]
                    ? `${
                        this.props.companyData.invoiceNumberPrefix
                          ? `${this.props.companyData.invoiceNumberPrefix}-`
                          : ''
                      }${this.props.currentEntity[invoiceNumberField]}${
                        this.props.companyData.invoiceNumberSuffix
                          ? `-${this.props.companyData.invoiceNumberSuffix}`
                          : ''
                      } `
                    : ''}
                </b>
                as follows:
              </p>

              <div className="tbl">
                <div className="row">
                  <div className="cell bold invoice-first-col">Material</div>
                  <div className="cell bold">
                    {this.props.t(
                      this.getUnloadingCity()
                        ? this.getUnloadingCity().headingEnglish
                        : 'Lieferstelle',
                    )}
                  </div>
                  <div className="cell bold reference-col">
                    {this.props.t(
                      this.getUnloadingCity()
                        ? this.getUnloadingCity().referenceEnglish
                        : 'Entladereferenz',
                    )}
                  </div>

                  <div className="cell bold">Quantity</div>
                  <div className="cell bold">Price per unit</div>
                  <div className="cell bold">Total price</div>
                </div>
                {this.props.currentEntity.articles.map(
                  (article, index) =>
                    this.displayArticle(article) && (
                      <div className="row">
                        <div className="cell invoice-first-col">
                          {' '}
                          {article.processor_deviating && article.processor_deviating !== ''
                            ? `${article.processor_deviating}`
                            : article.articleDetails.descriptionInEnglish}
                        </div>
                        <div className="cell">
                          {this.getUnloadingCity() ? this.getUnloadingCity().partner.city : ''}
                        </div>

                        {this.checkCashFlow() ? (
                          <React.Fragment>
                            <div className="cell reference-col">
                              {this.props.currentEntity.loadingReferenceSource}
                            </div>

                            <div className="cell">
                              {article.source_weight
                                ? `${getGermanNumber(
                                    formatNumberToEn(article.source_weight),
                                    'amount',
                                  )} ${article.source_unit}`
                                : 'N/V'}
                            </div>
                            <div className="cell">
                              {' '}
                              {getGermanNumber(formatNumberToEn(article.source_price))}{' '}
                              {article.source_currency === 'Euro' ? '€' : '$'}
                            </div>
                            <div className="cell">
                              {' '}
                              {getGermanNumber(
                                article.source_weight
                                  ? formatNumberToEn(article.source_weight) *
                                      parseFloat(
                                        article.source_price && article.source_price !== ''
                                          ? formatNumberToEn(article.source_price)
                                          : 0,
                                      )
                                  : 0 *
                                      parseFloat(
                                        article.source_price && article.source_price !== ''
                                          ? formatNumberToEn(article.source_price)
                                          : 0,
                                      ),
                              )}{' '}
                              {article.source_currency === 'Euro' ? '€' : '$'}
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div className="cell reference-col">
                              {this.props.currentEntity.loadingReferenceProcessor}
                            </div>

                            <div className="cell ">
                              {article.processor_weight
                                ? `${getGermanNumber(
                                    formatNumberToEn(article.processor_weight),
                                    'weight',
                                  )} ${article.processor_unit}`
                                : 'N/V'}
                            </div>
                            <div className="cell">
                              {' '}
                              {getGermanNumber(formatNumberToEn(article.processor_price))}{' '}
                              {article.processor_currency === 'Euro' ? '€' : '$'}
                            </div>
                            <div className="cell">
                              {' '}
                              {getGermanNumber(
                                article.processor_weight
                                  ? formatNumberToEn(article.processor_weight) *
                                      parseFloat(
                                        article.processor_price && article.processor_price !== ''
                                          ? formatNumberToEn(article.processor_price)
                                          : 0,
                                      )
                                  : 0 *
                                      parseFloat(
                                        article.processor_price && article.processor_price !== ''
                                          ? formatNumberToEn(article.processor_price)
                                          : 0,
                                      ),
                              )}{' '}
                              {article.processor_currency === 'Euro' ? '€' : '$'}
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    ),
                )}
              </div>

              <div className={'total page-avoid border-none'} style={{ marginTop: '14px' }}>
                <div className="row border-none">
                  <div className="cell border-none text-right invoice-first-col"></div>
                  <div className="cell border-none text-right"></div>
                  <div className="cell border-none text-right reference-col"></div>
                  <div className="cell border-none text-right"></div>
                  <div className="cell border-none text-right">Total</div>
                  {this.checkCashFlow() ? (
                    <div className="cell border-none text-right">
                      -{this.getTotalAmount()}{' '}
                      {this.props.currentEntity.articles.length > 0
                        ? this.props.currentEntity.articles[0].source_currency === 'Euro'
                          ? '€'
                          : '$'
                        : ''}
                    </div>
                  ) : (
                    <div className="cell border-none text-right">
                      -{this.getTotalAmount()}{' '}
                      {this.props.currentEntity.articles.length > 0
                        ? this.props.currentEntity.articles[0].processor_currency === 'Euro'
                          ? '€'
                          : '$'
                        : ''}
                    </div>
                  )}
                </div>

                <div className="row border-none">
                  <div className="cell border-none invoice-first-col"></div>
                  <div className="cell border-none"></div>
                  <div className="cell border-none reference-col"></div>
                  <div className="cell border-none"></div>
                  <div className="cell border-none text-right">VAT {this.getTaxPercent(tax)}% </div>
                  {this.checkCashFlow() ? (
                    <div className="cell border-none text-right">
                      -{this.getTaxAmount(this.getTotalAmount(false), tax)}{' '}
                      {this.props.currentEntity.articles.length > 0
                        ? this.props.currentEntity.articles[0].source_currency === 'Euro'
                          ? '€'
                          : '$'
                        : ''}
                    </div>
                  ) : (
                    <div className="cell border-none text-right">
                      -{this.getTaxAmount(this.getTotalAmount(false), tax)}{' '}
                      {this.props.currentEntity.articles.length > 0
                        ? this.props.currentEntity.articles[0].processor_currency === 'Euro'
                          ? '€'
                          : '$'
                        : ''}
                    </div>
                  )}
                </div>

                <div className="row gesamtbetrag border-none">
                  <div className="cell border-none invoice-first-col"></div>
                  <div className="cell border-none"></div>
                  <div className="cell border-none reference-col"></div>
                  <div className="cell border-none"></div>
                  <div className="cell border-none text-right bold">Total</div>
                  {this.checkCashFlow() ? (
                    <div className="cell border-none text-right bold">
                      -{this.getTotalAmountWithTax()}{' '}
                      {this.props.currentEntity.articles.length > 0
                        ? this.props.currentEntity.articles[0].source_currency === 'Euro'
                          ? '€'
                          : '$'
                        : ''}
                    </div>
                  ) : (
                    <div className="cell border-none text-right bold">
                      -{this.getTotalAmountWithTax()}{' '}
                      {this.props.currentEntity.articles.length > 0
                        ? this.props.currentEntity.articles[0].processor_currency === 'Euro'
                          ? '€'
                          : '$'
                        : ''}
                    </div>
                  )}
                </div>
              </div>

              <p className={'font-12'}>{invoiceTax ? invoiceTax.tax_text_processor : ''}</p>

              <div className="team-address page-avoid">
                <p className={'mt-15'}>Thank you for your order.</p>
                <p>With kind regards</p>
                <p className="mt-15">Your {this.props.companyData.name2} team</p>
              </div>
            </div>
            <Footer {...this.props} />
          </div>
        </div>
      );
    }
  }
}

export default withTranslation('common')(InvoiceCancellation);
