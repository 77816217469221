import React from 'react';
import ChipInput from 'material-ui-chip-input';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import OpeningHours from '../persons/OpeningHours';
import Locations from '../submenus/locations';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useSelector, useDispatch } from 'react-redux';
import * as Actions from '../../redux/store/actions/partners';

function Partners(props) {
  const dispatch = useDispatch();
  const { state, updateState, handleAdd, self } = props;
  const { locations } = useSelector(({ partners }) => partners);

  function handleAddClick() {
    const payload = { ...state };
    payload['locations'] = locations;
    updateState(payload);

    setTimeout(() => {
      handleAdd();
    }, 200);
  }

  function addNewRow() {
    dispatch(Actions.addLocation());
  }

  function handlePodChange(chips) {
    self.setState({ pod: chips });
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <form noValidate autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper style={{ padding: 12, height: '100%', width: '100%' }}>
              <Typography variant="h4" style={{ paddingBottom: 30 }}>
                {self.props.t(self.state.header0)}
              </Typography>
              {self.state.tile0}
              {self.state.partnerType && self.state.partnerType === 'Debitor/Kreditor' && (
                <ChipInput
                  classes={{
                    root: 'h-56',
                    input: 'mg-top-20',
                    chipContainer: 'mg-top-20',
                  }}
                  style={{
                    minWidth: 300,
                    margin: '2px 10px',
                  }}
                  defaultValue={[]}
                  onChange={(chips) => handlePodChange(chips)}
                  placeholder={'POD'}
                />
              )}
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper style={{ padding: 12, height: '100%', width: '100%' }}>
              <Typography variant="h4" style={{ paddingBottom: 30 }}>
                {self.props.t('Opening Hours')}
              </Typography>
              <OpeningHours />
            </Paper>
          </Grid>
          {self.state['partnerType'] && self.state['partnerType'] === 'Debitor/Kreditor' && (
            <Grid item xs={12}>
              <Paper style={{ padding: 12, height: '100%', width: '100%' }}>
                <Typography variant="h4" style={{ paddingBottom: 30 }}>
                  {self.props.t(self.state.header1)}
                </Typography>
                {self.state.tile1}
              </Paper>
            </Grid>
          )}

          <Grid item xs={12}>
            <Paper style={{ padding: 12, height: '100%', width: '100%' }}>
              <Typography variant="h4" style={{ display: 'inline-block' }}>
                {self.props.t('additional_locations')}
              </Typography>
              <AddCircleOutlineIcon
                style={{
                  fontSize: 28,
                  position: 'relative',
                  top: -2,
                  marginLeft: 15,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  addNewRow();
                }}
                color="primary"
              />
              <Locations variant={'add'} {...self.props} />
            </Paper>
          </Grid>
        </Grid>
      </form>
      <Button
        variant="contained"
        color="primary"
        startIcon={<CloudUploadIcon />}
        style={{ marginTop: 20, backgroundColor: 'white', color: 'green' }}
        onClick={handleAddClick}
      >
        {`${self.props.t('ADD')} ${self.props.t(self.props.currentTab)}`}
      </Button>
    </div>
  );
}

export default Partners;
