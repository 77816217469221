import _ from 'lodash';
import { APP_NAMESPACE } from '../../util/redux-constants';
import { put, post, get, del } from '../../util/http-utils';
import { updateStore, buildGenericInitialState, handleError } from '../../util/store-utils';
import { CHANGE_AUTH, GET_AUTHENTICATED_USER } from './authentication';
import { type } from 'os';
import { CLIENT_TABLE_COLUMNS, PERSON_TABLE_COLUMNS } from '../../constants/ui-constants';
import { showMessage } from '../store/actions/alerts';

const PERSON_ENDPOINT_BASE = 'persons';
const typeBase = `${APP_NAMESPACE}/${PERSON_ENDPOINT_BASE}/`;

// Constants
export const GET_PERSONS = `${typeBase}GET_PERSONS`;
export const GET_PERSON = `${typeBase}GET_PERSON`;
export const ADD_PERSON = `${typeBase}ADD_PERSON`;
export const EDIT_PERSON = `${typeBase}EDIT_PERSON`;
export const DELETE_PERSON = `${typeBase}DELETE_PERSON`;
export const GET_FIELD_AGENTS = `${typeBase}GET_FIELD_AGENTS`;
export const GET_ACCOUNT_MANAGERS = `${typeBase}GET_ACCOUNT_MANAGERS`;
export const GET_TRUCK_NOTIFICATIONS = `${typeBase}GET_TRUCK_NOTIFICATIONS`;
export const GET_CONTACT_FOR_FIELD_STAFF = `${typeBase}GET_CONTACT_FOR_FIELD_STAFF`;
export const GET_CONTACT_FOR_FINANCE = `${typeBase}GET_CONTACT_FOR_FINANCE`;
export const GET_TEAMS = `${typeBase}GET_TEAMS`;
export const GET_USERS = `${typeBase}GET_USERS`;
export const GET_CONTRACTS_USERS = `${typeBase}GET_CONTRACTS_USERS`;
// Actions

/**
 * getUser  - Fetches user from API, given id
 *
 * @param {String} id User's id for lookup
 * @returns {Promise}
 */
export const getPerson = (id) => async (dispatch) => {
  try {
    const response = await get(dispatch, GET_PERSON, `${PERSON_ENDPOINT_BASE}/${id}`, true);
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err);
  }
};

/**
 * getUsers  - Fetches users from API
 *
 * @returns {Promise}
 */
export const getPersons = () => async (dispatch) => {
  try {
    const response = await get(dispatch, GET_PERSONS, PERSON_ENDPOINT_BASE, true);
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err, GET_PERSON);
  }
};

export const getTeams = () => async (dispatch) => {
  try {
    const response = await post(dispatch, GET_TEAMS, `${PERSON_ENDPOINT_BASE}/teams`, {}, true);
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err, GET_TEAMS);
  }
};

/**
 * getUsers  - Fetches users from API
 *
 * @returns {Promise}
 */
export const getFieldAgents = () => async (dispatch) => {
  try {
    const response = await get(
      dispatch,
      GET_FIELD_AGENTS,
      `${PERSON_ENDPOINT_BASE}/fieldAgents`,
      true,
    );
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err, GET_PERSON);
  }
};

export const getAccountManagers = () => async (dispatch) => {
  try {
    const response = await get(
      dispatch,
      GET_ACCOUNT_MANAGERS,
      `${PERSON_ENDPOINT_BASE}/accountManagers`,
      true,
    );
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err, GET_PERSON);
  }
};

export const getUsers = (data) => async (dispatch) => {
  try {
    const response = await post(
      dispatch,
      GET_USERS,
      `${PERSON_ENDPOINT_BASE}/doc/email/users`,
      data,
    );
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err, GET_USERS);
  }
};

export const getContractsUsers = (data) => async (dispatch) => {
  try {
    const response = await post(
      dispatch,
      GET_CONTRACTS_USERS,
      `${PERSON_ENDPOINT_BASE}/doc/contracts/email/users`,
      data,
    );
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err, GET_USERS);
  }
};

export const getContractPersons = (data) => async (dispatch) => {
  try {
    const response = await post(
      dispatch,
      GET_CONTRACTS_USERS,
      `${PERSON_ENDPOINT_BASE}/contracts/persons`,
      data,
    );
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err, GET_USERS);
  }
};

export const getTruckNotifications = (data) => async (dispatch) => {
  try {
    const response = await post(
      dispatch,
      GET_TRUCK_NOTIFICATIONS,
      `${PERSON_ENDPOINT_BASE}/truckNotifications`,
      data,
    );
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err, GET_PERSON);
  }
};

export const getContactForFieldStaff = (data) => async (dispatch) => {
  try {
    const response = await post(
      dispatch,
      GET_CONTACT_FOR_FIELD_STAFF,
      `${PERSON_ENDPOINT_BASE}/contactforfieldstaff`,
      data,
    );
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err, GET_PERSON);
  }
};

export const getContactForFinance = (data) => async (dispatch) => {
  try {
    const response = await post(
      dispatch,
      GET_CONTACT_FOR_FINANCE,
      `${PERSON_ENDPOINT_BASE}/contactforfinance`,
      data,
    );
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err, GET_PERSON);
  }
};

/**
 * register - Creates a new PERSON for a user
 * @param {Object} formData  User's form data
 */
export const addPerson = (formData, callback, currentTabName, currentTabNumber) => async (
  dispatch,
) => {
  console.log('HER BOO');
  try {
    let payload = { ...formData };
    delete payload.documents;
    delete payload.tile0;
    console.log(payload);

    const response = await post(
      dispatch,
      ADD_PERSON,
      `${PERSON_ENDPOINT_BASE}/addPerson`,
      payload,
      false,
    );
    //console.log(response);

    // If the registration was successful, set the JWT as a cookie
    if (response.error === undefined) {
      callback(currentTabName, currentTabNumber, PERSON_TABLE_COLUMNS, 'Added Successfully!');
    } else {
      dispatch(showMessage({ message: response.error, messageType: 'error' }));
    }
  } catch (err) {
    await handleError(dispatch, err, CHANGE_AUTH);
  }
};

export const addPersonModal = (formData, callback) => async (dispatch) => {
  try {
    const response = await post(
      dispatch,
      ADD_PERSON,
      `${PERSON_ENDPOINT_BASE}/addPerson`,
      formData,
      false,
    );

    if (response.error === undefined) {
      callback({ status: 'success' });
    } else {
      callback({ status: 'error', errors: response.error });
    }
  } catch (err) {
    await handleError(dispatch, err, CHANGE_AUTH);
  }
};

/**
 * Edit PERSON - updates a PERSON
 * @param {Object} formData  User's form data
 */
export const editPerson = (id, formData, callback) => async (dispatch) => {
  try {
    console.log('EDITING PERSON!!');
    let payload = { ...formData };
    delete payload.documents;
    delete payload.tile0;

    const response = await put(
      dispatch,
      EDIT_PERSON,
      `${PERSON_ENDPOINT_BASE}/${id}`,
      payload,
      false,
    );

    if (response.PersonUpdated) {
      if (callback) {
        callback({ status: 'success' });
      } else {
        dispatch(showMessage({ message: 'Updated Person Successfully', messageType: 'success' }));
      }
    } else {
      if (callback) {
        callback({ status: 'error', errors: response.error });
      } else {
        dispatch(showMessage({ message: response.error, messageType: 'error' }));
      }
    }
  } catch (err) {
    await handleError(dispatch, err, CHANGE_AUTH);
  }
};

/**
 * Delete PERSON - updates a PERSON
 * @param {Object} formData  User's form data
 */
export const deletePerson = (id, callback) => async (dispatch) => {
  try {
    console.log('DELETING PERSON!!');

    const response = await del(dispatch, DELETE_PERSON, `${PERSON_ENDPOINT_BASE}/${id}`, true);
    console.log(response);

    // If the registration was successful, set the JWT as a cookie
    if (response) {
      callback('Deleted PERSON Successfully');
    }
  } catch (err) {
    await handleError(dispatch, err, CHANGE_AUTH);
  }
};

// Store
const INITIAL_STATE = {
  ...buildGenericInitialState([GET_PERSON, GET_PERSONS]),
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_AUTH:
      return updateStore(
        state,
        action,
        _.get(action, 'payload.user.id') ? { [action.payload.user.id]: action.payload.user } : {},
      );
    case GET_PERSON:
    case GET_AUTHENTICATED_USER:
      return updateStore(
        state,
        action,
        _.get(action, 'payload.user.id') ? { [action.payload.user.id]: action.payload.user } : {},
      );
    case GET_PERSONS:
      return updateStore(
        state,
        action,
        _.get(action, 'payload.users') ? _.mapKeys(action.payload.users, 'id') : {},
      );
    default:
      return state;
  }
};

// Selectors
export const getAuthenticatedUser = ({ user, authentication }) => user[authentication.user];
