import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import TextInput from '../form-fields/text-input';
import GenericForm from '../form-fields/generic-form';
import { login, CHANGE_AUTH } from '../../redux/modules/authentication';
import { errorPropTypes } from '../../util/proptype-utils';
import './authentication.scss';
import { withTranslation } from 'react-i18next';

const form = reduxForm({
  form: 'login',
});

class Login extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    desiredPath: PropTypes.string,
    login: PropTypes.func,
    errors: errorPropTypes,
    message: PropTypes.string,
    loading: PropTypes.bool,
  };

  static formSpec = [
    {
      id: 'email',
      name: 'email',
      label: 'Email',
      type: 'email',
      placeholder: 'you@yourdomain.com',
      component: TextInput,
    },
    {
      id: 'password',
      name: 'password',
      label: 'Password',
      type: 'password',
      placeholder: '********',
      component: TextInput,
    },
  ];

  componentDidMount() {
    let token = localStorage.getItem('token');
    if (token) {
      this.props.history.push('/dashboard');
      // window.location.href = '/dashboard'
    }
  }

  handleFormSubmit = (formProps) => {
    const { desiredPath } = this.props;
    if (desiredPath) {
      this.props.login(formProps, desiredPath);
    } else {
      this.props.login(formProps);
    }
  };

  render = () => {
    const { handleSubmit, errors, message, loading, t } = this.props;

    return (
      <div className={`login-container auth-box ${loading ? 'is-loading' : ''}`}>
        <img src={'/assets/img/logo.png'} className={'logo'} />
        <div className={'fields-container'}>
          <h3 className={'text-center mg-bottom-0'}>{t('Log in')}</h3>
          <GenericForm
            onSubmit={handleSubmit(this.handleFormSubmit)}
            errors={errors}
            message={message}
            formSpec={Login.formSpec}
            submitText={t('Log in')}
          />

          <p className={'note'}>
            {t('You need help?')} <a href={"mailto:support@onecycle.io"}>{t('Contact the support')}</a>
          </p>
        </div>
      </div>
    );
  };
}

const mapStateToProps = ({ authentication }) => ({
  errors: authentication.errors[CHANGE_AUTH],
  message: authentication.messages[CHANGE_AUTH],
  loading: authentication.loading[CHANGE_AUTH],
  authenticated: authentication.authenticated,
  desiredPath: authentication.desiredPath,
});

export default connect(mapStateToProps, { login })(form(withTranslation('common')(Login)));
