import _ from "lodash";
import { APP_NAMESPACE } from "../../util/redux-constants";
import { put, post, get, del } from "../../util/http-utils";
import { updateStore, buildGenericInitialState, handleError } from "../../util/store-utils";
import { CHANGE_AUTH, GET_AUTHENTICATED_USER } from "./authentication";
import { ADD_PERSON } from "./person";
import postPerson from "./person";
import { CLIENT_TABLE_COLUMNS } from "../../constants/ui-constants";
import { showMessage } from '../store/actions/alerts';

const PARTNER_ENDPOINT_BASE = "partners";
const typeBase = `${APP_NAMESPACE}/${PARTNER_ENDPOINT_BASE}/`;

// Constants
export const GET_PARTNERS = `${typeBase}GET_PARTNERS`;
export const GET_PARTNER = `${typeBase}GET_PARTNER`;
export const ADD_PARTNER = `${typeBase}ADD_PARTNER`;
export const EDIT_PARTNER = `${typeBase}EDIT_PARTNER`;
export const DELETE_PARTNER = `${typeBase}DELETE_PARTNER`;

// Actions

/**
 * getUser  - Fetches user from API, given id
 *
 * @param {String} id User's id for lookup
 * @returns {Promise}
 */
export const getPartner = (id) => async (dispatch) => {
  try {
    const response = await get(dispatch, GET_PARTNER, `${PARTNER_ENDPOINT_BASE}/${id}`, true);
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err);
  }
};

/**
 * getUsers  - Fetches users from API
 *
 * @returns {Promise}
 */
export const getPartners = () => async (dispatch) => {
  try {
    const response = await get(dispatch, GET_PARTNERS, PARTNER_ENDPOINT_BASE, true);
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err, GET_PARTNER);
  }
};

export const getDeviatingPartners = () => async (dispatch) => {
  try {
    const response = await post(
      dispatch,
      GET_PARTNERS,
      PARTNER_ENDPOINT_BASE + "/deviating",
      {},
      true
    );
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err, GET_PARTNER);
  }
};

/**
 * register - Creates a new PARTNER for a user
 * @param {Object} formData  User's form data
 */
export const addPartner = (formData, callback, currentTabName, currentTabNumber) => async (
  dispatch,
  getState
) => {
  try {
    formData.openingHours = getState().partners.openingHours;
    const response = await post(
      dispatch,
      ADD_PARTNER,
      `${PARTNER_ENDPOINT_BASE}/add`,
      formData,
      false
    );

    callback(
      currentTabName,
      currentTabNumber,
      CLIENT_TABLE_COLUMNS,
      "Added Successfully!",
      response.error
    );
  } catch (err) {
    await handleError(dispatch, err, CHANGE_AUTH);
  }
};

/**
 * Edit PARTNER - updates a PARTNER
 * @param {Object} formData  User's form data
 */
export const editPartner = (id, formData) => async (dispatch, getState) => {
  try {
    console.log("EDITING PARTNER!!");
    formData.openingHours = getState().partners.openingHours;

    if (
      formData.invoiceDeliveryMethod === 'Email' &&
      (!formData.invoiceEmail || formData.invoiceEmail.length === 0)
    ) {
      dispatch(showMessage({
        message: 'invoice_delivery_email_is_required',
        messageType: 'error',
      }));
      return;
    }

    const response = await put(
      dispatch,
      EDIT_PARTNER,
      `${PARTNER_ENDPOINT_BASE}/${id}`,
      formData,
      false
    );
    //console.log(response);

    // If the registration was successful, set the JWT as a cookie
    if (response.PartnerUpdated) {
      dispatch(showMessage({ message: "Edited PARTNER Successfully", messageType: "success" }))
    } else {
      dispatch(showMessage({ message: response.error, messageType: "error" }))
    }
  } catch (err) {
    await handleError(dispatch, err, CHANGE_AUTH);
  }
};

/**
 * Delete PARTNER - updates a PARTNER
 * @param {Object} formData  User's form data
 */
export const deletePartner = (id, callback) => async (dispatch) => {
  try {
    console.log("DELETING PARTNER!!");

    const response = await del(dispatch, DELETE_PARTNER, `${PARTNER_ENDPOINT_BASE}/${id}`, true);
    console.log(response);

    // If the registration was successful, set the JWT as a cookie
    if (response) {
      callback("Deleted PARTNER Successfully");
    }
  } catch (err) {
    await handleError(dispatch, err, CHANGE_AUTH);
  }
};

// Store
const INITIAL_STATE = {
  ...buildGenericInitialState([GET_PARTNER, GET_PARTNERS]),
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_AUTH:
      return updateStore(
        state,
        action,
        _.get(action, "payload.user.id") ? { [action.payload.user.id]: action.payload.user } : {}
      );
    case GET_PARTNER:
    case GET_AUTHENTICATED_USER:
      return updateStore(
        state,
        action,
        _.get(action, "payload.user.id") ? { [action.payload.user.id]: action.payload.user } : {}
      );
    case GET_PARTNERS:
      return updateStore(
        state,
        action,
        _.get(action, "payload.users") ? _.mapKeys(action.payload.users, "id") : {}
      );
    default:
      return state;
  }
};

// Selectors
export const getAuthenticatedUser = ({ user, authentication }) => user[authentication.user];
