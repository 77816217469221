import React from 'react';
import MaterialTable from 'material-table';
import moment from 'moment';
import { createMuiTheme } from '@material-ui/core/styles';
import { getClients, deleteClient } from '../../redux/modules/client';
import { getCarriers, deleteCarrier } from '../../redux/modules/carrier';
import { getArticles, deleteArticle } from '../../redux/modules/article';
import { getContracts, deleteContract } from '../../redux/modules/contracts';
import { getHolidays, deleteHoliday } from '../../redux/modules/holiday';
import { getPersons, getTeams, deletePerson } from '../../redux/modules/person';
import { getOrders, deleteOrder, copyOrder } from '../../redux/modules/order';
import { getBanks, deleteBank } from '../../redux/modules/bank';
import { getGroups, deleteGroup } from '../../redux/modules/group';
import { getCompanys, deleteCompany } from '../../redux/modules/company';
import { getPartners, deletePartner } from '../../redux/modules/partner';

import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import {
  TOP_NAVIGATION_ROUTES,
  BOTTOM_NAVIGATION_ROUTES,
  SYSTEM_NAVIGATION_ROUTES,
  PERMISSIONS_EDIT,
  PERMISSIONS_CREATE,
  PERMISSIONS_DELETE,
} from '../../constants/ui-constants';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { ThemeProvider } from '@material-ui/styles';
import { resetPersons } from '../../redux/store/actions/persons';
import { deleteWarehouse, getWarehouses } from '../../redux/modules/warehouse';
import withConfirm from '../dialogs/delete';
import { showMessage } from '../../redux/store/actions/alerts';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import IconButton from '@material-ui/core/IconButton';

const form = reduxForm({
  form: 'add',
});

const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      h6: {
        fontWeight: 'bold !important',
        color: '#1D283E',
      },
    },
  },
});

class EditTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: this.props.columns,
      data: [],
      tableTitle: this.props.tableTitle,
      selectedOrders: [],
      copies: 1,
      loading: true,
    };
  }

  async componentDidMount() {
    this.props.resetPersons();
    this.getTableData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.tableTitle !== prevProps.tableTitle) {
      this.setState({ tableTitle: this.props.tableTitle });
      this.getTableData();
    }
  }

  hasPermission(name) {
    let alwaysAllowedMenus = ['BANKS', 'TAX MATRIX', 'ARTICLES', 'TEAMS', 'WAREHOUSE', 'COMPANIES'];
    if (alwaysAllowedMenus.includes(this.props.tableTitle)) {
      return true;
    }

    return this.props.self.state.user.permissions[name];
  }

  getTableData = async (forceReload = false) => {
    this.props.resetPersons();
    this.setState({ loading: true });

    if (this.columnReference == this.props.columns && !forceReload) {
      this.setState({ loading: false });
      return;
    } else if (
      this.props.tableTitle === TOP_NAVIGATION_ROUTES[0] &&
      this.hasPermission('partners_read')
    ) {
      let response = await this.props.getPartners();
      this.columnReference = this.props.columns;
      this.setState({ data: response.allPartners, loading: false });
    } else if (
      this.props.tableTitle === TOP_NAVIGATION_ROUTES[1] &&
      this.hasPermission('carriers_read')
    ) {
      let response = await this.props.getCarriers();
      this.columnReference = this.props.columns;
      this.setState({ data: response.allCarriers, loading: false });
    } else if (this.props.tableTitle === TOP_NAVIGATION_ROUTES[6] && this.hasPermission('contracts_read')) {
      let response = await this.props.getContracts();
      this.columnReference = this.props.columns;
      this.setState({
        data: response.allContracts,
        loading: false,
      });
    } else if (this.props.tableTitle === TOP_NAVIGATION_ROUTES[2]) {
      let response = await this.props.getPersons();
      this.columnReference = this.props.columns;
      this.setState({ data: response.allPersons, loading: false });
    } else if (
      this.props.tableTitle === TOP_NAVIGATION_ROUTES[3] &&
      this.hasPermission('orders_read')
    ) {
      let response = await this.props.getOrders();
      this.columnReference = this.props.columns;
      this.setState({ data: response.allOrders, loading: false });
    } else if (this.props.tableTitle === SYSTEM_NAVIGATION_ROUTES[0]) {
      let response = await this.props.getBanks();
      this.columnReference = this.props.columns;
      this.setState({ data: response.allBanks, loading: false });
    } else if (this.props.tableTitle === SYSTEM_NAVIGATION_ROUTES[1]) {
      let response = await this.props.getGroups();
      this.columnReference = this.props.columns;
      this.setState({ data: response.allGroups, loading: false });
    } else if (this.props.tableTitle === SYSTEM_NAVIGATION_ROUTES[2]) {
      let response = await this.props.getArticles();
      this.columnReference = this.props.columns;
      this.setState({ data: response.allArticles, loading: false });
    } else if (this.props.tableTitle === SYSTEM_NAVIGATION_ROUTES[3]) {
      let response = await this.props.getTeams();
      this.columnReference = this.props.columns;
      this.setState({ data: response.allPersons, loading: false });
    } else if (this.props.tableTitle === SYSTEM_NAVIGATION_ROUTES[4]) {
      let response = await this.props.getWarehouses();
      this.columnReference = this.props.columns;
      this.setState({ data: response.allWarehouses, loading: false });
    } else if (this.props.tableTitle === SYSTEM_NAVIGATION_ROUTES[5]) {
      // let response = await this.props.getWarehouses();
      // this.columnReference = this.props.columns;
      // this.setState({ data: response.allWarehouses });
    } else if (this.props.tableTitle === BOTTOM_NAVIGATION_ROUTES[1]) {
      let response = await this.props.getCompanys();
      this.columnReference = this.props.columns;
      this.setState({ data: response.allCompanys, loading: false });
    } else {
    }
  };

  getColumns = (columns) => {
    let updatedColumns = [];
    for (let column of columns) {
      updatedColumns.push({
        title: this.props.t(column.title),
        field: column.field,
      });
    }

    return updatedColumns;
  };

  copyOrders = () => {
    const payload = {
      orders: this.state.selectedOrders,
      copies: this.state.copies,
    };

    this.props.copyOrder(payload, this.resetAndShowAlert);
  };

  handleNumberOfCopies = (event) => {
    this.setState({ copies: event.target.value });
  };

  resetAndShowAlert = (message, reset = false) => {
    this.getTableData(true);
    this.props.showMessage({ message: message, messageType: 'success' });

    if (reset) this.setState({ selectedOrders: [], copies: 1 });
  };

  handleEdit = (rowData) => {
    this.props.parentCallbackAction('EDIT', rowData);
  };

  getActions = () => {
    let actions = [];

    if (this.hasPermission(PERMISSIONS_CREATE[this.props.tableTitle])) {
      actions.push({
        icon: 'add',
        tooltip: 'Add',
        isFreeAction: true,
        onClick: (event, rowData) => this.props.parentCallbackAction('ADD'),
      });
    }

    if (this.hasPermission(PERMISSIONS_DELETE[this.props.tableTitle])) {
      actions.push({
        icon: 'delete',
        tooltip: 'Delete',
        position: 'row',
        onClick: (event, rowData) => {
          event.stopPropagation();
          this.props.confirm(
            (e) => {
              if (this.props.tableTitle === TOP_NAVIGATION_ROUTES[0]) {
                this.props.deletePartner(rowData._id, this.resetAndShowAlert);
              } else if (this.props.tableTitle === TOP_NAVIGATION_ROUTES[1]) {
                this.props.deleteCarrier(rowData._id, this.resetAndShowAlert);
              } else if (this.props.tableTitle === TOP_NAVIGATION_ROUTES[2]) {
                this.props.deletePerson(rowData._id, this.resetAndShowAlert);
              } else if (this.props.tableTitle === TOP_NAVIGATION_ROUTES[3]) {
                this.props.deleteOrder(rowData._id, this.resetAndShowAlert);
              } else if (this.props.tableTitle === TOP_NAVIGATION_ROUTES[6]) {
                this.props.deleteContract(rowData._id, this.resetAndShowAlert);
              } else if (this.props.tableTitle === SYSTEM_NAVIGATION_ROUTES[0]) {
                this.props.deleteBank(rowData._id, this.resetAndShowAlert);
              } else if (this.props.tableTitle === SYSTEM_NAVIGATION_ROUTES[1]) {
                this.props.deleteGroup(rowData._id, this.resetAndShowAlert);
              } else if (this.props.tableTitle === SYSTEM_NAVIGATION_ROUTES[2]) {
                this.props.deleteArticle(rowData._id, this.resetAndShowAlert);
              } else if (this.props.tableTitle === SYSTEM_NAVIGATION_ROUTES[3]) {
                this.props.deletePerson(rowData._id, this.resetAndShowAlert);
              } else if (this.props.tableTitle === SYSTEM_NAVIGATION_ROUTES[4]) {
                this.props.deleteWarehouse(rowData._id, this.resetAndShowAlert);
              } else if (this.props.tableTitle === BOTTOM_NAVIGATION_ROUTES[1]) {
                this.props.deleteCompany(rowData._id, this.resetAndShowAlert);
              }
            },
            {
              description: 'Are you sure?',
            },
          );
        },
      });
    }

    return actions;
  };

  getData = (data) => {
    let updatedData = [];
    for (let d of data) {
      if (d.role) {
        let roles = [];
        for (let role of d.role) {
          roles.push(this.props.t(role));
        }

        d.salutation = this.props.t(d.salutation);
        d.role = roles;
      }

      if (d.itemType) {
        d.itemType = this.props.t(d.itemType);
      }

      updatedData.push(d);
    }

    return updatedData;
  };

  getPageSize = () => {
    let finalRange = 100;
    const dataLength = this.state.data.length.toString();

    if (dataLength.length == 2) {
      const range = parseInt(dataLength[0]) * 10;
      if (parseInt(dataLength) > range) {
        finalRange = parseInt(dataLength[0]) + 1 * 10;
      } else {
        finalRange = range;
      }
    } else if (dataLength.length < 2) {
      finalRange = 10;
    }

    return finalRange;
  };

  render() {
    return (
      <React.Fragment>
        {this.state.selectedOrders.length > 0 &&
          this.props.tableTitle === TOP_NAVIGATION_ROUTES[3] && (
            <Paper
              style={{
                padding: '10px',
                display: 'flex',
                alignItems: 'flex-end',
              }}
            >
              <TextField
                type={'tel'}
                style={{ width: 220, marginRight: '16px' }}
                onChange={this.handleNumberOfCopies}
                name={'copies'}
                required={true}
                label={this.props.t('Number of copies')}
                value={this.state.copies}
              />
              <Button
                variant={'contained'}
                size={'large'}
                onClick={this.copyOrders}
                color="primary"
              >
                {this.props.t('copy')}
              </Button>
            </Paper>
          )}

        <div className={'custom-table-styles'}>
          <ThemeProvider theme={theme}>
            {!this.state.loading && (
              <MaterialTable
                localization={{
                  pagination: {
                    labelRowsSelect: `${this.props.t('rows')}`,
                    labelDisplayedRows: `{from}-{to} ${this.props.t('of')} {count}`,
                  },
                  toolbar: {
                    nRowsSelected: `{0} ${this.props.t('row(s)')} ${this.props.t('selected')}`,
                    searchTooltip: this.props.t('Search'),
                    searchPlaceholder: this.props.t('Search'),
                  },
                  header: {
                    actions: this.props.t('Actions'),
                  },
                  body: {
                    emptyDataSourceMessage: this.props.t('No records to display'),
                    filterRow: {
                      filterTooltip: this.props.t('Filter'),
                    },
                  },
                }}
                onRowClick={(event, rowData) => {
                  this.handleEdit(rowData);
                }}
                actions={this.getActions()}
                title={this.props.t(this.props.tableTitle)}
                columns={
                  this.props.tableTitle === SYSTEM_NAVIGATION_ROUTES[3]
                    ? [
                      { title: this.props.t('Salutation'), field: 'salutation' },
                      { title: this.props.t('First Name'), field: 'firstName' },
                      { title: this.props.t('Surname'), field: 'surname' },
                      {
                        title: this.props.t('Role'),
                        field: 'role',
                        render: (rowData) =>
                          rowData.role ? rowData.role.join(', ') : rowData.role,
                      },
                      { title: this.props.t('Department'), field: 'department' },
                      { title: this.props.t('Phone'), field: 'phone' },
                      { title: this.props.t('E-mail'), field: 'emails' },
                    ]
                    : this.props.tableTitle === TOP_NAVIGATION_ROUTES[3]
                      ? [
                        {
                          title: this.props.t('Nummer'),
                          field: 'orderNumber',
                          cellStyle: {
                            padding: '0px !important',
                          },
                        },
                        {
                          title: this.props.t('Status'),
                          field: 'orderNumber',
                          headerStyle: {
                            fontWeight: 'bold',
                            width: 200,
                            maxWidth: 200,
                          },
                          cellStyle: { width: 200, maxWidth: 200 },
                          render: (rowData) => {
                            return (
                              <span>
                                {rowData.status && rowData.status.yellow && (
                                  <span className={'yellow'}></span>
                                )}
                                {rowData.status && rowData.status.orange && (
                                  <span className={'orange'}></span>
                                )}
                                {rowData.status && rowData.status.green && (
                                  <span className={'green'}></span>
                                )}
                                {rowData.status && rowData.status.checkbox && (
                                  <span className={'blue'}></span>
                                )}
                              </span>
                            );
                          },
                        },
                        {
                          title: this.props.t('Date Purchase'),
                          field: 'source[0].name2',
                          render: (rowData) => {
                            return (
                              <span>
                                {rowData.docStatus.purchase_confirmation.date
                                  ? moment(rowData.docStatus.purchase_confirmation.date).format(
                                    'DD.MM.YYYY',
                                  )
                                  : this.props.t('N/A')}
                              </span>
                            );
                          },
                        },
                        {
                          title: this.props.t('Anfallstelle'),
                          field: 'source[0].name2',
                        },
                        {
                          title: this.props.t('Article'),
                          field:
                            this.props.i18n.language === 'de'
                              ? 'firstArticleNameGerman'
                              : 'firstArticleNameEnglish',
                        },
                        { title: this.props.t('Ladegewicht'), field: 'sourceWeight' },
                        {
                          title: this.props.t('Delivery Date'),
                          field: 'deliveryDate.startDate',
                          render: (rowData) => {
                            let date = '';
                            let processorInvoiceDate = rowData.firstArticleWeightSlipDate
                              ? rowData.firstArticleWeightSlipDate
                              : null;

                            if (
                              processorInvoiceDate &&
                              moment(processorInvoiceDate, 'DD.MM.YYYY').isValid()
                            ) {
                              date = processorInvoiceDate;
                            } else {
                              date =
                                rowData.deliveryDate && moment(rowData.deliveryDate).isValid()
                                  ? moment(rowData.deliveryDate.startDate).format('DD.MM.YYYY')
                                  : 'N/A';
                            }

                            return <span>{date}</span>;
                          },
                        },

                        {
                          title: this.props.t('Preis EK'),
                          field: 'sourcePrice',
                          render: (rowData) => (
                            <span>
                              {rowData.articleTotalSource
                                ? rowData.articleTotalSource
                                : rowData.firstArticleSourcePrice > 0
                                  ? rowData.firstArticleSourcePrice
                                  : ''}
                            </span>
                          ),
                        },
                        {
                          title: this.props.t('Preis VK'),
                          field: 'processorPrice',
                          render: (rowData) => (
                            <span>
                              {rowData.articleTotalProcessor
                                ? rowData.articleTotalProcessor
                                : rowData.firstArticleProcessorPrice > 0
                                  ? rowData.firstArticleProcessorPrice
                                  : ''}
                            </span>
                          ),
                        },
                        { title: this.props.t('Verwerter'), field: 'processor[0].name2' },
                        { title: this.props.t('Spediteur'), field: 'carrier[0].name2' },
                        { title: this.props.t('Transport'), field: 'shippingCost' },
                        {
                          title: this.props.t('Booking No'),
                          field: 'bookingNo',
                        },
                        {
                          title: this.props.t('invoice_no'),
                          field: 'invoiceNumbers',
                          render: (rowData) => (
                            <span>
                              {rowData.invoiceNumberSourceWithPrefixAndSuffix &&
                                rowData.invoiceNumberSourceWithPrefixAndSuffix.length > 0 && (
                                  <span>
                                    {this.props.t('P')}:{' '}
                                    {rowData.invoiceNumberSourceWithPrefixAndSuffix}
                                  </span>
                                )}
                              <br />
                              {rowData.invoiceNumberProcessorWithPrefixAndSuffix &&
                                rowData.invoiceNumberProcessorWithPrefixAndSuffix.length > 0 && (
                                  <span>
                                    {this.props.t('S')}:{' '}
                                    {rowData.invoiceNumberProcessorWithPrefixAndSuffix}
                                  </span>
                                )}
                            </span>
                          ),
                        },
                      ]
                      : this.getColumns(this.props.columns)
                }
                data={
                  this.props.tableTitle === SYSTEM_NAVIGATION_ROUTES[3] ||
                    this.props.tableTitle === SYSTEM_NAVIGATION_ROUTES[2]
                    ? this.getData(this.state.data)
                    : this.state.data
                }
                minRows={0}
                options={{
                  selection: this.props.tableTitle === TOP_NAVIGATION_ROUTES[3],
                  pageSize: this.getPageSize(),
                  pageSizeOptions: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
                  actionsColumnIndex: -1,
                }}
                onSelectionChange={(rows) => {
                  let selected = [...rows];
                  this.setState({ selectedOrders: selected });
                }}
              />
            )}
          </ThemeProvider>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ authentication }) => ({});

export default connect(mapStateToProps, {
  getClients,
  deleteClient,
  getCarriers,
  deleteCarrier,
  getArticles,
  deleteArticle,
  getHolidays,
  deleteHoliday,
  getPersons,
  deletePerson,
  deleteOrder,
  getOrders,
  getPartners,
  deletePartner,
  getBanks,
  deleteBank,
  getGroups,
  deleteGroup,
  getCompanys,
  deleteCompany,
  copyOrder,
  getTeams,
  resetPersons,
  getWarehouses,
  deleteWarehouse,
  showMessage,
  getContracts,
  deleteContract
})(form(withConfirm(withTranslation('common')(EditTable))));
