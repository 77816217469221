import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import Typography from "@material-ui/core/Typography";
import BillingConfirmation from "../pdfToHtml/billingconfirmation";
import { withTranslation } from "react-i18next";
import VisibilityIcon from "@material-ui/icons/Visibility";
import * as Actions from '../../redux/store/actions/alerts';
import { useDispatch } from 'react-redux';
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    if (props.articles.length > 0) {
      setOpen(true);
    } else {
      dispatch(
        Actions.showMessage({
          message: 'view_docs_add_articles_error',
          messageType: 'error',
        }),
      );
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {props.fullButton ? (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          color={"primary"}
          style={{ marginTop: 10, marginLeft: "12px" }}
          onClick={handleClickOpen}
        >
          {props.t("View Invoice")}
        </Button>
      ) : (
      
        <Button
        startIcon={<VisibilityIcon />}
        //   disabled={!props.permission}
          color={'primary'}
          size={"large"}
          onClick={handleClickOpen}
        >
        </Button>
      )}


      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={"lg"}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {props.t("Preview Billing Confirmation Document")}
        </DialogTitle>
        <DialogContent dividers>
          <BillingConfirmation {...props} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default withTranslation("common")(CustomizedDialogs);
