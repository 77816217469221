import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { withTranslation } from "react-i18next";
import { EMAIL_REGEX } from '../../constants/ui-constants';


function TextInput(props) {
  let [previous, setPrevious] = useState("");
  let [isError, setError] = useState(props.isError || false);

  // Validating field value
  const onchange = (evt, name) => {
    if (evt.target.type === "tel" && !/^[0-9+-\s]*$/.test(evt.target.value)) {
      evt.target.value = previous;
      return;
    }

    if (evt.target.type === "email" && !EMAIL_REGEX.test(evt.target.value)) {
      setError(true);
    } else {
      setError(false);
    }

    setPrevious(evt.target.value);
    props.change(evt, name);
  }

  return (
    <TextField
      error={isError}
      key={props.label}
      type={props.type}
      style={props.style}
      onChange={onchange}
      name={props.dbid}
      required={props.required}
      label={props.t(props.label)}
      dbid={props.dbid}
      defaultValue={props.defaultValue}
      value={props.value}
      pattern={EMAIL_REGEX}
    />
  );
}

export default withTranslation("common")(TextInput);
