import React from 'react';
import { getApiUrl } from '../../util/environment-utils';
import { arrayUnion, isEuCountry } from '../../helpers/common';
import { getLanguageAcronym } from '../../helpers/documents';

class AnnexDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      article: {
        basalAnnexIX: null,
        euWasteList: null,
      },
      createdAt: new Date(),
      wasteManagerProcessor: null,
      wasteManagerSource: null,
      wasteManagerCarrier: null,
      apiUrl: getApiUrl(),
      annex10i: ' ',
      wastecode: ' ',
      descriptionWaste: ' ',
      baselCodeEu: ' '
    };
  }

  componentWillMount() {
    this.getInfo();
  }

  getInfo = async () => {
    const articles = this.props.currentEntity.articles;
    const article = this.props.currentEntity.articles[0].articleDetails;

    let partner = this.props.processorData;
    let carrierInfo = this.props.carrierData;

    const createdAt = new Date(this.props.currentEntity.createdAt);

    const wasteManagerProcessor = partner.contactPersons.find((item) => {
      return item.type === 'Extern' && item.role.includes('Waste Officer');
    });

    const wasteManagerSource = this.props.sourceData.contactPersons.find((item) => {
      return item.role.includes('Waste Officer');
    });

    let wasteManagerCarrier = {};
    if (carrierInfo) {
      wasteManagerCarrier = carrierInfo.contactPersons.find((item) => {
        return item.role.includes('Waste Officer');
      });
    }

    let isSourceEu = isEuCountry(this.props.sourceData.country);
    let isProcessorEu = isEuCountry(this.props.processorData.country);

    let annex10iArray = [];
    let wasteCodeArray = [];
    let descriptionOfWaste = [];
    let baselCodeEu = [];
    for (let item of articles) {
      if (item.wastecode && item.wastecode !== '') wasteCodeArray.push(item.wastecode);

      if (isSourceEu == 'eu' && isProcessorEu == 'eu') {
        annex10iArray.push(item.articleDetails.basalCodeEu);
      } else {
        annex10iArray.push(item.articleDetails.basalAnnexIX);
      }

      if (item.articleDetails.descriptionOfWaste) {
        descriptionOfWaste.push(item.articleDetails.descriptionOfWaste);
      }

      if (item.articleDetails.basalCodeEu) {
        baselCodeEu.push(item.articleDetails.basalCodeEu)
      }
    }

    wasteCodeArray = arrayUnion(wasteCodeArray);
    annex10iArray = arrayUnion(annex10iArray);
    descriptionOfWaste = arrayUnion(descriptionOfWaste);
    baselCodeEu = arrayUnion(baselCodeEu);

    let annex10i = annex10iArray.join(', ');
    let wastecode = wasteCodeArray.join(', ');
    let descriptionWaste = descriptionOfWaste.join(', ');

    this.setState({
      article,
      createdAt,
      wasteManagerProcessor,
      wasteManagerSource,
      wasteManagerCarrier,
      annex10i,
      wastecode,
      descriptionWaste,
      baselCodeEu
    });
  };

  getWasteManager(data, type) {
    for (let manager of data.contactPersons) {
      if (manager.role.includes('Waste Officer')) {
        return type === 'name1'
          ? `${manager.name1}`
          : type === 'email'
          ? manager.emails[0]
          : manager[type];
      }
    }

    return '';
  }

  getLanguage = () => {
    let defaultLanguage = 'de';
    let language = this.props.sourceData
      ? this.props.sourceData
        ? this.props.sourceData.documentLanguage
        : defaultLanguage
      : defaultLanguage;

    return getLanguageAcronym(language)
  };

  render() {
    const addressArraySource = this.props.sourceData.address.split(',');
    const addressArrayProcessor = this.props.processorData.address.split(',');
    const addressFor11Source = addressArraySource[addressArraySource.length - 1];
    const addressFor11Processor = addressArrayProcessor[addressArrayProcessor.length - 1];
    const language = this.getLanguage();

    if (language === 'de') {
      return (
        <div className={'doc-annex'}>
          <section className="table_sec">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 w-100">
                  <div className="main_table">
                    <h1>
                      MITZUFÜHRENDE INFORMATIONEN FÜR DIE VERBRINGUNG <br /> DER IN ARTIKEL 3,
                      ABSÄTZE 2 UND 4 GENANNTEN ABFÄLLE
                    </h1>
                    <h2>
                      VERSANDINFORMATIONEN <sup>(1)</sup>
                    </h2>
                    <div className="first_border">
                      <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50">
                          <div className="form_1">
                            <h3>1. Person, die die Verbringung veranlasst:</h3>
                            <p>
                              <span>Name:</span> <small> {this.props.companyName}</small>
                            </p>
                            <p>
                              <span>Anschrift:</span>{' '}
                              <small> {this.props.companyData.address}</small>
                            </p>
                            <p>
                              <span>Kontaktperson:</span>{' '}
                              <small style={{ width: '76%' }}>
                                {' '}
                                {this.props.companyOfficer &&
                                Object.keys(this.props.companyOfficer).length > 0
                                  ? `${this.props.companyOfficer.firstName} ${this.props.companyOfficer.surname}`
                                  : 'N/V'}{' '}
                              </small>
                            </p>
                            <div className="row">
                              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50">
                                <p>
                                  <span>Tel.: </span>{' '}
                                  <small>
                                    {this.props.companyOfficer &&
                                    Object.keys(this.props.companyOfficer).length > 0
                                      ? this.props.companyOfficer.phone
                                      : 'N/V'}
                                  </small>
                                </p>
                              </div>
                              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50">
                                <p>
                                  <span>Fax:</span>{' '}
                                  <small>
                                    {' '}
                                    {this.props.companyOfficer &&
                                    Object.keys(this.props.companyOfficer).length > 0
                                      ? this.props.companyOfficer.fax
                                      : 'N/V'}
                                  </small>
                                </p>
                              </div>
                            </div>
                            <p>
                              <span>E-Mail:</span>{' '}
                              <small style={{ width: '89%' }}>
                                {' '}
                                {this.props.companyOfficer &&
                                Object.keys(this.props.companyOfficer).length > 0
                                  ? `${
                                      this.props.companyOfficer.emails[0]
                                        ? this.props.companyOfficer.emails[0]
                                        : 'N/V'
                                    } `
                                  : 'N/V'}{' '}
                              </small>
                            </p>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50 pd-14">
                          <div className="form_1 brr">
                            <h3>2. Importeur / Empfänger:</h3>
                            <p>
                              <span>Name:</span> <small> {this.props.processorData.name1}</small>
                            </p>
                            <p>
                              <span>Anschrift:</span>{' '}
                              <small>{this.props.processorData.address}</small>
                            </p>
                            <p>
                              <span>Kontaktperson:</span>{' '}
                              <small style={{ width: '76%' }}>
                                {' '}
                                {this.state.wasteManagerProcessor
                                  ? `${this.state.wasteManagerProcessor.firstName} ${this.state.wasteManagerProcessor.surname}`
                                  : 'N/V'}{' '}
                              </small>
                            </p>
                            <div className="row">
                              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50 ">
                                <p>
                                  <span>Tel.: </span>{' '}
                                  <small>
                                    {this.state.wasteManagerProcessor
                                      ? this.state.wasteManagerProcessor.phone
                                        ? this.state.wasteManagerProcessor.phone
                                        : 'N/V'
                                      : 'N/V'}
                                  </small>
                                </p>
                              </div>
                              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50">
                                <p>
                                  <span>Fax:</span>{' '}
                                  <small>
                                    {' '}
                                    {this.state.wasteManagerProcessor
                                      ? this.state.wasteManagerProcessor.fax
                                        ? this.state.wasteManagerProcessor.fax
                                        : 'N/V'
                                      : 'N/V'}
                                  </small>
                                </p>
                              </div>
                            </div>
                            <p>
                              <span>E-Mail:</span>{' '}
                              <small style={{ width: '89%' }}>
                                {' '}
                                {this.state.wasteManagerProcessor
                                  ? this.state.wasteManagerProcessor.emails.length > 0
                                    ? this.state.wasteManagerProcessor.emails.join(',')
                                    : 'N/V'
                                  : 'N/V'}
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="first_border second ">
                      <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50">
                          <div className="form_1">
                            <div className="row flex-column">
                              <div className="row">
                                <div className="col-xs-12 col-sm-6 col-md-8 col-lg-6 w-49">
                                  <h3>3. Tatsächliche Menge:</h3>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-8 col-lg-6 w-50">
                                  <p>
                                    <span>Tonnen (Mg):</span>
                                    <small className="big_b v c c big_b88988998"></small>
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="w-50"></div>
                                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-6 col-lg-offset-6 w-50 ml-50">
                                  <p>
                                    <span>m³:</span>{' '}
                                    <small
                                      className="big_b v c c big_b88988998fff"
                                      style={{ height: '15px' }}
                                    ></small>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50 pd0">
                          <div className="form_1 brr seo">
                            <div className="row">
                              <div className="col-xs-12 col-sm-8 col-md-12 pd0 ">
                                <h3 className="fon">
                                  4. Tatsächliches Datum der Verbringung:
                                  <small className="big_b v c c c123"></small>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="first_border box_5">
                      <div className="row">
                        <div className="col-xs-12 col-sm-4 col-md-4 w-33 ">
                          <div className="form_1 form_12 ">
                            <h3>
                              5.(a) 1<sup>st</sup> Transportunternehmen <i>(2)</i>
                            </h3>
                            <p>
                              <span>Name:</span>{' '}
                              <small style={{ width: '83%' }}>
                                {' '}
                                {this.props.carrierData ? this.props.carrierData.name1 : 'N/V'}
                              </small>
                            </p>
                            <p className="f1232133">
                              <span>Anschrift:</span>{' '}
                              <small style={{ width: '76%', fontSize: '80%' }}>
                                {' '}
                                {this.props.carrierData ? this.props.carrierData.address : 'N/V'}
                              </small>
                            </p>
                            <p className="f1232134">
                              <span>Kontaktperson:</span>{' '}
                              <small style={{ width: '63%' }}>
                                {' '}
                                {this.state.wasteManagerCarrier &&
                                Object.keys(this.state.wasteManagerCarrier).length > 0
                                  ? `${this.state.wasteManagerCarrier.firstName} ${this.state.wasteManagerCarrier.surname}`
                                  : 'N/V'}{' '}
                              </small>
                            </p>
                            <p>
                              <span>Tel :</span>{' '}
                              <small style={{ width: '85%' }}>
                                {' '}
                                {this.state.wasteManagerCarrier
                                  ? this.state.wasteManagerCarrier.phone
                                    ? this.state.wasteManagerCarrier.phone
                                    : 'N/V'
                                  : 'N/V'}
                              </small>
                            </p>
                            <p>
                              <span>Fax : </span>
                              <small style={{ width: '82%' }}>
                                {' '}
                                {this.state.wasteManagerCarrier
                                  ? this.state.wasteManagerCarrier.fax
                                    ? this.state.wasteManagerCarrier.fax
                                    : 'N/V'
                                  : 'N/V'}
                              </small>
                            </p>
                            <p>
                              <span>E-Mail:</span>{' '}
                              <small style={{ width: '82%' }}>
                                {this.state.wasteManagerCarrier &&
                                this.state.wasteManagerCarrier.emails
                                  ? this.state.wasteManagerCarrier.emails.length > 0
                                    ? this.state.wasteManagerCarrier.emails.join(',')
                                    : 'N/V'
                                  : 'N/V'}
                              </small>
                            </p>
                            <p className="f12321">
                              <span>Transportart:</span>{' '}
                              <small style={{ width: '65%' }}>
                                {this.props.currentEntity.vehicle}
                              </small>
                            </p>
                            <p className="f123212">
                              <span>Übergabedatum:</span> <small style={{ width: '85%' }}></small>
                            </p>
                            <p className="f123213">
                              <span>Unterschrift:</span> <small style={{ width: '85%' }}></small>
                            </p>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-4 w-33 pd-14">
                          <div className="form_1 form_2 form_2321">
                            <h3>
                              5.(b) 2<sup>nd</sup> Transportunternehmen
                            </h3>

                            <p>
                              <span>Name:</span> <small style={{ width: '85%' }}></small>
                            </p>
                            <p className="f1232133">
                              <span>Anschrift:</span>{' '}
                              <small style={{ width: '78%', fontSize: '80%' }}></small>
                            </p>
                            <p className="f1232134">
                              <span>Kontaktperson:</span> <small style={{ width: '65%' }}></small>
                            </p>
                            <p>
                              <span>Tel :</span> <small style={{ width: '85%' }}></small>
                            </p>
                            <p>
                              <span>Fax :</span>{' '}
                            </p>
                            <p>
                              <span>E-Mail:</span> <small style={{ width: '84%' }}></small>
                            </p>
                            <p className="f12321">
                              <span>Transportart:</span> <small style={{ width: '65%' }}></small>
                            </p>
                            <p className="f123212">
                              <span>Übergabedatum:</span> <small style={{ width: '65%' }}></small>
                            </p>
                            <p className="f123213">
                              <span>Unterschrift:</span> <small style={{ width: '65%' }}></small>
                            </p>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-4 w-33 pd-14">
                          <div
                            className="form_1 brr form_2"
                            style={{ paddingTop: '10px', paddingBottom: '0px' }}
                          >
                            <h3>
                              5.(c): 3<sup>rd</sup> Transportunternehmen
                            </h3>
                            <p>
                              <span>Name:</span> <small style={{ width: '85%' }}></small>
                            </p>
                            <p className="f1232133">
                              <span>Anschrift:</span>{' '}
                              <small style={{ width: '78%', fontSize: '80%' }}></small>
                            </p>
                            <p className="f1232134">
                              <span>Kontaktperson:</span> <small style={{ width: '65%' }}></small>
                            </p>
                            <p>
                              <span>Tel :</span> <small style={{ width: '85%' }}></small>
                            </p>
                            <p>
                              <span>Fax :</span>{' '}
                            </p>
                            <p>
                              <span>E-Mail:</span> <small style={{ width: '84%' }}></small>
                            </p>
                            <p className="f12321">
                              <span>Transportart:</span> <small style={{ width: '65%' }}></small>
                            </p>
                            <p className="f123212">
                              <span>Übergabedatum:</span> <small style={{ width: '85%' }}></small>
                            </p>
                            <p className="f123213">
                              <span>Unterschrift:</span> <small style={{ width: '85%' }}></small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="first_border third">
                      <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50">
                          <div className="form_1">
                            <h3>
                              {' '}
                              6. Abfallerzeuger <i>(3)</i>
                            </h3>
                            <h3> Ersterzeuger, Neuerzeuger oder Einsammler:</h3>
                            <p>
                              <span>Name:</span>{' '}
                              <small className="big_b s "> {this.props.sourceData.name1}</small>
                            </p>
                            <p>
                              <span>Anschrift:</span>{' '}
                              <small> {this.props.sourceData.address}</small>
                            </p>
                            <p>
                              <span>Kontaktperson:</span>{' '}
                              <small style={{ width: '76%' }}>
                                {' '}
                                {this.state.wasteManagerSource
                                  ? `${this.state.wasteManagerSource.firstName} ${this.state.wasteManagerSource.surname}`
                                  : 'N/V'}{' '}
                              </small>
                            </p>
                            <div className="row">
                              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50">
                                <p>
                                  <span>Tel.: </span>{' '}
                                  <small>
                                    {' '}
                                    {this.state.wasteManagerSource
                                      ? this.state.wasteManagerSource.phone
                                        ? this.state.wasteManagerSource.phone
                                        : 'N/V'
                                      : 'N/V'}
                                  </small>
                                </p>
                              </div>
                              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50">
                                <p>
                                  <span>Fax:</span>{' '}
                                  <small>
                                    {' '}
                                    {this.state.wasteManagerSource
                                      ? this.state.wasteManagerSource.fax
                                        ? this.state.wasteManagerSource.fax
                                        : 'N/V'
                                      : 'N/V'}
                                  </small>
                                </p>
                              </div>
                            </div>
                            <p>
                              <span>E-Mail:</span>{' '}
                              <small>
                                <a href="javascript:void(0)">
                                  {' '}
                                  {this.state.wasteManagerSource
                                    ? this.state.wasteManagerSource.emails.length > 0
                                      ? this.state.wasteManagerSource.emails.join(',')
                                      : 'N/V'
                                    : 'N/V'}{' '}
                                </a>
                              </small>
                            </p>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50 linee pd-14">
                          <div className="form_1 brr three">
                            <h3 style={{ margin: 0, lineHeight: '21px' }}>
                              {' '}
                              8. Verwertungsverfahren (oder gegebenenfalls Beseitigungsverfahren bei
                              in Artikel 3 Absatz 4 genannten Abfällen):
                            </h3>
                            <p>
                              <span>R-Code / D-Code </span>{' '}
                              <small className="big_b x" style={{ width: '71%' }}>
                                {' '}
                                {this.props.processorData.certifiedRecoveryOptions}
                              </small>
                            </p>
                          </div>
                          <div className="form_1 brr three">
                            <h3> 9. Übliche Bezeichnung der Abfälle:</h3>
                            <p>
                              <span> </span>
                              <small className="big_b z" style={{ width: '100%' }}>
                                {' '}
                                {/*Plastic Scrap (Polyolefine)*/}
                                {this.state.descriptionWaste}
                              </small>
                              <small
                                className="big_b z"
                                style={{ width: '100%', color: '#FFFFFF' }}
                              >
                                ...........................
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="first_border ex">
                      <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50">
                          <div className="form_1">
                            <h3>
                              7. Verwertungsanlage{' '}
                              <label className="containerd ">
                                <input type="checkbox" checked="checked" />
                                <span className="checkmark" />
                              </label>
                              <span>
                                Labor{' '}
                                <label className="containerd ">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>{' '}
                              </span>
                            </h3>
                            <p>
                              <span>Name:</span>{' '}
                              <small>
                                {' '}
                                {`${
                                  this.props.currentEntity.processorLocation
                                    ? this.props.currentEntity.processorLocation.name
                                    : this.props.processorData.name1
                                }`}
                              </small>
                            </p>
                            <p>
                              <span>Anschrift:</span>{' '}
                              <small>
                                {' '}
                                {this.props.currentEntity.processorLocation
                                  ? this.props.currentEntity.processorLocation.address
                                  : this.props.processorData.address}
                              </small>
                            </p>
                            <p>
                              <span>Kontaktperson:</span>{' '}
                              <small style={{ width: '76%' }}>
                                {' '}
                                {this.state.wasteManagerProcessor
                                  ? `${this.state.wasteManagerProcessor.firstName} ${this.state.wasteManagerProcessor.surname}`
                                  : 'N/V'}{' '}
                              </small>
                            </p>
                            <div className="row">
                              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50">
                                <p>
                                  <span>Tel.: </span>{' '}
                                  <small>
                                    {' '}
                                    {this.state.wasteManagerProcessor
                                      ? this.state.wasteManagerProcessor.phone
                                        ? this.state.wasteManagerProcessor.phone
                                        : 'N/V'
                                      : 'N/V'}
                                  </small>
                                </p>
                              </div>
                              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50">
                                <p>
                                  <span>Fax:</span>{' '}
                                  <small>
                                    {' '}
                                    {this.state.wasteManagerProcessor
                                      ? this.state.wasteManagerProcessor.fax
                                        ? this.state.wasteManagerProcessor.fax
                                        : 'N/V'
                                      : 'N/V'}
                                  </small>
                                </p>
                              </div>
                            </div>
                            <p>
                              <span>E-Mail:</span>{' '}
                              <small>
                                {' '}
                                {this.state.wasteManagerProcessor
                                  ? this.state.wasteManagerProcessor.emails.length > 0
                                    ? this.state.wasteManagerProcessor.emails.join(',')
                                    : 'N/V'
                                  : 'N/V'}
                              </small>
                            </p>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50 pd-14">
                          <div className="form_1 brr">
                            <h3>
                              10. Abfallidentifizierung{' '}
                              <span style={{ fontWeight: 100, fontSize: '14px' }}>
                                <i>(einschlägige Codes angeben):</i>
                              </span>
                            </h3>
                            <p>
                              <span>i) Basel Anlage IX :</span>
                              <small className="big_b v v big_b321 w-70">
                                {this.state.annex10i}{' '}
                              </small>{' '}
                            </p>
                            <p>
                              <span>
                                ii) OECD (falls abweichend von <i>(i)</i>)):
                              </span>
                              <small className="big_b v v big_b999 w-43" style={{ width: '50%' }}>
                                {' '}
                              </small>
                            </p>
                            <p>
                              <span>
                                iii) Anhang IIIA (<sup>4</sup>):
                              </span>
                              <small className="big_b v v big_b321 w-73"> </small>{' '}
                            </p>
                            <p>
                              <span>
                                iv) Anhang IIIB (<sup>5</sup>):
                              </span>
                              <small className="big_b v v big_b321 w-73"> </small>{' '}
                            </p>
                            <p>
                              <span>v) EU-Abfallverzeichnis:</span>{' '}
                              <small
                                className="big_b v v big_b321 big_b9999 w-63"
                                style={{ width: '66%' }}
                              >
                                {' '}
                                {this.state.wastecode}
                              </small>
                            </p>
                            <p>
                              <span>vi) Nationaler Code: </span>{' '}
                              <small className="big_b v v big_b321 w-73"> </small>
                            </p>
                              <p>
                                <span>vii) sonstiges (bitte angeben):</span>{' '}
                              <small
                                className="big_b v v big_b321 big_b9999 "
                                style={{ width: '48%' }}
                              >
                                {' '}
                                {this.state.baselCodeEu}
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="first_border five">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 w-100">
                          <div className="form_1">
                            <h3>11. Betroffene Staaten:</h3>
                            <div className="table-responsive " style={{ overflowX: 'unset' }}>
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>Ausfuhrstaat / Versandstaat </th>
                                    <th>Durchfuhrstaat(en) </th>
                                    <th style={{ borderRight: 0 }}>
                                      Einfuhrstaat / Empfängerstaat
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <td style={{ borderBottom: 0 }}>
                                    <p>
                                      <span />{' '}
                                      <small
                                        className="big_b v big_b32132165489"
                                        style={{ width: '100%' }}
                                      >
                                        {addressFor11Source}
                                      </small>
                                    </p>
                                  </td>

                                  <td className="ppp" style={{ borderBottom: 0 }}>
                                    <div className={'row fake-table'}>
                                      <div className={'border-right w-33'}>
                                        {this.props.countries && this.props.countries.join(' ')}
                                      </div>
                                      <div className={'border-right w-33'}>
                                        <span className={'letter-spacing'}>
                                          .................................
                                        </span>
                                      </div>
                                      <div className={'border-right w-33'}>
                                        <span className={'letter-spacing'}>
                                          .................................
                                        </span>
                                      </div>
                                    </div>

                                    <table
                                      style={{
                                        width: '100%',
                                        marginLeft: '-0.2645833333mm',
                                        marginTop: '-0.2645833333mm',
                                      }}
                                      className="table custom real-table"
                                    >
                                      <tr>
                                        <td>
                                          <p>
                                            <span />{' '}
                                            <small
                                              className="big_b g"
                                              style={{ borderBottom: 'none' }}
                                            >
                                              {this.props.countries &&
                                                this.props.countries.join(' ')}
                                            </small>
                                          </p>
                                        </td>
                                        <td>
                                          <p>
                                            <span />{' '}
                                            <small
                                              className="big_b g"
                                              style={{ borderBottom: 'none' }}
                                            ></small>
                                          </p>
                                        </td>
                                        <td style={{ borderRight: '0' }}>
                                          <p>
                                            <span />{' '}
                                            <small
                                              className="big_b g"
                                              style={{ borderBottom: 'none' }}
                                            ></small>
                                          </p>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                  <td style={{ borderBottom: 0 }}>
                                    <p>
                                      <span />{' '}
                                      <small
                                        className="big_b v big_b32132165489"
                                        style={{ width: '100%' }}
                                      >
                                        {' '}
                                        {addressFor11Processor}
                                      </small>
                                    </p>
                                  </td>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="first_border six">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 w-100">
                          <div className="form_1">
                            <h3>
                              12. Erklärung der die Verbringung veranlassenden Person:{' '}
                              <span>
                                {' '}
                                Ich erkläre hiermit, dass die obigen Informationen nach meinem
                                besten Wissen vollständig sind und der Wahrheit entsprechen. Ich
                                erkläre ferner, dass mit dem Empfänger wirksame vertragliche
                                Verpflichtungen schriftlich eingegangen wurden{' '}
                                <i>
                                  (ist bei den in Artikel 3 Absatz 4 genannten Abfällen nicht
                                  erforderlich)
                                </i>
                                :
                              </span>
                            </h3>
                            <div className="row">
                              <div className="col-xs-12 col-sm-4 col-md-4 w-33 col-lg-4">
                                <p>
                                  <span>Name: </span>{' '}
                                  <small className="big_b v b">
                                    {' '}
                                    {this.props.companyOfficer &&
                                    Object.keys(this.props.companyOfficer).length > 0
                                      ? `${this.props.companyOfficer.firstName} ${this.props.companyOfficer.surname}`
                                      : 'N/V'}{' '}
                                  </small>
                                </p>
                              </div>
                              <div className="col-xs-12 col-sm-4 col-md-4 w-33 col-lg-4">
                                <p>
                                  <span>Datum:</span>{' '}
                                  <small className="big_b v b" style={{ width: '83%' }}>
                                    {' '}
                                    {this.state.createdAt.getDate() +
                                      '.' +
                                      (this.state.createdAt.getMonth() + 1) +
                                      '.' +
                                      this.state.createdAt.getFullYear()}
                                  </small>
                                </p>
                              </div>
                              <div className="col-xs-12 col-sm-4 col-md-4 w-33 col-lg-4 flex">
                                <p>
                                  <span>Unterschrift: </span>{' '}
                                </p>

                                <span className={'letter-spacing'}>
                                  <img
                                    src={`${this.state.apiUrl}/companys/image/${this.props.companyData.signatures}`}
                                    className={'sign'}
                                  />{' '}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="first_border six">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 w-100">
                          <div className="form_1">
                            <h3>13. Unterschrift des Empfängers bei Entgegennahme der Abfälle:</h3>
                            <div className="row">
                              <div className="col-xs-12 col-sm-4 col-md-4 w-33 col-lg-4">
                                <p>
                                  <span>Name: </span>
                                  <span className={'letter-spacing'}>
                                      ................................
                                    </span>
                                  {/* <small className="big_b v b"></small> */}
                                </p>
                              </div>
                              <div className="col-xs-12 col-sm-4 col-md-4 w-33 col-lg-4">
                                <p>
                                  <span>Datum:</span>{' '}
                                  <span className={'letter-spacing'}>
                                      ................................
                                    </span>
                                  {/* <small className="big_b v b" style={{ width: '83%' }}> */}
                                    {/* {' '}
                                    {this.state.createdAt.getDate() +
                                      '.' +
                                      (this.state.createdAt.getMonth() + 1) +
                                      '.' +
                                      this.state.createdAt.getFullYear()} */}
                                  {/* </small> */}
                                </p>
                              </div>
                              <div className="col-xs-12 col-sm-4 col-md-4 w-33 col-lg-4">
                                <p>
                                  <span>
                                    Unterschrift:{' '}
                                    <span className={'letter-spacing'}>
                                      .........................
                                    </span>
                                  </span>{' '}
                                  {/*<small className="big_b v b" style={{ width: '75%' }}></small>*/}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="first_border seven">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 w-100">
                          <div className="form_1 text-center" style={{ textAlign: 'center' }}>
                            <h2>VON DER VERWERTUNGSANLAGE ODER VOM LABOR AUSZUFÜLLEN:</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="first_border sixx">
                      <div className="row flex-column">
                        <div className={'row'}>
                          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-45">
                            <div className="form_1 brr">
                              <h4 className={'row'}>
                                14. Eingang <span> bei der Verwertungsanlage: </span>&nbsp;
                                <span className={'empty-box'}></span>
                                {/*<label className="" style={{ all: 'unset' }}>*/}
                                {/*  <input type="checkbox" />*/}
                                {/*  /!*<span className="checkmark" />*!/*/}
                                {/*</label>*/}
                              </h4>
                              <h4>
                                {' '}
                                <span className={'m-left-45'}> oder beim Labor:</span>&nbsp;
                                <span className={'empty-box ml-2cm'}></span>
                              </h4>
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-55">
                            <div className={'margin-top-14'}>
                              <div className={'tonne'}>
                                In Empfang genommene Menge: Tonnen (Mg) :{' '}
                                <span className={'letter-spacing'}>.........................</span>
                              </div>

                              <div className={'m3'}>
                                m³: :{' '}
                                <span className={'letter-spacing'}>.........................</span>
                              </div>
                            </div>

                            {/*<div className="form_1 brr">*/}
                            {/*  <span>In Empfang genommene Menge:</span>*/}
                            {/*  <p className={'line-order'}>*/}
                            {/*    <span>Tonnen (Mg) :</span>{' '}*/}
                            {/*    <small className="big_b big_bvf231" style={{ border: 0 }}>*/}
                            {/*      {' '}*/}
                            {/*    </small>*/}
                            {/*  </p>*/}
                            {/*  /!*<p>*!/*/}
                            {/*  /!*  <span>In Empfang genommene Menge: Tonnen (Mg) :</span>{' '}*!/*/}
                            {/*  /!*  <small className="big_b u" style={{ width: '38%', border: 'none' }}>*!/*/}
                            {/*  /!*    {' '}*!/*/}
                            {/*  /!*  </small>*!/*/}
                            {/*  /!*</p>*!/*/}
                            {/*  <p className={'line-order'}>*/}
                            {/*    <span>m³:</span>{' '}*/}
                            {/*    <small className="big_b big_bvf231" style={{ border: 0 }}>*/}
                            {/*      {' '}*/}
                            {/*    </small>*/}
                            {/*  </p>*/}
                            {/*</div>*/}
                          </div>
                        </div>

                        <div className="clearfix"></div>
                        <div className="row">
                          <div className="col-xs-12 col-sm-4 col-md-4 w-33 col-lg-4">
                            <p>
                              <span>Name: </span> <small className="big_b v b"> </small>
                            </p>
                          </div>
                          <div className="col-xs-12 col-sm-4 col-md-4 w-33 col-lg-4">
                            <p>
                              <span>Datum:</span>{' '}
                              <small className="big_b v b" style={{ width: '83%' }}>
                                {' '}
                              </small>
                            </p>
                          </div>
                          <div className="col-xs-12 col-sm-4 col-md-4 w-33 col-lg-4">
                            <p>
                              <span>Unterschrift:</span>{' '}
                              <small className="big_b v b" style={{ width: '75%' }}></small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" last_text">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 w-100">
                          <div className="">
                            <ul>
                              <li>
                                (1) Mitzuführende Informationen bei der Verbringung der in der
                                grünen Liste aufgeführten Abfälle, die zur Verwertung bestimmt sind,
                                oder von Abfällen, die für eine Laboranalyse bestimmt sind, gemäß
                                der Verordnung (EG) Nr. 1013/2006. Beim Ausfüllen dieses Formulars
                                sind auch die spezifischen Anweisungen im Anhang IC der Verordnung
                                (EG) Nr. 1013/2006 zu berücksichtigen.
                              </li>
                              <li>
                                (2) Bei mehr als 3 Transportunternehmen sind die unter Nummer 5 a),
                                b), c) verlangten Informationen beizufügen.
                              </li>
                              <li>
                                (3) Wenn es sich bei der Person, die die Verbringung veranlasst,
                                nicht um den Erzeuger oder Einsammler handelt, sind auch
                                Informationen zum Erzeuger oder Einsammleranzugeben.
                              </li>
                              <li>
                                (4) Der/die entsprechende(n) Code(s) gemäß Anhang IIIA der
                                Verordnung (EG) Nr. 1013/2006 ist/sind – gegebenenfalls
                                hintereinander – anzugeben. Bestimmte Einträge des Basler
                                Übereinkommens wie B1100, B3010 oder B3020 sind, wie im Anhang IIIA
                                angegeben, auf bestimmte Abfallströme beschränkt.
                              </li>
                              <li>
                                (5) Es sind die im Anhang IIIB der Verordnung (EG) Nr. 1013/2006
                                aufgeführten BEU-Codes zu verwenden.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      );
    } else {
      return (
        <div className={'doc-annex'}>
          <section className="table_sec">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 w-100">
                  <div className="main_table">
                    <h1>
                      INFORMATION TO BE CARRIED OUT FOR THE SHIPMENT OF THE WASTE REFERRED TO IN
                      ARTICLE 3, PARAGRAPHS 2 AND 4
                    </h1>
                    <h2>
                      SHIPPING INFORMATION <sup>(1)</sup>
                    </h2>
                    <div className="first_border">
                      <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50">
                          <div className="form_1">
                            <h3>1. Person who arranges the shipment:</h3>
                            <p>
                              <span>Name:</span> <small> {this.props.companyName}</small>
                            </p>
                            <p>
                              <span>Address:</span> <small> {this.props.companyData.address}</small>
                            </p>
                            <p>
                              <span>Contact person:</span>{' '}
                              <small style={{ width: '76%' }}>
                                {' '}
                                {this.props.companyOfficer &&
                                Object.keys(this.props.companyOfficer).length > 0
                                  ? `${this.props.companyOfficer.firstName} ${this.props.companyOfficer.surname}`
                                  : 'N/V'}{' '}
                              </small>
                            </p>
                            <div className="row">
                              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50">
                                <p>
                                  <span>Tel.: </span>{' '}
                                  <small>
                                    {this.props.companyOfficer &&
                                    Object.keys(this.props.companyOfficer).length > 0
                                      ? this.props.companyOfficer.phone
                                      : 'N/V'}
                                  </small>
                                </p>
                              </div>
                              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50">
                                <p>
                                  <span>Fax:</span>{' '}
                                  <small>
                                    {' '}
                                    {this.props.companyOfficer &&
                                    Object.keys(this.props.companyOfficer).length > 0
                                      ? this.props.companyOfficer.fax
                                      : 'N/V'}
                                  </small>
                                </p>
                              </div>
                            </div>
                            <p>
                              <span>E-Mail:</span>{' '}
                              <small style={{ width: '89%' }}>
                                {' '}
                                {this.props.companyOfficer &&
                                Object.keys(this.props.companyOfficer).length > 0
                                  ? `${
                                      this.props.companyOfficer.emails[0]
                                        ? this.props.companyOfficer.emails[0]
                                        : 'N/V'
                                    } `
                                  : 'N/V'}{' '}
                              </small>
                            </p>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50 pd-14">
                          <div className="form_1 brr">
                            <h3>2. Importer / recipient:</h3>
                            <p>
                              <span>Name:</span> <small> {this.props.processorData.name1}</small>
                            </p>
                            <p>
                              <span>Address:</span>{' '}
                              <small>{this.props.processorData.address}</small>
                            </p>
                            <p>
                              <span>Contact person:</span>{' '}
                              <small style={{ width: '76%' }}>
                                {' '}
                                {this.state.wasteManagerProcessor
                                  ? `${this.state.wasteManagerProcessor.firstName} ${this.state.wasteManagerProcessor.surname}`
                                  : 'N/V'}{' '}
                              </small>
                            </p>
                            <div className="row">
                              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50 ">
                                <p>
                                  <span>Tel.: </span>{' '}
                                  <small>
                                    {this.state.wasteManagerProcessor
                                      ? this.state.wasteManagerProcessor.phone
                                        ? this.state.wasteManagerProcessor.phone
                                        : 'N/V'
                                      : 'N/V'}
                                  </small>
                                </p>
                              </div>
                              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50">
                                <p>
                                  <span>Fax:</span>{' '}
                                  <small>
                                    {' '}
                                    {this.state.wasteManagerProcessor
                                      ? this.state.wasteManagerProcessor.fax
                                        ? this.state.wasteManagerProcessor.fax
                                        : 'N/V'
                                      : 'N/V'}
                                  </small>
                                </p>
                              </div>
                            </div>
                            <p>
                              <span>E-Mail:</span>{' '}
                              <small style={{ width: '89%' }}>
                                {' '}
                                {this.state.wasteManagerProcessor
                                  ? this.state.wasteManagerProcessor.emails.length > 0
                                    ? this.state.wasteManagerProcessor.emails.join(',')
                                    : 'N/V'
                                  : 'N/V'}
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="first_border second ">
                      <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50">
                          <div className="form_1">
                            <div className="row flex-column">
                              <div className="row">
                                <div className="col-xs-12 col-sm-6 col-md-8 col-lg-6 w-49">
                                  <h3>3. Actual amount:</h3>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-8 col-lg-6 w-50">
                                  <p>
                                    <span>Tons (Mg):</span>
                                    <small className="big_b v c c big_b88988998"></small>
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="w-50"></div>
                                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-6 col-lg-offset-6 w-50 ml-50">
                                  <p>
                                    <span>m³:</span>{' '}
                                    <small
                                      className="big_b v c c big_b88988998fff"
                                      style={{ height: '15px' }}
                                    ></small>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50 pd0">
                          <div className="form_1 brr seo">
                            <div className="row">
                              <div className="col-xs-12 col-sm-8 col-md-12 pd0 ">
                                <h3 className="fon">
                                  4. Actual date of shipment:
                                  <small className="big_b v c c c123"></small>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="first_border box_5">
                      <div className="row">
                        <div className="col-xs-12 col-sm-4 col-md-4 w-33 ">
                          <div className="form_1 form_12 ">
                            <h3>
                              5.(a) 1<sup>st</sup> Transport companies <i>(2)</i>
                            </h3>
                            <p>
                              <span>Name:</span>{' '}
                              <small style={{ width: '83%' }}>
                                {' '}
                                {this.props.carrierData ? this.props.carrierData.name1 : 'N/V'}
                              </small>
                            </p>
                            <p className="f1232133">
                              <span>Address:</span>{' '}
                              <small style={{ width: '76%', fontSize: '80%' }}>
                                {' '}
                                {this.props.carrierData ? this.props.carrierData.address : 'N/V'}
                              </small>
                            </p>
                            <p className="f1232134">
                              <span>Contact person:</span>{' '}
                              <small style={{ width: '63%' }}>
                                {' '}
                                {this.state.wasteManagerCarrier &&
                                Object.keys(this.state.wasteManagerCarrier).length > 0
                                  ? `${this.state.wasteManagerCarrier.firstName} ${this.state.wasteManagerCarrier.surname}`
                                  : 'N/V'}{' '}
                              </small>
                            </p>
                            <p>
                              <span>Tel :</span>{' '}
                              <small style={{ width: '85%' }}>
                                {' '}
                                {this.state.wasteManagerCarrier
                                  ? this.state.wasteManagerCarrier.phone
                                    ? this.state.wasteManagerCarrier.phone
                                    : 'N/V'
                                  : 'N/V'}
                              </small>
                            </p>
                            <p>
                              <span>Fax : </span>
                              <small style={{ width: '82%' }}>
                                {' '}
                                {this.state.wasteManagerCarrier
                                  ? this.state.wasteManagerCarrier.fax
                                    ? this.state.wasteManagerCarrier.fax
                                    : 'N/V'
                                  : 'N/V'}
                              </small>
                            </p>
                            <p>
                              <span>E-Mail:</span>{' '}
                              <small style={{ width: '82%' }}>
                                {this.state.wasteManagerCarrier &&
                                this.state.wasteManagerCarrier.emails
                                  ? this.state.wasteManagerCarrier.emails.length > 0
                                    ? this.state.wasteManagerCarrier.emails.join(',')
                                    : 'N/V'
                                  : 'N/V'}
                              </small>
                            </p>
                            <p className="f12321">
                              <span>Mode of transport:</span>{' '}
                              <small style={{ width: '65%' }}>
                                {this.props.currentEntity.vehicle}
                              </small>
                            </p>
                            <p className="f123212">
                              <span>Handover date:</span> <small style={{ width: '85%' }}></small>
                            </p>
                            <p className="f123213">
                              <span>Signature:</span> <small style={{ width: '85%' }}></small>
                            </p>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-4 w-33 pd-14">
                          <div className="form_1 form_2 form_2321">
                            <h3>
                              5.(b) 2<sup>nd</sup> transport companies
                            </h3>

                            <p>
                              <span>Name:</span> <small style={{ width: '85%' }}></small>
                            </p>
                            <p className="f1232133">
                              <span>Address:</span>{' '}
                              <small style={{ width: '78%', fontSize: '80%' }}></small>
                            </p>
                            <p className="f1232134">
                              <span>Contact person:</span> <small style={{ width: '65%' }}></small>
                            </p>
                            <p>
                              <span>Tel :</span> <small style={{ width: '85%' }}></small>
                            </p>
                            <p>
                              <span>Fax :</span>{' '}
                            </p>
                            <p>
                              <span>E-Mail:</span> <small style={{ width: '84%' }}></small>
                            </p>
                            <p className="f12321">
                              <span>Handover date:</span> <small style={{ width: '65%' }}></small>
                            </p>
                            <p className="f123212">
                              <span>Handover date:</span> <small style={{ width: '65%' }}></small>
                            </p>
                            <p className="f123213">
                              <span>Signature:</span> <small style={{ width: '65%' }}></small>
                            </p>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-4 w-33 pd-14">
                          <div
                            className="form_1 brr form_2"
                            style={{ paddingTop: '10px', paddingBottom: '0px' }}
                          >
                            <h3>
                              5.(c): 3<sup>rd</sup> transport companies
                            </h3>
                            <p>
                              <span>Name:</span> <small style={{ width: '85%' }}></small>
                            </p>
                            <p className="f1232133">
                              <span>Address:</span>{' '}
                              <small style={{ width: '78%', fontSize: '80%' }}></small>
                            </p>
                            <p className="f1232134">
                              <span>Contact person:</span> <small style={{ width: '65%' }}></small>
                            </p>
                            <p>
                              <span>Tel :</span> <small style={{ width: '85%' }}></small>
                            </p>
                            <p>
                              <span>Fax :</span>{' '}
                            </p>
                            <p>
                              <span>E-Mail:</span> <small style={{ width: '84%' }}></small>
                            </p>
                            <p className="f12321">
                              <span>Mode of transport:</span>{' '}
                              <small style={{ width: '65%' }}></small>
                            </p>
                            <p className="f123212">
                              <span>Handover date:</span> <small style={{ width: '85%' }}></small>
                            </p>
                            <p className="f123213">
                              <span>Signature:</span> <small style={{ width: '85%' }}></small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="first_border third">
                      <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50">
                          <div className="form_1">
                            <h3>
                              {' '}
                              6. Waste Generators <i>(3)</i>
                            </h3>
                            <h3> First producer, new producer or collector:</h3>
                            <p>
                              <span>Name:</span>{' '}
                              <small className="big_b s "> {this.props.sourceData.name1}</small>
                            </p>
                            <p>
                              <span>Address:</span> <small> {this.props.sourceData.address}</small>
                            </p>
                            <p>
                              <span>Contact person:</span>{' '}
                              <small style={{ width: '76%' }}>
                                {' '}
                                {this.state.wasteManagerSource
                                  ? `${this.state.wasteManagerSource.firstName} ${this.state.wasteManagerSource.surname}`
                                  : 'N/V'}{' '}
                              </small>
                            </p>
                            <div className="row">
                              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50">
                                <p>
                                  <span>Tel.: </span>{' '}
                                  <small>
                                    {' '}
                                    {this.state.wasteManagerSource
                                      ? this.state.wasteManagerSource.phone
                                        ? this.state.wasteManagerSource.phone
                                        : 'N/V'
                                      : 'N/V'}
                                  </small>
                                </p>
                              </div>
                              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50">
                                <p>
                                  <span>Fax:</span>{' '}
                                  <small>
                                    {' '}
                                    {this.state.wasteManagerSource
                                      ? this.state.wasteManagerSource.fax
                                        ? this.state.wasteManagerSource.fax
                                        : 'N/V'
                                      : 'N/V'}
                                  </small>
                                </p>
                              </div>
                            </div>
                            <p>
                              <span>E-Mail:</span>{' '}
                              <small>
                                <a href="javascript:void(0)">
                                  {' '}
                                  {this.state.wasteManagerSource
                                    ? this.state.wasteManagerSource.emails.length > 0
                                      ? this.state.wasteManagerSource.emails.join(',')
                                      : 'N/V'
                                    : 'N/V'}{' '}
                                </a>
                              </small>
                            </p>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50 linee pd-14">
                          <div className="form_1 brr three">
                            <h3 style={{ margin: 0, lineHeight: '21px' }}>
                              {' '}
                              8. Recovery processes (or, where appropriate, disposal processes for
                              waste referred to in Article 3 (4)):
                            </h3>
                            <p>
                              <span>R-Code / D-Code </span>{' '}
                              <small className="big_b x" style={{ width: '71%' }}>
                                {' '}
                                {this.props.processorData.certifiedRecoveryOptions}
                              </small>
                            </p>
                          </div>
                          <div className="form_1 brr three">
                            <h3> 9. Usual designation of the waste:</h3>
                            <p>
                              <span> </span>
                              <small className="big_b z" style={{ width: '100%' }}>
                                {' '}
                                {/*Plastic Scrap (Polyolefine)*/}
                                {this.state.descriptionWaste}
                              </small>
                              <small
                                className="big_b z"
                                style={{ width: '100%', color: '#FFFFFF' }}
                              >
                                ...........................
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="first_border ex">
                      <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50">
                          <div className="form_1">
                            <h3>
                              7. Recovery facility{' '}
                              <label className="containerd ">
                                <input type="checkbox" checked="checked" />
                                <span className="checkmark" />
                              </label>
                              <span>
                                laboratory{' '}
                                <label className="containerd ">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>{' '}
                              </span>
                            </h3>
                            <p>
                              <span>Name:</span>{' '}
                              <small> {`${this.props.processorData.name1}`}</small>
                            </p>
                            <p>
                              <span>Address:</span>{' '}
                              <small> {this.props.processorData.address}</small>
                            </p>
                            <p>
                              <span>Contact person:</span>{' '}
                              <small style={{ width: '76%' }}>
                                {' '}
                                {this.state.wasteManagerProcessor
                                  ? `${this.state.wasteManagerProcessor.firstName} ${this.state.wasteManagerProcessor.surname}`
                                  : 'N/V'}{' '}
                              </small>
                            </p>
                            <div className="row">
                              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50">
                                <p>
                                  <span>Tel.: </span>{' '}
                                  <small>
                                    {' '}
                                    {this.state.wasteManagerProcessor
                                      ? this.state.wasteManagerProcessor.phone
                                        ? this.state.wasteManagerProcessor.phone
                                        : 'N/V'
                                      : 'N/V'}
                                  </small>
                                </p>
                              </div>
                              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50">
                                <p>
                                  <span>Fax:</span>{' '}
                                  <small>
                                    {' '}
                                    {this.state.wasteManagerProcessor
                                      ? this.state.wasteManagerProcessor.fax
                                        ? this.state.wasteManagerProcessor.fax
                                        : 'N/V'
                                      : 'N/V'}
                                  </small>
                                </p>
                              </div>
                            </div>
                            <p>
                              <span>E-Mail:</span>{' '}
                              <small>
                                {' '}
                                {this.state.wasteManagerProcessor
                                  ? this.state.wasteManagerProcessor.emails.length > 0
                                    ? this.state.wasteManagerProcessor.emails.join(',')
                                    : 'N/V'
                                  : 'N/V'}
                              </small>
                            </p>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-50 pd-14">
                          <div className="form_1 brr">
                            <h3>
                              10. Waste identification{' '}
                              <span style={{ fontWeight: 100, fontSize: '14px' }}>
                                <i>(indicate relevant codes):</i>
                              </span>
                            </h3>
                            <p>
                              <span>i) Basel Annex IX :</span>
                              <small className="big_b v v big_b321 w-70">
                                {this.state.annex10i}{' '}
                              </small>{' '}
                            </p>
                            <p>
                              <span>
                                ii) OECD (if different from <i>(i)</i>)):
                              </span>
                              <small className="big_b v v big_b999 w-43" style={{ width: '50%' }}>
                                {' '}
                              </small>
                            </p>
                            <p>
                              <span>
                                iii) Annex IIIA (<sup>4</sup>):
                              </span>
                              <small className="big_b v v big_b321 w-73"> </small>{' '}
                            </p>
                            <p>
                              <span>
                                iv) Annex IIIB (<sup>5</sup>):
                              </span>
                              <small className="big_b v v big_b321 w-73"> </small>{' '}
                            </p>
                            <p>
                              <span>v) EU list of waste:</span>{' '}
                              <small
                                className="big_b v v big_b321 big_b9999 w-63"
                                style={{ width: '66%' }}
                              >
                                {' '}
                                {this.state.wastecode}
                              </small>
                            </p>
                            <p>
                              <span>vi) National code: </span>{' '}
                              <small className="big_b v v big_b321 w-73"> </small>
                            </p>
                            <p>
                              <span>vii) Other (please specify):</span>{' '}
                              <small
                                className="big_b v v big_b321 big_b9999 "
                                style={{ width: '48%' }}
                              >
                                {' '}
                                {this.state.baselCodeEu}
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="first_border five">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 w-100">
                          <div className="form_1">
                            <h3>11. Affected states:</h3>
                            <div className="table-responsive " style={{ overflowX: 'unset' }}>
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>Country of export / country of dispatch</th>
                                    <th>Transit country (s) </th>
                                    <th style={{ borderRight: 0 }}>
                                      Country of import / country of destination
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <td style={{ borderBottom: 0 }}>
                                    <p>
                                      <span />{' '}
                                      <small
                                        className="big_b v big_b32132165489"
                                        style={{ width: '100%' }}
                                      >
                                        {addressFor11Source}
                                      </small>
                                    </p>
                                  </td>

                                  <td className="ppp" style={{ borderBottom: 0 }}>
                                    <div className={'row fake-table'}>
                                      <div className={'border-right w-33'}>
                                        {this.props.countries && this.props.countries.join(' ')}
                                      </div>
                                      <div className={'border-right w-33'}>
                                        <span className={'letter-spacing'}>
                                          .................................
                                        </span>
                                      </div>
                                      <div className={'border-right w-33'}>
                                        <span className={'letter-spacing'}>
                                          .................................
                                        </span>
                                      </div>
                                    </div>

                                    <table
                                      style={{
                                        width: '100%',
                                        marginLeft: '-0.2645833333mm',
                                        marginTop: '-0.2645833333mm',
                                      }}
                                      className="table custom real-table"
                                    >
                                      <tr>
                                        <td>
                                          <p>
                                            <span />{' '}
                                            <small
                                              className="big_b g"
                                              style={{ borderBottom: 'none' }}
                                            >
                                              {this.props.countries &&
                                                this.props.countries.join(' ')}
                                            </small>
                                          </p>
                                        </td>
                                        <td>
                                          <p>
                                            <span />{' '}
                                            <small
                                              className="big_b g"
                                              style={{ borderBottom: 'none' }}
                                            ></small>
                                          </p>
                                        </td>
                                        <td style={{ borderRight: '0' }}>
                                          <p>
                                            <span />{' '}
                                            <small
                                              className="big_b g"
                                              style={{ borderBottom: 'none' }}
                                            ></small>
                                          </p>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                  <td style={{ borderBottom: 0 }}>
                                    <p>
                                      <span />{' '}
                                      <small
                                        className="big_b v big_b32132165489"
                                        style={{ width: '100%' }}
                                      >
                                        {' '}
                                        {addressFor11Processor}
                                      </small>
                                    </p>
                                  </td>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="first_border six">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 w-100">
                          <div className="form_1">
                            <h3>
                              12. Declaration by the person arranging the shipment:{' '}
                              <span>
                                {' '}
                                I hereby declare that the above information is complete and true to
                                the best of my knowledge. I also declare that effective contractual
                                obligations have been entered into with the recipient in writing{' '}
                                <i>
                                  (not required for the waste specified in Article 3, paragraph 4)
                                </i>
                                :
                              </span>
                            </h3>
                            <div className="row">
                              <div className="col-xs-12 col-sm-4 col-md-4 w-33 col-lg-4">
                                <p>
                                  <span>Name: </span>{' '}
                                  <small className="big_b v b">
                                    {' '}
                                    {this.props.companyOfficer &&
                                    Object.keys(this.props.companyOfficer).length > 0
                                      ? `${this.props.companyOfficer.firstName} ${this.props.companyOfficer.surname}`
                                      : 'N/V'}{' '}
                                  </small>
                                </p>
                              </div>
                              <div className="col-xs-12 col-sm-4 col-md-4 w-33 col-lg-4">
                                <p>
                                  <span>Date:</span>{' '}
                                  <small className="big_b v b" style={{ width: '83%' }}>
                                    {' '}
                                    {this.state.createdAt.getDate() +
                                      '.' +
                                      (this.state.createdAt.getMonth() + 1) +
                                      '.' +
                                      this.state.createdAt.getFullYear()}
                                  </small>
                                </p>
                              </div>
                              <div className="col-xs-12 col-sm-4 col-md-4 w-33 col-lg-4 flex">
                                <p>
                                  <span>Signature: </span>{' '}
                                </p>

                                <span className={'letter-spacing'}>
                                  <img
                                    src={`${this.state.apiUrl}/companys/image/${this.props.companyData.signatures}`}
                                    className={'sign'}
                                  />{' '}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="first_border six">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 w-100">
                          <div className="form_1">
                            <h3>13. Signature of the recipient upon receipt of the waste:</h3>
                            <div className="row">
                              <div className="col-xs-12 col-sm-4 col-md-4 w-33 col-lg-4">
                                <p>
                                  <span>Name: </span>
                                  <span className={'letter-spacing'}>
                                      ................................
                                    </span>
                                  {/* <small className="big_b v b"></small> */}

                                </p>
                              </div>
                              <div className="col-xs-12 col-sm-4 col-md-4 w-33 col-lg-4">
                                <p>
                                  <span>Date:</span>{' '}
                                  <span className={'letter-spacing'}>
                                      ...........................
                                    </span>
                                  {/* <small className="big_b v b" style={{ width: '83%' }}> */}
                                    {/* {' '}
                                    {this.state.createdAt.getDate() +
                                      '.' +
                                      (this.state.createdAt.getMonth() + 1) +
                                      '.' +
                                      this.state.createdAt.getFullYear()} */}
                                  {/* </small> */}
                                </p>
                              </div>
                              <div className="col-xs-12 col-sm-4 col-md-4 w-33 col-lg-4">
                                <p>
                                  <span>
                                    Signature:{' '}
                                    <span className={'letter-spacing'}>
                                      .........................
                                    </span>
                                  </span>{' '}
                                  {/*<small className="big_b v b" style={{ width: '75%' }}></small>*/}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="first_border seven">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 w-100">
                          <div className="form_1 text-center" style={{ textAlign: 'center' }}>
                            <h2>TO BE COMPLETED BY THE RECYCLING PLANT OR THE LABORATORY:</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="first_border sixx">
                      <div className="row flex-column">
                        <div className={'row'}>
                          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-45">
                            <div className="form_1 brr">
                              <h4 className={'row'}>
                                14. Entrance <span> at the recovery facilit: </span>&nbsp;
                                <span className={'empty-box'}></span>
                                {/*<label className="" style={{ all: 'unset' }}>*/}
                                {/*  <input type="checkbox" />*/}
                                {/*  /!*<span className="checkmark" />*!/*/}
                                {/*</label>*/}
                              </h4>
                              <h4>
                                {' '}
                                <span className={'m-left-45'}> or at the laboratory:</span>&nbsp;
                                <span className={'empty-box ml-2cm'}></span>
                              </h4>
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 w-55">
                            <div className={'margin-top-14'}>
                              <div className={'tonne'}>
                                Received quantity: Tons (Mg) :{' '}
                                <span className={'letter-spacing'}>.........................</span>
                              </div>

                              <div className={'m3'}>
                                m³: :{' '}
                                <span className={'letter-spacing'}>.........................</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="clearfix"></div>
                        <div className="row">
                          <div className="col-xs-12 col-sm-4 col-md-4 w-33 col-lg-4">
                            <p>
                              <span>Name: </span> <small className="big_b v b"> </small>
                            </p>
                          </div>
                          <div className="col-xs-12 col-sm-4 col-md-4 w-33 col-lg-4">
                            <p>
                              <span>Date:</span>{' '}
                              <small className="big_b v b" style={{ width: '83%' }}>
                                {' '}
                              </small>
                            </p>
                          </div>
                          <div className="col-xs-12 col-sm-4 col-md-4 w-33 col-lg-4">
                            <p>
                              <span>Signature:</span>{' '}
                              <small className="big_b v b" style={{ width: '75%' }}></small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" last_text">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 w-100">
                          <div className="">
                            <ul>
                              <li>
                                (1) Information to be carried with the shipments of the green-listed
                                wastes that are intended for recovery or of wastes that are intended
                                for laboratory analysis in accordance with Regulation (EC) No.
                                1013/2006. When completing this form, the specific instructions in
                                Annex IC of Regulation (EC) No. 1013/2006 must also be taken into
                                account.
                              </li>
                              <li>
                                (2) If there are more than 3 transport companies, the information
                                required under number 5 a), b), c) must be attached.
                              </li>
                              <li>
                                If the person who arranges the shipment is not the producer or
                                collector, information on the producer or collector must also be
                                given.
                              </li>
                              <li>
                                (4) The corresponding code (s) according to Annex IIIA of Regulation
                                (EC) No. 1013/2006 must be indicated - one after the other, if
                                applicable. Certain entries of the Basel Convention such as B1100,
                                B3010 or B3020 are limited to certain waste streams, as specified in
                                Annex IIIA.
                              </li>
                              <li>
                                (5) The BEU codes listed in Annex IIIB of Regulation (EC) No.
                                1013/2006 are to be used.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      );
    }
  }
}

export default AnnexDocument;
