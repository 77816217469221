import _ from "lodash";
import { APP_NAMESPACE } from "../../util/redux-constants";
import { put, post, get, del } from "../../util/http-utils";
import { updateStore, buildGenericInitialState, handleError } from "../../util/store-utils";
import { CHANGE_AUTH, GET_AUTHENTICATED_USER } from "./authentication";
import { CONTRACTS_TABLE_COLUMNS } from "../../constants/ui-constants";
import { showMessage } from '../store/actions/alerts';

const CONTRACT_ENDPOINT_BASE = "contracts";
const typeBase = `${APP_NAMESPACE}/${CONTRACT_ENDPOINT_BASE}/`;

// Constants
export const GET_CONTRACTS = `${typeBase}GET_CONTRACTS`;
export const GET_CONTRACT = `${typeBase}GET_CONTRACT`;
export const ADD_CONTRACT = `${typeBase}ADD_CONTRACT`;
export const EDIT_CONTRACT = `${typeBase}EDIT_CONTRACT`;
export const DELETE_CONTRACT = `${typeBase}DELETE_CONTRACT`;
export const SEND_DOCUMENTS = `${typeBase}SEND_DOCUMENTS`;

// Actions

/**
 * getUser  - Fetches user from API, given id
 *
 * @param {String} id User's id for lookup
 * @returns {Promise}
 */
export const getContract = (id) => async (dispatch) => {
    try {
        const response = await get(dispatch, GET_CONTRACT, `${CONTRACT_ENDPOINT_BASE}/${id}`, true);
        return Promise.resolve(response);
    } catch (err) {
        await handleError(dispatch, err);
    }
};

/**
 * getUsers  - Fetches users from API
 *
 * @returns {Promise}
 */
export const getContracts = () => async (dispatch) => {
    try {
        const response = await get(dispatch, GET_CONTRACTS, CONTRACT_ENDPOINT_BASE, true);
        return Promise.resolve(response);
    } catch (err) {
        await handleError(dispatch, err, GET_CONTRACTS);
    }
};

/**
 * register - Creates a new ORDER for a user
 * @param {Object} formData  User's form data
 */
export const addContract = (formData, callback, currentTabName, currentTabNumber) => async (
    dispatch
) => {
    try {
        const response = await post(dispatch, ADD_CONTRACT, `${CONTRACT_ENDPOINT_BASE}/add`, formData, true);

        callback(
            currentTabName,
            currentTabNumber,
            CONTRACTS_TABLE_COLUMNS,
            "Added Successfully!",
            response.error
        );
        response.status = true;

        return response;
    } catch (err) {
        console.log("Error: ", err)
        await handleError(dispatch, err, CHANGE_AUTH);
    }
};


// export const copyContract = (data, callback) => async (dispatch) => {
//     try {
//         const response = await post(dispatch, COPY_CONTRACT, `${CONTRACT_ENDPOINT_BASE}/copy`, data, true);
//         if (response.error === undefined) {
//             callback("Copied Successfully!", true);
//         } else {
//             dispatch(showMessage({ message: response.error, messageType: "error" }));
//         }
//         return response;
//     } catch (err) {
//         console.log(err);
//         await handleError(dispatch, err, CHANGE_AUTH);
//     }
// };

/**
 * Edit Contract - updates a Contract
 * @param {Object} formData  User's form data
 */
export const editContract = (id, formData) => async (dispatch) => {
    // let result = formData.articles.every(e => e.source_cashflow !== "" && e.processor_cashflow !== "" && e.source_cashflow !== null && e.processor_cashflow !== null);
    // if (result) {
    try {
        console.log("EDITING CONTRACT!!");
        const response = await put(
            dispatch,
            EDIT_CONTRACT,
            `${CONTRACT_ENDPOINT_BASE}/${id}`,
            formData,
            false
        );
        // If the registration was successful, set the JWT as a cookie
        if (response.ContractUpdated) {
            dispatch(showMessage({ message: "Edited Contract Successfully", messageType: "success" }))
        } else {
            dispatch(showMessage({ message: response.error, messageType: "error" }))
        }
    } catch (err) {
        console.log(err)
        await handleError(dispatch, err, CHANGE_AUTH);
    }
    // }
    // else {
    //     dispatch(showMessage({ message: "Source Or Processor Cashflow Missing", messageType: "error" }))
    // }
};

/**
 * Delete Contract - updates a Contract
 * @param {Object} formData  User's form data
 */
export const deleteContract = (id, callback) => async (dispatch) => {
    try {
        console.log("DELETING ORDER!!");

        const response = await del(dispatch, DELETE_CONTRACT, `${CONTRACT_ENDPOINT_BASE}/${id}`, true);
        console.log(response);

        // If the registration was successful, set the JWT as a cookie
        if (response) {
            callback("Deleted ORDER Successfully");
        }
    } catch (err) {
        await handleError(dispatch, err, CHANGE_AUTH);
    }
};


export const sendContractDocument = (id, payload, language) => async (dispatch) => {
    try {
        const response = await post(
            dispatch,
            SEND_DOCUMENTS,
            `${CONTRACT_ENDPOINT_BASE}/senddocument/${id}/${language}`,
            payload,
            true
        );
        if (response.error === undefined) {
          dispatch(showMessage({ message: 'Sent Successfully', messageType: 'success' }));
        }
    } catch (err) {
        await handleError(dispatch, err, CHANGE_AUTH);
    }
};


// Store
const INITIAL_STATE = {
    ...buildGenericInitialState([GET_CONTRACT, GET_CONTRACTS]),
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CHANGE_AUTH:
            return updateStore(
                state,
                action,
                _.get(action, "payload.contract.id") ? { [action.payload.user.id]: action.payload.user } : {}
            );
        case GET_CONTRACT:
        case GET_AUTHENTICATED_USER:
            return updateStore(
                state,
                action,
                _.get(action, "payload.contract.id") ? { [action.payload.user.id]: action.payload.user } : {}
            );
        case GET_CONTRACTS:
            return updateStore(
                state,
                action,
                _.get(action, "payload.contract") ? _.mapKeys(action.payload.contract, "id") : {}
            );
        default:
            return state;
    }
};

// Selectors
export const getAuthenticatedUser = ({ user, authentication }) => user[authentication.user];
