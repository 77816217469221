import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'normalize.css';
import configureStore from './redux';
import Header from './components/header/header';
import Routes from './routes/';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import '@progress/kendo-theme-material/dist/all.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

// Import stylesheets
import './assets/stylesheets/base.scss';

import translationDE from './translations/de-DE.json';
import translationUS from './translations/en-US.json';
import Messages from './components/alerts';

const language = navigator.language || navigator.userLanguage;

i18next.init({
  interpolation: { escapeValue: false },
  lng: language && language.toLowerCase().indexOf('en') > -1 ? 'en' : 'de',
  resources: {
    en: {
      common: translationUS,
    },
    de: {
      common: translationDE,
    },
  },
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#56E39F',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#1D283E',
      contrastText: '#FFFFFF',
    },
  },
});

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <I18nextProvider i18n={i18next}>
        <div className="app-container">
          {localStorage.getItem('token') && <Header />}
          <main className={localStorage.getItem('token') ? '' : 'main-no-margin-padding'}>
            <Routes />
          </main>
          <Messages />
        </div>
      </I18nextProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

// Enable hot relading
module.hot.accept();
