import _ from "lodash";
import { APP_NAMESPACE } from "../../util/redux-constants";
import { put, post, get, del } from "../../util/http-utils";
import { updateStore, buildGenericInitialState, handleError } from "../../util/store-utils";
import { CHANGE_AUTH, GET_AUTHENTICATED_USER } from "./authentication";
import { ARTICLE_TABLE_COLUMNS, BANK_TABLE_COLUMNS } from "../../constants/ui-constants";
import { showMessage } from '../store/actions/alerts';

const BANK_ENDPOINT_BASE = "banks";
const typeBase = `${APP_NAMESPACE}/${BANK_ENDPOINT_BASE}/`;

// Constants
export const GET_BANKS = `${typeBase}GET_BANKS`;
export const GET_BANK = `${typeBase}GET_BANK`;
export const ADD_BANK = `${typeBase}ADD_BANK`;
export const EDIT_BANK = `${typeBase}EDIT_BANK`;
export const DELETE_BANK = `${typeBase}DELETE_BANK`;

// Actions

/**
 * getUser  - Fetches user from API, given id
 *
 * @param {String} id User's id for lookup
 * @returns {Promise}
 */
export const getBank = (id) => async (dispatch) => {
  try {
    const response = await get(dispatch, GET_BANK, `${BANK_ENDPOINT_BASE}/${id}`, true);
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err);
  }
};

/**
 * getUsers  - Fetches users from API
 *
 * @returns {Promise}
 */
export const getBanks = () => async (dispatch) => {
  try {
    const response = await get(dispatch, GET_BANKS, BANK_ENDPOINT_BASE, true);
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err, GET_BANK);
  }
};

/**
 * register - Creates a new BANK for a user
 * @param {Object} formData  User's form data
 */
export const addBank = (formData, callback, currentTabName, currentTabNumber) => async (
  dispatch
) => {
  try {
    let keysToRemove = ['tile0', 'tile1', 'tile2', 'tile3', 'tile4', 'tile5', 'tileData'];

    for (let key of Object.keys(formData)) {
      if (keysToRemove.includes(key)) delete formData[key];
    }

    const response = await post(dispatch, ADD_BANK, `${BANK_ENDPOINT_BASE}/add`, formData, false);
    //console.log(response);

    // If the registration was successful, set the JWT as a cookie
    if (response.error === undefined) {
      callback(currentTabName, currentTabNumber, BANK_TABLE_COLUMNS, "Added Successfully!");
    } else {
      dispatch(showMessage({ message: response.error, messageType: "error" }))
    }
  } catch (err) {
    await handleError(dispatch, err, CHANGE_AUTH);
  }
};

/**
 * Edit BANK - updates a BANK
 * @param {Object} formData  User's form data
 */
export const editBank = (id, formData) => async (dispatch) => {
  try {
    let keysToRemove = ['tile0', 'tile1', 'tile2', 'tile3', 'tile4', 'tile5', 'tileData'];

    for (let key of Object.keys(formData)) {
      if (keysToRemove.includes(key)) delete formData[key];
    }

    const response = await put(dispatch, EDIT_BANK, `${BANK_ENDPOINT_BASE}/${id}`, formData, false);
    console.log(response);

    // If the registration was successful, set the JWT as a cookie
    if (response.BankUpdated) {
      console.log(response);
      dispatch(showMessage({ message: "Edited BANK Successfully", messageType: "success" }))
    } else {
      dispatch(showMessage({ message: response.error, messageType: "error" }))
    }
  } catch (err) {
    await handleError(dispatch, err, CHANGE_AUTH);
  }
};

/**
 * Delete BANK - updates a BANK
 * @param {Object} formData  User's form data
 */
export const deleteBank = (id, callback) => async (dispatch) => {
  try {
    console.log("DELETING BANK!!");

    const response = await del(dispatch, DELETE_BANK, `${BANK_ENDPOINT_BASE}/${id}`, true);
    console.log(response);

    // If the registration was successful, set the JWT as a cookie
    if (response) {
      callback("Deleted BANK Successfully");
    }
  } catch (err) {
    await handleError(dispatch, err, CHANGE_AUTH);
  }
};

// Store
const INITIAL_STATE = {
  ...buildGenericInitialState([GET_BANK, GET_BANKS]),
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_AUTH:
      return updateStore(
        state,
        action,
        _.get(action, "payload.user.id") ? { [action.payload.user.id]: action.payload.user } : {}
      );
    case GET_BANK:
    case GET_AUTHENTICATED_USER:
      return updateStore(
        state,
        action,
        _.get(action, "payload.user.id") ? { [action.payload.user.id]: action.payload.user } : {}
      );
    case GET_BANKS:
      return updateStore(
        state,
        action,
        _.get(action, "payload.users") ? _.mapKeys(action.payload.users, "id") : {}
      );
    default:
      return state;
  }
};

// Selectors
export const getAuthenticatedUser = ({ user, authentication }) => user[authentication.user];
