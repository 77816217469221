import React from "react";
import { Grid, FormControl, Select } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../redux/store/actions/partners";
import TimeField from "react-simple-timefield";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

const totalBlocks = [0, 1];

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

function OpeningHours(props) {
  const dispatch = useDispatch();
  const { openingHours } = useSelector(({ partners }) => partners);

  const handleChange = (name, value, index) => {
    let currentData = openingHours;
    const newTime = value.replace(/-/g, ":");
    currentData[index][name] = newTime.substr(0, 5);
    dispatch(Actions.setOpeningHours(currentData));
  };

  const handleDaysChange = (event, index) => {
    let currentData = openingHours;
    currentData[index][event.target.name] = event.target.value;

    dispatch(Actions.setOpeningHours(currentData));
  };

  return (
    <div className={"mt-16"}>
      {totalBlocks.map((block, key) => (
        <Grid container spacing={3} key={key}>
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <FormControl variant="outlined" className={"w-full"}>
              <InputLabel>{props.t("Start Day")}</InputLabel>
              <Select
                value={openingHours[block] ? openingHours[block].start_day : ""}
                onChange={(event) => handleDaysChange(event, block)}
                label={props.t("Start Day")}
                name={"start_day"}
                className={"w-full"}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {days.map((day) => (
                  <MenuItem value={day}>{props.t(day)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <FormControl variant="outlined" className={"w-full"}>
              <InputLabel>{props.t("End Day")}</InputLabel>
              <Select
                value={openingHours[block] ? openingHours[block].end_day : ""}
                onChange={(event) => handleDaysChange(event, block)}
                label="End Day"
                name={"end_day"}
                className={"w-full"}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {days.map((day) => (
                  <MenuItem value={day}>{props.t(day)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <FormControl variant="outlined" className={"w-full"}>
              <TimeField
                value={openingHours[block] ? openingHours[block].start_time : ""}
                onChange={(event, value) => handleChange("start_time", value, block)}
                input={
                  <TextField
                    style={{ margin: 10, width: 300 }}
                    value={openingHours[block] ? openingHours[block].start_time : ""}
                    label={props.t("Start Time")}
                    name={"start_time"}
                  />
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <FormControl variant="outlined" className={"w-full"}>
              <TimeField
                value={openingHours[block] ? openingHours[block].end_time : ""}
                onChange={(event, value) => handleChange("end_time", value, block)}
                input={
                  <TextField
                    style={{ margin: 10, width: 300 }}
                    value={openingHours[block] ? openingHours[block].end_time : ""}
                    label={props.t("End Time")}
                    name={"end_time"}
                  />
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2}>
            <FormControl variant="outlined" className={"w-full"}>
              <TimeField
                value={openingHours[block] ? openingHours[block].start_time1 : ""}
                onChange={(event, value) => handleChange("start_time1", value, block)}
                input={
                  <TextField
                    style={{ margin: 10, width: 300 }}
                    value={openingHours[block] ? openingHours[block].start_time1 : ""}
                    label={props.t("Start Time")}
                    name={"start_time1"}
                  />
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <FormControl variant="outlined" className={"w-full"}>
              <TimeField
                value={openingHours[block] ? openingHours[block].end_time1 : ""}
                onChange={(event, value) => handleChange("end_time1", value, block)}
                input={
                  <TextField
                    style={{ margin: 10, width: 300 }}
                    value={openingHours[block] ? openingHours[block].end_time1 : ""}
                    label={props.t("End Time")}
                    name={"end_time1"}
                  />
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      ))}
    </div>
  );
}

export default withTranslation("common")(OpeningHours);
