import React from 'react';
import MaterialTable, { MTableBody } from 'material-table';
import { useTranslation } from 'react-i18next';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { makeStyles } from '@material-ui/core/styles';

import * as Actions from '../../redux/store/actions/stocks';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function StocksList(props) {
  const classes = useStyles();
  const [t, i18next] = useTranslation('common');
  const { stocks, getTotalAmount, setFilteredStocks } = props;

  return (
    <MaterialTable
      localization={{
        pagination: {
          labelDisplayedRows: `{from}-{to} ${t('of')} {count}`,
          labelRowsSelect: `${t('rows')}`,
        },
        toolbar: {
          nRowsSelected: `{0} ${t('rows')} ${t('selected')}`,
          searchTooltip: t('Search'),
          searchPlaceholder: t('Search'),
        },
        header: {
          actions: t('Actions'),
        },
        body: {
          emptyDataSourceMessage: t('No records to display'),
          filterRow: {
            filterTooltip: t('Filter'),
          },
        },
      }}
      title=""
      columns={[
        { title: t('Warehouse'), field: 'warehouse' },
        { title: t('Address'), field: 'warehouseAddress' },
        {
          title: t('Articles'),
          field: 'articleNames',
        },
        {
          title: t('Amount'),
          field: 'totalAmount',
        },
        {
          title: t('Weight'),
          field: 'totalWeight',
        },
        {
          title: t('Value'),
          field: 'totalValue',
        },
        {
          title: t('Contact'),
          field: 'contactPersons',
        },
      ]}
      data={stocks}
      components={{
        Body: (props) => (
          <MTableBody
            {...props}
            onFilterChanged={(columnId, value) => {
              // count = 0
              props.onFilterChanged(columnId, value);
            }}
          >
            {setFilteredStocks(props.renderData)}
          </MTableBody>
        ),
      }}
      detailPanel={[
        {
          tooltip: t('Show Articles'),
          render: (rowData) => {
            return (
              <TableContainer component={Paper}>
                <Table className={classes.table}>
                  <TableBody>
                    {rowData.articles[0].map((article, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ width: '45px' }}></TableCell>
                        <TableCell style={{ width: 'calc((100% - 0px) / 7)' }}></TableCell>
                        <TableCell style={{ width: 'calc((100% - 0px) / 7)' }}></TableCell>
                        <TableCell style={{ width: 'calc((100% - 0px) / 7)', paddingLeft: '24px' }}>
                          {i18next.language == 'de'
                            ? article.articleDetails.descriptionInGerman
                            : article.articleDetails.descriptionInEnglish}
                        </TableCell>
                        <TableCell style={{ width: 'calc((100% - 0px) / 7)', paddingLeft: '20px' }}>
                          {article.totalAmount}
                        </TableCell>
                        <TableCell style={{ width: 'calc((100% - 0px) / 7)' }}>
                          {article.totalWeight}
                        </TableCell>
                        <TableCell style={{ width: 'calc((100% - 0px) / 7)' }}>
                          {getTotalAmount(article)}
                        </TableCell>
                        <TableCell style={{ width: 'calc((100% - 0px) / 7)' }}></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            );
          },
        },
      ]}
      options={{
        filtering: true,
      }}
      onSearchChange={(data) => {
        console.log(data);
      }}
    />
  );
}

export default StocksList;
