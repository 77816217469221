import React from 'react';
import MaterialTable from 'material-table';
import { useTranslation } from 'react-i18next';

import * as Actions from '../../redux/store/actions/stocks';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      h6: {
        fontWeight: 'bold !important',
        color: '#1D283E',
      },
    },
  },
});

function TaxList(props) {
  const [t] = useTranslation('common');
  const { taxes } = props;

  return (
    <ThemeProvider theme={theme}>
      <MaterialTable
        localization={{
          pagination: {
            labelDisplayedRows: `{from}-{to} ${t('of')} {count}`,
            labelRowsSelect: `${t('rows')}`,
          },
          toolbar: {
            nRowsSelected: `{0} ${t('rows')} ${t('selected')}`,
            searchTooltip: t('Search'),
            searchPlaceholder: t('Search'),
          },
          header: {
            actions: t('Actions'),
          },
          body: {
            emptyDataSourceMessage: t('No records to display'),
            filterRow: {
              filterTooltip: t('Filter'),
            },
          },
        }}
        title=""
        columns={[
          { title: t('Country source'), field: 'country_source' },
          { title: t('Country processor'), field: 'country_processor' },
          {
            title: t('Article category'),
            field: 'article_category',
          },
          {
            title: t('Incoterms processor'),
            field: 'incoterms_processor',
          },
          {
            title: t('Incoterms source'),
            field: 'incoterms_source',
          },
          {
            title: t('Material flow source'),
            field: 'material_flow_source',
          },
          {
            title: t('Material flow processor'),
            field: 'material_flow_processor',
          },

          {
            title: t('Tax code processor'),
            field: 'tax_code_processor',
          },
          {
            title: t('Tax code source'),
            field: 'tax_code_source',
          },
          {
            title: t('Tax rate processor'),
            field: 'tax_rate_processor',
          },

          {
            title: t('Tax rate source'),
            field: 'tax_rate_source',
          },
          {
            title: t('Tax text processor'),
            field: 'tax_text_processor',
          },
          {
            title: t('Tax text source'),
            field: 'tax_text_source',
          },
        ]}
        data={taxes}
        options={{
          filtering: true,
        }}
      />
    </ThemeProvider>
  );
}

export default TaxList;
