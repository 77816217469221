import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import SaveIcon from '@material-ui/icons/Save';
import OnAddOrderPopup from '../../popup/on_order_add';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import moment from 'moment';
import PreviewAnnexDocument from '../../modals/previewAnnexDocument';
import PreviewInvoiceCorrectionSource from '../../modals/previewInvoiceCorrectionSource';
import PreviewInvoiceCorrectionProcessor from '../../modals/previewInvoiceCorrectionProcessor';
import PreviewInvoiceCancellationSource from '../../modals/previewInvoiceCancellationSource';
import PreviewInvoiceCancellationProcessor from '../../modals/previewInvoiceCancellationProcessor';
import PreviewPurchaseConfirmation from '../../modals/previewPurchaseConfirmation';
import PreviewSalesConfirmation from '../../modals/previewSalesConfirmation';
import PreviewTransportInformation from '../../modals/previewTransportInformation';
import PreviewInvoiceSource from '../../modals/previewInvoiceSource';
import PreviewInvoiceProcessor from '../../modals/previewInvoiceProcessor';
import PreviewTransportOrder from '../../modals/previewTransportationOrder';
import AssessmentDialog from '../../modals/previewAssessment';
import PreliminaryPurchaseConfirmation from '../../modals/previewPreliminaryPurchaseConfirmation';
import CommissionInvoiceDialog from '../../modals/previewCommissionInvoice';
import CommissionConfirmationDialog from '../../modals/previewCommissionConfirmation';
import PreviewBillingConfirmation from '../../modals/previewBillingConfirmation';
import { getApiUrl } from '../../../util/environment-utils';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

const defaultState = {
  annex: { status: false, date: null },
  purchase_confirmation: { status: false, date: null },
  sales_confirmation: { status: false, date: null },
  transport_information: { status: false, date: null },
  transport_order: { status: false, date: null },
  assessment: { status: false, date: null },
  preliminary_purchase_confirmation: { status: false, date: null },
  commission_confirmation: { status: false, date: null },
  commission_invoice: { status: false, date: null },
  billing_confirmation: { status: false, date: null },
  invoice_source: { status: false, date: null },
  invoice_processor: { status: false, date: null },
  invoice_correction_processor: { status: false, date: null },
  invoice_correction_source: { status: false, date: null },
  invoice_cancellation_source: { status: false, date: null },
  invoice_cancellation_processor: { status: false, date: null },
};

function SentDocDate({ name, date, component }) {
  return (
    <p className={'flex ml-12 align-center'}>
      {`${name} (${moment(date).format('DD.MM.YYYY')})`}&nbsp; {component}
    </p>
  );
}

function SendDocs(props) {
  const { currentEntity = {}, t, state, sendDocument, self } = props;
  const [checked, setChecked] = useState(defaultState);
  const [loading, setLoading] = useState(false);
  const [articleTypes, setArticleTypes] = useState([]);
  const [open, setOpen] = useState(false);
  const [cancellationLoading, setCancellationLoading] = useState(false);

  useEffect(() => {
    if (Object.keys(currentEntity).length > 0) {
      setChecked({
        annex: { status: false, date: null },
        purchase_confirmation: { status: false, date: null },
        sales_confirmation: { status: false, date: null },
        transport_information: { status: false, date: null },
        transport_order: { status: false, date: null },
        assessment: { status: false, date: null },
        preliminary_purchase_confirmation: { status: false, date: null },
        commission_confirmation: { status: false, date: null },
        commission_invoice: { status: false, date: null },
        billing_confirmation: { status: false, date: null },
        invoice_source: { status: false, date: null },
        invoice_processor: { status: false, date: null },
        invoice_correction_processor: { status: false, date: null },
        invoice_correction_source: { status: false, date: null },
        invoice_cancellation_source: { status: false, date: null },
        invoice_cancellation_processor: { status: false, date: null },
      });

      if (currentEntity.docStatus && currentEntity.docStatus.length > 0) {
        setChecked({ ...checked, ...currentEntity.docStatus });
      }
    }
  }, [currentEntity, currentEntity.docStatus]);

  function checkInvoiceArticles(articles, type = 'loadingInvoice') {
    const cashflow = type === 'loadingInvoice' ? 'source_cashflow' : 'processor_cashflow';
    const matchValue = type === 'loadingInvoice' ? 'Supplementary Payment' : 'Compensation';
    let isExist = false;

    for (let article of articles) {
      if (article[cashflow] === matchValue) {
        isExist = true;
        break;
      }
    }

    return isExist;
  }

  function handleCheckboxesChange(event) {
    let sourceArticles = checkInvoiceArticles(currentEntity.articles, 'loadingInvoice');
    let processorArticles = checkInvoiceArticles(currentEntity.articles, 'unloadingInvoice');

    let sourceInvoiceStatus =
      currentEntity.docStatus &&
      currentEntity.docStatus['invoice_source'] &&
      currentEntity.docStatus['invoice_source']['date'] &&
      currentEntity.invoiceNumberSource &&
      sourceArticles

    let processorInvoiceStatus =
      currentEntity.docStatus &&
      currentEntity.docStatus['invoice_processor'] &&
      currentEntity.docStatus['invoice_processor']['date'] &&
      currentEntity.invoiceNumberProcessor &&
      processorArticles

    if (event.target.name === 'invoice_source' && sourceInvoiceStatus) {
      setOpen(true);
    }

    if (event.target.name === 'invoice_processor' && processorInvoiceStatus) {
      setOpen(true);
    }

    let values = checked;
    values[event.target.name]['status'] = event.target.checked;
    setChecked({ ...values });
  }

  function checkIfDocAlreadySent(name) {
    return (
      Object.keys(currentEntity).length > 0 &&
      currentEntity.docStatus &&
      currentEntity.docStatus[name] &&
      currentEntity.docStatus[name]['status']
    );
  }

  function docSentDate(name) {
    let date = false;
    if (
      currentEntity.docStatus &&
      currentEntity.docStatus[name] &&
      currentEntity.docStatus[name]['date']
    ) {
      date = moment(currentEntity.docStatus[name]['date']).format('DD.MM.YYYY');
    }

    return date ? `(${date})` : date;
  }

  function isDisabled() {
    return (
      checked.annex.status ||
      checked.purchase_confirmation.status ||
      checked.sales_confirmation.status ||
      checked.transport_information.status ||
      checked.invoice_source.status ||
      checked.invoice_processor.status ||
      checked.transport_order.status ||
      checked.assessment.status ||
      checked.billing_confirmation.status ||
      checked.invoice_correction_source.status ||
      checked.invoice_cancellation_processor.status ||
      checked.invoice_correction_processor.status ||
      checked.invoice_cancellation_source.status
    );
  }

  // const email = state.processor ? state.source[0].email : '';

  const handleClose = () => {
    setOpen(false);
    checked.invoice_source.status = false;
    checked.invoice_processor.status = false;
  };

  const handleCreateNew = () => {
    let BASE_URL = getApiUrl();
    let documentData = self.createDocumentProps();

    // deleting unwanted values
    delete documentData.billingConfirmationEmail;

    setCancellationLoading(true);

    let payload = {
      orderId: currentEntity._id,
      documentData,
    };

    if (checked.invoice_processor.status) {
      payload.processor = checked.invoice_processor.status;
    }

    if (checked.invoice_source.status) {
      payload.source = checked.invoice_source.status;
    }

    axios
      .post(`${BASE_URL}/orders/correct/invoice`, payload, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then(
        (res) => {
          self.getOrderDetails();
          setTimeout(() => {
            setCancellationLoading(false);
            handleClose();
          }, 500);
        },
        (error) => {},
      );
  };

  function displaySourceInvoice() {
    const articles = currentEntity.articles;

    let index = articles.findIndex(
      (article) => article.source_cashflow === 'Supplementary Payment',
    );

    return index > -1;
  }

  function displayProcessorInvoice() {
    const articles = currentEntity.articles;

    let index = articles.findIndex((article) => article.processor_cashflow === 'Compensation');

    return index > -1;
  }

  return (
    <Grid container spacing={3} className={'ml-0 mt-16 mb-16'}>
      <Grid xs={12} sm={12} md={3} lg={3}>
        {!checkIfDocAlreadySent('annex') ? (
          <div className={'flex align-center'}>
            <FormGroup row style={{ display: 'inline', margin: 10 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={(checked.annex && checked.annex.status) || false}
                    onChange={handleCheckboxesChange}
                    name={'annex'}
                    disabled={self.currentEntity.articles.length === 0}
                  />
                }
                label={`${props.t('Annex Document')}${props.required ? ' *' : ''} ${
                  docSentDate('annex') ? docSentDate('annex') : ''
                }`}
              />
            </FormGroup>
            <div className={'preview'}>
              <PreviewAnnexDocument
                fetchArticle={self.fetchArticle}
                fetchPartner={self.fetchPartner}
                {...self.createDocumentProps()}
              />
            </div>
          </div>
        ) : (
          <SentDocDate
            name={props.t('Annex Document')}
            date={currentEntity.docStatus.annex.date}
            component={
              <PreviewAnnexDocument
                fetchArticle={self.fetchArticle}
                fetchPartner={self.fetchPartner}
                {...self.createDocumentProps()}
              />
            }
          />
        )}
      </Grid>
      <Grid xs={12} sm={12} md={3} lg={3}>
        {!checkIfDocAlreadySent('purchase_confirmation') ? (
          <div className={'flex align-center'}>
            <FormGroup row style={{ display: 'inline', margin: 10 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.purchase_confirmation.status || false}
                    onChange={handleCheckboxesChange}
                    name={'purchase_confirmation'}
                    disabled={self.currentEntity.articles.length === 0}
                  />
                }
                label={`${props.t(
                  currentEntity.destinationType === 'warehouse'
                    ? props.t('Final Purchase Confirmation')
                    : props.t('Purchase Confirmation'),
                )}${props.required ? ' *' : ''} ${
                  docSentDate('purchase_confirmation') ? docSentDate('purchase_confirmation') : ''
                }`}
              />
            </FormGroup>
            <div className={'preview'}>
              <PreviewPurchaseConfirmation
                {...self.createDocumentProps()}
                title={
                  currentEntity.destinationType === 'warehouse'
                    ? props.t('Final Purchase Confirmation')
                    : props.t('Purchase Confirmation')
                }
              />
            </div>
          </div>
        ) : (
          <SentDocDate
            name={
              currentEntity.destinationType === 'warehouse'
                ? props.t('Final Purchase Confirmation')
                : props.t('Purchase Confirmation')
            }
            date={currentEntity.docStatus.purchase_confirmation.date}
            component={
              <PreviewPurchaseConfirmation
                title={
                  currentEntity.destinationType === 'warehouse'
                    ? props.t('Final Purchase Confirmation')
                    : props.t('Purchase Confirmation')
                }
                {...self.createDocumentProps()}
              />
            }
          />
        )}
      </Grid>
      <Grid xs={12} sm={12} md={3} lg={3}>
        {!checkIfDocAlreadySent('sales_confirmation') ? (
          <div className={'flex align-center'}>
            <FormGroup row style={{ display: 'inline', margin: 10 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.sales_confirmation.status || false}
                    onChange={handleCheckboxesChange}
                    name={'sales_confirmation'}
                    disabled={self.currentEntity.articles.length === 0}
                  />
                }
                label={`${props.t('Sales Confirmation')}${props.required ? ' *' : ''} ${
                  docSentDate('sales_confirmation') ? docSentDate('sales_confirmation') : ''
                }`}
              />
            </FormGroup>
            <div className={'preview'}>
              <PreviewSalesConfirmation {...self.createDocumentProps()} />
            </div>
          </div>
        ) : (
          <SentDocDate
            name={props.t('Sales Confirmation')}
            date={currentEntity.docStatus.sales_confirmation.date}
            component={<PreviewSalesConfirmation {...self.createDocumentProps()} />}
          />
        )}
      </Grid>
      <Grid xs={12} sm={12} md={3} lg={3}>
        {!checkIfDocAlreadySent('transport_information') ? (
          <div className={'flex align-center'}>
            <FormGroup row style={{ display: 'inline', margin: 10 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.transport_information.status || false}
                    onChange={handleCheckboxesChange}
                    name={'transport_information'}
                    disabled={self.currentEntity.articles.length === 0}
                  />
                }
                label={`${props.t('Transportation Information')}${props.required ? ' *' : ''} ${
                  docSentDate('transport_information') ? docSentDate('transport_information') : ''
                }`}
              />
            </FormGroup>
            <div className={'preview'}>
              <PreviewTransportInformation {...self.createDocumentProps()} />
            </div>
          </div>
        ) : (
          <SentDocDate
            name={props.t('Transportation Information')}
            date={currentEntity.docStatus.transport_information.date}
            component={<PreviewTransportInformation {...self.createDocumentProps()} />}
          />
        )}
      </Grid>

      {displaySourceInvoice() && (
        <Grid xs={12} sm={12} md={3} lg={3}>
          {!checkIfDocAlreadySent('invoice_source') ? (
            <div className={'flex align-center'}>
              <FormGroup row style={{ display: 'inline', margin: 10 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked.invoice_source.status || false}
                      onChange={handleCheckboxesChange}
                      name={'invoice_source'}
                      disabled={
                        self.currentEntity.articles.length === 0 ||
                        !props.permissions.invoice_create
                      }
                    />
                  }
                  label={`${props.t('Loading Place Invoice')}${props.required ? ' *' : ''} ${
                    docSentDate('invoice_source') ? docSentDate('invoice_source') : ''
                  }`}
                />
              </FormGroup>
              <div className={'preview'}>
                <PreviewInvoiceSource
                  {...self.createDocumentProps()}
                  permission={props.permissions.invoice_read}
                />
              </div>
            </div>
          ) : (
            <SentDocDate
              name={props.t('Loading Place Invoice')}
              date={currentEntity.docStatus.invoice_source.date}
              component={<PreviewInvoiceSource {...self.createDocumentProps()} />}
            />
          )}
        </Grid>
      )}

      {displayProcessorInvoice() && (
        <Grid xs={12} sm={12} md={3} lg={3}>
          {!checkIfDocAlreadySent('invoice_processor') ? (
            <div className={'flex align-center'}>
              <FormGroup row style={{ display: 'inline', margin: 10 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked.invoice_processor.status || false}
                      onChange={handleCheckboxesChange}
                      name={'invoice_processor'}
                      disabled={
                        self.currentEntity.articles.length === 0 ||
                        !props.permissions.invoice_create
                      }
                    />
                  }
                  label={`${props.t('Unloading Place Invoice')}${props.required ? ' *' : ''} ${
                    docSentDate('invoice_processor') ? docSentDate('invoice_processor') : ''
                  }`}
                />
              </FormGroup>
              <div className={'preview'}>
                <PreviewInvoiceProcessor
                  {...self.createDocumentProps()}
                  permission={props.permissions.invoice_read}
                />
              </div>
            </div>
          ) : (
            <SentDocDate
              name={props.t('Unloading Place Invoice')}
              date={currentEntity.docStatus.invoice_processor.date}
              component={<PreviewInvoiceProcessor {...self.createDocumentProps()} />}
            />
          )}
        </Grid>
      )}

      {currentEntity.cancellationInvoice &&
        currentEntity.cancellationInvoice.source &&
        (!currentEntity.cancellationInvoice.sent_source ? (
          <Grid xs={12} sm={12} md={3} lg={3}>
            <div className={'flex align-center'}>
              <FormGroup row style={{ display: 'inline', margin: 10 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        (checked.invoice_cancellation_source &&
                          checked.invoice_cancellation_source.status) ||
                        false
                      }
                      onChange={handleCheckboxesChange}
                      name={'invoice_cancellation_source'}
                      disabled={self.currentEntity.articles.length === 0}
                    />
                  }
                  label={`${props.t('invoice_cancellation_loading_place')}${
                    props.required ? ' *' : ''
                  } (${moment(currentEntity.cancellationInvoice.updatedAt).format('DD.MM.YYYY')})`}
                />
              </FormGroup>
              <div className={'preview'}>
                <PreviewInvoiceCancellationSource
                  {...currentEntity.cancellationInvoice.order_details_source}
                  newInvoiceNumber={props.currentEntity.cancellationInvoiceNumberSource}
                />
              </div>
            </div>
          </Grid>
        ) : (
          <Grid xs={12} sm={12} md={3} lg={3}>
            <div className={'flex align-center'}>
              <SentDocDate
                name={props.t('invoice_cancellation_loading_place')}
                date={currentEntity.cancellationInvoice.updatedAt}
                component={
                  <PreviewInvoiceCancellationSource
                    {...currentEntity.cancellationInvoice.order_details_source}
                    newInvoiceNumber={props.currentEntity.cancellationInvoiceNumberSource}
                  />
                }
              />
            </div>
          </Grid>
        ))}

      {currentEntity.cancellationInvoice &&
        currentEntity.cancellationInvoice.processor &&
        (!currentEntity.cancellationInvoice.sent_processor ? (
          <Grid xs={12} sm={12} md={3} lg={3}>
            <div className={'flex align-center'}>
              <FormGroup row style={{ display: 'inline', margin: 10 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        (checked.invoice_cancellation_processor &&
                          checked.invoice_cancellation_processor.status) ||
                        false
                      }
                      onChange={handleCheckboxesChange}
                      name={'invoice_cancellation_processor'}
                      disabled={self.currentEntity.articles.length === 0}
                    />
                  }
                  label={`${props.t('invoice_cancellation_unloading_place')}${
                    props.required ? ' *' : ''
                  } (${moment(currentEntity.cancellationInvoice.updatedAt).format('DD.MM.YYYY')})`}
                />
              </FormGroup>
              <div className={'preview'}>
                <PreviewInvoiceCancellationProcessor
                  {...currentEntity.cancellationInvoice.order_details_processor}
                  newInvoiceNumber={props.currentEntity.cancellationInvoiceNumberProcessor}
                />
              </div>
            </div>
          </Grid>
        ) : (
          <Grid xs={12} sm={12} md={3} lg={3}>
            <div className={'flex align-center'}>
              <SentDocDate
                name={props.t('invoice_cancellation_unloading_place')}
                date={currentEntity.cancellationInvoice.updatedAt}
                component={
                  <PreviewInvoiceCancellationProcessor
                    {...currentEntity.cancellationInvoice.order_details_processor}
                    newInvoiceNumber={props.currentEntity.cancellationInvoiceNumberProcessor}
                  />
                }
              />
            </div>
          </Grid>
        ))}

      {currentEntity.correctionInvoice &&
        currentEntity.correctionInvoice.source &&
        (!currentEntity.correctionInvoice.sent_source ? (
          <Grid xs={12} sm={12} md={3} lg={3}>
            <div className={'flex align-center'}>
              <FormGroup row style={{ display: 'inline', margin: 10 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        (checked.invoice_correction_source &&
                          checked.invoice_correction_source.status) ||
                        false
                      }
                      onChange={handleCheckboxesChange}
                      name={'invoice_correction_source'}
                      disabled={self.currentEntity.articles.length === 0}
                    />
                  }
                  label={`${props.t('initial_invoice_loading_place')}${
                    props.required ? ' *' : ''
                  } (${moment(currentEntity.correctionInvoice.updatedAt).format('DD.MM.YYYY')})`}
                />
              </FormGroup>
              <div className={'preview'}>
                <PreviewInvoiceCorrectionSource
                  {...currentEntity.correctionInvoice.order_details_source}
                />
              </div>
            </div>
          </Grid>
        ) : (
          <Grid xs={12} sm={12} md={3} lg={3}>
            <div className={'flex align-center'}>
              <SentDocDate
                name={props.t('initial_invoice_loading_place')}
                date={currentEntity.correctionInvoice.updatedAt}
                component={
                  <PreviewInvoiceCorrectionSource
                    {...currentEntity.correctionInvoice.order_details_source}
                  />
                }
              />
            </div>
          </Grid>
        ))}

      {currentEntity.correctionInvoice &&
        currentEntity.correctionInvoice.processor &&
        (!currentEntity.correctionInvoice.sent_processor ? (
          <Grid xs={12} sm={12} md={3} lg={3}>
            <div className={'flex align-center'}>
              <FormGroup row style={{ display: 'inline', margin: 10 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        (checked.invoice_correction_processor &&
                          checked.invoice_correction_processor.status) ||
                        false
                      }
                      onChange={handleCheckboxesChange}
                      name={'invoice_correction_processor'}
                      disabled={self.currentEntity.articles.length === 0}
                    />
                  }
                  label={`${props.t('initial_invoice_unloading_place')}${
                    props.required ? ' *' : ''
                  } (${moment(currentEntity.correctionInvoice.updatedAt).format('DD.MM.YYYY')})`}
                />
              </FormGroup>
              <div className={'preview'}>
                <PreviewInvoiceCorrectionProcessor
                  {...currentEntity.correctionInvoice.order_details_processor}
                />
              </div>
            </div>
          </Grid>
        ) : (
          <Grid xs={12} sm={12} md={3} lg={3}>
            <div className={'flex align-center'}>
              <SentDocDate
                name={props.t('initial_invoice_unloading_place')}
                date={currentEntity.correctionInvoice.updatedAt}
                component={
                  <PreviewInvoiceCorrectionProcessor
                    {...currentEntity.correctionInvoice.order_details_processor}
                  />
                }
              />
            </div>
          </Grid>
        ))}

      <Grid xs={12} sm={12} md={3} lg={3}>
        {!checkIfDocAlreadySent('transport_order') ? (
          <div className={'flex align-center'}>
            <FormGroup row style={{ display: 'inline', margin: 10 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.transport_order.status || false}
                    onChange={handleCheckboxesChange}
                    name={'transport_order'}
                    disabled={self.currentEntity.articles.length === 0}
                  />
                }
                label={`${props.t('Transportation Order')}${props.required ? ' *' : ''} ${
                  docSentDate('transport_order') ? docSentDate('transport_order') : ''
                }`}
              />
            </FormGroup>
            <div className={'preview'}>
              <PreviewTransportOrder {...self.createDocumentProps()} />
            </div>
          </div>
        ) : (
          <SentDocDate
            name={props.t('Transportation Order')}
            date={currentEntity.docStatus.transport_order.date}
            component={<PreviewTransportOrder {...self.createDocumentProps()} />}
          />
        )}
      </Grid>
      {/* <!--- Billing confirmation --> */}

      <Grid xs={12} sm={12} md={3} lg={3}>
        {!checkIfDocAlreadySent('billing_confirmation') ? (
          <div className={'flex align-center'}>
            <FormGroup row style={{ display: 'inline', margin: 10 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.billing_confirmation.status || false}
                    onChange={handleCheckboxesChange}
                    name={'billing_confirmation'}
                    disabled={self.currentEntity.articles.length === 0}
                  />
                }
                label={`${props.t('Billing confirmation')}${props.required ? ' *' : ''} ${
                  docSentDate('billing_confirmation') ? docSentDate('billing_confirmation') : ''
                }`}
              />
            </FormGroup>
            <div className={'preview'}>
              <PreviewBillingConfirmation {...self.createDocumentProps()} />
            </div>
          </div>
        ) : (
          <SentDocDate
            name={props.t('Billing confirmation')}
            date={currentEntity.docStatus.billing_confirmation.date}
            component={<PreviewBillingConfirmation {...self.createDocumentProps()} />}
          />
        )}
      </Grid>
      {/* <!--- Billing confirmation --> */}

      {props.currentEntity.commissionArticles.length > 0 && (
        <React.Fragment>
          <Grid xs={12} sm={12} md={3} lg={3}>
            {!checkIfDocAlreadySent('commission_invoice') ? (
              <div className={'flex align-center'}>
                <FormGroup row style={{ display: 'inline', margin: 10 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked.commission_invoice || false}
                        onChange={handleCheckboxesChange}
                        name={'commission_invoice'}
                        disabled={self.currentEntity.articles.length === 0}
                      />
                    }
                    label={`${props.t('Commission Invoice')}${props.required ? ' *' : ''} ${
                      docSentDate('commission_invoice') ? docSentDate('commission_invoice') : ''
                    }`}
                  />
                </FormGroup>
                <div className={'preview'}>
                  <CommissionInvoiceDialog
                    currentEntity={props.currentEntity}
                    {...self.createDocumentProps()}
                  />
                </div>
              </div>
            ) : (
              <SentDocDate
                name={props.t('Commission Invoice')}
                date={currentEntity.docStatus.commission_invoice.date}
                component={
                  <CommissionInvoiceDialog
                    currentEntity={props.currentEntity}
                    {...self.createDocumentProps()}
                  />
                }
              />
            )}
          </Grid>

          <Grid xs={12} sm={12} md={3} lg={3}>
            {!checkIfDocAlreadySent('commission_confirmation') ? (
              <div className={'flex align-center'}>
                <FormGroup row style={{ display: 'inline', margin: 10 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked.commission_confirmation || false}
                        onChange={handleCheckboxesChange}
                        name={'commission_confirmation'}
                        disabled={self.currentEntity.articles.length === 0}
                      />
                    }
                    label={`${props.t('Commission Confirmation')}${props.required ? ' *' : ''} ${
                      docSentDate('commission_confirmation')
                        ? docSentDate('commission_confirmation')
                        : ''
                    }`}
                  />
                </FormGroup>
                <div className={'preview'}>
                  <CommissionConfirmationDialog
                    title={props.t('Commission Confirmation')}
                    currentEntity={props.currentEntity}
                    {...self.createDocumentProps()}
                  />
                </div>
              </div>
            ) : (
              <SentDocDate
                name={props.t('Commission Confirmation')}
                date={currentEntity.docStatus.commission_confirmation.date}
                component={
                  <CommissionConfirmationDialog
                    title={props.t('Commission Confirmation')}
                    currentEntity={props.currentEntity}
                    {...self.createDocumentProps()}
                  />
                }
              />
            )}
          </Grid>
        </React.Fragment>
      )}

      {currentEntity.destinationType === 'warehouse' && (
        <React.Fragment>
          <Grid xs={12} sm={12} md={3} lg={3}>
            {!checkIfDocAlreadySent('assessment') ? (
              <div className={'flex align-center'}>
                <FormGroup row style={{ display: 'inline', margin: 10 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked.assessment || false}
                        onChange={handleCheckboxesChange}
                        name={'assessment'}
                        disabled={self.currentEntity.articles.length === 0}
                      />
                    }
                    label={`${props.t('Assessment')}${props.required ? ' *' : ''} ${
                      docSentDate('assessment') ? docSentDate('assessment') : ''
                    }`}
                  />
                </FormGroup>
                <div className={'preview'}>
                  <AssessmentDialog articleTypes={articleTypes} {...self.createDocumentProps()} />
                </div>
              </div>
            ) : (
              <SentDocDate
                name={props.t('Assessment')}
                date={currentEntity.docStatus.assessment.date}
                component={
                  <AssessmentDialog articleTypes={articleTypes} {...self.createDocumentProps()} />
                }
              />
            )}
          </Grid>

          <Grid xs={12} sm={12} md={3} lg={3}>
            {!checkIfDocAlreadySent('preliminary_purchase_confirmation') ? (
              <div className={'flex align-center'}>
                <FormGroup row style={{ display: 'inline', margin: 10 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked.preliminary_purchase_confirmation || false}
                        onChange={handleCheckboxesChange}
                        name={'preliminary_purchase_confirmation'}
                        disabled={self.currentEntity.articles.length === 0}
                      />
                    }
                    label={`${props.t('Preliminary Purchase Confirmation')}${
                      props.required ? ' *' : ''
                    } ${
                      docSentDate('preliminary_purchase_confirmation')
                        ? docSentDate('preliminary_purchase_confirmation')
                        : ''
                    }`}
                  />
                </FormGroup>
                <div className={'preview'}>
                  <PreliminaryPurchaseConfirmation {...self.createDocumentProps()} />
                </div>
              </div>
            ) : (
              <SentDocDate
                name={props.t('Preliminary Purchase Confirmation')}
                date={currentEntity.docStatus.preliminary_purchase_confirmation.date}
                component={<PreliminaryPurchaseConfirmation {...self.createDocumentProps()} />}
              />
            )}
          </Grid>
        </React.Fragment>
      )}

      <Grid xs={12} sm={12} md={3} lg={3}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          style={{
            margin: 10,
          }}
          disabled={!isDisabled()}
          onClick={() => self.setState({ sendorderdocument: true })}
        >
          {t('Send')}
        </Button>
      </Grid>
      {state.sendorderdocument === true ? (
        <span>
          <OnAddOrderPopup
            closeDialogCancel={async (reset) => {
              reset();
              self.setState({ sendorderdocument: false });
              handleClose();
            }}
            closeDialogOk={async (data, reset) => {
              reset();
              self.setState({ docSendLoading: true });
              setLoading(true);
              await sendDocument(currentEntity._id, data, self.props.i18n.language);
              self.getOrderDetails();

              setTimeout(() => {
                setLoading(false);
                self.setState({ sendorderdocument: false, docSendLoading: false });
                handleClose();
              }, 300);
            }}
            loading={loading}
            setLoading={setLoading}
            state={self.state}
            checked={checked}
            createDocumentProps={self.createDocumentProps}
            fetchArticle={self.fetchArticle}
            fetchPartner={self.fetchPartner}
            currentEntity={currentEntity}
            checkIfDocAlreadySent={checkIfDocAlreadySent}
          />
        </span>
      ) : (
        ''
      )}
      <Dialog fullWidth open={open} aria-labelledby="draggable-dialog-title">
        <AppBar position="static">
          <Toolbar className="flex w-full">
            <Typography variant="subtitle1" color="inherit">
              {t('Attention')}
            </Typography>
          </Toolbar>
        </AppBar>
        {cancellationLoading && <LinearProgress color="secondary" />}
        <DialogContent>{t('invoice_correction_message')}</DialogContent>
        <DialogActions>
          <Button style={{ color: 'red' }} onClick={handleClose}>
            {t('Cancel')}
          </Button>
          <Button
            color="secondary"
            className="anti-bootstrap-button"
            onClick={handleCreateNew}
            autoFocus={true}
          >
            {t('Correct invoice')}
          </Button>
          <Button
            color="secondary"
            className="anti-bootstrap-button"
            onClick={() => self.setState({ sendorderdocument: true })}
            autoFocus={true}
          >
            {t('Send anyway')}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default SendDocs;
