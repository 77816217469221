import * as Actions from '../actions/partners';

const initialState = {
  openingHours: [
    {
      start_day: '',
      end_day: '',
      start_time: '00:00',
      end_time: '00:00',
      start_time1: '00:00',
      end_time1: '00:00',
    },
    {
      start_day: '',
      end_day: '',
      start_time: '00:00',
      end_time: '00:00',
      start_time1: '00:00',
      end_time1: '00:00',
    },
  ],
  locations: [],
  locationForm: {
    name: '',
    street: '',
    houseNumber: '',
    city: '',
    zipcode: '',
    country: '',
  },
};

const partners = function (state = initialState, action) {
  switch (action.type) {
    case Actions.RESET: {
      return {
        ...state,
        openingHours: [
          {
            start_day: '',
            end_day: '',
            start_time: '00:00',
            end_time: '00:00',
            start_time1: '00:00',
            end_time1: '00:00',
          },
          {
            start_day: '',
            end_day: '',
            start_time: '00:00',
            end_time: '00:00',
            start_time1: '00:00',
            end_time1: '00:00',
          },
        ],
      };
    }
    case Actions.RESET_LOCATIONS: {
      return {
        ...state,
        locations: [],
      };
    }
    case Actions.SET_OPENING_HOURS: {
      return {
        ...state,
        openingHours: [...action.payload],
      };
    }
    case Actions.ADD_LOCATION: {
      let locations = state.locations;

      locations.push({
        name: '',
        street: '',
        houseNumber: '',
        city: '',
        zipcode: '',
        country: '',
      });

      return {
        ...state,
        locations: [...locations],
      };
    }
    case Actions.REMOVE_LOCATION: {
      let locations = state.locations;
      locations.splice(action.payload, 1);

      return {
        ...state,
        locations: [...locations],
      };
    }
    case Actions.SET_LOCATION_VALUES: {
      return {
        ...state,
        locations: [...action.payload],
      };
    }
    default: {
      return state;
    }
  }
};

export default partners;
