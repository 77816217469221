import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Dropzone from "react-dropzone";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import Grid from "@material-ui/core/Grid";

function UploadSignatures({ self }) {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Paper style={{ padding: 12, height: "100%" }}>
          <Typography variant="h4" style={{ paddingBottom: 30 }}>
            {self.props.t("Upload Signature")}
          </Typography>
          <Dropzone
            onDrop={(event) => self.onDrop(event, 'signatures')}
            maxSize={10 * 1024 * 1024}
            onDropRejected={() => alert(self.props.t("file_size_limit"))}
          >
            {({ getRootProps, getInputProps }) => (
              <section className="dropzone-container">
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop signatures here, or click to select file</p>
                </div>
                {self.state.signatures && (
                  <aside>
                    <h4>{self.props.t("Files")}</h4>
                    <ul>
                      <li>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            className={"file-link"}
                            onClick={() => self.handleFileDownload(self.state.signatures, 'signatures')}
                          >
                            {self.state.signatures}
                          </p>
                          <Button
                            startIcon={<DeleteIcon />}
                            variant="outlined"
                            style={{
                              color: "red",
                              backgroundColor: "white",
                              float: "right",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              self.deleteAttachment('signatures');
                            }}
                          ></Button>
                        </div>
                      </li>
                    </ul>
                  </aside>
                )}
              </section>
            )}
          </Dropzone>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}

export default UploadSignatures;
