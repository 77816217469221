import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { formatDecimals } from "../../../helpers/common";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function Overview(props) {
  const { self } = props
  const classes = useStyles();
  const { state, getTotal } = props;

  function getTotalPrice(type) {
    let totalPrice = 0;

    for (let data of props.state[type]) {
      totalPrice += parseFloat(data.price_per_quality);
    }

    return formatDecimals(totalPrice);
  }

  function getOverallTotal(type) {
    const val = getTotalPrice(type);
    return formatDecimals(val - getTotal());
  }

  function getOverallAddedValue() {
    return formatDecimals(getOverallTotal("confirmed") - getOverallTotal("receive"));
  }

  function getResultPerTon(type) {
    const val = state.total > 0 ? (getOverallTotal(type) / state.total) * 1000 : 0;
    return formatDecimals(val);
  }

  function getResultPerTonAddedValue() {
    return formatDecimals(getResultPerTon("confirmed") - getResultPerTon("receive"));
  }

  function getPricePerTon(type) {
    const val = state.total > 0 ? (getTotalPrice(type) / state.total) * 1000 : 0;
    return formatDecimals(val);
  }

  function getPricePerTonAddedValue() {
    return formatDecimals(getPricePerTon("confirmed") - getPricePerTon("receive"));
  }

  return (
    <React.Fragment>
      <Typography variant="h4">{self.props.t(props.title)}</Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow style={{ background: "#EAEEF1" }}>
              <TableCell className={"bold"}>{self.props.t('Value')}</TableCell>
              <TableCell className={"bold"}>{self.props.t('Before')}</TableCell>
              <TableCell className={"bold"}>{self.props.t('Afterwards')}</TableCell>
              <TableCell className={"bold"}>{self.props.t('Added Value')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row" className={"bold table-data-color"}>
                {self.props.t('Overall result')}
              </TableCell>
              <TableCell>{getOverallTotal("receive")}</TableCell>
              <TableCell>{getOverallTotal("confirmed")}</TableCell>
              <TableCell
                className={parseFloat(getOverallAddedValue()) > 0 ? "active-color" : "red-color"}
              >
                {getOverallAddedValue()}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row" className={"bold table-data-color"}>
                {self.props.t('Result per tonne')}

              </TableCell>
              <TableCell>{getResultPerTon("receive")}</TableCell>
              <TableCell>{getResultPerTon("confirmed")}</TableCell>
              <TableCell
                className={
                  parseFloat(getResultPerTonAddedValue()) > 0 ? "active-color" : "red-color"
                }
              >
                {getResultPerTonAddedValue()}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row" className={"bold table-data-color"}>
                {self.props.t('EK price per ton')}

              </TableCell>
              <TableCell>{getPricePerTon("receive")}</TableCell>
              <TableCell>{getPricePerTon("confirmed")}</TableCell>
              <TableCell
                className={
                  parseFloat(getPricePerTonAddedValue()) > 0 ? "active-color" : "red-color"
                }
              >
                {getPricePerTonAddedValue()}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}
