import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import MaterialTable from 'material-table';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import { deletePerson } from '../../redux/modules/person';
import withConfirm from '../dialogs/delete';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && children}
    </div>
  );
}

function PersonSelectTabs({
  onAdd,
  onSelect,
  loaded,
  allPersons,
  t,
  onSave,
  onEdit,
  reload,
  onRemove,
  confirm,
}) {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [staticData, setStaticData] = useState([]);
  const [showAdd, setShowAdd] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getData = (data) => {
    let updatedData = [];
    for (let d of data) {
      let roles = [];
      for (let role of d.role) {
        roles.push(t(role));
      }

      d.salutation = t(d.salutation);
      d.role = roles;
      updatedData.push(d);
    }

    return updatedData;
  };

  const uncheck = (data) => {
    const result = data.map((item) => {
      const unchecked = { ...item };
      if (unchecked.tableData) unchecked.tableData.checked = false;
      return unchecked;
    });
    return result;
  };

  useEffect(() => {
    setStaticData(uncheck(loaded));
  }, [loaded]);

  function resetAndShowAlert(message) {
    alert(message);
  }

  return (
    <React.Fragment>
      <Tabs value={value} onChange={handleChange}>
        <Tab label={t('Current')} />
        <Tab label={t('Select Team')} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <MaterialTable
          localization={{
            pagination: {
              labelRowsSelect: `${t('rows')}`,
              labelDisplayedRows: `{from}-{to} ${t('of')} {count}`,
            },
            toolbar: {
              nRowsSelected: `{0} ${t('row(s)')} ${t('selected')}`,
              searchTooltip: t('Search'),
              searchPlaceholder: t('Search'),
            },
            header: {
              actions: t('Actions'),
            },
            body: {
              emptyDataSourceMessage: t('No records to display'),
              filterRow: {
                filterTooltip: t('Filter'),
              },
            },
          }}
          actions={[
            {
              icon: 'add',
              tooltip: 'Add',
              isFreeAction: true,
              onClick: () => onAdd(),
            },
            (rowData) => ({
              icon: rowData.type === 'Intern' ? '' : 'edit',
              position: 'row',
              disabled: rowData.type === 'Intern',
              onClick: (event, rowData) => {
                event.stopPropagation();
                onEdit(rowData);
                },
            }),
            (rowData) => ({
              icon: rowData.type === 'Intern' ? '' : 'delete',
              position: 'row',
              disabled: rowData.type === 'Intern',
              onClick: (event, rowData) => {
                event.stopPropagation();
                confirm(
                  (e) => {
                    if (rowData.type === 'Intern') {
                      onRemove(rowData._id);
                    } else {
                      dispatch(deletePerson(rowData._id, resetAndShowAlert));
                    }
                    reload();
                  },
                  {
                    description: 'Are you sure?',
                  },
                );
              },
            }),
          ]}
          columns={[
            { title: t('Salutation'), field: 'salutation' },
            { title: t('First Name'), field: 'firstName' },
            { title: t('Surname'), field: 'surname' },
            {
              title: t('Role'),
              field: 'role',
              render: (rowData) => rowData.role.join(', '),
            },
            { title: t('Department'), field: 'department' },
            { title: t('Phone'), field: 'phone' },
            { title: t('E-mail'), field: 'emails' },
          ]}
          data={getData(loaded)}
          title={''}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {showAdd && (
          <Button
            variant={'contained'}
            size={'large'}
            onClick={() => {
              setShowAdd(false);
              onSave();
            }}
            color="primary"
            style={{ marginBottom: '12px',backgroundColor:'white',color:'green' }}
          >
            Add
          </Button>
        )}
        <MaterialTable
          localization={{
            pagination: {
              labelRowsSelect: `${t('rows')}`,
              labelDisplayedRows: `{from}-{to} ${t('of')} {count}`,
            },
            toolbar: {
              nRowsSelected: `{0} ${t('row(s)')} ${t('selected')}`,
              searchTooltip: t('Search'),
              searchPlaceholder: t('Search'),
            },
            header: {
              actions: t('Actions'),
            },
            body: {
              emptyDataSourceMessage: t('No records to display'),
              filterRow: {
                filterTooltip: t('Filter'),
              },
            },
          }}
          actions={[]}
          options={{
            selection: true,
          }}
          onSelectionChange={(rows) => {
            if (rows.length > 0) {
              setShowAdd(true);
            }
            let selected = [...rows];
            let updated = loaded.concat(selected);
            onSelect(updated);
          }}
          columns={[
            { title: t('Salutation'), field: 'salutation' },
            { title: t('First Name'), field: 'firstName' },
            { title: t('Surname'), field: 'surname' },
            {
              title: t('Role'),
              field: 'role',
              render: (rowData) => rowData.role.join(', '),
            },
            { title: t('Department'), field: 'department' },
            { title: t('Phone'), field: 'phone' },
            { title: t('E-mail'), field: 'emails' },
          ]}
          data={getData(allPersons)}
          title={''}
        />
      </TabPanel>
    </React.Fragment>
  );
}

export default withConfirm(withTranslation('common')(PersonSelectTabs));
