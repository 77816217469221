import React from 'react';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { SYSTEM_NAVIGATION_ROUTES } from '../../../constants/ui-constants';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ArticleInputFieldData from '../../../Schemas/AddArticle.json';
import TextInput from '../../Inputs/TextInput';
import MultiChoice from '../../Inputs/MultiChoice';

function Article({ self }) {
  return (
    <div style={{ flexGrow: 1 }}>
      <Button
        variant="contained"
        color="primary"
        startIcon={<SaveIcon />}
        style={{ margin: 10, float: 'left',color: 'green',backgroundColor: 'white', }}
        onClick={() => self.props.editArticle(self.currentEntity._id, self.state)}
      >
        {`${self.props.t('Save')} ${self.props.t(
          SYSTEM_NAVIGATION_ROUTES[self.props.currentSubTab],
        )}`}
      </Button>
      <Button
        variant="contained"
        startIcon={<CancelIcon />}
        style={{ margin: 10, float: 'left', backgroundColor: 'white', color: 'red' }}
        onClick={() => self.props.cancelBtn()}
      >
        {self.props.t('Cancel')}
      </Button>
      <form noValidate autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper style={{ padding: 12, height: '100%' }}>
              <Typography variant="h4" style={{ paddingBottom: 30 }}>
                {self.props.t(self.state.header0)} <span style={{ fontWeight: 'normal' }}>{self.currentEntity.articleNumber ? self.currentEntity.articleNumber : ''}</span>
              </Typography>
              {Object.keys(ArticleInputFieldData['Article Information']).map((key, index) => {
                let field = ArticleInputFieldData['Article Information'][key];
                if (self.state[field.dbid] === undefined && self.currentEntity[field.dbid]) {
                  self.setState({ [field.dbid]: self.currentEntity[field.dbid] });
                }

                return field.type === 'TextInput' ? (
                  <TextInput
                    key={index}
                    style={{ margin: 10, width: 300 }}
                    defaultValue={self.currentEntity ? self.currentEntity[field.dbid] : ''}
                    change={(e) => {
                      const { value } = e.target;
                      self.setState({ [field.dbid]: value });
                    }}
                    name={field.label}
                    required={
                      self.state.itemType === 'Product' && (field.dbid === 'basalAnnexIX' || field.dbid === 'basalCodeEu')
                        ? false
                        : self.state.itemType === 'Service' &&
                          (field.dbid === 'basalAnnexIX' || field.dbid === 'basalCodeEu' || field.dbid === 'hsCode')
                        ? false
                        : self.state.itemType === 'Product' && field.dbid === 'avv' ? true : field.required
                    }
                    label={self.props.t(field.label)}
                    dbid={field.dbid}
                    type={'text'}
                  />
                ) : field.type === 'MultiChoice' ? (
                  <MultiChoice
                    key={field.label}
                    id={field.id}
                    dbid={field.dbid}
                    value={self.currentEntity ? self.currentEntity[field.dbid] : ''}
                    options={field.dbid === 'vehicle' ? self.state.vehicleTypes : field.options}
                    callbackValue={self.handleMultiChoiceChange}
                    required={field.required}
                    name={field.label}
                    label={field.label}
                    isEdit={true}
                  />
                ) : null;
              })}
            </Paper>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default Article;
