import React from 'react';
import moment from 'moment';
import {
  getCompanyAddress,
  getDates,
  getGermanCollectionValues, getLanguageAcronym,
  getOpeningHours,
  getRecipient,
  getUnloadingPartner,
} from '../../helpers/documents';
import { getApiUrl } from '../../util/environment-utils';
import { withTranslation } from 'react-i18next';
import Footer from './footer';

class TransportationInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date(),
      apiUrl: getApiUrl(),
      unloadingPartner: null,
    };
  }

  getUnloadingPartnerDetails() {
    let partner = getUnloadingPartner(
      this.props.sourceData,
      this.props.processorData,
      this.props.currentEntity,
    );

    return partner;
  }

  getLanguage = () => {
    let defaultLanguage = 'de';
    let language = this.props.carrierData
      ? this.props.carrierData
        ? this.props.carrierData.documentLanguage
        : defaultLanguage
      : defaultLanguage;

    return getLanguageAcronym(language);
  };

  render() {
    const language = this.getLanguage();

    if (language === 'de') {
      return (
        <div className={'transport-order-document-container'}>
          <div className={'doc-logo'}>
            <img
              src={`${this.state.apiUrl}/companys/image/${this.props.companyData.companyLogo}`}
              width={'140'}
            />
          </div>
          {/* carrier */}
          <div className={'carrier-container'}>
            <div>
              <div className="font-10 border-bottom-solid mg-bottom-14">
                <p
                  className={'mg-0'}
                >{`${this.props.companyName}, ${this.props.companyAddress}`}</p>
              </div>
              <span
                dangerouslySetInnerHTML={{
                  __html: getRecipient(this.props, 'transportation', language),
                }}
              ></span>
            </div>
            <div className={'right-section'}>
              <div className="font-12">{`Kontakt: ${this.props.firstName} ${this.props.surName}`}</div>
              <div className="font-12">{`Telefon: ${this.props.phone}`}</div>
              <div className="font-12">{`E-Mail: ${this.props.email}`}</div>
              <div className="font-12">{`Datum: ${moment(this.state.today).format(
                'DD.MM.YYYY',
              )}`}</div>
            </div>
          </div>

          <div className={'padding-top-12 font-12 bold'}>
            <p className={'font-14 bold'}>Transportinformationen</p>
            Dispo-Nummer: {this.props.dispositionNumber}
          </div>

          <div className={'padding-top-12 flex justify-space-between'}>
            <div className={'w-full'}>
              <div className="title font-14 bold title-container">Sorte/Warenbezeichnung</div>

              {this.props.currentEntity.articles.map((article, index) => (
                <div className="font-12 padding-top-5" key={index}>
                  <p className={'mg-0 font-12 bold'}>
                    {article.processor_deviating && article.processor_deviating !== ''
                      ? `${article.processor_deviating}`
                      : this.props.i18n.language === 'en'
                      ? article.articleDetails.descriptionInEnglish
                      : article.articleDetails.descriptionInGerman}
                  </p>
                  <p className={'mg-0'}>{article.processor_condition}</p>
                </div>
              ))}
            </div>
          </div>

          <div className={'padding-top-12 flex justify-space-between page-avoid'}>
            <div className={'w-full'}>
              <div className="title font-14 bold title-container">Ladedaten</div>
              <div className="font-12 padding-top-5">
                <p className={'mg-0'}>
                  <b>Ladestelle:</b>{' '}
                  {`${
                    this.props.sourceData.locationName
                      ? this.props.sourceData.locationName
                      : this.props.sourceData.name1
                  }, `}{' '}
                  {getCompanyAddress(this.props.sourceData, language)}
                </p>
                <p className={'mg-0'}>
                  <b>{getGermanCollectionValues(this.props.currentEntity.collectionSource)}:</b>{' '}
                  {getDates(this.props.currentEntity.pickupDate)}
                </p>
                <p className={'mg-0'}>
                  <b>Ladenummer:</b> {this.props.currentEntity.loadingReferenceSource}
                </p>
                <p className={'mg-0'}>
                  <b>EfactNR:</b> {this.props.sourceData.eFact}
                </p>
              </div>
            </div>

            <div className={'min-width-140 page-avoid text-right'}>
              <div className="title font-14 bold title-container">Öffnungszeiten</div>
              {this.props.sourceData.openingHours && this.props.sourceData.openingHours[0] && (
                <div
                  className={'font-12'}
                  dangerouslySetInnerHTML={{
                    __html: getOpeningHours(this.props.sourceData.openingHours[0], language),
                  }}
                />
              )}

              {this.props.sourceData.openingHours && this.props.sourceData.openingHours[1] && (
                <div
                  className={'font-12'}
                  dangerouslySetInnerHTML={{
                    __html: getOpeningHours(this.props.sourceData.openingHours[1], language),
                  }}
                />
              )}
            </div>
          </div>

          <div className={'padding-top-12 flex justify-space-between page-avoid'}>
            <div className={'w-full'}>
              <div className="title font-14 bold title-container">
                {this.getUnloadingPartnerDetails()
                  ? this.getUnloadingPartnerDetails().transport
                  : 'Entladedaten'}
              </div>
              <div className="font-12 padding-top-5">
                <p className={'mg-0'}>
                  {this.getUnloadingPartnerDetails() && (
                    <React.Fragment>
                      <b>{this.getUnloadingPartnerDetails().heading}:</b>{' '}
                      {`${
                        this.getUnloadingPartnerDetails().partner.locationName
                          ? this.getUnloadingPartnerDetails().partner.locationName
                          : this.getUnloadingPartnerDetails().partner.name1
                      }, `}{' '}
                      {getCompanyAddress(
                        this.getUnloadingPartnerDetails().partner,
                        language,
                      )}
                    </React.Fragment>
                  )}
                </p>
                <p className={'mg-0'}>
                  <b>{getGermanCollectionValues(this.props.currentEntity.deliveryProcess)}:</b>{' '}
                  {getDates(this.props.currentEntity.deliveryDate)}
                </p>
                <p className={'mg-0'}>
                  <b>
                    {' '}
                    {this.getUnloadingPartnerDetails().heading === 'Entladestelle'
                      ? 'Entladenummer'
                      : 'Ladenummer'}
                    :
                  </b>{' '}
                  {this.props.currentEntity.loadingReferenceProcessor}
                </p>
                <p className={'mg-0'}>
                  <b>EfactNR:</b> {this.props.processorData.eFact}
                </p>
              </div>
            </div>

            <div className={'min-width-140 page-avoid text-right'}>
              <div className="title font-14 bold title-container">Öffnungszeiten</div>
              {this.props.processorData.openingHours && this.props.processorData.openingHours[0] && (
                <div
                  className={'font-12'}
                  dangerouslySetInnerHTML={{
                    __html: getOpeningHours(this.props.processorData.openingHours[0], language),
                  }}
                />
              )}

              {this.props.processorData.openingHours && this.props.processorData.openingHours[1] && (
                <div
                  className={'font-12'}
                  dangerouslySetInnerHTML={{
                    __html: getOpeningHours(this.props.processorData.openingHours[1], language),
                  }}
                />
              )}
            </div>
          </div>

          <div className={'padding-top-12 page-avoid'}>
            <p className={'font-12'}>Bei Fragen, stehen wir Ihnen gerne zur Verfügung.</p>
            <p className={'font-12 padding-top-12'}>Mit freundlichen Grüßen</p>
            <p className={'font-12'}>Ihr {this.props.companyData.name2} Team</p>
          </div>

          <Footer {...this.props} />
        </div>
      );
    } else {
      return (
        <div className={'transport-order-document-container'}>
          <div className={'doc-logo'}>
            <img
              src={`${this.state.apiUrl}/companys/image/${this.props.companyData.companyLogo}`}
              width={'140'}
            />
          </div>
          {/* carrier */}
          <div className={'carrier-container'}>
            <div>
              <div className="font-10 border-bottom-solid mg-bottom-14">
                <p
                  className={'mg-0'}
                >{`${this.props.companyName}, ${this.props.companyAddress}`}</p>
              </div>
              <span
                dangerouslySetInnerHTML={{
                  __html: getRecipient(this.props, 'transportation', language),
                }}
              ></span>
            </div>
            <div className={'right-section'}>
              <div className="font-12">{`Contact: ${this.props.firstName} ${this.props.surName}`}</div>
              <div className="font-12">{`Phone: ${this.props.phone}`}</div>
              <div className="font-12">{`E-Mail: ${this.props.email}`}</div>
              <div className="font-12">{`Date: ${moment(this.state.today).format(
                'DD.MM.YYYY',
              )}`}</div>
            </div>
          </div>

          <div className={'padding-top-12 font-12 bold'}>
            <p className={'font-14 bold'}>Transport Information</p>
            Order Number: {this.props.dispositionNumber}
          </div>

          <div className={'padding-top-12 flex justify-space-between'}>
            <div className={'w-full'}>
              <div className="title font-14 bold title-container">
                Variety / Description of goods
              </div>

              {this.props.currentEntity.articles.map((article, index) => (
                <div className="font-12 padding-top-5" key={index}>
                  <p className={'mg-0 font-12 bold'}>
                    {article.processor_deviating && article.processor_deviating !== ''
                      ? `${article.processor_deviating}`
                      : article.articleDetails.descriptionInEnglish
                      }
                  </p>
                  <p className={'mg-0'}>{article.processor_condition}</p>
                </div>
              ))}
            </div>
          </div>

          <div className={'padding-top-12 flex justify-space-between page-avoid'}>
            <div className={'w-full'}>
              <div className="title font-14 bold title-container">Loading data</div>
              <div className="font-12 padding-top-5">
                <p className={'mg-0'}>
                  <b>Loading point:</b>{' '}
                  {`${
                    this.props.sourceData.locationName
                      ? this.props.sourceData.locationName
                      : this.props.sourceData.name1
                  }, `}{' '}
                  {getCompanyAddress(this.props.sourceData, language)}
                </p>
                <p className={'mg-0'}>
                  <b>{this.props.currentEntity.collectionSource}:</b>{' '}
                  {getDates(this.props.currentEntity.pickupDate)}
                </p>
                <p className={'mg-0'}>
                  <b>Source Loading reference:</b> {this.props.currentEntity.loadingReferenceSource}
                </p>
                <p className={'mg-0'}>
                  <b>EfactNR:</b> {this.props.sourceData.eFact}
                </p>
              </div>
            </div>

            <div className={'min-width-140 page-avoid text-right'}>
              <div className="title font-14 bold title-container">Opening hours</div>
              {this.props.sourceData.openingHours && this.props.sourceData.openingHours[0] && (
                <div
                  className={'font-12'}
                  dangerouslySetInnerHTML={{
                    __html: getOpeningHours(this.props.sourceData.openingHours[0], language),
                  }}
                />
              )}

              {this.props.sourceData.openingHours && this.props.sourceData.openingHours[1] && (
                <div
                  className={'font-12'}
                  dangerouslySetInnerHTML={{
                    __html: getOpeningHours(this.props.sourceData.openingHours[1], language),
                  }}
                />
              )}
            </div>
          </div>

          <div className={'padding-top-12 flex justify-space-between page-avoid'}>
            <div className={'w-full'}>
              <div className="title font-14 bold title-container">
                {this.props.t(
                  this.getUnloadingPartnerDetails()
                    ? this.getUnloadingPartnerDetails().transportEnglish
                    : 'Entladedaten',
                )}
              </div>
              <div className="font-12 padding-top-5">
                <p className={'mg-0'}>
                  {this.getUnloadingPartnerDetails() && (
                    <React.Fragment>
                      <b>{this.getUnloadingPartnerDetails().headingEnglish}:</b>{' '}
                      {`${
                        this.getUnloadingPartnerDetails().partner.locationName
                          ? this.getUnloadingPartnerDetails().partner.locationName
                          : this.getUnloadingPartnerDetails().partner.name1
                      }, `}{' '}
                      {getCompanyAddress(
                        this.getUnloadingPartnerDetails().partner,
                       language,
                      )}
                    </React.Fragment>
                  )}
                </p>
                <p className={'mg-0'}>
                  <b>{this.props.currentEntity.deliveryProcess}:</b>{' '}
                  {getDates(this.props.currentEntity.deliveryDate)}
                </p>
                <p className={'mg-0'}>
                  <b>
                    {' '}
                    {
                      this.getUnloadingPartnerDetails().heading === 'Entladestelle'
                        ? 'Unloading number'
                        : 'Loading number'
                    }
                    :
                  </b>{' '}
                  {this.props.currentEntity.loadingReferenceProcessor}
                </p>
                <p className={'mg-0'}>
                  <b>EfactNR:</b> {this.props.processorData.eFact}
                </p>
              </div>
            </div>

            <div className={'min-width-140 page-avoid text-right'}>
              <div className="title font-14 bold title-container">Opening hours</div>
              {this.props.processorData.openingHours && this.props.processorData.openingHours[0] && (
                <div
                  className={'font-12'}
                  dangerouslySetInnerHTML={{
                    __html: getOpeningHours(this.props.processorData.openingHours[0], language),
                  }}
                />
              )}

              {this.props.processorData.openingHours && this.props.processorData.openingHours[1] && (
                <div
                  className={'font-12'}
                  dangerouslySetInnerHTML={{
                    __html: getOpeningHours(this.props.processorData.openingHours[1], language),
                  }}
                />
              )}
            </div>
          </div>

          <div className={'padding-top-12 page-avoid'}>
            <p className={'font-12'}>Feel free to contact us if you have any questions.</p>
            <p className={'font-12 padding-top-12'}>With kind regards</p>
            <p className={'font-12'}>Your {this.props.companyData.name2} team</p>
          </div>

          <Footer {...this.props} />
        </div>
      );
    }
  }
}

export default withTranslation('common')(TransportationInformation);
