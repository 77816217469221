import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import PlusIcon from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import ZipcodeInput from '../Inputs/zipcodeInput';
import * as Actions from '../../redux/store/actions/partners';
import { useTranslation } from 'react-i18next';

function Locations(props) {
  const dispatch = useDispatch();
  const [t] = useTranslation("common");
  const { selectedPartner = null, variant } = props
  const { locations } = useSelector(({ partners }) => partners);

  useEffect(() => {
    if (variant === 'edit' && selectedPartner) {
      dispatch(Actions.setLocationValues(selectedPartner.locations ? selectedPartner.locations : []))
    } else {
      dispatch(Actions.setLocationValues([]))
    }
  }, [selectedPartner, variant])

  function addNewRow() {
    dispatch(Actions.addLocation());
  }

  function deleteRow(id) {
    dispatch(Actions.removeLocation(id));
  }

  const handleChange = (event, index) => {
    let data = locations;
    data[index][event.target.name] = event.target.value;

    dispatch(Actions.setLocationValues(data));
  };

  const handleZipcodeChange = (name, value, index) => {
    let data = locations;
    data[index][name] = value;

    dispatch(Actions.setLocationValues(data));
  }

  return (
    <div>
      {/* <Button
        startIcon={<PlusIcon />}
        variant="outlined"
        color={"primary"}
        style={{ marginTop: 10 }}
        onClick={() => {
          addNewRow();
        }}
      >
        {t('add_additional_location')}
      </Button> */}

      {locations.map((location, index) => (
        <div key={index} style={{ paddingTop: 12, height: '100%' }} className="expense-block">
          <Paper style={{ backgroundColor: '#e9f0ff' }}>
            <span onClick={() => {
              deleteRow(index);
            }} style={{ color: '#FF3B2F', backgroundColor: 'white', float: 'right', margin: 40, cursor: 'pointer' }} class="material-icons">remove_circle</span>
            {/* <Button
              startIcon={<DeleteIcon />}
              variant="outlined"
              style={{ color: 'red', backgroundColor: 'white', float: 'right', margin: 12 }}
              onClick={() => {
                deleteRow(index);
              }}
            >
              {t('remove_location')}
            </Button> */}
          </Paper>

          <TextField
            style={{ margin: 10, minWidth: '300px' }}
            value={location.name}
            onChange={(event) => handleChange(event, index)}
            label={`${t('Name')}`}
            name={'name'}
          />

          <TextField
            style={{ margin: 10, minWidth: '300px' }}
            value={location.street}
            onChange={(event) => handleChange(event, index)}
            label={`${t('Street')}`}
            name={'street'}
          />

          <TextField
            style={{ margin: 10, minWidth: '300px' }}
            value={location.houseNumber}
            onChange={(event) => handleChange(event, index)}
            label={`${t('House Number')}`}
            name={'houseNumber'}
          />

          <ZipcodeInput
            index={index}
            dbid={'zipcode'}
            style={{ margin: 10, width: 300 }}
            change={handleChange}
            setState={handleZipcodeChange}
            name={'Zipcode'}
            required={true}
            label={t('Zipcode')}
            zipcode={location.zipcode && location.zipcode.length > 0 ? location.zipcode : ''}
            city={location.city && location.city.length > 0 ? location.city : ''}
            country={location.country && location.country.length > 0 ? location.country : ''}
          />
        </div>
      ))}
    </div>
  );
}

export default Locations;
