import { get } from '../../../util/http-utils';
import { handleError } from '../../../util/store-utils';

export const RESET = '[ORDERS] RESET';
export const ADD_ARTICLE = '[ORDERS] ADD_ARTICLE';
export const REMOVE_ARTICLE = '[ORDERS] REMOVE_ARTICLE';
export const SET_VALUES = '[ORDERS] SET_VALUES';
export const SET_COUNTRIES = '[ORDERS] SET_COUNTRIES';
export const CORRECT_INVOICE = '[ORDERS] SET_ORDERS';

export const sourceCondition = `clean, free of impurities, without significant adhesions`;
export const sourceConditionGerman = `sauber, frei von Störstoffen, ohne nennenswerte Anhaftungen`;

export function reset() {
  return {
    type: RESET,
  };
}

export function add(payload) {
  return {
    type: ADD_ARTICLE,
    payload,
  };
}

export function remove(id) {
  return {
    type: REMOVE_ARTICLE,
    payload: id,
  };
}

export function setValues(payload) {
  return {
    type: SET_VALUES,
    payload,
  };
}

export function addCountries(payload) {
  return {
    type: SET_COUNTRIES,
    payload,
  };
}

export const correctInvoice = () => async (dispatch) => {
  try {
    const response = await get(dispatch, CORRECT_INVOICE, `${'/orders/correct/invoice'}`, true);
    dispatch({ type: CORRECT_INVOICE, payload: response });
    return response;
  } catch (err) {
    await handleError(dispatch, err, CORRECT_INVOICE);
  }
};
