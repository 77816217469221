import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { withTranslation } from "react-i18next";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function SimpleSelect(props) {
  const [value, setValue] = React.useState("");

  function getOptions(type) {
    if (type === 'intern') {
      if (props.variant == 'partner') {
        return props.optionsForPartner[0].internOptions
      } else if (props.variant == 'carrier') {
        return props.optionsForCarrier[0].internOptions
      } else {
        return props.options[0].internOptions
      }
    } else {
      if (props.variant == 'partner') {
        return props.optionsForPartner[1].externOptions
      } else if (props.variant == 'carrier') {
        return props.optionsForCarrier[1].externOptions
      } else {
        return props.options[1].externOptions
      }
    }

  }

  if (props.intern === "Intern") {
    return (
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        defaultValue={props.value}
        options={getOptions('intern')}
        disableCloseOnSelect
        onChange={(event, newValue) => {
          props.callbackValue(props.dbid, newValue);
          setValue(newValue);
        }}
        getOptionLabel={(option) => props.t(option)}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {props.t(option)}
          </React.Fragment>
        )}
        style={{
          margin: "10px",
          minWidth: "300px",
          maxWidth: "300px",
          display: "inline-flex",
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.t(props.label)}
            inputProps={{
              ...params.inputProps,
              autoComplete: `new-password`,
            }}
          />
        )}
      />
    );
  }
  else if (props.intern === "contract") {
    return (
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        defaultValue={props.value}
        options={props.options}
        disableCloseOnSelect
        onChange={(event, newValue) => {
          props.callbackValue(props.dbid, newValue);
          setValue(newValue);
        }}
        getOptionLabel={(option) => props.t(option)}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {props.t(option)}
          </React.Fragment>
        )}
        style={{
          margin: "10px",
          minWidth: "300px",
          maxWidth: "300px",
          display: "inline-flex",
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.t(props.label)}
            inputProps={{
              ...params.inputProps,
              autoComplete: `new-password`,
            }}
          />
        )}
      />
    );
  }
  else {
    return (
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        defaultValue={props.value}
        options={getOptions('extern')}
        disableCloseOnSelect
        onChange={(event, newValue) => {
          props.callbackValue(props.dbid, newValue);
          setValue(newValue);
        }}
        getOptionLabel={(option) => props.t(option)}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {props.t(option)}
          </React.Fragment>
        )}
        style={{ width: 500 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.t(props.label)}
            inputProps={{
              ...params.inputProps,
              autoComplete: `new-password`,
            }}
          />
        )}
      />
    );
  }
}

export default withTranslation("common")(SimpleSelect);
