import { GET_TAX, RESET } from "../../modules/tax";

const initialState = {
  taxes: [],
};

const taxes = function (state = initialState, action) {
  switch (action.type) {
    case RESET: {
      return {
        ...state,
        taxes: [],
      };
    }
    case GET_TAX: {
      return {
        ...state,
        taxes: action.payload.taxes,
      };
    }
    default: {
      return state;
    }
  }
};

export default taxes;
