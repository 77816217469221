export const RESET = "[CONTRACTS] RESET";
export const REMOVE = "[CONTRACTS] REMOVE";
export const ADD_ARTICLE = "[CONTRACTS] ADD_ARTICLE";
export const REMOVE_ARTICLE = "[CONTRACTS] REMOVE_ARTICLE";
export const SET_VALUES = "[CONTRACTS] SET VALUE";



export function setValues(payload) {
    return {
        type: SET_VALUES,
        payload,
    };
}
export function add(payload) {
    return {
        type: ADD_ARTICLE,
        payload,
    };
}
export function remove(id) {
    return {
        type: REMOVE_ARTICLE,
        payload: id,
    };

}
