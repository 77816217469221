import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import UploadSignatures from '../../company/upload-signatures';
import UploadLogo from '../../company/upload-logo';
import UploadAgb from '../../company/upload-agb';
import { Editor, RichUtils } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { InlineStyleControls, styleMap } from '../../common/rich-text-editor';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';

function Company({ self }) {
  function getBlockStyle(block) {
    switch (block.getType()) {
      case 'blockquote':
        return 'RichEditor-blockquote';
      default:
        return null;
    }
  }

  function toggleInlineStyle(inlineStyle) {
    let docType = self.state.docType;
    let documents = self.state.documents;
    documents[docType].description = RichUtils.toggleInlineStyle(
      documents[docType].description,
      inlineStyle,
    );
    self.setState({
      documents: documents,
    });
  }

  function handleEditorChange(value) {
    let docType = self.state.docType;
    let documents = self.state.documents;
    documents[docType].description = value;
    self.setState({
      documents: documents,
    });

    setHtmlValue();
  }

  function setHtmlValue() {
    let docType = self.state.docType;
    let documents = self.state.documents;
    let html = stateToHTML(documents[docType].description.getCurrentContent());

    documents[docType].descriptionHtml = html;

    self.setState({
      documents: documents,
    });
  }

  function handleSubjectChange(event) {
    let docType = self.state.docType;
    let documents = self.state.documents;
    documents[docType].subject = event.target.value;

    self.setState({
      documents: documents,
    });
  }

  function handleChange(event) {
    self.setState({ docType: event.target.value });
  }

  function handleEditCompany() {
    let keysToRemove = [
      'sourceLocation',
      'sourceLocations',
      'tile0',
      'tile1',
      'tile2',
      'tile3',
      'tile4',
      'tile5',
      'tileData',
      'vehicleTypes',
      'warehouseArticles',
      'processorLocation',
      'processorLocations',
      'selectedPersons',
      'selectedProcessor',
      'selectedSource',
    ];

    let payload = { ...self.state };

    for (let key of Object.keys(payload)) {
      if (keysToRemove.includes(key)) delete payload[key];
    }

    self.props.editCompany(self.currentEntity._id, payload);
  }

  if (self.props.currentSubTab == 0) {
    return (
      <div style={{ flexGrow: 1 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          style={{ margin: 10, float: 'left', backgroundColor: 'white', color: 'green' }}
          onClick={handleEditCompany}
        >
          {self.props.t('save_company')}
        </Button>
        <Button
          variant="contained"
          startIcon={<CancelIcon />}
          style={{ margin: 10, float: 'left', backgroundColor: 'white', color: 'red' }}
          onClick={() => self.props.cancelBtn()}
        >
          {self.props.t('Cancel')}
        </Button>
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper style={{ padding: 12, height: '100%' }}>
                <Typography variant="h4" style={{ paddingBottom: 30 }}>
                  {self.props.t(self.state.header0)}
                </Typography>
                {self.state.tile0}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper style={{ padding: 12, height: '100%' }}>
                <Typography variant="h4" style={{ paddingBottom: 30 }}>
                  {self.props.t(self.state.header1)}
                </Typography>
                {self.state.tile1}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper style={{ padding: 12, height: '100%' }}>
                <Typography variant="h4" style={{ paddingBottom: 30 }}>
                  {self.props.t(self.state.header2)}
                </Typography>
                {self.state.tile2}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper style={{ padding: 12, height: '100%' }}>
                <Typography variant="h4" style={{ paddingBottom: 30 }}>
                  {self.props.t(self.state.header3)}
                </Typography>
                {self.state.tile3}
              </Paper>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  } else if (self.props.currentSubTab == 1) {
    return (
      <div style={{ flexGrow: 1 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          style={{ margin: 10, float: 'left', backgroundColor: 'white', color: 'green' }}
          onClick={handleEditCompany}
        >
          {self.props.t('save_company')}
        </Button>
        <Button
          variant="contained"
          startIcon={<CancelIcon />}
          style={{ margin: 10, float: 'left', backgroundColor: 'white', color: 'red' }}
          onClick={() => self.props.cancelBtn()}
        >
          {self.props.t('Cancel')}
        </Button>
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper style={{ padding: 12, height: '100%' }}>
                <Typography variant="h4" style={{ paddingBottom: 30 }}>
                  {self.props.t(self.state.header4)}
                </Typography>

                <Grid item xs={12} sm={4} md={4} lg={4} style={{ marginTop: '16px' }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      {self.props.t('Select document type')}{' '}
                    </InputLabel>
                    <Select
                      labelId="select-document-type"
                      id="select-document-type"
                      value={self.state.docType || ''}
                      onChange={handleChange}
                      fullWidth
                    >
                      <MenuItem value={'default'}>{self.props.t('Default')}</MenuItem>
                      <MenuItem value={'annex'}>{self.props.t('Annex Document')} </MenuItem>
                      <MenuItem value={'purchase_confirmation'}>
                        {' '}
                        {self.props.t('Purchase Confirmation')}{' '}
                      </MenuItem>
                      <MenuItem value={'sales_confirmation'}>
                        {' '}
                        {self.props.t('Sales Confirmation')}{' '}
                      </MenuItem>
                      <MenuItem value={'transport_information'}>
                        {' '}
                        {self.props.t('Transportation Information')}
                      </MenuItem>
                      <MenuItem value={'invoice'}> {self.props.t('Invoice Document')}</MenuItem>
                      <MenuItem value={'transport_order'}>
                        {' '}
                        {self.props.t('Transportation Order')}
                      </MenuItem>
                      <MenuItem value={'billing_confirmation'}>
                        {' '}
                        {self.props.t('Billing confirmation')}{' '}
                      </MenuItem>
                      <MenuItem value={'invoice_cancellation'}>
                        {' '}
                        {self.props.t('Invoice Cancellation')}{' '}
                      </MenuItem>
                      <MenuItem value={'sales_contract'}>
                        {' '}
                        {self.props.t('Sales Contract')}{' '} </MenuItem>

                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} style={{ marginTop: '16px' }}>
                  <TextField
                    label="Subject"
                    value={self.state.documents[self.state.docType].subject || ''}
                    onChange={handleSubjectChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} style={{ marginTop: '16px' }}>
                  <div className="mb-16 RichEditor-root">
                    <InlineStyleControls
                      editorState={self.state.documents[self.state.docType].description}
                      onToggle={toggleInlineStyle}
                    />
                    <Editor
                      className="mt-8 mr-8"
                      editorState={self.state.documents[self.state.docType].description}
                      onChange={handleEditorChange}
                      customStyleMap={styleMap}
                      blockStyleFn={getBlockStyle}
                      placeholder="E-mail description"
                    />
                  </div>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  } else if (self.props.currentSubTab == 2) {
    return (
      <div style={{ flexGrow: 1 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          style={{ margin: 10, float: 'left', backgroundColor: 'white', color: 'green' }}
          onClick={handleEditCompany}
        >
          {self.props.t('save_company')}
        </Button>
        <Button
          variant="contained"
          startIcon={<CancelIcon />}
          style={{ margin: 10, float: 'left', backgroundColor: 'white', color: 'red' }}
          onClick={() => self.props.cancelBtn()}
        >
          {self.props.t('Cancel')}
        </Button>
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            <UploadSignatures self={self} />
            <UploadLogo self={self} />
            <UploadAgb self={self} />
          </Grid>
        </form>
      </div>
    );
  }
}

export default Company;
