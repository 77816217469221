import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { InputAdornment, TextField } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Done } from "@material-ui/icons";
import { formatDecimals } from "../../../helpers/common";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function Confirmed(props) {
  const { self } = props;
  const classes = useStyles();
  const [state, setState] = useState({
    currentEditIndex: -1,
    currentEditType: "",
  });

  function handleCellEdit(index, type) {
    setState({ ...state, currentEditIndex: index, currentEditType: type });
  }

  function handleDone(event) {
    event.preventDefault();
    event.stopPropagation();
    setState({ ...state, currentEditIndex: -1, currentEditType: "" });
  }

  function getWeight(currentBaleCount, index = -1) {
    let data = props.state.confirmed;
    let totalWeight = parseFloat(props.stats.total);
    let baleCount = parseFloat(props.stats.bale_count);
    let weight =
      data[index]["weight"] <= 0 && totalWeight > 0
        ? formatDecimals((totalWeight / baleCount) * parseFloat(currentBaleCount))
        : data[index]["weight"];
    if (index > -1) {
      data[index]["weight"] = weight;
    }
    return weight;
  }

  function getPricePerQuality(currentWeight, currentUnitPrice) {
    return formatDecimals(parseFloat(currentWeight) * parseFloat(currentUnitPrice) * 0.001);
  }

  function getTotalWeight() {
    let totalWeight = 0;

    for (let data of props.state.confirmed) {
      totalWeight += parseFloat(data.weight);
    }

    return formatDecimals(totalWeight);
  }

  function getTotalBaleCount() {
    let totalBaleCount = 0;

    for (let data of props.state.confirmed) {
      totalBaleCount += parseFloat(data.bale_count);
    }

    return totalBaleCount;
  }

  function getTotalBaleCountReceived() {
    let totalBaleCount = 0;

    for (let data of props.state.receive) {
      totalBaleCount += parseFloat(data.bale_count);
    }

    return totalBaleCount;
  }

  function getTotalPrice() {
    let totalPrice = 0;

    for (let data of props.state.confirmed) {
      totalPrice += parseFloat(data.price_per_quality);
    }

    return formatDecimals(totalPrice);
  }
  function handleInputChange(event, index) {
    event.preventDefault();
    event.stopPropagation();
    let data = props.state.confirmed;
    const value = event.target.value && event.target.value !== "" ? event.target.value : 0;
    data[index][event.target.name] = value;

    if (event.target.name === "bale_count") {
      data[index]["weight"] = getWeight(value, index);
    } else if (event.target.name === "unit_price") {
      data[index]["price_per_quality"] = getPricePerQuality(data[index]["weight"], value);
    }

    setState({ ...state, data: data });
  }

  return (
    <React.Fragment>
      <Typography variant="h4">{props.title}</Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow style={{ background: "#EAEEF1" }}>
              <TableCell className={"bold"}>{self.props.t("Quality")}</TableCell>
              <TableCell className={"bold"}>{self.props.t("Weight (t)")}</TableCell>
              <TableCell className={"bold"}>{self.props.t("Bale Count")}</TableCell>
              <TableCell className={"bold"}>{self.props.t("Unit Price")}</TableCell>
              <TableCell className={"bold"}>{self.props.t("Quality price")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.state.confirmed.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row" className={"table-data-color"}>
                  {row.quality}
                </TableCell>
                <TableCell onClick={() => handleCellEdit(index, "weight")}>
                  {getWeight(row.bale_count, index)}
                </TableCell>
                <TableCell onClick={() => handleCellEdit(index, "bale")}>
                  {state.currentEditIndex === index && state.currentEditType === "bale" ? (
                    <TextField
                      label={self.props.t("Bale Count")}
                      type={"number"}
                      autoFocus={true}
                      style={{ width: "120px" }}
                      name={"bale_count"}
                      onChange={(event) => handleInputChange(event, index)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleDone}
                              edge="end"
                            >
                              <Done />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    row.bale_count
                  )}
                </TableCell>
                <TableCell onClick={() => handleCellEdit(index, "unit")}>
                  {`${row.unit_price}€`}
                </TableCell>
                <TableCell onClick={() => handleCellEdit(index, "price_per_quality")}>
                  {`${getPricePerQuality(row.weight, row.unit_price)}€`}
                </TableCell>
              </TableRow>
            ))}

            <TableRow>
              <TableCell component="th" scope="row" className={"bold"}>
                Total
              </TableCell>
              <TableCell className={"bold"}>{getTotalWeight()}</TableCell>
              <TableCell
                className={
                  getTotalBaleCountReceived() !== getTotalBaleCount() ? "red-color bold" : "bold"
                }
              >
                {getTotalBaleCount()}
              </TableCell>
              <TableCell className={"bold"}></TableCell>
              <TableCell className={"bold"}>{getTotalPrice()}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}
