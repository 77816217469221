import React from 'react';
import DrawerTab from '../mainDrawer/drawer';
import SelectEditDeleteTable from '../../components/data-tables/edit-table';
import TopTabs from './toptab.js';
import AddForm from '../../components/Add/addForm';
import EditForm from '../../components/Edit/editForm';
import {
  GROUP_TABLE_COLUMNS,
  BANK_TABLE_COLUMNS,
  ARTICLE_TABLE_COLUMNS,
  SYSTEM_NAVIGATION_ROUTES,
  TOP_NAVIGATION_ROUTES,
  PERSON_TABLE_COLUMNS,
  CLIENT_TABLE_COLUMNS,
  WAREHOUSE_TABLE_COLUMNS,
  TAX_TABLE_COLUMNS,
} from '../../constants/ui-constants';
import { withTranslation } from 'react-i18next';
import Stocks from '../data-tables/stocks';
import Tax from '../data-tables/tax';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { getCompanys } from '../../redux/modules/company';
import { fetchUserDetails, fetchSettings } from '../../redux/store/actions/common';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      navColor: '',
      activeNavColor: '',
      currentTab: 'PARTNER',
      currentSubTab: 0,
      currentCols: CLIENT_TABLE_COLUMNS,
      currentAction: '',
      currentRowData: '',
      visibleModal: false,
      discard: true,
      theme: createMuiTheme({
        palette: {
          primary: {
            main: '#56E39F',
            contrastText: '#FFFFFF',
          },
          secondary: {
            main: '#1D283E',
            contrastText: '#FFFFFF',
          },
        },
      }),
    };
  }

  text = null;
  columns = null;

  async componentDidMount() {
    let data = await this.props.fetchUserDetails();
    const response = await this.props.getCompanys();
    await this.props.fetchSettings();

    let company = response.allCompanys[0];
    this.setState({
      user: data.user,
      navColor: company && company.secondaryColor ? company.secondaryColor : '#1D283E',
      activeNavColor: company && company.primaryColor ? company.primaryColor : '#56E39F',
      theme: createMuiTheme({
        palette: {
          primary: {
            main: company && company.primaryColor ? company.primaryColor : '#56E39F',
            contrastText: '#FFFFFF',
          },
          secondary: {
            main: company && company.secondaryColor ? company.secondaryColor : '#1D283E',
            contrastText: '#FFFFFF',
          },
        },
      }),
    });
  }

  setVisibleOpen = () => {
    this.setState({
      visibleModal: !this.state.visibleModal,
    });
  };

  confirmationLeavePage = async () => {
    await this.setState({
      discard: true,
    });
    this.callbackCurrentTab(this.text, this.columns);
  };

  cancelBtn = () => {
    this.callbackCurrentTab(this.text, this.columns);
  };

  callbackCurrentTab = (text, columns, currentSubTab = 0) => {
    this.text = text;
    this.columns = columns;

    // if (this.state.currentAction) {
    //   this.setState({ visibleModal: true });
    //
    //   if (this.state.discard) {
    //     this.setState({
    //       currentTab: text,
    //       currentCols: columns,
    //       currentAction: '',
    //       currentSubTab: currentSubTab,
    //       discard: false,
    //       visibleModal: false,
    //     });
    //   }
    //   return;
    // }

    this.setState({
      currentTab: text,
      currentCols: columns,
      currentAction: '',
      currentSubTab: currentSubTab,
    });
  };

  callbackCurrentSubTab = (text) => {
    this.setState({ currentSubTab: text });
  };
  callbackCurrentAction = (text, rowData) => {
    this.setState({ currentAction: text, currentRowData: rowData });
  };

  setCurrentTab = (currentTab, currentSubTab = 0, currentCols) => {
    this.setState({
      currentTab: currentTab,
      currentSubTab: currentSubTab.toString(),
      currentCols: currentCols,
      currentAction: '',
      currentRowData: '',
      visibleModal: false,
      discard: false,
    });
  };

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   if (Object.keys(this.props.user).length !== Object.keys(prevProps.user).length) {
  //     this.setState({ user: this.props.user });
  //   }
  // }

  render() {
    const { theme, user, loading } = this.state;
    if (Object.keys(user).length === 0) {
      return <MuiThemeProvider theme={theme}><div>Please wait...</div></MuiThemeProvider>
    }
    if (this.state.currentAction === 'ADD') {
      if (this.state.currentTab == TOP_NAVIGATION_ROUTES[3]) {
        return (
          <MuiThemeProvider theme={theme}>
            <div style={{ flex: 1, display: 'flex' }}>
              <DrawerTab
                parentCallback={this.callbackCurrentTab}
                currentTab={this.state.currentTab}
                setCurrentTab={this.setCurrentTab}
                navColor={this.state.navColor}
                activeNavColor={this.state.activeNavColor}
                self={this}
              />
              <main style={{ flexGrow: 1 }}>
                <TopTabs
                  currentTab={this.state.currentTab}
                  currentSubTab={this.state.currentSubTab}
                  parentCallback={this.callbackCurrentSubTab}
                  currentAction={this.state.currentAction}
                  setCurrentTab={this.setCurrentTab}
                  self={this}
                />
                <AddForm
                  currentSubTab={this.state.currentSubTab}
                  currentTab={this.state.currentTab}
                  parentCallback={this.callbackCurrentTab}
                  parentCallbackAction={this.callbackCurrentAction}
                  setCurrentTab={this.setCurrentTab}
                />
              </main>
              {/*<CloseEditModal*/}
              {/*  open={this.state.visibleModal}*/}
              {/*  setVisibleOpen={this.setVisibleOpen}*/}
              {/*  confirmationLeavePage={() => this.confirmationLeavePage()}*/}
              {/*  setCurrentTab={this.setCurrentTab}*/}
              {/*/>*/}
            </div>
          </MuiThemeProvider>
        );
      } else {
        return (
          <MuiThemeProvider theme={theme}>
            <div style={{ flex: 1, display: 'flex' }}>
              <DrawerTab
                parentCallback={this.callbackCurrentTab}
                currentTab={this.state.currentTab}
                setCurrentTab={this.setCurrentTab}
                navColor={this.state.navColor}
                activeNavColor={this.state.activeNavColor}
                self={this}
              />
              <main style={{ flexGrow: 1 }}>
                <AddForm
                  currentSubTab={this.state.currentSubTab}
                  currentTab={this.state.currentTab}
                  parentCallback={this.callbackCurrentTab}
                  parentCallbackAction={this.callbackCurrentAction}
                  setCurrentTab={this.setCurrentTab}
                />
              </main>
              {/*<CloseEditModal*/}
              {/*  open={this.state.visibleModal}*/}
              {/*  setVisibleOpen={this.setVisibleOpen}*/}
              {/*  confirmationLeavePage={() => this.confirmationLeavePage()}*/}
              {/*  setCurrentTab={this.setCurrentTab}*/}
              {/*/>*/}
            </div>
          </MuiThemeProvider>
        );
      }
    } else if (this.state.currentAction === 'EDIT') {
      if (
        this.state.currentSubTab == 0 ||
        this.state.currentSubTab == 1 ||
        this.state.currentSubTab == 2 ||
        this.state.currentSubTab == 3 ||
        this.state.currentSubTab == 4 ||
        this.state.currentSubTab == 5
      ) {
        return (
          <MuiThemeProvider theme={theme}>
            <div style={{ flex: 1, display: 'flex' }}>
              <DrawerTab
                parentCallback={this.callbackCurrentTab}
                currentTab={this.state.currentTab}
                setCurrentTab={this.setCurrentTab}
                navColor={this.state.navColor}
                activeNavColor={this.state.activeNavColor}
                self={this}
              />
              <main style={{ flexGrow: 1 }}>
                {/*<Typography paragraph className={'uppercase-span'}>EDIT {this.props.t(this.state.currentTab)}</Typography>*/}
                <TopTabs
                  currentTab={this.state.currentTab}
                  currentSubTab={this.state.currentSubTab}
                  parentCallback={this.callbackCurrentSubTab}
                  setCurrentTab={this.setCurrentTab}
                  self={this}
                />
                <EditForm
                  cancelBtn={this.cancelBtn}
                  currentTab={this.state.currentTab}
                  currentSubTab={this.state.currentSubTab}
                  currentRowData={this.state.currentRowData}
                  setCurrentTab={this.setCurrentTab}
                  permission={this}
                />
              </main>
              {/*<CloseEditModal*/}
              {/*  open={this.state.visibleModal}*/}
              {/*  setVisibleOpen={this.setVisibleOpen}*/}
              {/*  confirmationLeavePage={() => this.confirmationLeavePage()}*/}
              {/*  setCurrentTab={this.setCurrentTab}*/}
              {/*/>*/}
            </div>
          </MuiThemeProvider>
        );
      } else {
        return (
          <MuiThemeProvider theme={theme}>
            <div style={{ flex: 1, display: 'flex' }}>
              <DrawerTab
                parentCallback={this.callbackCurrentTab}
                currentTab={this.state.currentTab}
                setCurrentTab={this.setCurrentTab}
                navColor={this.state.navColor}
                activeNavColor={this.state.activeNavColor}
                self={this}
              />
              <main style={{ flexGrow: 1 }}>
                {/*<Typography paragraph className={'uppercase-span'}>EDIT {this.props.t(this.state.currentTab)}</Typography>*/}
                <TopTabs
                  currentSubTab={this.state.currentSubTab}
                  parentCallback={this.callbackCurrentSubTab}
                  setCurrentTab={this.setCurrentTab}
                  self={this}
                />
                {this.state.currentSubTab}
              </main>
              {/*<CloseEditModal*/}
              {/*  open={this.state.visibleModal}*/}
              {/*  setVisibleOpen={this.setVisibleOpen}*/}
              {/*  confirmationLeavePage={() => this.confirmationLeavePage()}*/}
              {/*  setCurrentTab={this.setCurrentTab}*/}
              {/*/>*/}
            </div>
          </MuiThemeProvider>
        );
      }
    } else if (this.state.currentTab === 'SYSTEM') {
      if (this.state.currentSubTab == 0) {
        return (
          <MuiThemeProvider theme={theme}>
            <div style={{ flex: 1, display: 'flex' }}>
              <DrawerTab
                parentCallback={this.callbackCurrentTab}
                currentTab={this.state.currentTab}
                setCurrentTab={this.setCurrentTab}
                navColor={this.state.navColor}
                activeNavColor={this.state.activeNavColor}
                self={this}
              />
              <main style={{ flexGrow: 1 }}>
                {/*<Typography paragraph className={'uppercase-span'}>{this.props.t(this.state.currentTab)}</Typography>*/}
                <TopTabs
                  currentTab={this.state.currentTab}
                  currentSubTab={this.state.currentSubTab}
                  parentCallback={this.callbackCurrentSubTab}
                  setCurrentTab={this.setCurrentTab}
                  self={this}
                />
                <SelectEditDeleteTable
                  parentCallbackAction={this.callbackCurrentAction}
                  parentCallback={this.callbackCurrentTab}
                  tableTitle={SYSTEM_NAVIGATION_ROUTES[0]}
                  columns={BANK_TABLE_COLUMNS}
                  setCurrentTab={this.setCurrentTab}
                  self={this}
                />
              </main>
              {/*<CloseEditModal*/}
              {/*  open={this.state.visibleModal}*/}
              {/*  setVisibleOpen={this.setVisibleOpen}*/}
              {/*  confirmationLeavePage={() => this.confirmationLeavePage()}*/}
              {/*  setCurrentTab={this.setCurrentTab}*/}
              {/*/>*/}
            </div>
          </MuiThemeProvider>
        );
      } else if (this.state.currentSubTab == -1) {
        return (
          <MuiThemeProvider theme={theme}>
            <div style={{ flex: 1, display: 'flex' }}>
              <DrawerTab
                parentCallback={this.callbackCurrentTab}
                currentTab={this.state.currentTab}
                setCurrentTab={this.setCurrentTab}
                navColor={this.state.navColor}
                activeNavColor={this.state.activeNavColor}
                self={this}
              />
              <main style={{ flexGrow: 1 }}>
                {/*<Typography paragraph className={'uppercase-span'}>{this.props.t(this.state.currentTab)}</Typography>*/}
                <TopTabs
                  currentTab={this.state.currentTab}
                  currentSubTab={this.state.currentSubTab}
                  parentCallback={this.callbackCurrentSubTab}
                  setCurrentTab={this.setCurrentTab}
                  self={this}
                />
                <SelectEditDeleteTable
                  parentCallbackAction={this.callbackCurrentAction}
                  parentCallback={this.callbackCurrentTab}
                  tableTitle={SYSTEM_NAVIGATION_ROUTES[1]}
                  columns={GROUP_TABLE_COLUMNS}
                  setCurrentTab={this.setCurrentTab}
                  self={this}
                />
              </main>
              {/*<CloseEditModal*/}
              {/*  open={this.state.visibleModal}*/}
              {/*  setVisibleOpen={this.setVisibleOpen}*/}
              {/*  confirmationLeavePage={() => this.confirmationLeavePage()}*/}
              {/*  setCurrentTab={this.setCurrentTab}*/}
              {/*/>*/}
            </div>
          </MuiThemeProvider>
        );
      } else if (this.state.currentSubTab == 1) {
        return (
          <MuiThemeProvider theme={theme}>
            <div style={{ flex: 1, display: 'flex' }}>
              <DrawerTab
                parentCallback={this.callbackCurrentTab}
                currentTab={this.state.currentTab}
                setCurrentTab={this.setCurrentTab}
                navColor={this.state.navColor}
                activeNavColor={this.state.activeNavColor}
                self={this}
              />
              <main style={{ flexGrow: 1 }}>
                <TopTabs
                  currentTab={this.state.currentTab}
                  currentSubTab={this.state.currentSubTab}
                  parentCallback={this.callbackCurrentSubTab}
                  setCurrentTab={this.setCurrentTab}
                  self={this}
                />
                {/*<iframe*/}
                {/*  style={{ width: '100%', height: 680 }}*/}
                {/*  src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTUNYBUdqY_ANASx4JDJIAzSqu3L5Q_pUtCCOjCDRqiTY0c1FI_h5Wa72BXNsEfMKIv973-2GO-Hgvt/pubhtml?widget=true&amp;headers=false"*/}
                {/*></iframe>*/}
                <Tax
                  parentCallbackAction={this.callbackCurrentAction}
                  parentCallback={this.callbackCurrentTab}
                  tableTitle={SYSTEM_NAVIGATION_ROUTES[1]}
                  columns={TAX_TABLE_COLUMNS}
                  setCurrentTab={this.setCurrentTab}
                  self={this}
                />
              </main>
              {/*<CloseEditModal*/}
              {/*  open={this.state.visibleModal}*/}
              {/*  setVisibleOpen={this.setVisibleOpen}*/}
              {/*  confirmationLeavePage={() => this.confirmationLeavePage()}*/}
              {/*  setCurrentTab={this.setCurrentTab}*/}
              {/*/>{' '}*/}
            </div>
          </MuiThemeProvider>
        );
      } else if (this.state.currentSubTab == 3) {
        return (
          <MuiThemeProvider theme={theme}>
            <div style={{ flex: 1, display: 'flex' }}>
              <DrawerTab
                parentCallback={this.callbackCurrentTab}
                currentTab={this.state.currentTab}
                setCurrentTab={this.setCurrentTab}
                navColor={this.state.navColor}
                activeNavColor={this.state.activeNavColor}
                self={this}
              />
              <main style={{ flexGrow: 1 }}>
                {/*<Typography paragraph className={'uppercase-span'}>{this.props.t(this.state.currentTab)}</Typography>*/}
                <TopTabs
                  currentTab={this.state.currentTab}
                  currentSubTab={this.state.currentSubTab}
                  parentCallback={this.callbackCurrentSubTab}
                  setCurrentTab={this.setCurrentTab}
                  self={this}
                />
                <SelectEditDeleteTable
                  parentCallbackAction={this.callbackCurrentAction}
                  parentCallback={this.callbackCurrentTab}
                  tableTitle={SYSTEM_NAVIGATION_ROUTES[3]}
                  columns={PERSON_TABLE_COLUMNS}
                  setCurrentTab={this.setCurrentTab}
                  self={this}
                />
              </main>
              {/*<CloseEditModal*/}
              {/*  open={this.state.visibleModal}*/}
              {/*  setVisibleOpen={this.setVisibleOpen}*/}
              {/*  confirmationLeavePage={this.confirmationLeavePage}*/}
              {/*  setCurrentTab={this.setCurrentTab}*/}
              {/*/>*/}
            </div>
          </MuiThemeProvider>
        );
      } else if (this.state.currentSubTab == 2) {
        return (
          <MuiThemeProvider theme={theme}>
            <div style={{ flex: 1, display: 'flex' }}>
              <DrawerTab
                parentCallback={this.callbackCurrentTab}
                currentTab={this.state.currentTab}
                setCurrentTab={this.setCurrentTab}
                navColor={this.state.navColor}
                activeNavColor={this.state.activeNavColor}
                self={this}
              />
              <main style={{ flexGrow: 1 }}>
                {/*<Typography paragraph className={'uppercase-span'}>{this.props.t(this.state.currentTab)}</Typography>*/}
                <TopTabs
                  currentTab={this.state.currentTab}
                  currentSubTab={this.state.currentSubTab}
                  parentCallback={this.callbackCurrentSubTab}
                  setCurrentTab={this.setCurrentTab}
                  self={this}
                />
                <SelectEditDeleteTable
                  parentCallbackAction={this.callbackCurrentAction}
                  parentCallback={this.callbackCurrentTab}
                  tableTitle={SYSTEM_NAVIGATION_ROUTES[2]}
                  columns={ARTICLE_TABLE_COLUMNS}
                  setCurrentTab={this.setCurrentTab}
                  self={this}
                />
              </main>
              {/*<CloseEditModal*/}
              {/*  open={this.state.visibleModal}*/}
              {/*  setVisibleOpen={this.setVisibleOpen}*/}
              {/*  confirmationLeavePage={this.confirmationLeavePage}*/}
              {/*  setCurrentTab={this.setCurrentTab}*/}
              {/*/>*/}
            </div>
          </MuiThemeProvider>
        );
      } else if (this.state.currentSubTab == 4) {
        return (
          <MuiThemeProvider theme={theme}>
            <div style={{ flex: 1, display: 'flex' }}>
              <DrawerTab
                parentCallback={this.callbackCurrentTab}
                currentTab={this.state.currentTab}
                setCurrentTab={this.setCurrentTab}
                navColor={this.state.navColor}
                activeNavColor={this.state.activeNavColor}
                self={this}
              />
              <main style={{ flexGrow: 1 }}>
                {/*<Typography paragraph className={'uppercase-span'}>{this.props.t(this.state.currentTab)}</Typography>*/}
                <TopTabs
                  currentTab={this.state.currentTab}
                  currentSubTab={this.state.currentSubTab}
                  parentCallback={this.callbackCurrentSubTab}
                  setCurrentTab={this.setCurrentTab}
                  self={this}
                />
                <SelectEditDeleteTable
                  parentCallbackAction={this.callbackCurrentAction}
                  parentCallback={this.callbackCurrentTab}
                  tableTitle={SYSTEM_NAVIGATION_ROUTES[4]}
                  columns={WAREHOUSE_TABLE_COLUMNS}
                  setCurrentTab={this.setCurrentTab}
                  self={this}
                />
              </main>
              {/*<CloseEditModal*/}
              {/*  open={this.state.visibleModal}*/}
              {/*  setVisibleOpen={this.setVisibleOpen}*/}
              {/*  confirmationLeavePage={this.confirmationLeavePage}*/}
              {/*  setCurrentTab={this.setCurrentTab}*/}
              {/*/>*/}
            </div>
          </MuiThemeProvider>
        );
      }
    }
    // else if (this.state.currentTab == TOP_NAVIGATION_ROUTES[6]) {
    //   return (
    //     <MuiThemeProvider theme={theme}>
    //       <div style={{ flex: 1, display: 'flex' }}>
    //         <DrawerTab
    //           parentCallback={this.callbackCurrentTab}
    //           currentTab={this.state.currentTab}
    //           setCurrentTab={this.setCurrentTab}
    //           navColor={this.state.navColor}
    //           activeNavColor={this.state.activeNavColor}
    //           self={this}
    //         />
    //         <main style={{ flexGrow: 1 }}>
    //           {/*<Typography paragraph className={'uppercase-span'}>{this.props.t(this.state.currentTab)}</Typography>*/}
    //           <SelectEditDeleteTable
    //             parentCallbackAction={this.callbackCurrentAction}
    //             parentCallback={this.callbackCurrentTab}
    //             tableTitle={this.state.currentTab}
    //             columns={this.state.currentCols}
    //             setCurrentTab={this.setCurrentTab}
    //             self={this}
    //           />
    //         </main>
    //         {/*{this.state.visibleModal ? (*/}
    //         {/*  <CloseEditModal*/}
    //         {/*    open={this.state.visibleModal}*/}
    //         {/*    setVisibleOpen={this.setVisibleOpen}*/}
    //         {/*    confirmationLeavePage={this.confirmationLeavePage}*/}
    //         {/*    setCurrentTab={this.setCurrentTab}*/}
    //         {/*  />*/}
    //         {/*) : (*/}
    //         {/*  ''*/}
    //         {/*)}*/}
    //       </div>
    //     </MuiThemeProvider>
    //   );
    // }
    else if (this.state.currentTab == TOP_NAVIGATION_ROUTES[5]) {
      return (
        <MuiThemeProvider theme={theme}>
          <div style={{ flex: 1, display: 'flex' }}>
            <DrawerTab
              parentCallback={this.callbackCurrentTab}
              currentTab={this.state.currentTab}
              setCurrentTab={this.setCurrentTab}
              navColor={this.state.navColor}
              activeNavColor={this.state.activeNavColor}
              self={this}
            />
            <main style={{ flexGrow: 1 }}>
              <Stocks
                parentCallbackAction={this.callbackCurrentAction}
                parentCallback={this.callbackCurrentTab}
                tableTitle={this.state.currentTab}
                columns={this.state.currentCols}
                setCurrentTab={this.setCurrentTab}
                self={this}
              />
            </main>
          </div>
        </MuiThemeProvider>
      );
    } else {
      return (
        <MuiThemeProvider theme={theme}>
          <div style={{ flex: 1, display: 'flex' }}>
            <DrawerTab
              parentCallback={this.callbackCurrentTab}
              currentTab={this.state.currentTab}
              setCurrentTab={this.setCurrentTab}
              navColor={this.state.navColor}
              activeNavColor={this.state.activeNavColor}
              self={this}
            />
            <main style={{ flexGrow: 1 }}>
              {/*<Typography paragraph className={'uppercase-span'}>{this.props.t(this.state.currentTab)}</Typography>*/}
              <SelectEditDeleteTable
                parentCallbackAction={this.callbackCurrentAction}
                parentCallback={this.callbackCurrentTab}
                tableTitle={this.state.currentTab}
                columns={this.state.currentCols}
                setCurrentTab={this.setCurrentTab}
                self={this}
              />
            </main>
            {/*{this.state.visibleModal ? (*/}
            {/*  <CloseEditModal*/}
            {/*    open={this.state.visibleModal}*/}
            {/*    setVisibleOpen={this.setVisibleOpen}*/}
            {/*    confirmationLeavePage={this.confirmationLeavePage}*/}
            {/*    setCurrentTab={this.setCurrentTab}*/}
            {/*  />*/}
            {/*) : (*/}
            {/*  ''*/}
            {/*)}*/}
          </div>
        </MuiThemeProvider>
      );
    }
  }
}

const mapStateToProps = ({ common }) => ({
  user: common.user,
});

export default connect(mapStateToProps, { getCompanys, fetchUserDetails, fetchSettings })(
  withTranslation('common')(Dashboard),
);
