import React from 'react';
import ChipInput from 'material-ui-chip-input';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../redux/store/actions/orders';
import { useTranslation } from 'react-i18next';

const CountriesField = (props) => {
  const [t] = useTranslation('common');
  const dispatch = useDispatch();

  const { form } = useSelector(({ persons }) => persons);

  function handleChange(chips) {
    dispatch(Actions.addCountries(chips));
  }

  return (
    <ChipInput
      classes={{
        root: 'h-56',
        input: 'mg-top-20',
        chipContainer: 'mg-top-20',
      }}
      style={{ width: props.width ? props.width : '300px', marginLeft: '10px', marginTop: '2px', marginRight: '10px' }}
      defaultValue={props.value}
      onChange={(chips) => handleChange(chips)}
      placeholder={t('Transit country')}
    />
  );
};

export default CountriesField;
