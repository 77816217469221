import React from 'react';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { SYSTEM_NAVIGATION_ROUTES } from '../../../constants/ui-constants';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import UploadSignatures from './upload-signature';

function Team({ self }) {
  return (
    <div style={{ flexGrow: 1 }}>
      <Button
        variant="contained"
        color="primary"
        startIcon={<SaveIcon />}
        style={{ margin: 10, float: 'left', color: 'green', backgroundColor: 'white' }}
        onClick={() => {
          const payload = { ...self.state };
          payload.emails = self.props.personForm.emails;
          self.props.editPerson(self.currentEntity._id, payload);
        }}
      >
        {`${self.props.t('Save')} ${self.props.t(
          SYSTEM_NAVIGATION_ROUTES[self.props.currentSubTab],
        )}`}
      </Button>
      <Button
        variant="contained"
        startIcon={<CancelIcon />}
        style={{ margin: 10, float: 'left', backgroundColor: 'white', color: 'red' }}
        onClick={() => self.props.cancelBtn()}
      >
        {self.props.t('Cancel')}
      </Button>
      <form noValidate autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper style={{ padding: 12, height: '100%' }}>
              <Typography variant="h4" style={{ paddingBottom: 30 }}>
                {self.props.t(self.state.header0)}
              </Typography>
              {self.state.tile0}
              <UploadSignatures self={self} />
            </Paper>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default Team;
