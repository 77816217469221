import { get } from "../../../util/http-utils";
import { handleError } from "../../../util/store-utils";

export const GET_STOCKS = "[STOCKS] GET STOCKS";
export const RESET = "[STOCKS] RESET";
export const FILTER_DATA = "[STOCKS] FILTER DATA";

export function reset() {
  return {
    type: RESET,
  };
}

export const fetchStocks = (language) => async (dispatch) => {
  try {
    const response = await get(dispatch, RESET, `${'stocks'}/${language}`, true);
    dispatch({ type: GET_STOCKS, payload: response });
  } catch (err) {
    await handleError(dispatch, err, GET_STOCKS);
  }
};

export const filterData = (data) => {
  let baleCount = 0;
  let totalValue = 0;
  let totalWeight = 0;

  for (let d of data) {
    for (let article of d.articles[0]) {
      totalWeight += parseFloat(article.source_weight > 0 ? article.source_weight : "0");
      totalValue +=
        parseFloat(article.source_price) *
        parseFloat(article.source_weight > 0 ? article.source_weight : "1");
      baleCount += parseFloat(
        article.source_amount && article.source_packaging_units === "Bales"
          ? article.source_amount
          : "0"
      );
    }
  }

  return {
    type: FILTER_DATA,
    payload: { baleCount, totalValue, totalWeight },
  };
};
