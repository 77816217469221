import _ from "lodash";
import { APP_NAMESPACE } from "../../util/redux-constants";
import { put, post, get, del } from "../../util/http-utils";
import { updateStore, buildGenericInitialState, handleError } from "../../util/store-utils";
import { CHANGE_AUTH, GET_AUTHENTICATED_USER } from "./authentication";
import { ORDER_TABLE_COLUMNS } from "../../constants/ui-constants";
import { showMessage } from '../store/actions/alerts';

const ORDER_ENDPOINT_BASE = "orders";
const typeBase = `${APP_NAMESPACE}/${ORDER_ENDPOINT_BASE}/`;

// Constants
export const GET_ORDERS = `${typeBase}GET_ORDERS`;
export const GET_ORDER = `${typeBase}GET_ORDER`;
export const ADD_ORDER = `${typeBase}ADD_ORDER`;
export const EDIT_ORDER = `${typeBase}EDIT_ORDER`;
export const DELETE_ORDER = `${typeBase}DELETE_ORDER`;
export const SEND_DOCUMENTS = `${typeBase}SEND_DOCUMENTS`;
export const COPY_ORDERS = `${typeBase}COPY_ORDERS`;
export const CHECK_IF_WAREHOUSE = `${typeBase}CHECK_IF_WAREHOUSE`;
export const GET_LOCATIONS = `${typeBase}GET_LOCATIONS`
// Actions

/**
 * getUser  - Fetches user from API, given id
 *
 * @param {String} id User's id for lookup
 * @returns {Promise}
 */
export const getOrder = (id) => async (dispatch) => {
  try {
    const response = await get(dispatch, GET_ORDER, `${ORDER_ENDPOINT_BASE}/${id}`, true);
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err);
  }
};

/**
 * getUsers  - Fetches users from API
 *
 * @returns {Promise}
 */
export const getOrders = () => async (dispatch) => {
  try {
    const response = await get(dispatch, GET_ORDERS, ORDER_ENDPOINT_BASE, true);
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err, GET_ORDER);
  }
};

/**
 * register - Creates a new ORDER for a user
 * @param {Object} formData  User's form data
 */
export const addOrder = (formData, callback, currentTabName, currentTabNumber) => async (
  dispatch
) => {
  console.log("HER BOO", formData);
  try {
    const response = await post(dispatch, ADD_ORDER, `${ORDER_ENDPOINT_BASE}/add`, formData, true);

    callback(
      currentTabName,
      currentTabNumber,
      ORDER_TABLE_COLUMNS,
      "Added Successfully!",
      response.error
    );
    response.status = true;

    return response;
  } catch (err) {
    console.log("Error: ", err)
    await handleError(dispatch, err, CHANGE_AUTH);
  }
};

export const sendDocument = (id, payload, language) => async (dispatch) => {
  try {
    const response = await post(
      dispatch,
      SEND_DOCUMENTS,
      `${ORDER_ENDPOINT_BASE}/senddocument/${id}/${language}`,
      payload,
      true
    );
    if (response.error === undefined) {
      dispatch(showMessage({ message: "Sent Successfully", messageType: "success" }))
    }
  } catch (err) {
    await handleError(dispatch, err, CHANGE_AUTH);
  }
};

export const copyOrder = (data, callback) => async (dispatch) => {
  try {
    const response = await post(dispatch, COPY_ORDERS, `${ORDER_ENDPOINT_BASE}/copy`, data, true);
    if (response.error === undefined) {
      callback("Copied Successfully!", true);
    } else {
      dispatch(showMessage({ message: response.error, messageType: "error" }));
    }
    return response;
  } catch (err) {
    console.log(err);
    await handleError(dispatch, err, CHANGE_AUTH);
  }
};

/**
 * Edit ORDER - updates a ORDER
 * @param {Object} formData  User's form data
 */
export const editOrder = (id, formData) => async (dispatch) => {
  let result = formData.articles.every(e => e.source_cashflow !== "" && e.processor_cashflow !== "" && e.source_cashflow !== null && e.processor_cashflow !== null);
  if (result) {
    try {
      console.log("EDITING ORDER!!");
      const response = await put(
        dispatch,
        EDIT_ORDER,
        `${ORDER_ENDPOINT_BASE}/${id}`,
        formData,
        false
      );
      // If the registration was successful, set the JWT as a cookie
      if (response.OrderUpdated) {
        dispatch(showMessage({ message: "Edited ORDER Successfully", messageType: "success" }))
      } else {
        dispatch(showMessage({ message: response.error, messageType: "error" }))
      }
    } catch (err) {
      console.log(err)
      await handleError(dispatch, err, CHANGE_AUTH);
    }
  }
  else {
    dispatch(showMessage({ message: "Source Or Processor Cashflow Missing", messageType: "error" }))
  }
};

/**
 * Delete ORDER - updates a ORDER
 * @param {Object} formData  User's form data
 */
export const deleteOrder = (id, callback) => async (dispatch) => {
  try {
    console.log("DELETING ORDER!!");

    const response = await del(dispatch, DELETE_ORDER, `${ORDER_ENDPOINT_BASE}/${id}`, true);
    console.log(response);

    // If the registration was successful, set the JWT as a cookie
    if (response) {
      callback("Deleted ORDER Successfully");
    }
  } catch (err) {
    await handleError(dispatch, err, CHANGE_AUTH);
  }
};

export const checkIsWarehouse = (id) => async (dispatch) => {
  try {

    const response = await get(dispatch, CHECK_IF_WAREHOUSE, `${ORDER_ENDPOINT_BASE}/is_warehouse/${id}`, true);
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err, CHANGE_AUTH);
  }
}

export const getLocations = (id) => async (dispatch) => {
  try {

    const response = await get(dispatch, GET_LOCATIONS, `${ORDER_ENDPOINT_BASE}/get_locations/${id}`, true);
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err, CHANGE_AUTH);
  }
}

// Store
const INITIAL_STATE = {
  ...buildGenericInitialState([GET_ORDER, GET_ORDERS]),
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_AUTH:
      return updateStore(
        state,
        action,
        _.get(action, "payload.user.id") ? { [action.payload.user.id]: action.payload.user } : {}
      );
    case GET_ORDER:
    case GET_AUTHENTICATED_USER:
      return updateStore(
        state,
        action,
        _.get(action, "payload.user.id") ? { [action.payload.user.id]: action.payload.user } : {}
      );
    case GET_ORDERS:
      return updateStore(
        state,
        action,
        _.get(action, "payload.users") ? _.mapKeys(action.payload.users, "id") : {}
      );
    default:
      return state;
  }
};

// Selectors
export const getAuthenticatedUser = ({ user, authentication }) => user[authentication.user];
