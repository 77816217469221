import React from 'react';
import { getGroups } from '../../redux/modules/group';
import { getCompanys } from '../../redux/modules/company';
import {
  getFieldAgents,
  getPersons,
  getAccountManagers,
  getTruckNotifications,
  getContactForFieldStaff,
} from '../../redux/modules/person';
import { sendDocument } from '../../redux/modules/order';
import { getBanks } from '../../redux/modules/bank';
import { getCarriers } from '../../redux/modules/carrier';
import { getPartners, getDeviatingPartners } from '../../redux/modules/partner';
import { getTeams } from '../../redux/modules/person';
import { getWarehouses } from '../../redux/modules/warehouse';
import { getCountries } from '../../redux/modules/countries';

import { connect } from 'react-redux';
import { ARTICLE_ITEM_TYPE_MENU_CHOICES } from '../../constants/ui-constants';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { withTranslation } from 'react-i18next';
import { getEfact } from '../../redux/modules/common';

class DatabaseMultiChoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      menuItems: [],
      response: [],
      nameProperty: '',
    };
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  async componentDidMount() {
    let value = this.getValue();

    this.setState({ value: value });

    if (this.props.method == 'getPartners') {
      const response = await this.props.getPartners();
      const companies = await this.props.getCompanys();
      if (
        this.props.label === 'Consignee' ||
        this.props.label === 'Notify' ||
        this.props.label === 'End User'
      ) {
        response.allPartners.push({ name2: 'Same as buyer' });
        this.setState({
          response: response.allPartners,
          nameProperty: 'name2',
          value: value || 'Same as buyer',
        });
      } else if (this.props.label === 'Shipper') {
        let shipper = response.allPartners.concat(companies.allCompanys);
        let getResp = shipper.filter((item, pos) => shipper.indexOf(item) === pos);
        let length = response.allPartners.length;
        this.setState({
          response: getResp,
          nameProperty: 'name2',
          value: getResp[length].name2 || '',
        });

        this.props.self.setState({
          shipper: getResp[length].name2,
          shipperId: getResp[length]._id,
        });
      } else {
        this.setState({ response: response.allPartners, nameProperty: 'name2' });
      }
    } else if (this.props.method == 'getDeviatingPartners') {
      const response = await this.props.getDeviatingPartners();

      this.setState({ response: response.allPartners, nameProperty: 'name2' });
    } else if (this.props.method == 'getCarriers') {
      const response = await this.props.getCarriers();

      this.setState({ response: response.allCarriers, nameProperty: 'name2' });
    } else if (this.props.method == 'getArticles') {
      const response = await this.props.getArticles();

      this.setState({ response: response.allArticles, nameProperty: 'descriptionInEnglish' }); // todo should be able to switch between german and english?
    } else if (this.props.method == 'getGroups') {
      const response = await this.props.getGroups();

      this.setState({ response: response.allGroups, nameProperty: 'groupName' });
    } else if (this.props.method === 'getItemTypes') {
      this.setState({ response: ARTICLE_ITEM_TYPE_MENU_CHOICES, nameProperty: 'description' });
    } else if (this.props.method === 'getCompanys') {
      const response = await this.props.getCompanys();

      this.setState({ response: response.allCompanys, nameProperty: 'name1' });
    } else if (this.props.method === 'getFieldAgents') {
      const response = await this.props.getFieldAgents();

      this.setState({ response: response.allFieldAgents, nameProperty: 'surname' });
    } else if (this.props.method === 'getAccountManagers') {
      const response = await this.props.getAccountManagers();

      this.setState({ response: response.allPersons, nameProperty: 'fullName' });
    } else if (this.props.method === 'getBanks') {
      const response = await this.props.getBanks();

      this.setState({ response: response.allBanks, nameProperty: 'bankName' });
    } else if (this.props.method === 'getAccountManagersWithResponsibility') {
      const response = await this.props.getAccountManagers();
      this.setState({
        response: response.allPersons,
        nameProperty: 'personNameWithResponsibility',
      });
    } else if (this.props.method === 'getTeams') {
      const response = await this.props.getTeams();
      if (this.props.label === 'Commercial') {
        let Array = [];
        response.allPersons.map((item, index) =>
          item.role.filter((items) => {
            if (items == 'Account Manager') {
              Array.push(item);
            }
          }),
        );
        this.setState({
          response: Array,
          nameProperty: 'fullName',
        });
      } else {
        this.setState({
          response: response.allPersons,
          nameProperty: 'fullName',
        });
      }
    } else if (this.props.method === 'getTruckNotificationsWithResponsibility') {
      const response = await this.props.getTruckNotifications();
      this.setState({
        response: response.allPersons,
        nameProperty: 'personNameWithResponsibility',
      });
    } else if (this.props.method === 'getContactForFieldStaffWithResponsibility') {
      const response = await this.props.getContactForFieldStaff();
      this.setState({
        response: response.allPersons,
        nameProperty: 'personNameWithResponsibility',
      });
    } else if (this.props.method === 'getCountries') {
      const response = await this.props.getCountries();
      this.setState({
        response: response,
        nameProperty: 'name',
      });
    } else if (this.props.method === 'getEfact') {
      const response = await this.props.getEfact();
      let efacts = [];

      for (let efact of response) {
        efacts.push({ name: `${efact.Anlagennummer} | ${efact.V_BEZEICHNUNG1}` });
      }

      this.setState({
        response: efacts,
        nameProperty: 'name',
      });
    } else if (this.props.method == 'getPartnersAndWarehouse') {
      const response = await this.props.getPartners();
      const warehouses = await this.props.getWarehouses();
      const data = warehouses.allWarehouses.concat(response.allPartners);

      this.setState({ response: data, nameProperty: 'name1' });
    }
  }

  getValue() {
    let value =
      this.props.value && typeof this.props.value !== 'string'
        ? this.props.value[0]
          ? this.props.value[0]
          : this.props.value
        : this.props.value;
    if (this.props.add) {
      return;
    } else {
      return value ? value : '';
    }
  }

  render() {
    return this.state.nameProperty && this.state.nameProperty !== '' ? (
      <Autocomplete
        style={{
          margin: 10,
          minWidth: this.props.width ? this.props.width : 300,
          maxWidth: this.props.width ? this.props.width : 300,
          display: 'inline-flex',
        }}
        id="combo-box-demo"
        value={this.state.value}
        options={this.state.response}
        onChange={(event, newValue) => {
          this.setState({ value: newValue });
          this.props.callbackValue(
            this.props.dbid,
            newValue ? newValue[this.state.nameProperty] : newValue,
            this.currentOption,
            this.props.label,
            newValue,
          );
        }}
        getOptionLabel={(option) => {
          this.currentOption = option ? option[this.state.nameProperty] : option;
          if (option === null || option === undefined) {
            return '??';
          } else if (typeof option === 'object' && this.state.nameProperty != undefined) {
            if (option[this.state.nameProperty] != undefined) {
              // return `${this.props.t(option[this.state.nameProperty])}${
              //   option ? (option.name2 ? ` (${option.name2})` : '') : ''
              // }`;
              return option[this.state.nameProperty];
            } else {
              // return `${this.props.t(this.currentOption)}${
              //   option ? (option.name2 ? ` (${option.name2})` : '') : ''
              // }`;
              return option.name2;
            }
          } else {
            return this.props.t(option);
          }
        }}
        getOptionSelected={(option) =>
          typeof this.state.value !== 'string' && this.state.value
            ? option._id === this.state.value._id
            : this.state.value
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={this.props.t(this.props.label)}
            required={this.props.required}
            inputProps={{
              ...params.inputProps,
              autoComplete: `new-password`,
            }}
          />
        )}
      />
    ) : null;
  }
}

const mapStateToProps = ({ authentication }) => ({});

export default connect(mapStateToProps, {
  getGroups,
  getBanks,
  getCompanys,
  getFieldAgents,
  getPersons,
  getCarriers,
  getPartners,
  getWarehouses,
  getDeviatingPartners,
  getAccountManagers,
  getTruckNotifications,
  sendDocument,
  getContactForFieldStaff,
  getCountries,
  getEfact,
  getTeams,
})(withTranslation('common')(DatabaseMultiChoice));
