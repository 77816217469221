import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Dropzone from 'react-dropzone';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';

function UploadSignatures({ self }) {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Paper style={{ padding: 12, height: '100%' }}>
          <Typography variant="h4" style={{ paddingBottom: 30 }}>
            {self.props.t('Upload Signature')}
          </Typography>
          <Dropzone
            onDrop={(event) => self.handleTeamSignature(event)}
            maxSize={10 * 1024 * 1024}
            multiple={false}
            onDropRejected={() => alert(self.props.t('file_size_limit'))}
          >
            {({ getRootProps, getInputProps }) => (
              <section className="dropzone-container">
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop signatures here, or click to select file</p>
                </div>

                {self.state.teamSignature && (
                  <React.Fragment>
                    <aside className="display-flex align-center">
                      <ul className="display-flex" style={{ margin: 0 }}>
                        <div className="display-flex">
                          <li className="list-style-none">
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <p
                                className={'file-link'}
                                onClick={() =>
                                  self.handleTeamSignatureDownload(self.state.teamSignature)
                                }
                              >
                                {self.state.teamSignature}
                              </p>
                            </div>
                          </li>
                        </div>
                      </ul>
                      <>
                        <DeleteIcon
                          style={{
                            color: 'red',
                            backgroundColor: 'white',
                            float: 'right',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                          onClick={() => {
                            self.setState({ teamSignature: null });
                          }}
                        />
                      </>
                    </aside>
                  </React.Fragment>
                )}
              </section>
            )}
          </Dropzone>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}

export default UploadSignatures;
