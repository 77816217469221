import React, { useState } from 'react';
import moment from 'moment';
import { getApiUrl } from '../../util/environment-utils';
import { getCountry } from '../../helpers/documents';

function Header(props) {
  const [apiUrl] = useState(getApiUrl());

  function getWeightSlipDate() {
    let articles = props.currentEntity.articles;
    let weightSlipsDate = '';

    for (let article of articles) {
      if (article.processor_weightslip_date) {
        weightSlipsDate = article.processor_weightslip_date;
        break;
      }
    }

    return weightSlipsDate;
  }

  return (
    <React.Fragment>
      <div className={`${props.client === 'belgium' ? 'belgium-logo' : 'logo text-center'}`}>
        <img src={`${apiUrl}/companys/image/${props.companyData.companyLogo}`} alt="Main Logo" />
      </div>
      <div className="header">
        <div className="row">
          <div className="left">
            {props.client !== 'belgium' && (
              <>
                <p>{`${props.companyName}, ${props.companyAddress}`}</p>
                {props.accountManager ? (
                  <div dangerouslySetInnerHTML={{ __html: props.address }} />
                ) : (
                    <React.Fragment>
                      <h4>
                        {`${props.locationData.name1}`} <br />
                        {`${props.locationData.street} ${props.locationData.houseNumber}`} <br />
                        {`${props.locationData.zipcode} ${props.locationData.city}`}
                      </h4>
                      <h4>{`${getCountry(props.locationData.country, props.partnerLanguage)}`}</h4>
                    </React.Fragment>
                  )}
              </>
            )}
          </div>
          <div className="right mt-3">
            {props.client === 'belgium' && (
              <div className={'belgium-header'}>
                <p>{`${props.companyName}, ${props.companyAddress}`}</p>
                {props.accountManager ? (
                  <div dangerouslySetInnerHTML={{ __html: props.address }} />
                ) : (
                    <React.Fragment>
                      <h4>
                        {`${props.locationData.name1}`} <br />
                        {`${props.locationData.street} ${props.locationData.houseNumber}`} <br />
                        {`${props.locationData.zipcode} ${props.locationData.city}`}
                      </h4>
                      <h4>{`${getCountry(props.locationData.country, props.partnerLanguage)}`}</h4>
                    </React.Fragment>
                  )}
              </div>
            )}
            {props.accountManager ? (
              <div className="address-box-header">
                <ul>
                  <li>
                    <span> {props.partnerLanguage === 'de' ? 'Kontakt' : 'Contact'}</span>
                    <span>
                      :{' '}
                      <a href="#">
                        {props.accountManager.firstName
                          ? `${props.accountManager.firstName} ${props.accountManager.surname}`
                          : 'N/V'}
                      </a>
                    </span>
                  </li>
                  <li>
                    <span>{props.partnerLanguage === 'de' ? 'Telefon' : 'Phone'}</span>
                    <span>: {props.accountManager.phone ? props.accountManager.phone : 'N/V'}</span>
                  </li>
                  <li>
                    <span>E-Mail </span>
                    <span>
                      :{' '}
                      {props.accountManager.emails && props.accountManager.emails.length > 0
                        ? props.accountManager.emails[0]
                        : 'N/V'}
                    </span>
                  </li>
                </ul>
              </div>
            ) : (
                <div className="address-box-header-invoice">
                  <ul>
                    <li>
                      <span>E-Mail</span>
                      <span>
                        : {props.companyData.email}
                      </span>
                    </li>
                    <li>
                      <span>{props.partnerLanguage === 'de' ? 'Unsere USt-ID' : 'Our VAT ID'}</span>
                      <span>: {props.companyData.ustID}</span>
                    </li>
                    <li>
                      <span>{props.partnerLanguage === 'de' ? 'Bestellung' : 'Order'}</span>
                      <span>: {props.currentEntity.orderNumber}</span>
                    </li>
                    <li>
                      <span>{props.partnerLanguage === 'de' ? 'Ihre USt-ID' : 'Your VAT ID'}</span>

                      <span>: {props.locationData.taxID ? props.locationData.taxID : 'N/V'}</span>
                    </li>
                    <li>
                      <span>
                        {props.partnerLanguage === 'de' ? 'Leistungsdatum' : 'Delivery date'}
                      </span>
                      <span>: {getWeightSlipDate()}</span>
                    </li>
                  </ul>
                </div>
              )}

            <p className="mt-2 date">{moment().format('DD.MM.YYYY')}</p>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
}

export default Header;
