import _ from "lodash";
import { APP_NAMESPACE } from "../../util/redux-constants";
import { put, post, get, del } from "../../util/http-utils";
import { updateStore, buildGenericInitialState, handleError } from "../../util/store-utils";
import { CHANGE_AUTH, GET_AUTHENTICATED_USER } from "./authentication";

const WEIGHTSLIP_ENDPOINT_BASE = "weightslips";
const typeBase = `${APP_NAMESPACE}/${WEIGHTSLIP_ENDPOINT_BASE}/`;

// Constants
export const GET_WEIGHTSLIPS = `${typeBase}GET_WEIGHTSLIPS`;
export const GET_WEIGHTSLIP = `${typeBase}GET_WEIGHTSLIP`;
export const ADD_WEIGHTSLIP = `${typeBase}ADD_WEIGHTSLIP`;
export const EDIT_WEIGHTSLIP = `${typeBase}EDIT_WEIGHTSLIP`;
export const DELETE_WEIGHTSLIP = `${typeBase}DELETE_WEIGHTSLIP`;

// Actions

/**
 * getUser  - Fetches user from API, given id
 *
 * @param {String} id User's id for lookup
 * @returns {Promise}
 */
export const getWeightslip = (id) => async (dispatch) => {
  try {
    const response = await get(dispatch, GET_WEIGHTSLIP, `${WEIGHTSLIP_ENDPOINT_BASE}/${id}`, true);
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err);
  }
};

/**
 * getUsers  - Fetches users from API
 *
 * @returns {Promise}
 */
export const getWeightslips = () => async (dispatch) => {
  try {
    const response = await get(dispatch, GET_WEIGHTSLIPS, WEIGHTSLIP_ENDPOINT_BASE, true);
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err, GET_WEIGHTSLIP);
  }
};

/**
 * register - Creates a new WEIGHTSLIP for a user
 * @param {Object} formData  User's form data
 */
export const addWeightslip = (formData) => async (dispatch) => {
  try {
    const response = await post(
      dispatch,
      ADD_WEIGHTSLIP,
      `${WEIGHTSLIP_ENDPOINT_BASE}/add`,
      formData,
      false
    );
    //console.log(response);

    // If the registration was successful, set the JWT as a cookie
    if (response.error === undefined) {
      alert("Added Successfully!");
      window.location.reload();
    } else {
      alert(response.error);
    }
  } catch (err) {
    await handleError(dispatch, err, CHANGE_AUTH);
  }
};

/**
 * Edit WEIGHTSLIP - updates a WEIGHTSLIP
 * @param {Object} formData  User's form data
 */
export const editWeightslip = (id, formData) => async (dispatch) => {
  try {
    console.log("EDITING WEIGHTSLIP!!");
    console.log(formData);

    const response = await put(
      dispatch,
      EDIT_WEIGHTSLIP,
      `${WEIGHTSLIP_ENDPOINT_BASE}/${id}`,
      formData,
      false
    );
    //console.log(response);

    // If the registration was successful, set the JWT as a cookie
    if (response.WeightslipUpdated) {
      alert("Edited WEIGHTSLIP Successfully");
    } else {
      alert(response.error);
    }
  } catch (err) {
    await handleError(dispatch, err, CHANGE_AUTH);
  }
};

/**
 * Delete WEIGHTSLIP - updates a WEIGHTSLIP
 * @param {Object} formData  User's form data
 */
export const deleteWeightslip = (id) => async (dispatch) => {
  try {
    console.log("DELETING WEIGHTSLIP!!");

    const response = await del(
      dispatch,
      DELETE_WEIGHTSLIP,
      `${WEIGHTSLIP_ENDPOINT_BASE}/${id}`,
      true
    );
    console.log(response);

    // If the registration was successful, set the JWT as a cookie
    if (response) {
      alert("DELETED WEIGHTSLIP Successfully");
    }
  } catch (err) {
    await handleError(dispatch, err, CHANGE_AUTH);
  }
};

// Store
const INITIAL_STATE = {
  ...buildGenericInitialState([GET_WEIGHTSLIP, GET_WEIGHTSLIPS]),
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_AUTH:
      return updateStore(
        state,
        action,
        _.get(action, "payload.user.id") ? { [action.payload.user.id]: action.payload.user } : {}
      );
    case GET_WEIGHTSLIP:
    case GET_AUTHENTICATED_USER:
      return updateStore(
        state,
        action,
        _.get(action, "payload.user.id") ? { [action.payload.user.id]: action.payload.user } : {}
      );
    case GET_WEIGHTSLIPS:
      return updateStore(
        state,
        action,
        _.get(action, "payload.users") ? _.mapKeys(action.payload.users, "id") : {}
      );
    default:
      return state;
  }
};

// Selectors
export const getAuthenticatedUser = ({ user, authentication }) => user[authentication.user];
