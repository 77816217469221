export function formatDecimals(number) {
  const numberString = number.toString();
  return numberString.indexOf('.') > -1 ? parseFloat(number).toFixed(2) : number;
}

export function formatNumberToEn(number) {
  if (number) {
    number = number.toString();
    number = convertToUs(number);
    number = number.replace(/\,/g, '');
  }

  return number;
}

function convertToUs(number) {
  number = number.replace(/,|./gi, (val) => {
    if (val === '.') {
      return ',';
    } else if (val === ',') {
      return '.';
    } else {
      return val;
    }
  });
  return number;
}

export function isEuCountry(country) {
  var countries = [
    {
      name: 'Austria',
      code: 'AT',
    },
    {
      name: 'Belgium',
      code: 'BE',
    },
    {
      name: 'Bulgaria',
      code: 'BG',
    },
    {
      name: 'Cyprus',
      code: 'CY',
    },
    {
      name: 'Czech Republic',
      code: 'CZ',
    },
    {
      name: 'Germany',
      code: 'DE',
    },
    {
      name: 'Denmark',
      code: 'DK',
    },
    {
      name: 'Estonia',
      code: 'EE',
    },
    {
      name: 'Spain',
      code: 'ES',
    },
    {
      name: 'Finland',
      code: 'FI',
    },
    {
      name: 'France',
      code: 'FR',
    },
    {
      name: 'Greece',
      code: 'GR',
    },
    {
      name: 'Croatia',
      code: 'HR',
    },
    {
      name: 'Hungary',
      code: 'HU',
    },
    {
      name: 'Ireland',
      code: 'IE',
    },
    {
      name: 'Italy',
      code: 'IT',
    },
    {
      name: 'Lithuania',
      code: 'LT',
    },
    {
      name: 'Luxembourg',
      code: 'LU',
    },
    {
      name: 'Latvia',
      code: 'LV',
    },
    {
      name: 'Malta',
      code: 'MT',
    },
    {
      name: 'Netherlands',
      code: 'NL',
    },
    {
      name: 'Poland',
      code: 'PL',
    },
    {
      name: 'Portugal',
      code: 'PT',
    },
    {
      name: 'Romania',
      code: 'RO',
    },
    {
      name: 'Sweden',
      code: 'SE',
    },
    {
      name: 'Slovenia',
      code: 'SI',
    },
    {
      name: 'Slovakia',
      code: 'SK',
    },
  ];

  let index = countries.findIndex((val) => val.name.toLowerCase() === country.toLowerCase());

  return index > -1 ? 'eu' : 'neu';
}

export function arrayUnion(dataArray) {
  dataArray = new Set(dataArray);
  dataArray = [...dataArray];
  return dataArray;
}
