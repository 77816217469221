import React from "react";
import moment from "moment";
import {
  getCompanyAddress,
  getDates,
  getGermanCollectionValues, getGermanNumber,
  getPaymentTerms,
  getRecipient,
} from '../../helpers/documents';
import { getApiUrl } from "../../util/environment-utils";
import { withTranslation } from 'react-i18next';
import Footer from './footer';

class PreliminaryPurchaseConf extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date(),
      apiUrl: getApiUrl()
    };
  }

  render() {
    return (
      <div className={"transport-order-document-container"}>
        <div className={"doc-logo"}>
          <img
            src={`${this.state.apiUrl}/companys/image/${this.props.companyData.companyLogo}`}
            width={"140"}
          />
        </div>
        {/* carrier */}
        <div className={"carrier-container"}>
          <div>
            <div className="font-10 border-bottom-solid mg-bottom-14">
              <p className={"mg-0"}>{`${this.props.companyName}, ${this.props.companyAddress}`}</p>
            </div>
            <span
              dangerouslySetInnerHTML={{
                __html: getRecipient(this.props, "purchase_confirmation", this.props.language),
              }}
            ></span>
          </div>
          <div className={'right-section'}>
            <div className="font-12">{`Kontakt: ${this.props.firstName} ${this.props.surName}`}</div>
            <div className="font-12">{`Telefon: ${this.props.phone}`}</div>
            <div className="font-12">{`E-Mail: ${this.props.email}`}</div>
            <div className="font-12">{`Datum: ${moment(this.state.today).format(
              "DD.MM.YYYY"
            )}`}</div>
          </div>
        </div>

        <div className={"padding-top-12 font-12 bold"}>
          <p className={"font-14 bold"}> Einkaufsbestätigung</p>
          Dispo-Nummer: {this.props.dispositionNumber}
        </div>

        <div className={"padding-top-12 font-12"}>
          <p className={"mg-0"}>Sehr geehrte Damen und Herren,</p>
          <br />
          <p className={"mg-0"}>
            auf Grundlage unsere allgemeinen Einkaufsbedingungen kaufen wir von Ihnen wie folgt:
          </p>
        </div>

        <div className={"padding-top-12 flex justify-space-between"}>
          <div className={"w-full"}>
            <div className="title font-14 bold title-container">Sorte/Warenbezeichnung</div>

            {this.props.currentEntity.articles.map((article, index) => (
              <div className="font-12 padding-top-5" key={index}>
                <p className={"mg-0 font-12"}>
                  {article.source_deviating && article.source_deviating !== ""
                    ? `${article.source_deviating} ${
                      article.articleDetails.avv ? `AVV ${article.articleDetails.avv}` : ""
                    }`
                    : this.props.i18n.language === 'en'
                      ? article.articleDetails.descriptionInEnglish
                      : article.articleDetails.descriptionInGerman}
                </p>
                <p className={"mg-0"}>{article.source_condition}</p>
              </div>
            ))}
          </div>

          <div>
            <div className="title font-14 bold title-container">Preis/to</div>
            {this.props.currentEntity.articles.map((article, index) => (
              <div className="font-12 padding-top-5" key={index}>
                <p className={"mg-0 font-12"}>
                  <b></b>
                </p>
                <p className={"mg-0"}>
                  {article.source_price}
                  {article.source_currency === "Euro" ? "€" : "$"}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className={"padding-top-12 flex justify-space-between"}>
          <div className={"w-full"}>
            <div className="font-12 padding-top-5">
              <p className={"mg-0"}>
                <b>Mindestladegewicht:&nbsp;</b> {`${getGermanNumber(this.props.minLoadingWeight)} t`}
              </p>
              {/*<p className={'mg-0'}><b>Lieferbedingung:&nbsp;</b> {getIncotermAddress(this.props.currentEntity.incotermSource, this.props.sourceData, this.props.processorData)}</p>*/}
              <p className={"mg-0"}>
                <b>Lieferbedingung:&nbsp;</b>
                {this.props.currentEntity.incotermSource}
              </p>
              <p className={"mg-0"}>
                <b>{getGermanCollectionValues(this.props.currentEntity.collectionSource)}:&nbsp;</b>{" "}
                {getDates(this.props.currentEntity.pickupDate)}
              </p>
              {/*<p className={'mg-0'}><b>Ansprechpartner:&nbsp;</b> {this.props.currentEntity.accountManagerSource[0].firstName} {this.props.currentEntity.accountManagerSource[0].surname}, Tel: {this.props.currentEntity.accountManagerSource[0].phone}</p>*/}
              <p className={"mg-0"}>
                <b>Ladestelle:&nbsp;</b>
                {this.props.sourceData.name1}, {getCompanyAddress(this.props.sourceData, this.props.language)}
              </p>
              <p className={"mg-0"}>
                <b>Ladereferenz:&nbsp;</b> {this.props.loadingReferenceNumber}
              </p>
              <p className={"mg-0"}>
                <b>Zahlungsbedingungen:&nbsp;</b>{" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: getPaymentTerms(
                      this.props.currentEntity.deviatingInvoiceAddressSource[0] ? this.props.currentEntity.deviatingInvoiceAddressSource[0].paymentTerms : this.props.sourceData.paymentTerms,
                      this.props.currentEntity,
                      "source",
                      this.props.language
                    ),
                  }}
                />
              </p>
            </div>
          </div>
        </div>

        <div className={"padding-top-12"}>
          <p className={"font-12"}>
            Bitte senden Sie uns direkt nach der Be- bzw. Entladung den Wiegeschein per E-Mail an
            dispo@srcone.de zu. Vielen Dank!
          </p>
          <p className={"font-12"}>Bei Fragen, stehen wir Ihnen gerne zur Verfügung.</p>
          <p className={"font-12"}>Mit freundlichen Grüßen</p>
          <p className={"font-12"}>Ihr {this.props.companyData.name2} Team</p>
        </div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default withTranslation("common")(PreliminaryPurchaseConf);
