import React from 'react';
import Grid from '@material-ui/core/Grid';
import { formatDecimals, formatNumberToEn } from '../../../helpers/common';
import { Typography } from '@material-ui/core';
import { getGermanNumber } from '../../../helpers/documents';

function Finance(props) {
  const { currentEntity = {}, t, self } = props;

  function processorTotal() {
    let articles = currentEntity.articles;
    let total = 0;
    if (articles) {
      articles.map((article) => {
        let price = !isNaN(parseFloat(article.processor_price))
          ? parseFloat(formatNumberToEn(article.processor_price))
          : 0;
        let weight = !isNaN(parseFloat(article.processor_weight))
          ? parseFloat(formatNumberToEn(article.processor_weight))
          : 0;
        if (article.processor_cashflow && article.processor_cashflow !== '') {
          let shouldSubtract = article.processor_cashflow !== 'Compensation';
          total = shouldSubtract ? total - price * weight : total + price * weight;
        } else {
          total = total + 0;
        }
      });
    }

    return formatDecimals(total);
  }

  function sourceTotal() {
    let articles = currentEntity.articles;
    let total = 0;
    if (articles) {
      articles.map((article) => {
        let price = !isNaN(parseFloat(article.source_price))
          ? parseFloat(formatNumberToEn(article.source_price))
          : 0;
        let weight = !isNaN(parseFloat(article.source_weight))
          ? parseFloat(formatNumberToEn(article.source_weight))
          : 0;

        if (article.source_cashflow && article.source_cashflow !== '') {
          let shouldSubtract = article.source_cashflow === 'Compensation';
          total = shouldSubtract ? total - price * weight : total + price * weight;
        } else {
          total = total + 0;
        }
      });
    }

    return formatDecimals(total);
  }

  function commissionTotal() {
    let articles = currentEntity.commissionArticles;
    let total = 0;
    if (articles) {
      articles.map((article) => {
        total =
          total +
          (!isNaN(parseFloat(article.commission_price))
            ? parseFloat(formatNumberToEn(article.commission_price))
            : 0);
      });
    }

    return formatDecimals(total);
  }

  function profit() {
    let total =
      parseFloat(processorTotal()) +
      parseFloat(sourceTotal()) +
      parseFloat(commissionTotal()) -
      (!isNaN(parseFloat(currentEntity.shippingCost))
        ? parseFloat(formatNumberToEn(currentEntity.shippingCost))
        : 0);
    return formatDecimals(total);
  }

  return (
    <Grid container spacing={3} className={'ml-0 mt-16 mb-16'}>
      <Grid xs={6} sm={6} md={6} lg={6}>
        <Typography variant={'h4'}>
          {props.heading} <span style={{ fontWeight: 'normal' }}>{currentEntity.orderNumber}</span>
        </Typography>
      </Grid>
      <Grid xs={6} sm={6} md={6} lg={6}>
        <Typography variant={'h4'} style={{ float: 'right', marginRight: '35px' }}>
          <span className={profit() > 0 ? 'primary' : 'error'}>
            {getGermanNumber(profit())}
            {currentEntity.articles.length > 0
              ? !currentEntity.articles[0].source_currency ||
                currentEntity.articles[0].source_currency === 'Euro'
                ? '€'
                : '$'
              : ''}
          </span>{' '}
          <span style={{ marginLeft: '12px' }}>
            {currentEntity.status && currentEntity.status.yellow && <span className={'yellow'} />}
            {currentEntity.status && currentEntity.status.orange && <span className={'orange'} />}
            {currentEntity.status && currentEntity.status.green && <span className={'green'} />}
            {currentEntity.status && currentEntity.status.checkbox && <span className={'blue'} />}
          </span>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Finance;
