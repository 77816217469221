import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Articles from '../submenus/multiArticle-new';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import DatabaseMultiChoice from '../Inputs/DatabaseMultiChoice';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const warehouse = {
  type: 'DatabaseMultiChoice',
  label: 'Warehouse',
  required: true,
  method: 'getPartnersAndWarehouse',
  dbid: 'processor',
};

function Orders(props) {
  const { state, updateState, handleAdd, self } = props;
  const { articles } = useSelector(({ orders }) => orders);


  function handleAddClick() {
    const payload = { ...state };
    payload['articles'] = articles;
    updateState(payload);

    setTimeout(() => {
      handleAdd();
    }, 200);
  }

  function handleValueChange(event, value) {
    props.handleMultipleChoiceChange({ dbid: 'vehicle' }, value);
  }

  const handleAlignment = (event, newAlignment) => {
    props.setDestinationType(newAlignment);
  };

  function handleWarehouseChange(event) {
    props.self.setFromWarehouse();
  }

  function handleAutocompleteChange(event, newValue, type) {
    self.setState({ [type]: newValue._id });
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <form noValidate autoComplete="off">
        <Grid container spacing={3}>
          <Grid item container xs={12}>
            <Paper style={{ padding: 12, width: '100%' }}>
              <Typography variant="h4" style={{ paddingBottom: 30 }}>
                {props.t('Order Partner')}
              </Typography>

              <Grid item xs={12} style={{ marginLeft: '10px' }}>
                <ToggleButtonGroup
                  value={state.destinationType}
                  exclusive
                  onChange={handleAlignment}
                  aria-label="text alignment"
                >
                  <ToggleButton
                    value="dropshippment"
                    classes={{
                      root: 'pd-5',
                    }}
                  >
                    <Typography variant="subtitle1" className={'font-12'}>
                      {props.t('Drop Shipment')}
                    </Typography>
                  </ToggleButton>
                  <ToggleButton
                    value="warehouse"
                    classes={{
                      root: 'pd-5',
                    }}
                  >
                    <Typography variant="subtitle1" className={'font-12'}>
                      {props.t('To Warehouse')}
                    </Typography>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>

              <Grid item xs={12}>
                {state.tile0[0]}
                {self.state.sourceLocations.length > 0 && (
                  <Autocomplete
                    style={{
                      margin: 10,
                      minWidth: 300,
                      maxWidth: 300,
                      display: 'inline-flex',
                    }}
                    id="combo-box-demo"
                    options={self.state.sourceLocations || []}
                    onChange={(event, newValue) => {
                      handleAutocompleteChange(event, newValue, 'sourceLocation');
                    }}
                    getOptionLabel={(option) =>
                      `${option.name ? `${option.name}, ` : ''}${option.address}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={props.t('Source Locations')}
                        autoComplete={`off`}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: `off`,
                        }}
                      />
                    )}
                  />
                )}

                {state.warehouseArticles.length > 0 && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.fromWarehouse}
                        onChange={handleWarehouseChange}
                        name="fromWarehouse"
                        color="primary"
                      />
                    }
                    label={props.t('From warehouse')}
                    style={{ width: '300px', justifyContent: 'center' }}
                  />
                )}
                {state.tile0[1]}
                {state.tile0[2]}
              </Grid>
              <Grid item xs={12}>
                {state.destinationType === 'dropshippment' ? (
                  state.tile0[3]
                ) : (
                    <DatabaseMultiChoice
                      id={warehouse.id}
                      name={warehouse.label}
                      dbid={warehouse.dbid}
                      value={self.state[warehouse.dbid]}
                      required={warehouse.required}
                      method={warehouse.method}
                      getClients={() => self.props.getClients()}
                      getCarriers={() => self.props.getCarriers()}
                      getPersons={() => self.props.getPersons()}
                      getOrders={() => self.props.getOrders()}
                      getArticles={() => self.props.getArticles()}
                      getPartners={() => self.props.getPartners()}
                      getAccountManagers={() => self.props.getAccountManagers()} //
                      label={self.props.t(warehouse.label)}
                      callbackValue={self.handleDatabaseMultiChoiceChange}
                      add={true}
                    />
                  )}
                {self.state.processorLocations.length > 0 && (
                  <Autocomplete
                    style={{
                      margin: 10,
                      minWidth: 300,
                      maxWidth: 300,
                      display: 'inline-flex',
                    }}
                    id="combo-box-demo"
                    options={self.state.processorLocations || []}
                    onChange={(event, newValue) => {
                      handleAutocompleteChange(event, newValue, 'processorLocation');
                    }}
                    getOptionLabel={(option) =>
                      `${option.name ? `${option.name}, ` : ''}${option.address}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={props.t('Processor Locations')}
                        autoComplete={`off`}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: `off`,
                        }}
                      />
                    )}
                  />
                )}
                {state.destinationType === 'dropshippment' && state.tile0[4]}
                {state.tile0[5]}
              </Grid>
              <br />
            </Paper>
            <Paper style={{ padding: 12, width: '100%', marginTop: 20 }}>
              <Typography variant="h4">{props.t('Transport')}</Typography>
              <Grid item xs={12}>
                {state.tile0[6]}
                {state.tile0[7]}
                {state.tile0[8]}
                {state.tile0[9]}
                {/*</Grid>*/}
                {/*<Grid item xs={12}>*/}
                {state.tile0[10]}
                {state.tile0[11]}
                <Autocomplete
                  style={{
                    margin: 10,
                    minWidth: 200,
                    maxWidth: 200,
                    display: 'inline-flex',
                  }}
                  id="tags-outlined"
                  options={props.state.vehicleTypes || []}
                  getOptionLabel={(option) => props.t(option)}
                  value={props.t(props.state['vehicle'] || '')}
                  onChange={(event, value) => handleValueChange(event, value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={props.t('Vehicle')}
                      placeholder={props.t('Vehicle')}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: `off`,
                      }}
                    />
                  )}
                />
                {state.tile0[13]}
                {state.tile0[14]}
              </Grid>
              <br />
              <Grid item xs={12}>
                {state.tile0[15]}
                {state.tile0[16]}
                {state.tile0[17]}
                {state.tile0[18]}
                {state.tile0[19]}
              </Grid>
              <Grid item xs={12}>
                <span style={{ marginLeft: '292px' }} />
                {state.tile0[20]}
                {state.tile0[21]}
                {state.tile0[22]}
                {state.tile0[23]}
              </Grid>
            </Paper>
            <Paper style={{ padding: 12, width: '100%', marginTop: 20 }}>
              {state.tile3}
              <Articles
                variant={'add'}
                state={state}
                orderType={state.destinationType}
                warehouseArticles={state.warehouseArticles}
                fromWarehouse={state.fromWarehouse}
                sourceCountry={
                  self.state.sourceLocation
                    ? self.state.sourceLocation.country
                    : self.state.selectedSource ? self.state.selectedSource.country : ""
                }
                processorCountry={
                  self.state.processorLocation
                    ? self.state.processorLocation.country
                    : self.state.selectedProcessor ? self.state.selectedProcessor.country : ""
                }
              />
            </Paper>
          </Grid>
        </Grid>
      </form>
      <Button
        variant="contained"
        color="primary"
        startIcon={<CloudUploadIcon />}
        style={{ marginTop: 20, backgroundColor: 'white', color: 'green' }}
        onClick={() => handleAddClick()}
      >
        {`${props.t('ADD')} ${props.t('ORDERS')}`}
      </Button>
    </div>
  );
}

export default withTranslation('common')(Orders);
