import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import { getArticles } from '../../redux/modules/article';
import * as Actions from '../../redux/store/actions/contracts';
import { withTranslation } from 'react-i18next';

function ContractArticles(props) {
  const dispatch = useDispatch();
  const { contract, variant, state } = props;
  const { articles } = useSelector(({ contracts }) => contracts);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function fetchArticles() {
      let data = await dispatch(getArticles());
      setOptions(data.allArticles);
    }

    fetchArticles();
  }, [dispatch]);

  useEffect(() => {
    if (variant === 'edit' && contract.articles.length > 0) {
      dispatch(
        Actions.setValues([
          {
            article_id: '',
            article_name: '',
            deviating_article_name: '',
            currency: 'Euro',
            price: '',
            loads: '',
            unit: 't',
            container_type: '40ft HC',
            weight_per_load: '',
          },
        ]),
      );

      setTimeout(() => {
        let allArticles = contract.articles;
        dispatch(Actions.setValues(allArticles));
      });
    } else if (variant === 'add' || contract.articles.length === 0) {
      dispatch(
        Actions.setValues([
          {
            article_id: '',
            article_name: '',
            deviating_article_name: '',
            currency: 'Euro',
            price: '',
            loads: '',
            unit: 't',
            container_type: '40ft HC',
            weight_per_load: '',
          },
        ]),
      );
    }
  }, [contract, variant]);

  function addNewRow() {
    dispatch(Actions.add(props.i18n.language));
  }

  function deleteRow(id) {
    dispatch(Actions.remove(id));
  }

  function handleAutocompleteChange(event, newValue, index, type) {
    let data = articles;
    if (type === 'article') {
      if (newValue) {
        data[index]['article_name'] = `${newValue.descriptionInEnglish} ${
          newValue.avv ? `AVV ${newValue.avv}` : ''
        }`;
        data[index]['articleDetails'] = newValue;
        data[index]['article_id'] = newValue._id;
      } else {
        data[index]['article_name'] = '';
        data[index]['articleDetails'] = {};
        data[index]['article_id'] = null;
      }
    } else {
      let val = newValue ? (newValue.inputValue ? newValue.inputValue : newValue) : '';
      data[index][type] = val;
    }

    dispatch(Actions.setValues(data));
  }

  function handleChange(event, index) {
    let data = articles;

    if (event.target.name === 'loads') {
      data[index]['weight_per_load'] = parseInt(event.target.value) * 22;
    }
    data[index][event.target.name] = event.target.value;

    dispatch(Actions.setValues(data));
  }

  return (
    <div>
      <Typography variant="h4" style={{ paddingTop: 30, display: 'inline-block' }}>
        {props.t(state.header3)}
      </Typography>
      <AddCircleOutlineIcon
        style={{ fontSize: 28, position: 'relative', top: -2, marginLeft: 15, cursor: 'pointer' }}
        color="primary"
        onClick={() => {
          addNewRow();
        }}
      />

      {articles.map((article, index) => (
        <div key={index} style={{ paddingTop: 12, height: '100%' }} className="expense-block">
          <div className="display-flex justify-space-betweens align-center">
            <div>
              <Autocomplete
                style={{
                  margin: 10,
                  minWidth: 300,
                  maxWidth: 300,
                  display: 'inline-flex',
                }}
                id="combo-box-demo"
                value={
                  props.i18n.language === 'en'
                    ? article.articleDetails
                      ? article.articleDetails.descriptionInEnglish
                      : null
                    : article.articleDetails
                    ? article.articleDetails.descriptionInGerman
                    : null
                }
                options={options}
                onChange={(event, newValue) => {
                  handleAutocompleteChange(event, newValue, index, 'article');
                }}
                getOptionLabel={(option) =>
                  option.descriptionInEnglish
                    ? `${
                        props.i18n.language === 'en'
                          ? option.descriptionInEnglish
                          : option.descriptionInGerman
                      } ${option.avv ? `(AVV ${option.avv})` : ''}`
                    : option
                }
                getOptionSelected={(option) => article.article_id === option._id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={props.t('Article')}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />

              <br />
              <span className="article_label">{props.t('Sales')} : </span>
              <TextField
                style={{ margin: 10, minWidth: '300px' }}
                value={article.price}
                onChange={(event) => handleChange(event, index)}
                required={false}
                label={`${props.t('Price')}`}
                name={'price'}
              />

              <Autocomplete
                style={{
                  margin: 10,
                  minWidth: 150,
                  maxWidth: 150,
                  display: 'inline-flex',
                }}
                id="combo-box-demo"
                value={article.currency || 'Euro'}
                options={['Euro', 'USD']}
                onChange={(event, newValue) =>
                  handleAutocompleteChange(event, newValue, index, 'currency')
                }
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${props.t('currency')}`}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />

              <Autocomplete
                style={{
                  margin: 10,
                  minWidth: 150,
                  maxWidth: 150,
                  display: 'inline-flex',
                }}
                id="combo-box-demo"
                value={article.unit || 't'}
                options={['t', 'Kg', 'Piece']}
                onChange={(event, newValue) =>
                  handleAutocompleteChange(event, newValue, index, 'unit')
                }
                getOptionLabel={(option) => props.t(option)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${props.t('unit')}`}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />

              <TextField
                style={{ margin: 10, minWidth: '150px' }}
                value={article.loads}
                onChange={(event) => handleChange(event, index)}
                required={false}
                label={`${props.t('Loads')}`}
                name={'loads'}
              />

              <Autocomplete
                style={{
                  margin: 10,
                  minWidth: 150,
                  maxWidth: 150,
                  display: 'inline-flex',
                }}
                id="combo-box-demo"
                value={article.container_type || '40ft HC'}
                options={['40ft HC']}
                onChange={(event, newValue) =>
                  handleAutocompleteChange(event, newValue, index, 'container_type')
                }
                getOptionLabel={(option) => props.t(option)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${props.t('container_type')}`}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />

              <TextField
                style={{ margin: 10, minWidth: '250px' }}
                value={article.deviating_article_name}
                onChange={(event) => handleChange(event, index)}
                label={`${props.t('deviating_article_name')}`}
                name={'deviating_article_name'}
                required={true}
              />
              <TextField
                style={{ margin: 10, minWidth: '250px' }}
                value={article.weight_per_load || ''}
                onChange={(event) => handleChange(event, index)}
                label={`${props.t('weight_per_load')}`}
                name={'weight_per_load'}
                required={true}
                disabled={!article.loads || article.loads.length === 0}
              />
            </div>
            <div>
              <span
                style={{
                  color: 'red',
                  float: 'right',
                  margin: 12,
                  cursor: 'pointer',
                  fontSize: 30,
                }}
                onClick={() => {
                  deleteRow(index);
                }}
                class="material-icons"
              >
                remove_circle
              </span>
            </div>
          </div>
          <hr style={{ marginTop: 30, width: '100%', color: 'lightgray' }} />
        </div>
      ))}
    </div>
  );
}

export default withTranslation('common')(ContractArticles);
