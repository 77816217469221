import React from "react";
import TextField from "@material-ui/core/TextField";
import { withTranslation } from "react-i18next";

var IBAN = require("iban");

class IbanTextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iban: ''
    };
  }

  componentDidMount() {
    this.setState({ ...this.props });
  }

  handleTextFieldChange = async (evt) => {
    evt.persist();
    const value = evt.target.value;

    await this.setState({
      [evt.target.name]: value,
    });
    await this.props.callbackValue(evt.target.name, value);
    if (IBAN.isValid(value)) {
      let bic;
      var headers = new Headers();

      headers.append("Authorization", "Basic " + btoa("infens_GmbH" + ":" + "zetzaf-Nyqvim-xibcu7"));
      fetch(`https://rest.sepatools.eu/validate_iban/${value}`, { headers: headers })
        .then((response) => response.json())
        .then(async (data) => {
          bic = data.bic_candidates[0].bic;
          await this.setState({ bic: bic, bankName: data.bank });
          await this.props.callbackValue("bic", bic);
          await this.props.callbackValue("bankName", data.bank);
        });
    }
  };

  handleTextFieldChangeBic = async (evt) => {
    evt.persist();
    const value = evt.target.value;
    await this.props.callbackValue(evt.target.name, value);
    await this.setState({ bic: value });
  };

  handleTextFieldChangeBankName = (e) => {
    e.persist();
    const value = e.target.value;
    const name = e.target.name;
    this.setState({
      [name]: value,
    });
    this.props.callbackValue(name, value);
  };
  render() {
    return (
      <React.Fragment>
        <div style={{ margin: 10, display: "inline" }}>
          {IBAN.isValid(this.state.iban) || this.state.iban == "" ? (
            <TextField
              key={this.props.obj.label}
              style={{ marginTop: 10, width: 300 }}
              onChange={this.handleTextFieldChange}
              name={this.props.obj.dbid}
              required={this.props.obj.required}
              label={this.props.obj.label}
              defaultValue={this.state.iban}
              value={this.state.iban}
            />
          ) : (
            <TextField
              error
              key={this.props.obj.label}
              style={{ marginTop: 10, width: 300 }}
              onChange={this.handleTextFieldChange}
              name={this.props.obj.dbid}
              required={this.props.obj.required}
              label={this.props.obj.label}
              id="standard-error-helper-text"
              defaultValue={this.state.iban}
              value={this.state.iban}
              helperText={this.props.t("Invalid") + this.props.t("IBAN") + "."}
            />
          )}
        </div>
        {IBAN.isValid(this.state.iban) && this.state.bic != undefined && (
          <div style={{ margin: 10, display: "inline" }}>
            <TextField
              key={"BIC"}
              style={{ marginTop: 10, width: 300 }}
              name={"bic"}
              label={"BIC"}
              onChange={this.handleTextFieldChangeBic}
              value={this.state.bic}
              required={this.props.obj.required}
            />
            <TextField
              key={"BankName"}
              style={{ marginTop: 10, marginLeft: 20, width: 300 }}
              name={"bankName"}
              label={"Bank name"}
              onChange={this.handleTextFieldChangeBankName}
              value={this.state.bankName}
              required={this.props.obj.required}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation("common")(IbanTextInput);
