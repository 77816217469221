import _ from "lodash";
import { APP_NAMESPACE } from "../../util/redux-constants";
import { put, post, get, del } from "../../util/http-utils";
import { updateStore, buildGenericInitialState, handleError } from "../../util/store-utils";
import { CHANGE_AUTH, GET_AUTHENTICATED_USER } from "./authentication";
import { ARTICLE_TABLE_COLUMNS } from "../../constants/ui-constants";
import { showMessage } from '../store/actions/alerts';

const ARTICLE_ENDPOINT_BASE = "articles";
const typeBase = `${APP_NAMESPACE}/${ARTICLE_ENDPOINT_BASE}/`;

// Constants
export const GET_ARTICLES = `${typeBase}GET_ARTICLES`;
export const GET_ARTICLE = `${typeBase}GET_ARTICLE`;
export const ADD_ARTICLE = `${typeBase}ADD_ARTICLE`;
export const EDIT_ARTICLE = `${typeBase}EDIT_ARTICLE`;
export const DELETE_ARTICLE = `${typeBase}DELETE_ARTICLE`;
export const GET_ARTICLE_TYPES = `${typeBase}GET_ARTICLE_TYPES`;

// Actions

/**
 * getUser  - Fetches user from API, given id
 *
 * @param {String} id User's id for lookup
 * @returns {Promise}
 */
export const getArticle = (id) => async (dispatch) => {
  try {
    const response = await get(dispatch, GET_ARTICLE, `${ARTICLE_ENDPOINT_BASE}/${id}`, true);
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err);
  }
};

/**
 * getUsers  - Fetches users from API
 *
 * @returns {Promise}
 */
export const getArticles = () => async (dispatch) => {
  try {
    const response = await get(dispatch, GET_ARTICLES, ARTICLE_ENDPOINT_BASE, true);
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err, GET_ARTICLE);
  }
};

/**
 * register - Creates a new ARTICLE for a user
 * @param {Object} formData  User's form data
 */
export const addArticle = (formData, callback, currentTabName, currentTabNumber) => async (
  dispatch
) => {
  try {
    let keysToRemove = ['tile0', 'tile1', 'tile2', 'tile3', 'tile4', 'tile5', 'tileData'];

    for (let key of Object.keys(formData)) {
      if (keysToRemove.includes(key)) delete formData[key];
    }

    const response = await post(
      dispatch,
      ADD_ARTICLE,
      `${ARTICLE_ENDPOINT_BASE}/add`,
      formData,
      false
    );
    //console.log(response);

    // If the registration was successful, set the JWT as a cookie
    if (response.error === undefined) {
      callback(currentTabName, currentTabNumber, ARTICLE_TABLE_COLUMNS, "Added Successfully!");
    } else {
      dispatch(showMessage({ message: response.error, messageType: "error" }))
    }
  } catch (err) {
    await handleError(dispatch, err, CHANGE_AUTH);
  }
};

/**
 * Edit ARTICLE - updates a ARTICLE
 * @param {Object} formData  User's form data
 */
export const editArticle = (id, formData) => async (dispatch) => {
  try {
    let keysToRemove = ['tile0', 'tile1', 'tile2', 'tile3', 'tile4', 'tile5', 'tileData'];

    for (let key of Object.keys(formData)) {
      if (keysToRemove.includes(key)) delete formData[key];
    }

    const response = await put(
      dispatch,
      EDIT_ARTICLE,
      `${ARTICLE_ENDPOINT_BASE}/${id}`,
      formData,
      false
    );
    //console.log(response);

    // If the registration was successful, set the JWT as a cookie
    if (response.ArticleUpdated) {
      dispatch(showMessage({ message: "Edited ARTICLE Successfully", messageType: "success" }))
    } else {
      dispatch(showMessage({ message: response.error, messageType: "error" }))
    }
  } catch (err) {
    await handleError(dispatch, err, CHANGE_AUTH);
  }
};

/**
 * Delete ARTICLE - updates a ARTICLE
 * @param {Object} formData  User's form data
 */
export const deleteArticle = (id, callback) => async (dispatch) => {
  try {
    console.log("DELETING ARTICLE!!");

    const response = await del(dispatch, DELETE_ARTICLE, `${ARTICLE_ENDPOINT_BASE}/${id}`, true);
    console.log(response);

    // If the registration was successful, set the JWT as a cookie
    if (response) {
      callback("Deleted ARTICLE Successfully");
    }
  } catch (err) {
    await handleError(dispatch, err, CHANGE_AUTH);
  }
};

export const getArticleTypes = (payload) => async (dispatch) => {
  try {
    const response = await post(
      dispatch,
      GET_ARTICLE_TYPES,
      `${ARTICLE_ENDPOINT_BASE}/types`,
      payload,
      true
    );
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err, CHANGE_AUTH);
  }
};

// Store
const INITIAL_STATE = {
  ...buildGenericInitialState([GET_ARTICLE, GET_ARTICLES]),
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_AUTH:
      return updateStore(
        state,
        action,
        _.get(action, "payload.user.id") ? { [action.payload.user.id]: action.payload.user } : {}
      );
    case GET_ARTICLE:
    case GET_AUTHENTICATED_USER:
      return updateStore(
        state,
        action,
        _.get(action, "payload.user.id") ? { [action.payload.user.id]: action.payload.user } : {}
      );
    case GET_ARTICLES:
      return updateStore(
        state,
        action,
        _.get(action, "payload.users") ? _.mapKeys(action.payload.users, "id") : {}
      );
    default:
      return state;
  }
};

// Selectors
export const getAuthenticatedUser = ({ user, authentication }) => user[authentication.user];
