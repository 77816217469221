import React from "react";
import { DatePicker, DateInput } from "@progress/kendo-react-dateinputs";
import "@progress/kendo-react-intl";
import "@progress/kendo-react-tooltip";
import "@progress/kendo-react-common";
import "@progress/kendo-react-popup";
import "@progress/kendo-react-buttons";
import "@progress/kendo-date-math";
import "@progress/kendo-react-dropdowns";
import TextField from "@material-ui/core/TextField";
import { withTranslation } from "react-i18next";

const RenderMyDateInput = (props) => {
  if (props.value !== null) {
    return <DateInput value={props.value} onChange={props.onChange} format="dd.MM.yyyy" />;
  }
  return (
    <TextField
      className="k-textbox"
      onFocus={(e) => {
        e.target.value = "";
        props.onChange(e);
      }}
      label={props.ariaLabelledBy}
      required={props.required}
    />
  );
};

function KendoDatePicker(props) {
  const [ddlState, setDdlState] = React.useState();
  const editorId = "sizes-editor";
  const labelId = "sizes-label";

  return (
    <div
      style={{
        position: "relative",
        margin: 10,
        display: "inline-flex",
        bottom: "3px",
        color: "#D3D3D3",
      }}
    >
      <DatePicker
        culture={"de-DE"}
        id={props.id}
        ariaLabelledBy={props.t(props.label)}
        style={{ color: "#D3D3D3", position: "absolute", margin: 10, bottom: "30px", width: 3000 }}
        className={"date-picker-order"}
        weekNumber={true}
        firstDay={"mon"}
        format="dd.MM.yyyy"
        value={ddlState || (props.defaultValue ? new Date(props.defaultValue) : null)}
        dateInput={RenderMyDateInput}
        onChange={(event) => {
          if (props["dbid"] === "pickupDate" || props["dbid"] === "deliveryDate") {
            props.callbackValue(props["dbid"], { startDate: event.target.value });
          } else {
            props.callbackValue(props["dbid"], event.target.value);
          }

          if (event.target.value)
            setDdlState(new Date(event.target.value));
        }}
      />
    </div>
  );
}

export default withTranslation("common")(KendoDatePicker);
