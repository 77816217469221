import React from 'react';
import { getDates } from '../../helpers/documents';
import { getApiUrl } from '../../util/environment-utils';
import { withTranslation } from 'react-i18next';

const ldpeData = [
  {
    article: 'LDPE 98/2',
    ballen: '',
    appreciation: '',
  },
  {
    article: 'LDPE 95/5',
    ballen: '',
    appreciation: 'Zu 98/2',
  },
  {
    article: 'LDPE 90/10',
    ballen: '',
    appreciation: 'Zu 95/5',
  },
  {
    article: 'LDPE 80/20',
    ballen: '',
    appreciation: 'Zu 90/10',
  },
  {
    article: 'LDPE 60/40',
    ballen: '',
    appreciation: 'Zu 80/20',
  },
  {
    article: 'LDPE bunt',
    ballen: '',
    appreciation: 'Zu 80/20',
  }
];

let bigbags = [
  {
    article: 'PP Big Bags A',
  },
  {
    article: 'PP Big Bags B',
  },
  {
    article: 'Big Bags C',
  },
]

class Assessment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date(),
      apiUrl: getApiUrl(),
    };
  }

  getArticleName = (article) => {
    return article.article_name;
  };

  valid = (article) => {
    let status = true;

    let descriptionInGermanCheck = article.articleDetails.descriptionInGerman
      ? article.articleDetails.descriptionInGerman.toLowerCase().includes('ldpe') ||
        article.articleDetails.descriptionInGerman.toLowerCase().includes('big bags')
      : true;
    let descriptionInEnglishCheck = article.articleDetails.descriptionInEnglish
      ? article.articleDetails.descriptionInEnglish.toLowerCase().includes('ldpe') ||
        article.articleDetails.descriptionInEnglish.toLowerCase().includes('big bags')
      : true;

    if (descriptionInGermanCheck && descriptionInEnglishCheck) {
      status = false;
    }

    return status;
  };

  render() {
    return (
      <div className={'transport-order-document-container assessment-doc'}>
        <div className={'doc-logo'}>
          <img
            src={`${this.state.apiUrl}/companys/image/${this.props.companyData.companyLogo}`}
            width={'140'}
          />
        </div>
        <div className={'flex justify-space-between align-center'}>
          <div>
            <span className={'font-14 underline bold'}>EINGANGSBEURTEILUNG</span>
            <span className={'font-12t'}>
              &nbsp;({this.props.processorData ? this.props.processorData.name1 : 'N/A'})
            </span>
          </div>
        </div>

        <table className={'margin-top-30'}>
          <tr>
            <td className={'font-12 width-200'}>Lieferant (Anfallstelle)</td>
            <td className={'font-12'}>{this.props.sourceData.name1}</td>
          </tr>
          <tr>
            <td className={'font-12 width-200'}>Einkäufer (ADM)</td>
            <td className={'font-12'}>{`${this.props.firstName} ${this.props.surName}`}</td>
          </tr>
          <tr>
            <td className={'font-12 width-200'}>Lieferdatum</td>
            <td className={'font-12'}>{getDates(this.props.currentEntity.deliveryDate)}</td>
          </tr>
          <tr>
            <td className={'font-12 width-200'}>Transporteur (Spedi)</td>
            <td className={'font-12'}>
              {this.props.carrierData ? this.props.carrierData.name1 : 'N/V'}
            </td>
          </tr>
          <tr>
            <td className={'font-12 width-200'}>Gesamtgewicht</td>
            <td className={'font-12'}>{this.props.currentEntity.minLoadingWeight}</td>
          </tr>
          <tr>
            <td className={'font-12 width-200'}>Auftrags-Nr.</td>
            <td className={'font-12'}>{this.props.currentEntity.orderNumber}</td>
          </tr>
          <tr>
            <td className={'font-12 width-200'}>LKW Kennzeichen</td>
            <td className={'font-12'}></td>
          </tr>
          <tr>
            <td className={'font-12 width-200'}>Einkaufsqualität (Artieklbez)</td>
            <td className={'font-12'}>ArticleDescription</td>
          </tr>
        </table>

        <table className={'margin-top-30 article-table'}>
          <tr>
            <th className={'font-12'}>Artikel</th>
            <th className={'font-12'}>Anzahl Ballen EK</th>
            <th className={'font-12'}>Aufwertung (Anzahl Ballen)</th>
          </tr>

          {this.props.currentEntity.articles.map(
            (article, index) =>
              this.valid(article) && (
                <tr key={index}>
                  <td className={'font-12'}>{this.getArticleName(article)}</td>
                  <td></td>
                  <td></td>
                </tr>
              ),
          )}

          {this.props.articleTypes.length > 0 && (
            <React.Fragment>
              {ldpeData.map((val) => (
                <tr>
                  <td className={'font-12'}>{val.article}</td>
                  <td>{val.ballen}</td>
                  <td>{val.appreciation}</td>
                </tr>
              ))}
            </React.Fragment>
          )}
        </table>
      </div>
    );
  }
}

export default withTranslation('common')(Assessment);
