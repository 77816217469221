import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { reducer as formReducer } from 'redux-form';
import reduxThunk from 'redux-thunk';
import userReducer from './modules/user';
import authenticationReducer from './modules/authentication';
import partners from './store/reducers/partners';
import persons from './store/reducers/persons';
import orders from './store/reducers/orders';
import stocks from './store/reducers/stocks';
import alerts from './store/reducers/alerts';
import taxes from './store/reducers/taxes';
import common from './store/reducers/common';
import contracts from './store/reducers/contract';

const createStoreWithMiddleware = compose(
  applyMiddleware(reduxThunk),
  window.devToolsExtension ? window.devToolsExtension() : (f) => f,
)(createStore);

const rootReducer = combineReducers({
  authentication: authenticationReducer,
  user: userReducer,
  form: formReducer,
  partners,
  persons,
  orders,
  stocks,
  alerts,
  taxes,
  common,
  contracts
});

const configureStore = (initialState) => createStoreWithMiddleware(rootReducer, initialState);
export default configureStore;
