import _ from 'lodash';
import {APP_NAMESPACE} from '../../util/redux-constants';
import {put, post, get, del} from '../../util/http-utils';
import {updateStore, buildGenericInitialState, handleError} from '../../util/store-utils';
import {CHANGE_AUTH, GET_AUTHENTICATED_USER} from './authentication';
import { CLIENT_TABLE_COLUMNS } from '../../constants/ui-constants';
import { showMessage } from '../store/actions/alerts';

const WAREHOUSE_ENDPOINT_BASE = 'warehouses';
const typeBase = `${APP_NAMESPACE}/${WAREHOUSE_ENDPOINT_BASE}/`;

// Constants
export const GET_WAREHOUSES = `${typeBase}GET_WAREHOUSES`;
export const GET_WAREHOUSE = `${typeBase}GET_WAREHOUSE`;
export const ADD_WAREHOUSE = `${typeBase}ADD_WAREHOUSE`;
export const EDIT_WAREHOUSE = `${typeBase}EDIT_WAREHOUSE`;
export const DELETE_WAREHOUSE = `${typeBase}DELETE_WAREHOUSE`;

// Actions

/**
 * getUser  - Fetches user from API, given id
 *
 * @param {String} id User's id for lookup
 * @returns {Promise}
 */
export const getWarehouse = (id) => async (dispatch) => {
  try {
    const response = await get(dispatch, GET_WAREHOUSE, `${WAREHOUSE_ENDPOINT_BASE}/${id}`, true);
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err, GET_WAREHOUSE);
  }
};

/**
 * getUsers  - Fetches users from API
 *
 * @returns {Promise}
 */
export const getWarehouses = () => async (dispatch) => {
  try {
    const response = await get(dispatch, GET_WAREHOUSES, WAREHOUSE_ENDPOINT_BASE, true);
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err, GET_WAREHOUSES);
  }
};

export const getDeviatingWarehouses = () => async (dispatch) => {
  try {
    const response = await post(dispatch, GET_WAREHOUSES, WAREHOUSE_ENDPOINT_BASE + '/deviating',{}, true);
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err, GET_WAREHOUSES);
  }
};

/**
 * register - Creates a new PARTNER for a user
 * @param {Object} formData  User's form data
 */
export const addWarehouse = (formData, callback, currentTabName, currentTabNumber) => async (dispatch, getState) => {
    try {
      formData.openingHours = getState().partners.openingHours
      const response = await post(
        dispatch,
        ADD_WAREHOUSE,
        `${WAREHOUSE_ENDPOINT_BASE}/add`,
        formData,
        false,
      );

      callback(currentTabName, currentTabNumber, CLIENT_TABLE_COLUMNS, 'Added Successfully!', response.error);
    } catch (err) {
      await handleError(dispatch, err, CHANGE_AUTH);
    }
  }
;

/**
 * Edit PARTNER - updates a PARTNER
 * @param {Object} formData  User's form data
 */
export const editWarehouse = (id, formData) => async (dispatch, getState) => {
  try {
    console.log('EDITING PARTNER!!');

    formData.openingHours = getState().partners.openingHours
    const response = await put(
      dispatch,
      EDIT_WAREHOUSE,
      `${WAREHOUSE_ENDPOINT_BASE}/${id}`,
      formData,
      false,
    );
    //console.log(response);

    // If the registration was successful, set the JWT as a cookie
    if (response.PartnerUpdated) {
      dispatch(showMessage({ message: "Edited WAREHOUSE Successfully", messageType: "success" }))
    } else {
      dispatch(showMessage({ message: response.error, messageType: "error" }))
    }
  } catch (err) {
    await handleError(dispatch, err, CHANGE_AUTH);
  }
};

/**
 * Delete PARTNER - updates a PARTNER
 * @param {Object} formData  User's form data
 */
export const deleteWarehouse = (id, callback) => async (dispatch) => {
  try {
    console.log('DELETING PARTNER!!');

    const response = await del(dispatch, DELETE_WAREHOUSE, `${WAREHOUSE_ENDPOINT_BASE}/${id}`, true);
    console.log(response);

    // If the registration was successful, set the JWT as a cookie
    if (response) {
      callback('Deleted WAREHOUSE Successfully')
    }
  } catch (err) {
    await handleError(dispatch, err, CHANGE_AUTH);
  }
};

// Store
const INITIAL_STATE = {
  ...buildGenericInitialState([GET_WAREHOUSE, GET_WAREHOUSES]),
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_AUTH:
      return updateStore(
        state,
        action,
        _.get(action, 'payload.user.id') ? {[action.payload.user.id]: action.payload.user} : {},
      );
    case GET_WAREHOUSE:
    case GET_AUTHENTICATED_USER:
      return updateStore(
        state,
        action,
        _.get(action, 'payload.user.id') ? {[action.payload.user.id]: action.payload.user} : {},
      );
    case GET_WAREHOUSES:
      return updateStore(
        state,
        action,
        _.get(action, 'payload.users') ? _.mapKeys(action.payload.users, 'id') : {},
      );
    default:
      return state;
  }
};

// Selectors
export const getAuthenticatedUser = ({user, authentication}) => user[authentication.user];
