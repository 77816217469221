import React from 'react';
import TextField from '@material-ui/core/TextField';
import { getArticles } from '../../redux/modules/article';
import DeleteIcon from '@material-ui/icons/Delete';
import { Checkbox } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { withTranslation } from 'react-i18next';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Dropzone from 'react-dropzone';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import axios from 'axios';
import { getApiUrl } from '../../util/environment-utils';
var _ = require('lodash');

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

class MultiInvoice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: '',
      fieldType: '',
      deleteIncomingDoc: [],
    };
  }

  componentDidMount() {
    let key = '';
    let fieldType = '';
    if (this.props.entityType === 'Processor') {
      key = 'processorEntries';
      fieldType = 'processor';
    } else if (this.props.entityType === 'Source') {
      key = 'sourceEntries';
      fieldType = 'source';
    } else {
      key = 'carrierEntries';
      fieldType = 'carrier';
    }
    this.setState({ type: key, fieldType: fieldType });
  }

  onDrop = (files, index) => {
    let data = new FormData();
    let BASE_URL = getApiUrl();
    data.append('files', files[0]);

    axios.post(`${BASE_URL}/orders/upload`, data, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    }).then(
      (res) => {
        let fileName = res.data.fileName;
        let entries = this.props.self.state[this.props.type];
        if (entries[index]['files'] && entries[index]['files'].length > 0) {
          entries[index]['files'].push(fileName);
        } else {
          entries[index]['files'] = [fileName];
        }
        this.props.self.setState({ [this.state.type]: entries });
      },
      (error) => {
        console.log(error);
      },
    );
  };

  addNewRow = () => {
    let entries = this.props.self.state[this.props.type];
    entries.push({
      firstField: '',
      invoiceNumber: '',
      invoiceDate: null,
    });

    this.props.self.setState({ [this.props.type]: entries });
  };

  deleteRow = (index) => {
    let entries = this.props.self.state[this.props.type];
    entries.splice(index, 1);

    this.props.self.setState({ [this.props.type]: entries });
  };

  handleTextFieldChange = (evt, index) => {
    let entries = this.props.self.state[this.props.type];
    entries[index][evt.target.name] = evt.target.value;

    this.props.self.setState({ [this.props.type]: entries });
  };

  handleDateChange = (date, index, type) => {
    let entries = this.props.self.state[this.props.type];
    entries[index][type] = moment(date).format('DD.MM.YYYY');

    this.props.self.setState({ [this.props.type]: entries });
  };

  handleAutocompleteChange = (event, value, index) => {
    let entries = this.props.self.state[this.props.type];
    entries[index][this.state.fieldType] = value;

    this.props.editOrderInstance.setState({ [this.state.fieldType]: [value] })
    this.props.self.setState({ [this.state.fieldType]: entries });
  };

  deleteAttachment = (index, fileIndex) => {
    let value = this.state.deleteIncomingDoc.find((x) => x.mainIndex === index);
    let entries = this.props.self.state[this.props.type];
    value.checkbox.map((data, i) => {
      entries[index]['files'].splice(data, 1);
    });

    this.props.self.setState({ [this.state.type]: entries });
  };

  handleDownload = (name) => {
    this.props.self.handleFileDownload(name);
  };
  getArticlesCheck = (e, file, index, mainIndex) => {
    let value = this.state.deleteIncomingDoc.find((x) => x.mainIndex === mainIndex);
    if (value !== undefined) {
      if (e.target.checked) {
        value.checkbox.push(index);
        this.setState(this.state.deleteIncomingDoc);
      } else {
        for (var i in value.checkbox) {
          if (value.checkbox[i] == index) {
            value.checkbox.splice(index, 1);
            break;
          }
        }
        this.setState(this.state.deleteIncomingDoc);
      }
    } else {
      let newdata = [];
      newdata.push(index);
      this.setState({
        deleteIncomingDoc: [
          ...this.state.deleteIncomingDoc,
          { mainIndex: mainIndex, checkbox: newdata },
        ],
      });
    }
  };
  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div style={{ marginTop: '5px' }}>
          {(this.props.self.state[this.props.type] || []).map((entry, i) => {
            return (
              <div
                style={{ padding: 12, height: '100%' }}
                className="expense-block"
                key={`key_${i}`}
              >
                <div style={{ padding: 5 }}>
                  <br />
                  <div className="display-flex align-center">
                    <Grid
                      container
                      spacing={3}
                      style={{ flexWrap: 'nowrap', marginLeft: '2px', maxWidth: '40%' }}
                    >
                      <span className="article_label display-inline-flex">
                        {this.props.entityType.toLowerCase() === 'source'
                          ? 'Purchase'
                          : this.props.entityType.toLowerCase() === 'processor'
                          ? 'Sales'
                          : 'Transport'}
                        &nbsp;:
                      </span>
                      {this.props.entityType.toLowerCase() === 'forwarder' ? (
                        <Autocomplete
                          style={{
                            margin: 10,
                            minWidth: 170,
                            maxWidth: 170,
                            display: 'inline-flex',
                          }}
                          id="combo-box-demo"
                          value={entry['carrier']}
                          options={this.props.self.state.carriers}
                          onChange={(event, newValue) =>
                            this.handleAutocompleteChange(event, newValue, i)
                          }
                          getOptionLabel={(option) => (option ? option.name2 : option)}
                          getOptionSelected={(option) =>
                            typeof entry[this.props.entityType.toLowerCase()] !== 'string' &&
                            entry[this.props.entityType.toLowerCase()]
                              ? option._id === entry[this.props.entityType.toLowerCase()]._id
                              : entry[this.props.entityType.toLowerCase()]
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={this.props.t(this.props.entityType)}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: `new-source-currency`,
                              }}
                            />
                          )}
                        />
                      ) : (
                        <Autocomplete
                          style={{
                            margin: 10,
                            minWidth: 170,
                            maxWidth: 170,
                            display: 'inline-flex',
                          }}
                          id="combo-box-demo"
                          value={entry[this.props.entityType.toLowerCase()]}
                          options={this.props.self.state.partners}
                          onChange={(event, newValue) =>
                            this.handleAutocompleteChange(event, newValue, i)
                          }
                          getOptionLabel={(option) => (option ? option.name2 : option)}
                          getOptionSelected={(option) =>
                            typeof entry[this.props.entityType.toLowerCase()] !== 'string' &&
                            entry[this.props.entityType.toLowerCase()]
                              ? option._id === entry[this.props.entityType.toLowerCase()]._id
                              : entry[this.props.entityType.toLowerCase()]
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={this.props.t(this.props.entityType)}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: `new-source-currency`,
                              }}
                            />
                          )}
                        />
                      )}

                      <TextField
                        required={true}
                        value={entry.invoiceNumber}
                        label={`${this.props.t('Invoice number')}`}
                        onChange={(event) => this.handleTextFieldChange(event, i)}
                        style={{ margin: 10, width: '170px' }}
                        name={'invoiceNumber'}
                        dbid={'invoiceNumber'}
                      />

                      <KeyboardDatePicker
                        style={{ margin: 10, width: '170px' }}
                        disableToolbar
                        variant="inline"
                        format="DD.MM.YYYY"
                        margin="normal"
                        label={`${this.props.t('Invoice date')}`}
                        value={
                          moment(entry.invoiceDate, 'DD.MM.YYYY').isValid()
                            ? moment(entry.invoiceDate, 'DD.MM.YYYY').toDate()
                            : null
                        }
                        onChange={(date) => this.handleDateChange(date, i, 'invoiceDate')}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        autoOk={true}
                      />
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item sm={12} md={10} xs={12} lg={12} style={{ margin: '12px' }}>
                        <Dropzone
                          onDrop={(event) => this.onDrop(event, i)}
                          maxSize={10 * 1024 * 1024}
                          onDropRejected={() => alert(this.props.t('file_size_limit'))}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section className="dropzone-container display-flex  flex-direction-row">
                              <div
                                style={{ maxWidth: '53%' }}
                                {...getRootProps({ className: 'dropzone' })}
                              >
                                <input {...getInputProps()} />
                                <p>
                                  {this.props.t(
                                    "Drag 'n' drop invoice doc here, or click to select file",
                                  )}
                                </p>
                              </div>
                              <aside className="display-flex align-center">
                                <ul className="display-flex" style={{ margin: 0, padding: 0 }}>
                                  {entry.files &&
                                    entry.files.map((file, fileIndex) => (
                                      <div className="display-flex">
                                        <Checkbox
                                          onChange={(e) =>
                                            this.getArticlesCheck(e, file, fileIndex, i)
                                          }
                                        />
                                        <li className="list-style-none" key={fileIndex}>
                                          <div
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'space-between',
                                            }}
                                          >
                                            <p
                                              className={'file-link'}
                                              onClick={() => this.handleDownload(file)}
                                            >
                                              {file}
                                            </p>
                                          </div>
                                        </li>
                                      </div>
                                    ))}
                                </ul>
                                {this.state.deleteIncomingDoc.length > 0 &&
                                  this.state.deleteIncomingDoc[i] !== undefined &&
                                  this.state.deleteIncomingDoc[i].checkbox.length > 0 && (
                                    <>
                                      {' '}
                                      <DeleteIcon
                                        style={{
                                          color: 'red',
                                          backgroundColor: 'white',
                                          float: 'right',
                                          display: 'flex',
                                          justifyContent: 'flex-end',
                                          alignItems: 'center',
                                        }}
                                        onClick={() => {
                                          this.deleteAttachment(i);
                                        }}
                                      />
                                    </>
                                  )}
                              </aside>
                            </section>
                          )}
                        </Dropzone>
                      </Grid>
                    </Grid>
                    {i == 0 ? (
                      <AddCircleOutlineIcon
                        style={{
                          fontSize: 28,
                          position: 'relative',
                          top: -2,
                          marginLeft: 15,
                          cursor: 'pointer',
                        }}
                        color="primary"
                        onClick={() => {
                          this.addNewRow();
                        }}
                      />
                    ) : (
                      <span
                        style={{ color: 'red', cursor: 'pointer', fontSize: 30 }}
                        onClick={() => {
                          this.deleteRow(i);
                        }}
                        class="material-icons"
                      >
                        remove_circle
                      </span>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

const mapStateToProps = ({ authentication }) => ({});

export default connect(mapStateToProps, { getArticles })(withTranslation('common')(MultiInvoice));
