import React from 'react';
import TextInput from '../../Inputs/TextInput';
import ArticleInputFieldData from '../../../Schemas/AddArticle.json';
import MultiChoice from '../../Inputs/MultiChoice';

function AddArticle({ self }) {
  return Object.keys(ArticleInputFieldData['Article Information']).map((key, index) => {
    let field = ArticleInputFieldData['Article Information'][key];

    return field.type === 'TextInput' ? (
      <TextInput
        key={index}
        style={{ margin: 10, width: 300 }}
        change={self.handleTextFieldChange}
        name={field.label}
        required={
          self.state.itemType === 'Product' && (field.dbid === 'basalAnnexIX' || field.dbid === 'basalCodeEu')
            ? false
            : self.state.itemType === 'Service' &&
              (field.dbid === 'basalAnnexIX' || field.dbid === 'basalCodeEu' || field.dbid === 'hsCode')
            ? false
            : self.state.itemType === 'Waste' &&
            (field.dbid === 'avv' ) ? true : field.required
        }
        defaultValue={field.dbid === 'descriptionOfWaste' ? self.state.descriptionOfWaste : undefined}
        label={self.props.t(field.label)}
        dbid={field.dbid}
        type={'text'}
      />
    ) : field.type === 'MultiChoice' ? (
      <MultiChoice
        key={index}
        id={field.id}
        callbackValue={self.handleMultiChoiceChange}
        required={field.required}
        options={field.options}
        label={self.props.t(field.label)}
        dbid={field.dbid}
        setExtern={self.setExtern}
        value={undefined}
      />
    ) : null;
  });
}

export default AddArticle;
