import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Paper from "@material-ui/core/Paper";
import { useDispatch, useSelector } from "react-redux";

import * as Actions from "../../redux/store/actions/stocks";
import Grid from "@material-ui/core/Grid";
import { formatDecimals, formatNumberToEn } from '../../helpers/common';
import StocksList from "./stocks-list";


function Stocks(props) {
  const [t, i18next] = useTranslation("common");
  const dispatch = useDispatch();
  const { stocks, totalBales, totalValue, totalWeight } = useSelector(({ stocks }) => stocks);
  const [filteredStocks = [], setFilteredStocks] = useState(stocks);
  const [counts, setCounts] = useState({ totalBales: 0, totalWeight: 0, totalValue: 0 });

  useEffect(() => {
    dispatch(Actions.fetchStocks(i18next.language));
  }, [dispatch]);

  useEffect(() => {
    if (stocks.length > 0)
      setCounts({
        totalBales: totalBales,
        totalWeight: totalWeight,
        totalValue: totalValue,
      });
  }, [stocks]);

  function getTotalAmount(article) {
    return formatDecimals(
      formatNumberToEn(article.source_price) *
        (article.source_weight && article.source_weight > 0 ? formatNumberToEn(article.source_weight) : 1)
    );
  }

  useEffect(() => {
    updateTotalValues(filteredStocks);
  }, [filteredStocks.length]);

  function updateTotalValues(data) {
    dispatch(Actions.filterData(data));
  }


  return (
    <React.Fragment>
      <Paper>
        <Grid container sm={12} xs={12} md={12} lg={12}>
          <Grid sm={4} xs={4} md={4} lg={4} />
          <Grid
            sm={4}
            xs={4}
            md={4}
            lg={4}
            style={{ display: "flex", justifyContent: "space-between", maxWidth: "300px" }}
          >
            <div style={{ textAlign: "center" }}>
              <h2 className={"mg-0"}>{totalValue}</h2>
              <h3>{t('Value')}</h3>
            </div>
            <div style={{ textAlign: "center" }}>
              <h2 className={"mg-0"}>{totalBales}</h2>
              <h3>{t('Bale Count')}</h3>
            </div>
            <div style={{ textAlign: "center" }}>
              <h2 className={"mg-0"}>{formatDecimals(totalWeight)}</h2>
              <h3>{t('Weight')}</h3>
            </div>
          </Grid>
          <Grid sm={4} xs={4} md={4} lg={4} />
        </Grid>
        <StocksList stocks={stocks} getTotalAmount={getTotalAmount} setFilteredStocks={setFilteredStocks} />
      </Paper>
    </React.Fragment>
  );
}

export default Stocks;
