import React, { useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';

function ColorInput(props) {
  const [state, setState] = useState('');

  useEffect(() => {
    if (state == '') {
      setState(props.value);
    }
  }, [props.value]);

  function handleChange(e) {
    e.preventDefault();
    e.persist();
    if (e.target.value) {
      setState(e.target.value);
      props.change(e);
    }
  }

  return (
    <TextField
      label={props.label}
      style={props.style}
      name={props.dbid}
      autoComplete={props.dbid}
      value={state}
      onChange={handleChange}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment>
            <input
              id={`${props.dbid}_select`}
              name={props.dbid}
              value={state}
              type="color"
              onChange={handleChange}
            />
          </InputAdornment>
        ),
      }}
    />
  );
}

export default ColorInput;
