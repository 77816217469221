// Namespace for application
export const APP_NAMESPACE = "mkrn-starter";

// Request status indicators
export const PENDING = "PENDING";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";

// Request types (lowercase for easy axios access)
export const DELETE = "delete";
export const GET = "get";
export const POST = "post";
export const PUT = "put";
