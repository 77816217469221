import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import * as Actions from '../../redux/store/actions/alerts';
import { useTranslation } from 'react-i18next';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function Messages(props) {
  const dispatch = useDispatch();
  const [t] = useTranslation("common");
  const { message, messageType } = useSelector(({ alerts }) => alerts);
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(Actions.hideMessage());
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={messageType === 'success'}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          <div style={{ fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: t(message) }} />
        </Alert>
      </Snackbar>
      <Snackbar
        open={messageType === 'error'}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          <div style={{ fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: t(message) }} />
        </Alert>
      </Snackbar>
    </div>
  );
}
