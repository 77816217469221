import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import { withTranslation } from "react-i18next";

function CheckboxLabels(props) {
  const [checked, setChecked] = React.useState(props.checked);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    props.callbackValue(props.isEdit ? props.dbid : props.label, event.target.checked);
  };

  return (
    <FormGroup row style={{ display: "inline", margin: 10 }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked || false}
            onChange={(e) => handleChange(e)}
            name={props.dbid}
          />
        }
        label={`${props.t(props.label)}${props.required ? " *" : ""}`}
      />
    </FormGroup>
  );
}

export default withTranslation("common")(CheckboxLabels);
