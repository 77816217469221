import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Alert from '../notification/alert';
import { errorPropTypes } from '../../util/proptype-utils';
import Button from '@material-ui/core/Button';

const GenericForm = ({ formSpec = [], errors = [], message = '', onSubmit, submitText }) => (
  <form className="form" onSubmit={onSubmit}>
    <Alert errors={errors} icon="error_outline" />
    <Alert message={message} icon="done" />
    <ul className="form-list">
      {formSpec.map((field) => (
        <li key={field.id} style={{marginBottom: '16px'}}>
          <Field {...field} className="field-style" />
        </li>
      ))}
    </ul>

    <Button
      type="submit"
      variant="contained"
      color="primary"
      style={{ width: '100%', height: '30px', borderRadius: '2px', boxShadow: 'none' }}
    >
      {submitText}
    </Button>

    <br />
  </form>
);

GenericForm.propTypes = {
  onSubmit: PropTypes.func,
  formSpec: PropTypes.arrayOf(
    PropTypes.shape({
      placeholder: PropTypes.string,
      type: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
      label: PropTypes.string,
      component: PropTypes.func,
    }),
  ),
  message: PropTypes.string,
  errors: errorPropTypes,
  submitText: PropTypes.string,
};

export default GenericForm;
