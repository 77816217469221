import React from 'react';
import {
  getGermanNumber,
  getLanguageAcronym,
  getPaymentTerms,
  getRecipient,
  getUnloadingPartner,
} from '../../helpers/documents';
import { getApiUrl } from '../../util/environment-utils';
import { withTranslation } from 'react-i18next';
import Footer from './footer';
import Header from './header';
import { formatNumberToEn } from '../../helpers/common';

class BillingConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date(),
      apiUrl: getApiUrl(),
    };
  }

  getUnloadingCity = () => {
    let partner = getUnloadingPartner(
      this.props.sourceData,
      this.props.processorData,
      this.props.currentEntity,
      'incotermSource'
    );
    return partner;
  };

  checkCashFlow() {
    return this.props.currentEntity.articles[0].source_cashflow === 'Supplementary Payment';
  }

  getLanguage = () => {
    let defaultLanguage = 'de';
    let partner = this.props.sourceData;
    let language = partner
      ? partner
        ? partner.documentLanguage
        : defaultLanguage
      : defaultLanguage;

    return getLanguageAcronym(language);
  };

  render() {
    const language = this.getLanguage();

    if (language === 'de') {
      return (
        <div className={'documents-container'}>
          <div className="main invoice">
            {this.checkCashFlow() ? (
              <Header
                address={getRecipient(this.props, 'purchase_confirmation', language)}
                accountManager={this.props.fieldAgent}
                partnerLanguage={language}
                {...this.props}
              />
            ) : (
                <Header
                  address={getRecipient(this.props, 'purchase_confirmation', language)}
                  accountManager={this.props.fieldAgent}
                  partnerLanguage={language}
                  {...this.props}
                />
              )}
            <div className="invoice-body">
              <h3>
                Abrechnungsbestätigung <span>{this.props.currentEntity.orderNumber}</span>
              </h3>
              <p className="disc">Sehr geehrte Damen und Herren,</p>
              <p className="disc-2">
                hiermit bestätigen wir Ihnen den Empfang einer Ladung und bitten um
                Rechnungsstellung wie folgt:
              </p>

              <div className="tbl">
                <div className="row">
                  <div className="cell bold first-col">Material</div>
                  <div className="cell bold cell-small">
                    {this.getUnloadingCity() ? this.getUnloadingCity().heading : 'Lieferstelle'}
                  </div>
                  <div className="cell bold reference-col cell-small">
                    {this.getUnloadingCity()
                      ? this.getUnloadingCity().reference
                      : 'Entladereferenz'}
                  </div>

                  <div className="cell bold cell-small">Menge</div>
                  <div className="cell bold cell-small">Preis je Einheit</div>
                </div>
                {this.props.currentEntity.articles.map(
                  (article, index) =>
                    article.source_cashflow === 'Compensation' &&
                    article.source_weight &&
                    parseFloat(formatNumberToEn(article.source_weight)) != 0 && (
                      <div className="row">
                        <div className="cell first-col">
                          {' '}
                          {article.source_deviating && article.source_deviating !== ''
                            ? `${article.source_deviating}`
                            : language === 'en'
                              ? article.articleDetails.descriptionInEnglish
                              : article.articleDetails.descriptionInGerman}
                        </div>
                        <div className="cell cell-small">
                          {this.getUnloadingCity() ? this.getUnloadingCity().partner.city : ''}
                        </div>
                        <div className="cell reference-col cell-small">
                          {this.props.currentEntity.loadingReferenceSource}
                        </div>

                        <div className="cell cell-small">
                          {article.source_weight
                            ? `${getGermanNumber(
                              formatNumberToEn(article.source_weight),
                              'amount',
                            )} ${article.source_unit}`
                            : 'N/V'}
                        </div>
                        <div className="cell cell-small">
                          {' '}
                          {getGermanNumber(formatNumberToEn(article.source_price))}{' '}
                          {article.source_currency === 'Euro' ? '€' : '$'}
                        </div>
                      </div>
                    ),
                )}
              </div>

              <div className="mg-22 ">
                {/*{this.props.currentEntity.orderRemark && (*/}
                <p className={'font-12'}>
                  {' '}
                  <b>
                    Bitte schicken Sie Ihre Rechnung mit Angabe unserer Disponummer{' '}
                    {this.props.currentEntity.orderNumber} an {this.props.billingConfirmationEmail}
                    <br />
                    (BITTE PER E-MAIL, NICHT PER POST)
                  </b>
                </p>
                {/*// )}*/}

                <div>
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: getPaymentTerms(
                        this.props.currentEntity.deviatingInvoiceAddressSource[0]
                          ? this.props.currentEntity.deviatingInvoiceAddressSource[0].paymentTerms
                          : this.props.sourceData.paymentTerms,
                        this.props.currentEntity,
                        'source',
                        language,
                      ),
                    }}
                  />
                </div>
              </div>

              <p className={'font-12'}>{this.props.tax ? this.props.tax.tax_text_source : ''}</p>

              <div className="team-address page-avoid">
                <p className={'mt-15'}>Vielen Dank für den Auftrag!</p>
                <p>Mit freundlichem Gruß</p>
                <p className="mt-15">Ihr {this.props.companyData.name2} Team</p>
              </div>
            </div>
            <Footer {...this.props} />
          </div>
        </div>
      );
    } else {
      return (
        <div className={'documents-container'}>
          <div className="main invoice">
            {this.checkCashFlow() ? (
              <Header
                address={getRecipient(this.props, 'purchase_confirmation', language)}
                accountManager={this.props.fieldAgent}
                partnerLanguage={language}
                {...this.props}
              />
            ) : (
                <Header
                  address={getRecipient(this.props, 'purchase_confirmation', language)}
                  accountManager={this.props.fieldAgent}
                  partnerLanguage={language}
                  {...this.props}
                />
              )}
            <div className="invoice-body">
              <h3>
                Billing confirmation <span>{this.props.currentEntity.orderNumber}</span>
              </h3>
              <p className="disc">Dear Sir or Madam,</p>
              <p className="disc-2">
                We hereby confirm the receipt of one load and kindly ask you to invoice us as follows:
              </p>

              <div className="tbl">
                <div className="row">
                  <div className="cell bold first-col">Material</div>
                  <div className="cell bold cell-small">
                    {this.props.t(
                      this.getUnloadingCity()
                        ? this.getUnloadingCity().headingEnglish
                        : 'Lieferstelle',
                    )}
                  </div>
                  <div className="cell bold reference-col cell-small">
                    {this.props.t(
                      this.getUnloadingCity()
                        ? this.getUnloadingCity().referenceEnglish
                        : 'Entladereferenz',
                    )}
                  </div>

                  <div className="cell bold cell-small">Quantity</div>
                  <div className="cell bold cell-small">Price per unit</div>
                </div>
                {this.props.currentEntity.articles.map(
                  (article, index) =>
                    article.source_cashflow === 'Compensation' &&
                    article.source_weight &&
                    parseFloat(formatNumberToEn(article.source_weight)) != 0 && (
                      <div className="row">
                        <div className="cell first-col">
                          {' '}
                          {article.source_deviating && article.source_deviating !== ''
                            ? `${article.source_deviating}`
                            : article.articleDetails.descriptionInEnglish}
                        </div>
                        <div className="cell cell-small">
                          {this.getUnloadingCity() ? this.getUnloadingCity().partner.city : ''}
                        </div>

                        <div className="cell reference-col cell-small">
                          {this.props.currentEntity.loadingReferenceSource}
                        </div>

                        <div className="cell cell-small">
                          {article.source_weight
                            ? `${getGermanNumber(
                              formatNumberToEn(article.source_weight),
                              'amount',
                            )} ${article.source_unit}`
                            : 'N/V'}
                        </div>
                        <div className="cell cell-small">
                          {' '}
                          {getGermanNumber(formatNumberToEn(article.source_price))}{' '}
                          {article.source_currency === 'Euro' ? '€' : '$'}
                        </div>
                      </div>
                    ),
                )}
              </div>

              <div className="mg-22 ">
                {/*{this.props.currentEntity.orderRemark && (*/}
                <p className={'font-12'}>
                  {' '}
                  <b>
                    Please send your invoice with our dispatch number{' '}
                    {this.props.currentEntity.orderNumber} to {this.props.billingConfirmationEmail} (PLEASE PER E-
                    MAIL, NOT BY POST)
                  </b>
                </p>
                {/*// )}*/}

                <div>
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: getPaymentTerms(
                        this.props.currentEntity.deviatingInvoiceAddressSource[0]
                          ? this.props.currentEntity.deviatingInvoiceAddressSource[0].paymentTerms
                          : this.props.sourceData.paymentTerms,
                        this.props.currentEntity,
                        'source',
                        language,
                      ),
                    }}
                  />
                </div>
              </div>

              <p className={'font-12'}>{this.props.loadingInvoiceTax ? this.props.loadingInvoiceTax.tax_text_source : ''}</p>

              <div className="team-address page-avoid">
                <p className={'mt-15'}>Thank you for your order.</p>
                <p>With kind regards</p>
                <p className="mt-15">Your {this.props.companyData.name2} Team</p>
              </div>
            </div>
            <Footer {...this.props} />
          </div>
        </div>
      );
    }
  }
}

export default withTranslation('common')(BillingConfirmation);
