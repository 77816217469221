import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import Paper from '@material-ui/core/Paper';
import OnAddContractPopup from '../../popup/on_contract_add';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import moment from 'moment';
import PreviewContractDocument from '../../modals/previewContractDocument';
import Dropzone from 'react-dropzone';
import DeleteIcon from '@material-ui/icons/Delete';

function SentDocDate({ name, date, component }) {
  return (
    <p className={'flex ml-12 align-center'}>
      {`${name} (${moment(date).format('DD.MM.YYYY')})`}&nbsp; {component}
    </p>
  );
}

function SendDocs(props) {
  const { currentEntity = {}, t, state, sendContractDocument, self } = props;
  const [checked, setChecked] = useState({ sales: { status: false, date: null } });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    if (Object.keys(currentEntity).length > 0) {
      getInfo();
      setChecked({
        sales: { status: false, date: null },
      });
      if (currentEntity.docStatus && currentEntity.docStatus.length > 0) {
        setChecked({ ...checked, ...currentEntity.docStatus });
      }
    }
  }, [currentEntity, currentEntity.docStatus]);

  function handleCheckboxesChange(event) {
    let values = checked;
    values[event.target.name]['status'] = event.target.checked;
    setChecked({ ...values });
  }

  function checkIfDocAlreadySent(name) {
    return (
      Object.keys(currentEntity).length > 0 &&
      currentEntity.docStatus &&
      currentEntity.docStatus[name] &&
      currentEntity.docStatus[name]['status']
    );
  }

  function docSentDate(name) {
    let date = false;
    if (
      currentEntity.docStatus &&
      currentEntity.docStatus[name] &&
      currentEntity.docStatus[name]['date']
    ) {
      date = moment(currentEntity.docStatus[name]['date']).format('DD.MM.YYYY');
    }

    return date ? `(${date})` : date;
  }

  function isDisabled() {
    return ((checked || {}).sales || {}).status || false;
  }

  const getInfo = async () => {
    let bankDetails = await props.getBank(currentEntity.bankId);
    let buyerPartner = await props.getPartner(currentEntity.buyerId);
    let teams = await props.getTeams();
    let index = (teams.allPersons || []).findIndex((_ap) => _ap._id === currentEntity.commercialId);
    let commercials = null;
    if (index > -1) {
      commercials = teams[index];
    }

    let company = await props.getCompanys();
    let update = {
      bankDetails,
      company,
      commercials,
      buyerPartner,
      currentEntity,
    };

    setData(update);
  };

  return (
    <Grid container spacing={3} className={'ml-0 mt-16 mb-16'}>
      {/* <Grid item xs={12} sm={12} md={12} lg={12}> */}
      <Paper style={{ padding: 12, height: '100%', width: '100%',marginRight: '25px' }}>
        <Grid container spacing={3} style={{width:'100%'}} className={'ml-0 mt-16 mb-16'}>
          <Grid xs={12} sm={12} md={3} lg={2}>
            {!checkIfDocAlreadySent('sales') ? (
              <div className={'flex align-center'}>
                <FormGroup row style={{ display: 'inline', margin: 10 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={(checked.sales && checked.sales.status) || false}
                        onChange={handleCheckboxesChange}
                        name={'sales'}
                        disabled={((self.currentEntity || {}).articles || []).length === 0}
                      />
                    }
                    label={`${props.t('Sales Contract')}${props.required ? ' *' : ''} ${
                      docSentDate('sales') ? docSentDate('sales') : ''
                    }`}
                  />
                </FormGroup>
                <div className={'preview'}>
                  <PreviewContractDocument
                    data={data}
                    contract={props.currentEntity}
                    {...self.createContractDocumentProps()}
                    {...props}
                  />
                </div>
              </div>
            ) : (
              <SentDocDate
                name={props.t('Sales Contract')}
                date={currentEntity.docStatus.sales.date}
                component={
                  <PreviewContractDocument
                    data={data}
                    contract={props.currentEntity}
                    {...self.createContractDocumentProps()}
                    {...props}
                  />
                }
              />
            )}
          </Grid>

          <Grid xs={12} sm={12} md={1} lg={1}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              style={{
                margin: 10,
              }}
              disabled={!isDisabled()}
              onClick={() => self.setState({ sendcontractdocument: true })}
            >
              {t('Send')}
            </Button>
          </Grid>

          <Grid style={{ display: 'flex', alignItems: 'center' }} xs={12} sm={12} md={3} lg={9}>
            <p style={{ margin: '0 20px' }}>{t('Signed Contract')}:</p>
            <Dropzone
              onDrop={(event) => self.handleDropContractors(event)}
              maxSize={10 * 1024 * 1024}
              multiple={false}
              onDropRejected={() => alert(t('file_size_limit'))}
              style={{ display: 'flex' }}
            >
              {({ getRootProps, getInputProps }) => (
                <section
                  className="dropzone-container"
                  style={{ display: 'flex', flexDirection: 'row' }}
                >
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop images here</p>
                  </div>
                  {self.state.signedContract && (
                    <React.Fragment>
                      <aside className="display-flex align-center">
                        <ul className="display-flex" style={{ margin: 0 }}>
                          <div className="display-flex">
                            <li className="list-style-none">
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <p
                                  className={'file-link'}
                                  onClick={() =>
                                    self.handleSignedContractDownload(self.state.signedContract)
                                  }
                                >
                                  {self.state.signedContract}
                                </p>
                              </div>
                            </li>
                          </div>
                        </ul>
                        <>
                          <DeleteIcon
                            style={{
                              color: 'red',
                              backgroundColor: 'white',
                              float: 'right',
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                            }}
                            onClick={() => {
                              self.setState({ signedContract: null });
                            }}
                          />
                        </>
                      </aside>
                    </React.Fragment>
                  )}
                </section>
              )}
            </Dropzone>
          </Grid>
        </Grid>
      </Paper>
      {state.sendcontractdocument === true ? (
        <span>
          <OnAddContractPopup
            data={data}
            closeDialogCancel={async (reset) => {
              reset();
              self.setState({ sendcontractdocument: false });
            }}
            closeDialogOk={async (data, reset) => {
              reset();
              self.setState({ docSendLoading: true });
              setLoading(true);
              await sendContractDocument(currentEntity._id, data, self.props.i18n.language);

              setTimeout(() => {
                self.getContractDetails();
                setLoading(false);
                self.setState({ sendcontractdocument: false, docSendLoading: false });
              }, 300);
            }}
            loading={loading}
            setLoading={setLoading}
            state={self.state}
            checked={checked}
            createDocumentProps={self.createDocumentProps}
            fetchArticle={self.fetchArticle}
            fetchPartner={self.fetchPartner}
            currentEntity={currentEntity}
            checkIfDocAlreadySent={checkIfDocAlreadySent}
            {...props}
          />
        </span>
      ) : (
        ''
      )}
    </Grid>
  );
}

export default SendDocs;
