export const SET_EMAIL = "[PERSONS] SET EMAIL";
export const RESET = "[PERSONS] RESET";

export function resetPersons() {
  return {
    type: RESET,
  };
}

export function setEmail(payload) {
  return {
    type: SET_EMAIL,
    payload,
  };
}
