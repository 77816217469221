import * as Actions from "../actions/orders";
import { sourceCondition, sourceConditionGerman } from '../actions/orders';

const initialState = {
  form: {
    articleDetails: {},
    article_name: "",
    article_id: "",
    source_price: "",
    source_currency: "Euro",
    source_unit: "t",
    source_cashflow: "",
    source_condition: sourceCondition,
    source_deviating: "",
    processor_price: "",
    processor_currency: "Euro",
    processor_unit: "t",
    processor_cashflow: "",
    processor_condition: "",
    processor_deviating: "",
    countries: "",
  },
  articles: [
    {
      articleDetails: {},
      article_name: "",
      article_id: "",
      source_price: "",
      source_currency: "Euro",
      source_unit: "t",
      source_cashflow: "",
      source_condition: sourceCondition,
      source_deviating: "",
      processor_price: "",
      processor_currency: "Euro",
      processor_unit: "t",
      processor_cashflow: "",
      processor_condition: "",
      processor_deviating: "",
    },
  ],
};

const orders = function (state = initialState, action) {
  switch (action.type) {
    case Actions.RESET: {
      return {
        ...state,
        articles: [],
      };
    }
    case Actions.ADD_ARTICLE: {
      let articles = state.articles;

      articles.push({
        articleDetails: {},
        article_name: "",
        article_id: "",
        source_price: "",
        source_currency: "Euro",
        source_unit: "t",
        source_cashflow: "",
        source_condition: action.payload === 'en' ? sourceCondition : sourceConditionGerman,
        source_deviating: "",
        processor_price: "",
        processor_currency: "Euro",
        processor_unit: "t",
        processor_cashflow: "",
        processor_condition: "",
        processor_deviating: "",
        countries: "",
      });

      return {
        ...state,
        articles: articles,
      };
    }
    case Actions.REMOVE_ARTICLE: {
      let articles = state.articles;

      articles.splice(action.payload, 1);

      return {
        ...state,
        articles: articles,
      };
    }
    case Actions.SET_VALUES: {
      return {
        ...state,
        articles: action.payload,
      };
    }
    case Actions.SET_COUNTRIES: {
      return {
        ...state,
        form: {
          ...state.form,
          countries: action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default orders;
