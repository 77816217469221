import React from "react";
import ChipInput from "material-ui-chip-input";
import { useDispatch, useSelector } from "react-redux";

import * as Actions from "../../redux/store/actions/persons";

function EmailField(props) {
  const dispatch = useDispatch();
  const { form } = useSelector(({ persons }) => persons);

  function handleChange(chips) {
    dispatch(Actions.setEmail(chips));
  }

  return (
    <ChipInput
      classes={{
        root: "h-56",
        input: "mg-top-20",
        chipContainer: "mg-top-20",
      }}
      style={{
        minWidth: 300,
        margin: '2px 10px'
      }}
      defaultValue={form.emails}
      onChange={(chips) => handleChange(chips)}
      placeholder={"E-mail addresses*"}
    />
  );
}

export default EmailField;
