import React from "react";
import { Modal } from "@material-ui/core";
import MultiChoice from "../Inputs/MultiChoice";
import MultiMultiChoice from "../Inputs/MultiMultichoice";
import DateRangePicker from "../Inputs/DateRangePicker";
import DateRangePicker2 from "../Inputs/DateRangePicker2";
import InvoiceDeliveryMethod from "../Inputs/InvoiceDeliveryMethodInput";
import PaymentTermsMethod from "../Inputs/PaymentTermsMethod";
import CheckBox from "../Inputs/Checkbox";
import DatePicker from "../Inputs/DatePicker";
import LocationPicker from "../Inputs/LocationPicker";
import DatabaseMultiChoice from "../Inputs/DatabaseMultiChoice";
import personSchema from "../../Schemas/AddPerson.json";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";

import { addPersonModal } from "../../redux/modules/person";

import { withTranslation } from "react-i18next";
import { reduxForm } from "redux-form";
import EmailField from "../persons/Emails";
import "./modalStyles.scss";
import TextInput from "../Inputs/TextInput";
import { showMessage } from '../../redux/store/actions/alerts';

const form = reduxForm({
  form: "add",
});

class PersonAddModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputFields: [],
      tileData: [],
      tile0: [],
      tile1: [],
      tile2: [],
      tile3: [],
      tile4: [],
      tile5: [],
      header0: "",
      header1: "",
      header2: "",
      header3: "",
    };
  }

  componentDidMount() {
    this.setTiles();
  }

  setTiles = () => {
    let headers = Object.keys(personSchema);
    headers.map((header, index) => {
      let stuffer = "header" + index;
      this.setState({ [stuffer]: header });
    });

    const tiles = Object.values(personSchema).map((tile, index) => {
      let tileValues = Object.values(tile).map((question) => {
        return this.createInputField(question);
      });
      let stuff = "tile" + index;
      this.setState({ [stuff]: tileValues });
    });

    this.setState({ tileData: tiles });
  };

  createInputField = (obj) => {
    if (obj.type === "TextInput") {
      return (
        <TextInput
          key={obj.label}
          style={{ margin: 10, width: 300 }}
          change={this.handleTextFieldChange}
          name={obj.label}
          required={obj.required}
          label={this.props.t(obj.label)}
          dbid={obj.dbid}
        />
      );
    } else if (obj.type === "MultiChoice") {
      return (
        <MultiChoice
          key={obj.label}
          id={obj.id}
          callbackValue={this.handleMultiChoiceChange}
          required={obj.required}
          options={obj.options}
          label={obj.label}
          dbid={obj.dbid}
        />
      );
    } else if (obj.type === "MultiMultiChoice") {
      return (
        <MultiMultiChoice
          key={obj.label}
          id={obj.id}
          callbackValue={this.handleMultiChoiceChange}
          required={obj.required}
          options={obj.options}
          optionsForPartner={obj.optionsForPartner}
          optionsForCarrier={obj.optionsForCarrier}
          label={obj.label}
          dbid={obj.dbid}
          intern={this.state.type}
          variant={this.props.variant}
        />
      );
    } else if (obj.type === "DeliveryDateRangeMultiChoice") {
      return (
        <DateRangePicker
          key={obj.label}
          id={obj.id}
          callbackValue={this.handleMultiChoiceChange}
          callbackValueRange={this.handleChildStateChange}
          callbackDateChange={this.handleDateChange}
          required={obj.required}
          options={obj.options}
          label={obj.label}
          dbid={obj.dbid}
          sourceCollection={this.state["Source Collection"]}
          dateLabel={obj.dateLabel}
          dateDbid={obj.dateDbid}
          dateName={obj.dateName}
        />
      );
    } else if (obj.type === "DeliveryDateRangeMultiChoice2") {
      return (
        <DateRangePicker2
          key={obj.label}
          id={obj.id}
          callbackValue={this.handleMultiChoiceChange}
          callbackValueRange={this.handleChildStateChange}
          callbackDateChange={this.handleDateChange}
          required={obj.required}
          options={obj.options}
          label={obj.label}
          dbid={obj.dbid}
          processorDelivery={this.state["Processor Delivery"]}
          dateLabel={obj.dateLabel}
          dateDbid={obj.dateDbid}
          dateName={obj.dateName}
        />
      );
    } else if (obj.type === "InvoiceDeliveryMethod") {
      return (
        <InvoiceDeliveryMethod
          key={obj.label}
          key2={obj.emailLabel}
          id={obj.id}
          callbackValue={this.handleMultiChoiceChange}
          callbackValueText={this.handleTextFieldChange}
          emailLabel={obj.emailLabel}
          required={obj.required}
          options={obj.options}
          label={obj.label}
          dbid={obj.dbid}
          invoiceDeliveryMethod={this.state["Invoice Delivery Method"]}
        />
      );
    } else if (obj.type === "PaymentTermsMethod") {
      return (
        <PaymentTermsMethod
          key={obj.label}
          key2={obj.daysLabel}
          id={obj.id}
          callbackValue={this.handleMultiChoiceChange}
          callbackValueText={this.handleTextFieldChange}
          daysLabel={obj.daysLabel}
          required={obj.required}
          options={obj.options}
          label={obj.label}
          dbid={obj.dbid}
          paymentTerms={this.state["Payment Terms"]}
        />
      );
    } else if (obj.type === "CheckBox") {
      return (
        <CheckBox
          key={obj.label}
          id={obj.id}
          dbid={obj.dbid}
          callbackValue={this.handleCheckboxChange}
          required={obj.required}
          label={obj.label}
        />
      );
    } else if (obj.type === "DatePicker") {
      return (
        <DatePicker
          key={obj.label}
          id={obj.id}
          callbackValue={this.handleDateChange}
          required={obj.required}
          label={obj.label}
          name={obj.label}
          dbid={obj.dbid}
          value={this.state[obj.dbid]}
        />
      );
    } else if (obj.type === "LocationPicker") {
      return (
        <LocationPicker
          key={obj.label}
          id={obj.id}
          dbid={obj.dbid}
          callbackValue={this.handleMultiChoiceChange}
          required={obj.required}
          label={obj.label}
        />
      );
    } else if (obj.type === "DatabaseMultiChoice") {
      return (
        <DatabaseMultiChoice
          key={obj.label}
          id={obj.id}
          name={obj.label}
          dbid={obj.dbid}
          value={this.state[obj.dbid]}
          required={obj.required}
          method={obj.method}
          getClients={() => this.props.getClients()}
          getCarriers={() => this.props.getCarriers()}
          getPersons={() => this.props.getPersons()}
          getOrders={() => this.props.getOrders()}
          getPartners={() => this.props.getPartners()}
          getArticles={() => this.props.getArticles()}
          getAccountManagers={() => this.props.getAccountManagers()} //
          getTruckNotifications={() => this.props.getTruckNotifications}
          getFieldAgents={() => this.props.getFieldAgents()}
          label={obj.label}
          callbackValue={this.handleDatabaseMultiChoiceChange}
        />
      );
    }
  };

  handleMultiChoiceChange = async (name, value) => {
    await this.setState({
      [name]: value,
    });
    this.setTiles();
  };

  handleTextFieldChange = (evt) => {
    const value = evt.target.value;
    this.setState({
      [evt.target.name]: value,
    });
  };

  handleDatabaseMultiChoiceChange = (name, value, data, dataLabel) => {
    this.setState({
      [name]: value,
      [dataLabel]: data,
    });
  };

  handleCheckboxChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleDateChange = (name, date) => {
    this.setState({
      [name]: date,
    });
  };

  handleChildStateChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleAddClick = () => {
    let payload = { ...this.state };
    payload.emails = this.props.personForm.emails;

    if (this.props.variant === "partner")
      payload.partnerId = this.props.currentEntity._id;
    else if (this.props.variant === "carrier")
      payload.carrierId = this.props.currentEntity._id;

    this.props.addPersonModal(payload, this.callback);
  }

  callback = (response) => {
    if (response.status === 'error') {
      this.props.showMessage({ message: response.errors, messageType: "error" })
    } else {
      this.props.showMessage({ message: "Added Successfully!", messageType: "success" })
      this.props.handleClose();
    }
  }

  validate = (data) => {
    let errors = {}
    for (let key of Object.keys(data)) {
      if ((!data[key].emails || data[key].emails.length === 0)) {
        errors[key] = "This is a required field"
      }
    }

    return errors
  }

  render() {
    return (
      <Modal open={this.props.open} onClose={this.props.handleClose} className={"addPerson"}>
        <form noValidate autoComplete="off">
          <Paper style={{ padding: 12, height: "100%" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h4" style={{ paddingBottom: 30 }}>
                  {this.props.t(this.state.header0)}
                </Typography>
                {this.state.tile0.map((tile) => {
                  if (
                    (tile.key === "Responsibility" || tile.key === "Department") &&
                    this.props.variant === "carrier"
                  ) {
                    return false;
                  }

                  if (tile.key !== "E-mail") {
                    return tile;
                  } else {
                    return <EmailField />;
                  }
                })}
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CloudUploadIcon />}
              style={{ marginTop: 20,backgroundColor:'white',color:'green' }}
              onClick={() => this.handleAddClick()}
            >
              {`${this.props.t("ADD")} ${this.props.t("PERSON")}`}
            </Button>
          </Paper>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = ({ authentication, partners, persons }) => ({
  personForm: persons.form,
});

export default connect(mapStateToProps, {
  showMessage,
  addPersonModal,
})(form(withTranslation("common")(PersonAddModal)));
