import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import Button from '@material-ui/core/Button';
import { InputLabel } from '@material-ui/core';
import * as Actions from '../../redux/modules/tax';
import { useDispatch, useSelector } from 'react-redux';
import TaxList from './tax-list';
import axios from 'axios';
import { getApiUrl } from '../../util/environment-utils';
import { useTranslation } from 'react-i18next';

const getKey = (str) => {
  let key = str
    .replace(/\u21b5/g, '')
    .toLowerCase()
    .replace(/\s+/g, '_');
  return key;
};
function Tax(props) {
  const [t] = useTranslation('common');
  const dispatch = useDispatch();
  const [taxes, setTaxes] = useState([]);
  useEffect(() => {
    function getTaxes() {
      let BASE_URL = getApiUrl();
      axios
        .get(`${BASE_URL}/tax`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then(
          (res) => {
            let taxes = res.data.taxes;
            if (taxes.length > 0) setTaxes(taxes);
          },
          (error) => {
            console.log(error);
          },
        );
    }
    if (taxes.length == 0) getTaxes();
  }, [taxes]);
  useEffect(() => {}, [dispatch]);
  function handleFileChange(event) {
    const files = Object.keys(event.target.files).map((file) => event.target.files[file]);
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? 'binary' : 'array',
        bookVBA: true,
      });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      let newData = [];
      for (let row of data) {
        let d = {};
        for (let key of Object.keys(row)) {
          d[getKey(key)] = row[key];
        }
        newData.push(d);
      }
      dispatch(Actions.importTax(newData));
    };
    if (rABS) {
      reader.readAsBinaryString(files[0]);
    } else {
      reader.readAsArrayBuffer(files[0]);
    }
  }
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '14px' }}>
        <input
          type="file"
          className="hidden"
          style={{ display: 'none' }}
          id="template"
          onChange={(event) => handleFileChange(event)}
        />
        <InputLabel htmlFor="template" className="custom-file-upload">
          <Button component={'span'} variant="contained" color="secondary">
            {t('Upload File')}
          </Button>
        </InputLabel>
      </div>
      <TaxList taxes={taxes} />
    </div>
  );
}
export default Tax;
