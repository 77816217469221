import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import OpeningHours from '../../persons/OpeningHours';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { SYSTEM_NAVIGATION_ROUTES } from '../../../constants/ui-constants';
import AddArticle from './add-article';
import UploadSignatures from './upload-signatures';

function System({ self }) {
  return (
    <div style={{ flexGrow: 1 }}>
      <form noValidate autoComplete="off">
        <Grid container spacing={3}>
          {self.props.currentSubTab === 2 ? (
            <Grid item xs={12}>
              <Paper style={{ padding: 12, height: '100%' }}>
                <Typography variant="h4" style={{ paddingBottom: 30 }}>
                  {self.props.t(self.state.header0)}
                </Typography>
                <AddArticle self={self} />
              </Paper>
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <Paper style={{ padding: 12, height: '100%' }}>
                  <Typography variant="h4" style={{ paddingBottom: 30 }}>
                    {self.props.t(self.state.header0)}
                  </Typography>
                  {self.state.tile0}
                  {self.props.currentSubTab === 3 && <UploadSignatures self={self}/>}
                </Paper>
              </Grid>
              {self.state.tile1 && self.state.tile1.length > 0 && (
                <Grid item xs={12}>
                  <Paper style={{ padding: 12, height: '100%' }}>
                    <Typography variant="h4" style={{ paddingBottom: 30 }}>
                      {self.props.t(self.state.header1)}
                    </Typography>
                    {self.state.tile1}
                  </Paper>
                </Grid>
              )}
            </>
          )}

          {self.props.currentSubTab === 4 && (
            <Grid item xs={12}>
              <Paper style={{ padding: 12, height: '100%' }}>
                <Typography variant="h4" style={{ paddingBottom: 30 }}>
                  {self.props.t('Opening Hours')} {112222}
                </Typography>
                <OpeningHours />
              </Paper>
            </Grid>
          )}
        </Grid>
      </form>
      <Button
        variant="contained"
        color="primary"
        startIcon={<CloudUploadIcon />}
        style={{ marginTop: 20, backgroundColor: 'white', color: 'green' }}
        onClick={() => self.handleAdd()}
      >
        {`${self.props.t('ADD')} ${self.props.t(
          SYSTEM_NAVIGATION_ROUTES[self.props.currentSubTab],
        )}`}
      </Button>
    </div>
  );
}

export default System;
