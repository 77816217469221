import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Confirmed from "./confirmed";
import Sale from "./sale";
import Overview from "./overview";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { formatDecimals } from "../../../helpers/common";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Gallery from "./gallery";
import { useDispatch } from "react-redux";
import { save, confirm, fetch } from "../../../redux/modules/tuning";

function Tuning(props) {
  const { self } = props;
  const dispatch = useDispatch();
  const [state, setState] = useState({
    _id: null,
    confirmed: [
      { quality: "98/02", weight: "0", bale_count: "0", unit_price: "0", price_per_quality: "0" },
      { quality: "95/05", weight: "0", bale_count: "0", unit_price: "0", price_per_quality: "0" },
      { quality: "90/10", weight: "0", bale_count: "0", unit_price: "0", price_per_quality: "0" },
      { quality: "80/20", weight: "0", bale_count: "0", unit_price: "0", price_per_quality: "0" },
      { quality: "60/40", weight: "0", bale_count: "0", unit_price: "0", price_per_quality: "0" },
      { quality: "Waste", weight: "0", bale_count: "0", unit_price: "0", price_per_quality: "0" },
    ],
    receive: [
      { quality: "98/02", weight: "0", bale_count: "0", upgrade: "0", unit_price: "0", price_per_quality: "0" },
      { quality: "95/05", weight: "0", bale_count: "0", upgrade: "0", unit_price: "0", price_per_quality: "0" },
      { quality: "90/10", weight: "0", bale_count: "0", upgrade: "0", unit_price: "0", price_per_quality: "0" },
      { quality: "80/20", weight: "0", bale_count: "0", upgrade: "0", unit_price: "0", price_per_quality: "0" },
      { quality: "60/40", weight: "0", bale_count: "0", upgrade: "0", unit_price: "0", price_per_quality: "0" },
      { quality: "Waste", weight: "0", bale_count: "0", upgrade: "0", unit_price: "0", price_per_quality: "0" },
    ],
    total: 0,
    bale_count: "44",
    handling: 0,
    preprocessing: 0,
    transport: 0,
    seafreight: 0,
  });
  const [gallery, setGallery] = React.useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    self.props.dispatch(fetch(self.currentEntity._id)).then((res) => {
      if (Object.keys(res.tuning).length > 0) {
        const tuning = res.tuning;
        setState({
          ...state,
          _id: tuning._id,
          confirmed: tuning.confirmed,
          receive: tuning.receive,
          handling: tuning.shippingCosts.handling,
          preprocessing: tuning.shippingCosts.preprocessing,
          transport: tuning.shippingCosts.transport,
          seafreight: tuning.shippingCosts.seafreight,
        });

        setImages(tuning.gallery);
      }
    });
  }, [dispatch]);

  const handleClickOpen = () => {
    setGallery(true);
  };

  function handleInputChange(event) {
    const fieldName = event.target.name;
    const value = event.target.value;
    setState({ ...state, [fieldName]: value });
  }

  function getTotal() {
    return (
      parseFloat(state.handling !== "" ? state.handling : 0) +
      parseFloat(state.preprocessing !== "" ? state.preprocessing : 0) +
      parseFloat(state.transport !== "" ? state.transport : 0) +
      parseFloat(state.seafreight !== "" ? state.seafreight : 0)
    );
  }

  function getCostPerTon() {
    return getTotal() > 0 ? formatDecimals((getTotal() / state.total) * 1000) : 0;
  }

  function handleSave() {
    const payload = {};
    payload._id = state._id;
    payload.confirmed = state.confirmed;
    payload.receive = state.receive;
    payload.shippingCosts = {
      total: state.total,
      bale_count: state.bale_count,
      handling: state.handling,
      preprocessing: state.preprocessing,
      transport: state.transport,
      seafreight: state.seafreight,
    };
    payload.gallery = images;
    payload.orderId = self.currentEntity._id;

    dispatch(save(payload));
  }

  function handleConfirm() {
    dispatch(confirm(state._id));
  }

  return (
    <React.Fragment>
      <Paper style={{ padding: 12 }}>
        <div>
          <Typography variant="h4">{self.props.t('Added Value')}</Typography>
        </div>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          style={{
            margin: "10px 0px",
            float: "left"
            ,color: 'green',
            backgroundColor: 'white'
          }}
          onClick={handleSave}
        >
          {self.props.t("save_tuning")}
        </Button>
        <Button
          variant="contained"
          startIcon={<CancelIcon />}
          style={{ margin: 10, float: "left", backgroundColor: "white", color: "red" }}
          onClick={() => window.location.reload()}
        >
          {self.props.t("Cancel")}
        </Button>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={6} md={6}>
            <Confirmed title={self.props.t("Confirmed")} stats={state} state={state} setState={setState} {...props} />
          </Grid>
          <Grid item xs={12} sm={6} lg={6} md={6}>
            <Sale title={self.props.t("Receive & Sale")} stats={state} state={state} setState={setState} {...props}/>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} md={6}>
            <Overview
              title={""}
              stats={state}
              state={state}
              setState={setState}
              getTotal={getTotal}
              {...props}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={6} md={6}>
            <p className={"font-16"}>
              <TextField
                type={"number"}
                style={{ minWidth: "300px" }}
                value={state.total}
                required={false}
                onChange={(event) =>
                  setState({
                    ...state,
                    total: event.target.value && event.target.value !== "" ? parseFloat(event.target.value) : 0,
                  })
                }
                label={`${self.props.t("Total weight")}`}
              />
            </p>
            <p className={"font-16"}>
              <b>{self.props.t('Bale Count')}: </b> {state.bale_count}
            </p>
            <p>
              <b>{self.props.t('Weight Per bale')}: </b>{" "}
              {(parseInt(state.total) / parseInt(state.bale_count)).toFixed(2)}
            </p>
          </Grid>
        </Grid>
      </Paper>

      <Paper style={{ padding: 12, marginTop: 30 }}>
        <Typography variant="h4" style={{ paddingBottom: 30 }}>
          {self.props.t('Shipping Costs')}
        </Typography>

        <Grid container spacing={3} style={{ marginLeft: "0px", marginBottom: "15px" }}>
          <Grid item md={3} lg={3} xs={3} sm={3}>
            <p className={"font-16"}>
              <b>{self.props.t('Total cost of transport')}:</b> {getTotal()}
            </p>
          </Grid>
          <Grid item md={3} lg={3} xs={3} sm={3}>
            <p className={"font-16"}>
              <b>{self.props.t('Total cost per ton')}: </b> {getCostPerTon()}
            </p>
          </Grid>
        </Grid>

        <TextField
          type={"number"}
          style={{ margin: 10, minWidth: "300px" }}
          value={state.handling}
          required={false}
          onChange={handleInputChange}
          label={`${self.props.t("Handling")}`}
          name={"handling"}
        />
        <TextField
          type={"number"}
          style={{ margin: 10, minWidth: "300px" }}
          value={state.preprocessing}
          required={false}
          onChange={handleInputChange}
          label={`${self.props.t("Preprocessing")}`}
          name={"preprocessing"}
        />
        <TextField
          type={"number"}
          style={{ margin: 10, minWidth: "300px" }}
          value={state.seafreight}
          required={false}
          onChange={handleInputChange}
          label={`${self.props.t("Sea freight")}`}
          name={"seafreight"}
        />
        <TextField
          type={"number"}
          style={{ margin: 10, minWidth: "300px" }}
          value={state.transport}
          required={false}
          onChange={handleInputChange}
          label={`${self.props.t("Transport")}`}
          name={"transport"}
        />
      </Paper>

      <Grid container spacing={3} style={{ margin: 0 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          style={{
            margin: "10px 0px",
            float: "left",
            backgroundColor:'white',color:'green'
          }}
          onClick={handleConfirm}
        >
          {self.props.t("confirm_tuning")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<VisibilityIcon />}
          style={{ margin: 10, float: "left" }}
          onClick={handleClickOpen}
        >
          {self.props.t("View Gallery")}
        </Button>
      </Grid>
      {gallery && (
        <Gallery gallery={gallery} images={images} setImages={setImages} setGallery={setGallery} {...self.props} />
      )}
    </React.Fragment>
  );
}

export default Tuning;
