import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Articles from '../submenus/multiArticle-new';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';

function Orders(props) {
  const { self, state, editOrder, currentEntity } = props;
  const { articles } = useSelector(({ orders }) => orders);

  function handleValueChange(event, value) {
    props.handleMultipleChoiceChange({ dbid: 'vehicle' }, value);
  }

  const handleAlignment = (event, newAlignment) => {
    props.setDestinationType(newAlignment);
  };

  const getPayload = (data) => {
    let payload = {
      fieldAgent: data.accountManagerSource,
      source: data.source,
      deviatingInvoiceAddressProcessor: data.deviatingInvoiceAddressProcessor,
      deviatingInvoiceAddressSource: data.deviatingInvoiceAddressSource,
      processor: data.processor,
      carrier: data.carrier,
      vehicle: data.vehicle,
      category: data.category,
      minLoadingWeight: data.minLoadingWeight,
      transportationBudget: data.transportationBudget,
      shippingCost: data.shippingCost,
      currency: data.currency,
      incotermSource: data.incotermSource,
      incotermProcessor: data.incotermProcessor,
      collectionSource: data.collectionSource,
      deliveryProcess: data.deliveryProcess,
      pickupDate: data.pickupDate,
      deliveryDate: data.deliveryDate,
      loadingReferenceSource: data.loadingReferenceSource,
      loadingReferenceProcessor: data.loadingReferenceProcessor,
      articles: data.articles,
      weightslips: data.weightslips,
      claims: [],
      accountManagerProcessor:
        typeof data.accountManagerProcessor !== 'string'
          ? [data.accountManagerProcessor]
          : data.accountManagerProcessor,
      docs: data.docs,
      countries: data.countries,
      sourceLocationId: data.sourceLocation ? data.sourceLocation._id : null,
      processorLocationId: data.processorLocation ? data.processorLocation._id : null,
      bookingNo: data.bookingNo,
    };
    return payload;
  };

  function handleAutocompleteChange(event, newValue, type) {
    self.setState({ [type]: newValue });
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<SaveIcon />}
        style={{
          margin: 10,
          backgroundColor: 'white',
          color: 'green',
          float: 'left',
        }}
        onClick={() => {
          let data = { ...state };
          data['articles'] = articles;
          let payload = getPayload(data);

          editOrder(currentEntity._id, payload);

          setTimeout(() => {
            self.getOrderDetails();
          }, 400);
        }}
      >
        {props.t('Update Order Details')}
      </Button>
      <Button
        variant="contained"
        startIcon={<CancelIcon />}
        style={{ margin: 10, float: 'left', backgroundColor: 'white', color: 'red' }}
        onClick={() => window.location.reload()}
      >
        {props.t('Cancel')}
      </Button>
      <Paper style={{ padding: 12, width: '100%' }}>
        {/* <Typography variant="h4">
          {props.t(state.header0)}
        </Typography> */}

        <Typography variant="h4" style={{ paddingBottom: 30, width: '100%' }}>
          {props.t('Order Partner')}
        </Typography>

        <Grid item xs={12} style={{ marginLeft: '10px' }}>
          <ToggleButtonGroup
            value={state.destinationType}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
            disabled={true}
          >
            <ToggleButton
              value="dropshippment"
              classes={{
                root: 'pd-5',
              }}
              disabled={true}
            >
              <Typography variant="subtitle1" className={'font-12'}>
                {props.t('Drop Shipment')}
              </Typography>
            </ToggleButton>
            <ToggleButton
              value="warehouse"
              classes={{
                root: 'pd-5',
              }}
              disabled={true}
            >
              <Typography variant="subtitle1" className={'font-12'}>
                {props.t('Warehouse')}
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        <Grid item xs={12}>
          {state.tile0[0]}
          {self.state.sourceLocations.length > 0 && (
            <Autocomplete
              style={{
                margin: 10,
                minWidth: 300,
                maxWidth: 300,
                display: 'inline-flex',
              }}
              id="combo-box-demo"
              value={self.state.sourceLocation}
              options={self.state.sourceLocations || []}
              onChange={(event, newValue) => {
                handleAutocompleteChange(event, newValue, 'sourceLocation');
              }}
              getOptionLabel={(option) => `${option.name ? `${option.name}, ` : ''}${option.address}`}
              getOptionSelected={(option) => self.state.sourceLocation._id === option._id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={props.t('Source Locations')}
                  autoComplete={`off`}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: `off`,
                  }}
                />
              )}
            />
          )}
          {state.tile0[1]}
          {state.tile0[2]}
        </Grid>
        <Grid item xs={12}>
          {state.tile0[3]}
          {self.state.processorLocations.length > 0 && (
            <Autocomplete
              style={{
                margin: 10,
                minWidth: 300,
                maxWidth: 300,
                display: 'inline-flex',
              }}
              id="combo-box-demo"
              value={self.state.processorLocation}
              options={self.state.processorLocations || []}
              onChange={(event, newValue) => {
                handleAutocompleteChange(event, newValue, 'processorLocation');
              }}
              getOptionLabel={(option) => `${option.name ? `${option.name}, ` : ''}${option.address}`}
              getOptionSelected={(option) => self.state.processorLocation._id === option._id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={props.t('Processor Locations')}
                  autoComplete={`off`}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: `off`,
                  }}
                />
              )}
            />
          )}
          {state.tile0[4]}
          {state.tile0[5]}
        </Grid>
        <br />
      </Paper>
      <Paper style={{ padding: 12, marginTop: 30, width: '100%' }}>
        <Typography variant="h4">{props.t('Transport')}</Typography>
        <Grid item xs={12}>
          {state.tile0[6]}
          {state.tile0[7]}
          {state.tile0[8]}
          {state.tile0[9]}
          {/*</Grid>*/}
          {/*<Grid item xs={12}>*/}
          {state.tile0[10]}
          {state.tile0[11]}
          <Autocomplete
            style={{
              margin: 10,
              minWidth: 200,
              maxWidth: 200,
              display: 'inline-flex',
            }}
            id="tags-outlined"
            options={props.state.vehicleTypes}
            getOptionLabel={(option) => props.t(option)}
            value={props.state.vehicle}
            onChange={(event, value) => handleValueChange(event, value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={props.t('Vehicle')}
                placeholder={props.t('Vehicle')}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: `new-password`,
                }}
              />
            )}
          />
          {state.tile0[13]}
          {state.tile0[14]}
        </Grid>
        <br />
        <Grid item xs={12}>
          {state.tile0[15]}
          {state.tile0[16]}
          {state.tile0[17]}
          {state.tile0[18]}
          {state.tile0[19]}
        </Grid>
        <Grid item xs={12}>
          <span style={{ marginLeft: '292px' }} />
          {state.tile0[20]}
          {state.tile0[21]}
          {state.tile0[22]}
          {state.tile0[23]}
        </Grid>

        <hr style={{ marginTop: 30, width: '95%', color: 'lightgray' }} />
      </Paper>
      {/* <Typography variant="h4" style={{ paddingTop: 30, display: 'inline-block' }}>
        {props.t(state.header3)}
      </Typography> */}
      <Paper style={{ padding: 12, marginTop: 30, width: '100%' }}>
        {state.tile3}
        <Articles
          state={state}
          variant={'edit'}
          order={currentEntity}
          orderType={state.destinationType}
          sourceCountry={
            self.state.sourceLocation
              ? self.state.sourceLocation.country
              : self.state.selectedSource ? self.state.selectedSource.country : ""
          } s
          processorCountry={
            self.state.processorLocation
              ? self.state.processorLocation.country
              : self.state.selectedProcessor ? self.state.selectedProcessor.country : ""
          }
        />
      </Paper>
    </>
  );
}

export default withTranslation('common')(Orders);
