import * as React from 'react';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DatePicker from './DatePicker';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { IntlProvider, load } from '@progress/kendo-react-intl';

import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

load(likelySubtags, currencyData, weekData);

weekData.supplemental.weekData.firstDay.US = 'mon';

class MyComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: {
        start: this.props.dateValue ? moment(this.props.dateValue.startDate).toDate() : null,
        end: this.props.dateValue ? moment(this.props.dateValue.endDate).toDate() : null,
      },
      sourceCollection: '',
    };
  }

  handleSelect = (ranges) => {
    let selection = {
      startDate: moment(ranges.value.start).toISOString(),
      endDate: moment(ranges.value.end).toISOString(),
    };

    this.setState({ selection: selection });
    this.props.callbackValueRange(this.props.dateDbid, selection);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.selection === undefined) {
      this.setState({
        selection: {
          start: moment(this.props.dateValue.startDate).toDate(),
          end: moment(this.props.dateValue.endDate).toDate(),
        },
      });
    }
  }

  render() {
    if (
      this.props.sourceCollection &&
      this.props.sourceCollection.toLowerCase().includes('period')
    ) {
      return (
        <IntlProvider locale={'en-US'}>
          <DateRangePicker
            format="dd.MM.yyyy"
            defaultValue={this.state.selection}
            ranges={this.state.selection}
            onChange={(item) => this.handleSelect(item)}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
          />
        </IntlProvider>
      );
    } else {
      // const date = this.props.dateValue ? new Date(this.props.dateValue) : '';

      return (
        <div style={{ display: 'inline-flex' }}>
          <IntlProvider locale={'en-US'}>
            <DatePicker
              key={this.props.dateLabel}
              id={this.props.dateLabel}
              callbackValue={this.props.callbackDateChange}
              required={false}
              label={this.props.t(this.props.dateLabel)}
              name={this.props.dateDbid}
              dbid={this.props.dateDbid}
              defaultValue={
                this.props.dateValue ? this.props.dateValue.startDate : this.props.dateValue
              }
            />
          </IntlProvider>
        </div>
      );
    }
  }
}

export default withTranslation('common')(MyComponent);
