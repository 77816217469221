import React from "react";
import { fieldPropTypes } from "../../util/proptype-utils";
import { TextField } from '@material-ui/core';

const TextInput = ({ input, meta, id, placeholder, type, label = "", extraClasses = "" }) => (
    <TextField
      {...input}
      id={id}
      placeholder={placeholder}
      type={type}
      style={{width: '100%', fontSize: '14px', fontFamily: 'Poppins'}}
    />
);


TextInput.propTypes = fieldPropTypes;

export default TextInput;
