import _ from "lodash";
import { APP_NAMESPACE } from "../../util/redux-constants";
import { put, post, get, del } from "../../util/http-utils";
import { updateStore, buildGenericInitialState, handleError } from "../../util/store-utils";
import { CHANGE_AUTH, GET_AUTHENTICATED_USER } from "./authentication";
import { CARRIER_TABLE_COLUMNS } from "../../constants/ui-constants";
import { showMessage } from '../store/actions/alerts';

const CARRIER_ENDPOINT_BASE = "carriers";
const typeBase = `${APP_NAMESPACE}/${CARRIER_ENDPOINT_BASE}/`;

// Constants
export const GET_CARRIERS = `${typeBase}GET_CARRIERS`;
export const GET_CARRIER = `${typeBase}GET_CARRIER`;
export const ADD_CARRIER = `${typeBase}ADD_CARRIER`;
export const EDIT_CARRIER = `${typeBase}EDIT_CARRIER`;
export const DELETE_CARRIER = `${typeBase}DELETE_CARRIER`;

// Actions

/**
 * getUser  - Fetches user from API, given id
 *
 * @param {String} id User's id for lookup
 * @returns {Promise}
 */
export const getCarrier = (id) => async (dispatch) => {
  try {
    const response = await get(dispatch, GET_CARRIER, `${CARRIER_ENDPOINT_BASE}/${id}`, true);
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err);
  }
};

/**
 * getUsers  - Fetches users from API
 *
 * @returns {Promise}
 */
export const getCarriers = () => async (dispatch) => {
  try {
    const response = await get(dispatch, GET_CARRIERS, CARRIER_ENDPOINT_BASE, true);
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err, GET_CARRIER);
  }
};

/**
 * register - Creates a new CARRIERS for a user
 * @param {Object} formData  User's form data
 */
export const addCarrier = (formData, callback, currentTabName, currentTabNumber) => async (
  dispatch
) => {
  try {
    let keysToRemove = ['tile0', 'tile1', 'tile2', 'tile3', 'tile4', 'tile5', 'tileData'];

    for (let key of Object.keys(formData)) {
      if (keysToRemove.includes(key)) delete formData[key];
    }

    const response = await post(
      dispatch,
      ADD_CARRIER,
      `${CARRIER_ENDPOINT_BASE}/add`,
      formData,
      false
    );
    callback(
      currentTabName,
      currentTabNumber,
      CARRIER_TABLE_COLUMNS,
      "Added Successfully!",
      response.error
    );
  } catch (err) {
    await handleError(dispatch, err, CHANGE_AUTH);
  }
};

/**
 * Edit CARRIERS - updates a CARRIERS
 * @param {Object} formData  User's form data
 */
export const editCarrier = (id, formData) => async (dispatch) => {
  try {
    if (
      formData.invoiceDeliveryMethod === 'Email' &&
      (!formData.invoiceEmail || formData.invoiceEmail.length === 0)
    ) {
      dispatch(showMessage({
        message: 'invoice_delivery_email_is_required',
        messageType: 'error',
      }));
      return;
    }
    let keysToRemove = ['tile0', 'tile1', 'tile2', 'tile3', 'tile4', 'tile5', 'tileData'];

    for (let key of Object.keys(formData)) {
      if (keysToRemove.includes(key)) delete formData[key];
    }

    const response = await put(
      dispatch,
      EDIT_CARRIER,
      `${CARRIER_ENDPOINT_BASE}/${id}`,
      formData,
      false
    );

    // If the registration was successful, set the JWT as a cookie
    if (response.CarrierUpdated) {
      dispatch(showMessage({ message: "Edited CARRIER Successfully", messageType: "success" }))
    } else {
      dispatch(showMessage({ message: response.error, messageType: "error" }))
    }
  } catch (err) {
    await handleError(dispatch, err, CHANGE_AUTH);
  }
};

/**
 * Delete CARRIERS - updates a CARRIERS
 * @param {Object} formData  User's form data
 */
export const deleteCarrier = (id, callback) => async (dispatch) => {
  try {
    console.log("DELETING CARRIER!!");

    const response = await del(dispatch, DELETE_CARRIER, `${CARRIER_ENDPOINT_BASE}/${id}`, true);
    console.log(response);

    // If the registration was successful, set the JWT as a cookie
    if (response) {
      callback("Deleted CARRIER Successfully");
    }
  } catch (err) {
    await handleError(dispatch, err, CHANGE_AUTH);
  }
};

// Store
const INITIAL_STATE = {
  ...buildGenericInitialState([GET_CARRIER, GET_CARRIERS]),
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_AUTH:
      return updateStore(
        state,
        action,
        _.get(action, "payload.user.id") ? { [action.payload.user.id]: action.payload.user } : {}
      );
    case GET_CARRIER:
    case GET_AUTHENTICATED_USER:
      return updateStore(
        state,
        action,
        _.get(action, "payload.user.id") ? { [action.payload.user.id]: action.payload.user } : {}
      );
    case GET_CARRIERS:
      return updateStore(
        state,
        action,
        _.get(action, "payload.users") ? _.mapKeys(action.payload.users, "id") : {}
      );
    default:
      return state;
  }
};

// Selectors
export const getAuthenticatedUser = ({ user, authentication }) => user[authentication.user];
