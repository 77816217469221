import { APP_NAMESPACE } from "../../util/redux-constants";
import { put, post, get } from "../../util/http-utils";
import { handleError } from "../../util/store-utils";

const TUNING_ENDPOINT_BASE = "tuning";
const typeBase = `${APP_NAMESPACE}/${TUNING_ENDPOINT_BASE}/`;

export const GET_TUNING = `${typeBase}GET_TUNING`;
export const SAVE_TUNING = `${typeBase}SAVE_TUNING`;
export const CONFIRM = `${typeBase}CONFIRM`;

export const fetch = (id) => async (dispatch) => {
  try {
    const response = await get(dispatch, GET_TUNING, `${TUNING_ENDPOINT_BASE}/${id}`, true);
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err);
  }
};

export const save = (payload) => async (dispatch) => {
  try {
    const response = await post(
      dispatch,
      SAVE_TUNING,
      `${TUNING_ENDPOINT_BASE}/save`,
      payload,
      true
    );
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err);
  }
};

export const confirm = (id) => async (dispatch) => {
  try {
    const response = await put(
      dispatch,
      CONFIRM,
      `${TUNING_ENDPOINT_BASE}/confirm/${id}`,
      {},
      true
    );
    return Promise.resolve(response);
  } catch (err) {
    await handleError(dispatch, err);
  }
};

export const getAuthenticatedUser = ({ user, authentication }) => user[authentication.user];
