import { GET_USER_DETAILS, RESET, SETTINGS } from '../actions/common';

const initialState = {
  user: {},
};

const common = function (state = initialState, action) {
  switch (action.type) {
    case RESET: {
      return {
        ...state,
        user: {},
        settings: {},
      };
    }
    case GET_USER_DETAILS: {
      return {
        ...state,
        user: action.payload.user,
      };
    }
    case SETTINGS: {
      return {
        ...state,
        settings: action.payload ? action.payload.data : {},
      };
    }
    default: {
      return state;
    }
  }
};

export default common;
