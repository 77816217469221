import * as Actions from '../actions/persons';

const initialState = {
  form: {
    emails: [],
  },
};

const persons = function (state = initialState, action) {
  switch (action.type) {
    case Actions.RESET: {
      return {
        ...state,
        form: {
          emails: [],
        },
      };
    }
    case Actions.SET_EMAIL: {
      return {
        ...state,
        form: {
          ...state.form,
          emails: action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default persons;
