import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 10,
    minWidth: 140,
    maxWidth: 300,
    display: "inline-flex",
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
  },
}));

function SimpleSelect(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState("");

  if (value === "Extern") {
    props.setExtern();
  }

  if (props.isEdit) {
    return (
      <Autocomplete
        id="combo-box-demo"
        style={{
          margin: 10,
          minWidth: props.width ? props.width : 300,
          maxWidth: props.width ? props.width : 300,
          display: "inline-flex",
        }}
        defaultValue={props.value}
        options={props.options}
        onChange={(event, newValue) => {
          props.callbackValue(props.dbid, newValue);
          setValue(newValue);
        }}
        getOptionLabel={(option) => props.t(option)}
        className={classes.formControl}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.t(props.label)}
            required={props.required}
            inputProps={{
              ...params.inputProps,
              autoComplete: `new-password`,
            }}
          />
        )}
      />
    );
  } else {
    return (
      <Autocomplete
        style={{
          margin: 10,
          minWidth: props.width ? props.width : 300,
          maxWidth: props.width ? props.width : 300,
          display: "inline-flex",
        }}
        id="combo-box-demo"
        defaultValue={props.value}
        options={props.options}
        getOptionLabel={(option) => props.t(option)}
        onChange={(event, newValue) => {
          props.callbackValue(props.dbid, newValue);
          setValue(newValue);
        }}
        className={classes.formControl}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.t(props.label)}
            required={props.required}
            inputProps={{
              ...params.inputProps,
              autoComplete: `new-password`,
            }}
          />
        )}
      />
    );
  }
}

export default withTranslation("common")(SimpleSelect);
