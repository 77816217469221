import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { defaultPermissions } from '../../helpers/permissions';

export default function PermissionsSelect(props) {
  const { self, variant } = props;
  const [userPermissions, setUserPermissions] = useState(defaultPermissions);
  const [firstLoad, setFirstLoad] = useState(false);

  useEffect(() => {
    if (!firstLoad) {
      if (Object.keys(self.state.permissions).length > 0 && variant === 'edit') {
        setFirstLoad(true);
        setUserPermissions({ ...userPermissions, ...self.state.permissions });
      } else {
        setFirstLoad(true);
        setUserPermissions({ ...userPermissions, ...defaultPermissions });
      }
    }
  }, [Object.keys(self.state.permissions).length, variant, firstLoad]);

  function handlePermissionChange(event) {
    let permissions = userPermissions;
    permissions[event.target.name] = event.target.checked;

    setUserPermissions({ ...userPermissions, ...permissions });
    self.setState({ permissions: permissions });
  }
  function changeLabel(name) {
    let newName = name.split(" ")
    return self.props.t(newName[0]) + ' '+self.props.t(newName[1]);
  }
  return (
    <div>
      <Typography variant="h4" style={{ paddingTop: 30, paddingBottom: 15 }}>
        Permissions
      </Typography>
      <Grid container spacing={3}>
        {permissions.map((permission, index) => (
          <Grid item xs={12} sm={4} md={3} lg={3} key={index}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={userPermissions[permission.value]}
                  onChange={handlePermissionChange}
                  name={permission.value}
                  color="primary"
                />
              }
              label={changeLabel(permission.title)}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

const permissions = [
  { title: 'Carriers (CREATE)', value: 'carriers_create' },
  { title: 'Carriers (READ)', value: 'carriers_read' },
  { title: 'Carriers (UPDATE)', value: 'carriers_update' },
  { title: 'Carriers (DELETE)', value: 'carriers_delete' },

  { title: 'Finance (READ)', value: 'finance_read' },

  { title: 'Invoice (CREATE)', value: 'invoice_create' },
  { title: 'Invoice (READ)', value: 'invoice_read' },

  { title: 'Orders (CREATE)', value: 'orders_create' },
  { title: 'Orders (READ)', value: 'orders_read' },
  { title: 'Orders (UPDATE)', value: 'orders_update' },
  { title: 'Orders (DELETE)', value: 'orders_delete' },

  { title: 'Partners (CREATE)', value: 'partners_create' },
  { title: 'Partners (READ)', value: 'partners_read' },
  { title: 'Partners (UPDATE)', value: 'partners_update' },
  { title: 'Partners (DELETE)', value: 'partners_delete' },

  { title: 'Contracts (CREATE)', value: 'contracts_create' },
  { title: 'Contracts (READ)', value: 'contracts_read' },
  { title: 'Contracts (UPDATE)', value: 'contracts_update' },
  { title: 'Contracts (DELETE)', value: 'contracts_delete' },

  { title: 'Tuning (READ)', value: 'tuning_read' },
  { title: 'Tuning (UPDATE)', value: 'tuning_update' },

  { title: 'Weightslips (READ)', value: 'weightSlips_read' },
  { title: 'Weightslips (UPDATE)', value: 'weightSlips_update' },

  { title: 'Stocks', value: 'stocks_read' },
  { title: 'System', value: 'system_read' },

  { title: 'Company', value: 'company_read' },
];
