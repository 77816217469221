import { get } from '../../../util/http-utils';
import { handleError } from '../../../util/store-utils';

export const GET_USER_DETAILS = '[COMMON] GET_USER_DETAILS';
export const RESET = '[COMMON] RESET';
export const FILTER_DATA = '[COMMON] FILTER DATA';
export const SETTINGS = '[COMMON] SETTINGS';


export function reset() {
  return {
    type: RESET,
  };
}

export const fetchUserDetails = () => async (dispatch) => {
  try {
    const response = await get(dispatch, RESET, `${'user/info'}`, true);
    dispatch({ type: GET_USER_DETAILS, payload: response });
    return response
  } catch (err) {
    await handleError(dispatch, err, GET_USER_DETAILS);
  }
};

export const fetchSettings = () => async (dispatch) => {
  try {
    const response = await get(dispatch, SETTINGS, `${'user/settings'}`, true);
    dispatch({ type: SETTINGS, payload: response });
    return response
  } catch (err) {
    await handleError(dispatch, err, SETTINGS);
  }
};

