import { APP_NAMESPACE } from '../../util/redux-constants';
import { get, post } from '../../util/http-utils';
import { handleError } from '../../util/store-utils';
import { showMessage } from '../store/actions/alerts';

const TAX_ENDPOINT_BASE = 'tax';
const typeBase = `${APP_NAMESPACE}/${TAX_ENDPOINT_BASE}`;

export const GET_TAX = `${typeBase}GET_TAX`;
export const POST_TAX = `${typeBase}POST_TAX`;
export const RESET = `${typeBase}RESET`;

export const fetchTaxes = () => async (dispatch) => {
    const response = await get(dispatch, GET_TAX, `${TAX_ENDPOINT_BASE}`, true);
    dispatch({ type: "GET_TAX", payload: response });

};

export const importTax = (payload) => async (dispatch) => {
  try {
    const response = await post(dispatch, POST_TAX, `${TAX_ENDPOINT_BASE}/import`, payload,true);
    dispatch(showMessage({ message: "Tax Imported Successfully", messageType: "success" }))
  } catch (err) {
    await handleError(dispatch, err);
  }
};

export const reset = () => {
  return {
    type: RESET
  }
}

export const getAuthenticatedUser = ({ user, authentication }) => user[authentication.user];
